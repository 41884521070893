import { ApolloError, useMutation } from "@apollo/client";
import { FunctionComponent, useEffect, useState } from "react";
import { ACCEPT_TOS } from "../graphql/Mutations/Companies";

export const DisableTOS: FunctionComponent = () => {
    const [acceptTOS] = useMutation(ACCEPT_TOS, {
        onCompleted: () => acceptTOSCompleted(),
        onError: (errorInput) => acceptTOSError(errorInput),
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<ApolloError>();

    useEffect(() => {
        void acceptTOS({ variables: {} });
    }, [acceptTOS]);

    const acceptTOSCompleted = () => {
        setSuccess(true);
    };

    const acceptTOSError = (errorInput: ApolloError) => {
        setError(errorInput);
    };

    return success ? <h1>TOS DISABLED</h1> : <h1>Waiting to disable {JSON.stringify(error)}</h1>;
};
