import { Component } from "react";
import { Link, match } from "react-router-dom";
import { Query } from "@apollo/client/react/components";
import { FetchMoreOptions, QueryResult } from "@apollo/client";
import { debounce } from "lodash";
import styled, { Button, Checkbox, GrabCADApplicationColors } from "grabcad-ui-elements";
import { IScreenProps } from "../../screens/Screen";
import { PaginationLinks } from "../../view/Navigation/PaginationLinks";
import type { CompanyData, CompanyTOSState } from "../../graphql";
import { Notifier } from "../../view/Notifier";
import { LIST_COMPANIES } from "../../graphql/Queries/Companies/ListCompanies";

const COMPANIES_PER_PAGE = 20;

const TOSHeader = styled.th`
    width: 10%;
`;

const HighlightRow = styled.tr`
    &:hover {
        background-color: #deeeff;
    }
`;

export const HighlightEntry = styled.td`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: #4183c4;
    }
`;

export const tosColors = {
    ACCEPTED: GrabCADApplicationColors.GC_SUCCESS_SEMANTIC,
    SEEN: GrabCADApplicationColors.GC_WARNING_SEMANTIC,
    NOT_SEEN: GrabCADApplicationColors.GC_ERROR_SEMANTIC,
} as const;

export const tosNames = {
    ACCEPTED: "Accepted",
    SEEN: "Seen",
    NOT_SEEN: "Not Seen",
} as const;

type ListCompaniesResponse = {
    totalCount: number;
    companies: {
        id: number;
        name: string;
        urlPrefix?: string;
        tosState: CompanyTOSState;
        admins: {
            email: string;
        }[];
        resellerInfo?: {
            accountNumber?: string;
            contactPerson?: string;
            email?: string;
            name?: string;
            phoneNumber?: string;
        };
        customerInfo?: {
            accountNumber?: string;
            email?: string;
            name?: string;
            phoneNumber?: string;
        };
    }[];
};

type ExistingCompanyData = CompanyData & { id: number };

interface ICompanyListProps extends IScreenProps {
    match: match<{ id?: string }>;
}
interface ICompanyListState {
    companyToDelete?: ExistingCompanyData;
    searchString?: string;
    page: number;
    isExternalCompany: boolean;
    hasActiveLicenses: boolean;
}

export class CompanyList extends Component<ICompanyListProps, ICompanyListState> {
    constructor(props: ICompanyListProps) {
        super(props);
        this.state = {
            searchString: "",
            page: 1,
            isExternalCompany: true,
            hasActiveLicenses: true,
        };
    }

    /**
     * Update search string (query/filter).
     *
     * @param {string} filter search filter
     */
    private updateSearchString = debounce(
        (filter: string) =>
            this.setState({
                searchString: filter,
                page: 1,
            }),
        400
    );

    /**
     * Handle click event on page number button (fetch corresponding page of data from server).
     *
     * @param {(args: FetchMoreOptions & { variables: { skip: number } })} fetchMore Apollo Client `fetchMore` function
     * @param {number} page page number
     */
    private fetchPageFromServer = (
        fetchMore: (args: FetchMoreOptions & { variables: { skip: number } }) => void,
        page: number
    ) =>
        fetchMore({
            variables: {
                skip: COMPANIES_PER_PAGE * (page - 1),
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return previousResult;
                }
                return {
                    ...previousResult,
                    listCompanies: {
                        totalCount: fetchMoreResult.listCompanies.totalCount,
                        companies: [...fetchMoreResult.listCompanies.companies],
                    },
                };
            },
        });

    render() {
        return (
            <Query
                query={LIST_COMPANIES}
                variables={{
                    skip: COMPANIES_PER_PAGE * (this.state.page - 1),
                    take: COMPANIES_PER_PAGE,
                    search: this.state.searchString?.toLowerCase(),
                    isExternalCompany: this.state.isExternalCompany,
                    hasActiveLicenses: this.state.hasActiveLicenses,
                }}
                fetchPolicy="no-cache"
            >
                {({
                    loading,
                    error,
                    data,
                    fetchMore,
                }: QueryResult<{ listCompanies?: ListCompaniesResponse }>) => {
                    if (error) {
                        Notifier.error(error);
                        return null;
                    }

                    const { listCompanies } = data || {};

                    let companies: ListCompaniesResponse["companies"] | undefined;
                    let companyCount: number | undefined;
                    let filteredCompanyCount: number | undefined;
                    if (listCompanies) {
                        companies = [...listCompanies.companies];
                        companyCount = listCompanies.totalCount;
                        filteredCompanyCount = companies.length;
                    }

                    return (
                        <div>
                            <Button
                                id="qa-companyList-newCompany"
                                className="ui button primary right floated"
                                type="submit"
                                onClick={() => this.props.history?.push("company/new")}
                            >
                                New Company
                            </Button>
                            <Button
                                id="qa-companyList-newCompanyFromWB"
                                className="ui button primary right floated"
                                type="submit"
                                onClick={() => this.props.history?.push("company/wb")}
                            >
                                Create from WB
                            </Button>
                            <div
                                className="ui left icon input"
                                style={{ alignItems: "center", marginBottom: "1rem" }}
                            >
                                <i className="search icon" />
                                <input
                                    id="qa-companyList-search"
                                    type="text"
                                    placeholder="Search..."
                                    defaultValue={this.state.searchString}
                                    onChange={(event) =>
                                        this.updateSearchString(event.target.value)
                                    }
                                />

                                <Checkbox
                                    className="qa-companyList-active-checkbox"
                                    label={"Companies with Active Licenses"}
                                    style={{ marginLeft: "1rem" }}
                                    onChange={() => {
                                        this.setState({
                                            hasActiveLicenses: !this.state.hasActiveLicenses,
                                        });
                                    }}
                                    checked={this.state.hasActiveLicenses}
                                />

                                <Checkbox
                                    className="qa-companyList-external-checkbox"
                                    label={"External Companies"}
                                    style={{ marginLeft: "2rem" }}
                                    onChange={() => {
                                        this.setState({
                                            isExternalCompany: !this.state.isExternalCompany,
                                        });
                                    }}
                                    checked={this.state.isExternalCompany}
                                />
                            </div>

                            <h2 className="page-header">
                                Companies (
                                {filteredCompanyCount !== companyCount
                                    ? `${filteredCompanyCount} of ${companyCount}`
                                    : companyCount}
                                )
                            </h2>

                            <table className="ui table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Actions</th>
                                        <TOSHeader>Terms of Service</TOSHeader>
                                    </tr>
                                </thead>

                                {loading || !data ? (
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="ui loader active" />
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <>
                                        <tbody>
                                            {companies!.map((company) => (
                                                <HighlightRow key={company.id}>
                                                    <HighlightEntry
                                                        data-label="Name"
                                                        className="custom-link-entry"
                                                    >
                                                        <Link
                                                            className="custom-link"
                                                            to={`company/${company.id}`}
                                                        >
                                                            {company.name}
                                                        </Link>
                                                    </HighlightEntry>
                                                    <td data-label="Actions">
                                                        <Link
                                                            id={`qa-companyList-assignLicense_${company.id}`}
                                                            className="qa-companyList-assignLicense"
                                                            to={`/license/assign/${company.id}`}
                                                        >
                                                            Assign License
                                                        </Link>
                                                    </td>
                                                    <td data-label="Terms of Service">
                                                        <b
                                                            style={{
                                                                color: tosColors[company.tosState],
                                                            }}
                                                        >
                                                            {tosNames[company.tosState]}
                                                        </b>
                                                    </td>
                                                </HighlightRow>
                                            ))}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <th colSpan={3}>
                                                    <PaginationLinks
                                                        total={listCompanies!.totalCount}
                                                        perPage={COMPANIES_PER_PAGE}
                                                        currentPage={this.state.page}
                                                        goToPage={(page) => {
                                                            this.setState({ page });
                                                        }}
                                                        fetchPageFromServer={(page: number) =>
                                                            this.fetchPageFromServer(
                                                                fetchMore,
                                                                page
                                                            )
                                                        }
                                                    />
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </>
                                )}
                            </table>
                        </div>
                    );
                }}
            </Query>
        );
    }
}
