/* eslint-disable max-classes-per-file */
import gql from "graphql-tag";
import React from "react";
import { Mutation } from "@apollo/client/react/components";
import { ApolloError, MutationFunction, MutationOptions, MutationResult } from "@apollo/client";

export const CREATE_COMPANY_ACCESS_OPTION = gql`
    mutation createCompanyAccessOption(
        $urls: [UrlData!]
        $companyId: Int!
        $groupIdsToAdd: [Int!]!
    ) {
        createCompanyAccessOption(
            urls: $urls
            companyId: $companyId
            groupIdsToAdd: $groupIdsToAdd
        ) {
            accessOptions {
                id
                url
                state
                groups {
                    id
                    name
                }
            }
        }
    }
`;

export const DELETE_COMPANY_ACCESS_OPTION = gql`
    mutation deleteCompanyAccessOption($id: Int!, $companyId: Int!) {
        deleteCompanyAccessOption(id: $id, companyId: $companyId)
    }
`;

export const UPDATE_COMPANY_ACCESS_OPTION = gql`
    mutation updateCompanyAccessOptionState($id: Int!, $newState: String!) {
        updateCompanyAccessOptionState(id: $id, newState: $newState) {
            id
            url
            state
        }
    }
`;

export const UPDATE_COMPANY_ACCESS_OPTION_GROUPS = gql`
    mutation updateCompanyAccessOptionGroups(
        $id: Int!
        $companyId: Int!
        $groupIdsToAdd: [Int!]
        $groupIdsToRemove: [Int!]
    ) {
        updateCompanyAccessOptionGroups(
            id: $id
            companyId: $companyId
            groupIdsToAdd: $groupIdsToAdd
            groupIdsToRemove: $groupIdsToRemove
        ) {
            id
            url
            groups {
                id
                name
            }
        }
    }
`;

export interface CreateAccessOptionVariables {
    urls: { url: string; isUrlGenerated: boolean }[];
    companyId: number;
    groupIdsToAdd: number[];
}

export interface CreateAccessOptionResult {
    accessOptions: {
        id: number;
        url: string;
        state: string;
        groups: { id: number; name: string }[];
    }[];
}

export interface CreateAccessOptionProps
    extends Omit<
        MutationOptions<CreateAccessOptionResult, CreateAccessOptionVariables>,
        "mutation"
    > {
    children: (
        mutateFn: MutationFunction<CreateAccessOptionResult, CreateAccessOptionVariables>,
        result: MutationResult<CreateAccessOptionResult>
    ) => JSX.Element;
    onError?: (error: ApolloError) => void;
}

export class CreateAccessOptionMutation extends React.Component<CreateAccessOptionProps> {
    render() {
        const { children, ...props } = this.props;

        return (
            <Mutation<CreateAccessOptionResult, CreateAccessOptionVariables>
                mutation={CREATE_COMPANY_ACCESS_OPTION}
                {...props}
            >
                {children}
            </Mutation>
        );
    }
}

export interface DeleteAccessOptionMutationVariables {
    id: number;
    companyId: number;
}

export interface DeleteAccessOptionMutationProps
    extends Omit<MutationOptions<boolean, DeleteAccessOptionMutationVariables>, "mutation"> {
    children: (
        mutateFn: MutationFunction<boolean, DeleteAccessOptionMutationVariables>,
        result: MutationResult<boolean>
    ) => JSX.Element;
    onError?: (error: ApolloError) => void;
}

export class DeleteAccessOptionMutation extends React.Component<DeleteAccessOptionMutationProps> {
    render() {
        const { children, variables, ...props } = this.props;
        return (
            <Mutation<boolean, DeleteAccessOptionMutationVariables>
                mutation={DELETE_COMPANY_ACCESS_OPTION}
                variables={variables}
                {...props}
            >
                {children}
            </Mutation>
        );
    }
}

export interface UpdateCompanyAccessOptionGroupsVariables {
    id: number;
    companyId: number;
    groupIdsToAdd?: number[];
    groupIdsToRemove?: number[];
}

export interface UpdateCompanyAccessOptionGroupsResult {
    id: number;
    url: string;
    state: string;
    groups: { id: number; name: string }[];
}

export interface UpdateCompanyAccessOptionGroupsProps
    extends Omit<
        MutationOptions<
            UpdateCompanyAccessOptionGroupsResult,
            UpdateCompanyAccessOptionGroupsVariables
        >,
        "mutation"
    > {
    children: (
        mutateFn: MutationFunction<
            UpdateCompanyAccessOptionGroupsResult,
            UpdateCompanyAccessOptionGroupsVariables
        >,
        result: MutationResult<UpdateCompanyAccessOptionGroupsResult>
    ) => JSX.Element;
    onError?: (error: ApolloError) => void;
}

export class UpdateCompanyAccessOptionGroupsMutation extends React.Component<UpdateCompanyAccessOptionGroupsProps> {
    render() {
        const { children, variables, ...props } = this.props;
        return (
            <Mutation<
                UpdateCompanyAccessOptionGroupsResult,
                UpdateCompanyAccessOptionGroupsVariables
            >
                mutation={UPDATE_COMPANY_ACCESS_OPTION_GROUPS}
                variables={variables}
                {...props}
            >
                {children}
            </Mutation>
        );
    }
}
