import { Button, Header, Modal } from "grabcad-ui-elements";
import { Notifier } from "../Notifier";
import { useDeleteCompanies } from "../../graphql/Mutations/Companies";

interface Props {
    ids: number[];
    hardDelete: boolean;
    open: boolean;
    onClose: () => void;
    update: () => void;
}

export const DeleteCompaniesConfirmation = (props: Props) => {
    const [update] = useDeleteCompanies({
        update: props.update,
        onError: (error) => Notifier.error(error),
        variables: {
            ids: props.ids,
            hard: props.hardDelete,
        },
    });

    return (
        <Modal basic size="small" open={props.open} onClose={props.onClose}>
            <Header icon="trash" content="Delete the following companies?" />
            <Modal.Content>
                <h3>{`${props.ids.length} companies selected`}</h3>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={props.onClose}>
                    Cancel
                </Button>
                <Button
                    negative
                    onClick={() => {
                        void update();
                        props.onClose();
                    }}
                >
                    Delete
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
