import { FunctionComponent, useContext } from "react";
import { ApolloCache, Context, InMemoryCache, MutationUpdaterFunction } from "@apollo/client";
import { Button, Header, Modal } from "grabcad-ui-elements";
import { Notifier } from "../Notifier";
import {
    RemoveUserFromGroupsMutation,
    RemoveUserFromGroupsResult,
    RemoveUserFromGroupsVariables,
} from "../../graphql/Mutations/Users/RemoveUserFromGroups";
import { IScreenProps } from "../../screens/Screen";
import { PATHS } from "../../Routes";
import { ApplicationContext } from "../../components/ApplicationProvider";

export interface RemoveUserFromGroupsProps extends IScreenProps {
    email: string;
    onUpdate?: MutationUpdaterFunction<
        RemoveUserFromGroupsResult,
        RemoveUserFromGroupsVariables,
        Context,
        ApolloCache<InMemoryCache>
    >;
    open: boolean;
    groupIdsToRemove: number[];
    groupNamesToRemove: string[];
    onClose(): void;
}

export const RemoveUserFromGroupsConfirmationModal: FunctionComponent<RemoveUserFromGroupsProps> = (
    props
) => {
    const { t } = useContext(ApplicationContext);

    return (
        <RemoveUserFromGroupsMutation
            onError={(error) => Notifier.error(error)}
            update={props.onUpdate}
        >
            {(MutationFunction) => {
                const groupNames = props.groupNamesToRemove.join(", ");

                return (
                    <Modal basic size="small" open={props.open} onClose={props.onClose}>
                        <Header
                            icon="trash"
                            content={t("remove_user_from_groups.are_you_sure", {
                                email: props.email,
                            })}
                        />{" "}
                        <Modal.Content>
                            <p>{t("remove_user_from_groups.explanation")}</p>{" "}
                            <p>
                                {t("remove_user_from_groups.groups", {
                                    group_names: groupNames,
                                })}
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    id="qa-removeUserFromGroupsConfirmation-cancel"
                                    secondary
                                    onClick={props.onClose}
                                >
                                    {t("forms.cancel")}
                                </Button>
                                <Button
                                    id="qa-removeUserFromGroupsConfirmation"
                                    secondary
                                    onClick={() =>
                                        props.history.push(`${PATHS.user}/${props.email}`)
                                    }
                                >
                                    {" "}
                                    {t("remove_user_from_groups.user_details")}
                                </Button>
                                <Button
                                    id="qa-removeUserFromGroupsConfirmation-remove"
                                    negative
                                    onClick={() => {
                                        MutationFunction({
                                            variables: {
                                                email: props.email,
                                                groupIdsToRemove: props.groupIdsToRemove,
                                            },
                                        })
                                            .then(() => props.onClose())
                                            .catch(Notifier.error);
                                    }}
                                >
                                    {t("remove_user_from_groups.yes_remove")}
                                </Button>
                            </div>
                        </Modal.Actions>
                    </Modal>
                );
            }}
        </RemoveUserFromGroupsMutation>
    );
};
