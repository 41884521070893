import _ from "lodash";

export type IsEqualFn<T> = (a: T, b: T) => boolean;

const isEqualComparator = (value: any, other: any): boolean | undefined => {
    if (value === other) {
        return true;
    }
    // treat null and undefined and empty string as equal to each other
    if (
        (_.isNull(value) || _.isUndefined(value) || value === "") &&
        (_.isNull(other) || _.isUndefined(other) || other === "")
    ) {
        return true;
    }
    if (Array.isArray(value)) {
        if (value.length !== other.length) {
            return false;
        }
        let otherSorted = other.slice().sort();
        return value
            .slice()
            .sort()
            .every((v, i) => v === otherSorted[i]);
    }
    return undefined; // NB: undefined means "use default method"
};

export const deepEquals = (left: any, right: any) => _.isEqualWith(left, right, isEqualComparator);

/**
 * Identifies duplicate items in an array.
 *
 * @param items items with possible duplicates
 * @param areItemsEqual defines what it means for two items to be duplicates
 * @returns a boolean[] with the same length as `items`. Each boolean corresponds to an item and
 * is set to true if that item is a duplicate, and false if not.
 */
export function identifyDuplicates<T>(items: T[], areItemsEqual: IsEqualFn<T>): boolean[] {
    const out = new Array<boolean>(items.length).fill(false);

    for (let i = 0; i < items.length; i++) {
        if (out[i]) {
            // Skip duplicates that are already marked
            continue;
        }

        for (let j = i + 1; j < items.length; j++) {
            if (out[j]) {
                continue;
            }

            if (areItemsEqual(items[i], items[j])) {
                out[i] = true;
                out[j] = true;
            }
        }
    }

    return out;
}
