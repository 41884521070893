import { InMemoryCache, GraphQLRequest, HttpLink, ApolloClient, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { config } from "./config";
import { v4 } from "uuid";

const cache = new InMemoryCache();

const companyQueryParam = () => {
    const hostname = window.location.hostname;
    const hostParts = hostname.split(".");
    return hostParts.length > 1 ? `&company=${hostParts[0]}` : "";
};

const redirectToLogin = () => {
    let currentUrl = window.location.href;
    window.location.assign(
        `${config.REACT_APP_LOGIN_URL}?return_to=${currentUrl}${companyQueryParam()}`
    );
};

const errorLink = onError(({ graphQLErrors, forward, operation }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            if (err.extensions?.code) {
                if (err.extensions.code === "UNAUTHENTICATED") {
                    // TODO: Move this constant to a shared location GC-77462
                    redirectToLogin();
                }
            }
        }
    }
    return forward(operation);
});

// Must match @grabcad/cls-middleware NPM module
const X_REQUEST_HEADER_NAME = "X-Request-ID";

function nextRequestId() {
    return `c:${v4().replace(/-/g, "")}`;
}

const customHdrLink = setContext((op: GraphQLRequest, prevCtx: any) => ({
    headers: {
        // Include request ID to help debug requests end-to-end between client + server
        [X_REQUEST_HEADER_NAME]: nextRequestId(),
        ...prevCtx.headers,
    },
}));

const apiLink = new HttpLink({ uri: config.REACT_APP_API_URL, credentials: "include" });

export const GraphQLClient = new ApolloClient({
    cache,
    link: from([errorLink, customHdrLink, apiLink]),
    connectToDevTools: true,
});
