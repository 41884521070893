import { CompanyAccessOption, Role } from "../graphql";
import React from "react";
import { Link } from "react-router-dom";
import { IScreenProps } from "../screens/Screen";
import { ApplicationContext } from "./ApplicationProvider";
import { editAccessOptionPath, PATHS } from "../Routes";
import { Notifier } from "../view/Notifier";
import {
    TableHeaderNoWrap,
    TableDataNoWrap,
    LIGHT_GREEN,
    LIGHT_RED,
    LIGHT_YELLOW,
    TRANSPARENT,
} from "./Styles";
import styled, { Button, Image } from "grabcad-ui-elements";
import {
    DeleteAccessOptionModal,
    UpdateAccessOptionModal,
} from "../screens/Company/CompanyAccessOptionsModals";
import { UPDATE_COMPANY_ACCESS_OPTION } from "../graphql/Mutations/CompanyAccessOptions";
import { HighlightEntry } from "../screens/Company/CompanyList";
import EditSvg from "../images/edit.svg";
import TrashSvg from "../images/trash.svg";

const Icon = styled(Image)`
    display: inline !important;
    margin-right: 5px;
    height: 1.35em;
    width: 1.35em;

    :hover {
        height: 1.55em;
    }
`;

export interface CompanyAccessOptionsTableProps extends IScreenProps {
    accessOptions: CompanyAccessOption[];
    role?: string;
    refetch: any;
    companyId?: number; // Include company column if companyId is not provided
}

interface CompanyAccessOptionsTableState {
    accessOptionToDelete: number;
    accessOptionToApprove: number;
    accessOptionToReject: number;
    shouldRenderSameDomain: boolean;
}

export class CompanyAccessOptionsTable extends React.Component<
    CompanyAccessOptionsTableProps,
    CompanyAccessOptionsTableState
> {
    static contextType = ApplicationContext;
    context!: React.ContextType<typeof ApplicationContext>;

    constructor(props: CompanyAccessOptionsTableProps) {
        super(props);
        this.state = {
            accessOptionToDelete: 0,
            accessOptionToApprove: 0,
            accessOptionToReject: 0,
            shouldRenderSameDomain: this.shouldRenderSameDomain(this.props.accessOptions),
        };
    }

    shouldRenderSameDomain(accessOptions: CompanyAccessOption[]) {
        return (
            this.props.role === Role.GlobalAdmin &&
            !!accessOptions.find(
                (option) =>
                    option.companyWithSameUrl &&
                    option.companyWithSameUrl.id !== this.getCompanyId(option)
            )
        );
    }

    getCompanyId(accessOption: CompanyAccessOption) {
        if (this.props.companyId !== undefined) {
            return this.props.companyId;
        }

        return accessOption.company.id;
    }

    renderCompanyHeader() {
        const { t } = this.context;

        if (this.props.companyId !== undefined) {
            return <></>;
        }

        return <TableHeaderNoWrap>{t("company.breadcrumb")}</TableHeaderNoWrap>;
    }

    renderCompanyColumnData(accessOption: CompanyAccessOption) {
        if (this.props.companyId !== undefined) {
            return <></>;
        }

        return (
            <HighlightEntry
                data-label="Company"
                onClick={() =>
                    this.props.history?.push(`${PATHS.company}/${accessOption.company.id}`)
                }
            >
                {accessOption.company.name}
            </HighlightEntry>
        );
    }

    getEditOptionLink(accessOption: CompanyAccessOption) {
        return editAccessOptionPath(accessOption.id);
    }

    renderActionColumnData(accessOption: CompanyAccessOption) {
        return (
            <TableDataNoWrap data-label="Action">
                {this.props.role !== Role.GlobalAdmin && (
                    <Link
                        id={`qa-companyAccessOptionsTable-edit_${accessOption.id}`}
                        className="qa-companyAccessOptionsTable-edit"
                        to={`${this.getEditOptionLink(accessOption)}?return_to=${
                            PATHS.domainRequests
                        }`}
                    >
                        <Icon src={EditSvg} />
                    </Link>
                )}
                <a
                    id={`qa-companyAccessOptionsTable-removeEnabled_${accessOption.id}`}
                    className="qa-companyAccesOptionsTable-removeEnabled"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ accessOptionToDelete: accessOption.id })}
                >
                    <Icon src={TrashSvg} />
                </a>
                {this.renderGlobalAdminActions(accessOption)}
            </TableDataNoWrap>
        );
    }

    renderGlobalAdminActions(accessOption: CompanyAccessOption) {
        if (this.props.role === Role.GlobalAdmin) {
            const { t } = this.context;
            return (
                <>
                    {" "}
                    |{" "}
                    <a
                        id={`qa-companyAccessOptionsTable-approveEnabled_${accessOption.id}`}
                        className="qa-companyAccesOptionsTable-approveEnabled"
                        style={{ cursor: "pointer", color: LIGHT_GREEN, fontWeight: "bold" }}
                        onClick={() => this.setState({ accessOptionToApprove: accessOption.id })}
                    >
                        {t("users.invitation_settings.approve")}
                    </a>{" "}
                    |{" "}
                    <a
                        id={`qa-companyAccessOptionsTable-rejectEnabled_${accessOption.id}`}
                        className="qa-companyAccesOptionsTable-rejectEnabled"
                        style={{ cursor: "pointer", color: LIGHT_RED, fontWeight: "bold" }}
                        onClick={() => this.setState({ accessOptionToReject: accessOption.id })}
                    >
                        {t("users.invitation_settings.reject")}
                    </a>
                </>
            );
        }
        return null;
    }

    renderApproveAccessOptionConfirmation() {
        return (
            <UpdateAccessOptionModal
                id={this.state.accessOptionToApprove}
                update={() => {
                    Notifier.success("Successfully approved domain");
                    this.props.refetch().catch((err: Error) => Notifier.error(err));
                }}
                newState={"APPROVED"}
                mutation={UPDATE_COMPANY_ACCESS_OPTION}
                open={this.state.accessOptionToApprove > 0}
                onClose={() => this.setState({ accessOptionToApprove: 0 })}
            />
        );
    }

    renderRejectAccessOptionConfirmation() {
        return (
            <UpdateAccessOptionModal
                id={this.state.accessOptionToReject}
                update={() => {
                    Notifier.success("Successfully rejected domain");
                    this.props.refetch().catch((err: Error) => Notifier.error(err));
                }}
                newState={"DENIED"}
                mutation={UPDATE_COMPANY_ACCESS_OPTION}
                open={this.state.accessOptionToReject > 0}
                onClose={() => this.setState({ accessOptionToReject: 0 })}
            />
        );
    }

    renderDeleteAccessOptionConfirmation(companyId: number) {
        return (
            <DeleteAccessOptionModal
                id={this.state.accessOptionToDelete}
                companyId={companyId}
                onUpdate={() => {
                    Notifier.success("Successfully deleted domain");
                    this.props.refetch().catch((err: Error) => Notifier.error(err));
                }}
                open={this.state.accessOptionToDelete > 0}
                onClose={() => this.setState({ accessOptionToDelete: 0 })}
            />
        );
    }

    renderCompanyWithSameDomainHeader() {
        if (this.state.shouldRenderSameDomain) {
            const { t } = this.context;
            return (
                <TableHeaderNoWrap>
                    {t("users.invitation_settings.domain_same_company")}
                </TableHeaderNoWrap>
            );
        }
        return null;
    }

    renderCompanyWithDomainColumnData(accessOption: CompanyAccessOption) {
        if (this.state.shouldRenderSameDomain) {
            if (!accessOption.companyWithSameUrl) {
                return <TableDataNoWrap />;
            }

            const differentCompany = accessOption.companyWithSameUrl.id !== this.props.companyId;
            return (
                <TableDataNoWrap data-label="Company with Same Domain">
                    <a
                        onClick={() =>
                            this.props.history?.push(
                                `${PATHS.company}/${accessOption.companyWithSameUrl.id}`
                            )
                        }
                    >
                        {differentCompany ? accessOption.companyWithSameUrl.name : ""}
                    </a>
                </TableDataNoWrap>
            );
        }
        return null;
    }

    private getDomainStateColor(state: string): string {
        switch (state) {
            case "approved": {
                return LIGHT_GREEN;
            }
            case "denied": {
                return LIGHT_RED;
            }
            case "pending_approval": {
                return LIGHT_YELLOW;
            }
            default: {
                return TRANSPARENT;
            }
        }
    }

    render(): JSX.Element {
        const { t } = this.context;
        if (!this.props.accessOptions?.length) {
            return <div />;
        }

        return (
            <table className="ui table">
                <thead>
                    <tr>
                        {this.renderCompanyHeader()}
                        <TableHeaderNoWrap>
                            {t("users.invitation_settings.domain_name")}
                        </TableHeaderNoWrap>
                        <TableHeaderNoWrap>
                            {t("users.invitation_settings.domain_groups")}
                        </TableHeaderNoWrap>
                        <TableHeaderNoWrap>
                            {t("users.invitation_settings.domain_state")}
                        </TableHeaderNoWrap>
                        <TableHeaderNoWrap>
                            {t("users.invitation_settings.domain_actions")}
                        </TableHeaderNoWrap>
                        {this.renderCompanyWithSameDomainHeader()}
                    </tr>
                </thead>
                <tbody>
                    {this.props.accessOptions.map((accessOption: CompanyAccessOption) => (
                        <tr key={accessOption.id}>
                            {this.renderCompanyColumnData(accessOption)}
                            <TableDataNoWrap data-label="Url">{accessOption.url}</TableDataNoWrap>
                            <TableDataNoWrap data-label="Groups">
                                {accessOption.groups.map((group, index) => (
                                    <span key={index}>
                                        {!!index && ", "}
                                        <Link
                                            id={`qacompanyAccessOptionsTable-group_${group.id}`}
                                            className="qa-companyAccessOptionsTable-group"
                                            to={`${PATHS.userGroup}/${group.id}`}
                                        >
                                            {group.name}
                                        </Link>
                                    </span>
                                ))}
                            </TableDataNoWrap>
                            <TableDataNoWrap data-label="State">
                                <Button
                                    fluid
                                    style={{
                                        backgroundColor: this.getDomainStateColor(
                                            accessOption.state
                                        ),
                                        color: "#000000",
                                        fontWeight: "normal",
                                        border: "0px",
                                        display: "flex",
                                        justifyContent: "center",
                                        cursor: "default",
                                    }}
                                >
                                    {t(
                                        `users.invitation_settings.domain_states.${accessOption.state}`
                                    )}
                                </Button>
                            </TableDataNoWrap>
                            {this.renderActionColumnData(accessOption)}
                            {this.renderDeleteAccessOptionConfirmation(
                                this.getCompanyId(accessOption)
                            )}
                            {this.renderApproveAccessOptionConfirmation()}
                            {this.renderRejectAccessOptionConfirmation()}
                            {this.renderCompanyWithDomainColumnData(accessOption)}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}
