import styled, { Button, Checkbox as GC_Checkbox } from "grabcad-ui-elements";

export const OperatingHoursContainer = styled.div<{ disabled: boolean }>`
    display: grid;
    grid-row-gap: 35px;

    &[disabled] {
        opacity: 0.6;
        pointer-events: none;
    }
`;

export const EnabledBox = styled.div`
    display: flex;
    flex-direction: row;

    > .checkbox {
        margin-left: 30px;
        cursor: pointer;
        > * {
            cursor: pointer;
        }
    }
`;

export const DayRow = styled.div`
    display: grid;
    grid-template-columns: 130px auto;
    gap: 20px 15px;
    align-items: start;
    justify-items: start;
    justify-content: start;
    align-content: center;
`;

export const DayBox = styled.div`
    cursor: pointer;
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

    > * {
        cursor: pointer;
    }
`;

export const Checkbox = styled(GC_Checkbox)`
    margin-right: 8px;
`;

export const TimeSelect = styled.select`
    width: 100px !important;
    height: 32px !important;
    border: 1px solid #ccc !important;
    &:hover {
        border: 1px solid #003393 !important;
    }
`;

export const RangeList = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
`;
export const RangeRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

export const IconButton = styled(Button)`
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 8px 10px !important;
    gap: 8px !important;
    width: 32px !important;
    height: 32px !important;
    background: #ffffff !important;
    color: #333333 !important;
    border: 1px solid #ccc !important;

    &:hover {
        border: 1px solid #003393 !important;
        color: #003393 !important;
        border-radius: 4px;
    }
`;
