import React from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryOptions, QueryResult, ApolloError } from "@apollo/client";
import { SdkData } from "../types";

const GET_COMPANY_SDKS = gql`
    query companySdks {
        companySdks {
            sdks {
                id
                name
                productName
                url
                version
                lastModified
                icon
            }
        }
    }
`;

export interface GetCompanySdksQueryResult {
    companySdks: {
        sdks: SdkData[];
    };
}

export interface GetCompanySdksQueryProps extends Omit<QueryOptions, "query"> {
    children: (result: QueryResult<GetCompanySdksQueryResult>) => JSX.Element;
    displayName?: string;
    skip?: boolean;
    onCompleted?: (data: GetCompanySdksQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class GetCompanySdksQuery extends React.Component<GetCompanySdksQueryProps> {
    render() {
        const { children, variables, ...props } = this.props;

        return (
            <Query<GetCompanySdksQueryResult>
                query={GET_COMPANY_SDKS}
                fetchPolicy="no-cache"
                variables={variables}
                {...props}
            >
                {children}
            </Query>
        );
    }
}
