import _ from "lodash";
import { validate } from "isemail";
import { type TranslationFunction } from "../components/ApplicationProvider";

export const parseEmails = (string: string): string[] => {
    return _.uniq(`${string}`.split(/[,\s]+/))
        .filter((s) => !!s)
        .map((email) => email.toLowerCase());
};

export const invalidEmails = (emails: string[]) => {
    return emails.filter((email) => !validate(email));
};

export const emailsInvalidReason = (
    emails: string[],
    t: TranslationFunction
): string | undefined => {
    const badEmails = invalidEmails(emails);
    if (badEmails.length === 1) {
        return `${t("users.invalid_email.single_truncated")}${badEmails[0]}`;
    }
    if (badEmails.length > 1) {
        return `${t("users.invalid_email.multiple_truncated")}${badEmails.join(", ")}`;
    }
};
