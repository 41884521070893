import {
    IGateway,
    JobIdDto,
    CreateGatewayResult,
    PrinterDetails,
    GetGatewayInstallerURL,
} from "../../../shared/src/types";
import { apiDelete, apiGet, apiPost } from "./Apis-wrapper";
import { config } from "../config"; // use is later whe work with production

/**
 * Class representing API endpoints for interacting with Zeus.
 *
 * Zeus is a backend service responsible for handling authentication
 * and authorization logic. It provides various APIs for interacting
 * with the server.
 *
 * For more details, refer to the Zeus repository documentation:
 * [https://github.com/GrabCAD/zeus-gateway/blob/main/docs/control-api.md]
 *
 */

export default class APIEndpoints {
    private static readonly ZEUS_API_URL: string = config.ZEUS_API_URL || "http://localhost:3400";
    public static readonly TEST_URL: string = `${APIEndpoints.ZEUS_API_URL}/api`;
    public static readonly GATEWAYS_LIST: string = `${APIEndpoints.ZEUS_API_URL}/api/public/gateways`;
    public static readonly PRINTERS_LIST: string = `${APIEndpoints.ZEUS_API_URL}/api/public/client-devices`;
    public static readonly CREATE_GATEWAY: string = `${APIEndpoints.ZEUS_API_URL}/api/public/gateways`;
    public static readonly REQUEST_GATEWAY_INSTALLER: string = `${APIEndpoints.ZEUS_API_URL}/api/public/hyperion`;
    public static readonly GET_GATEWAY_INSTALLER_STATUS: string = `${APIEndpoints.ZEUS_API_URL}/api/public/hyperion`;
    public static readonly REMOVE_GATEWAY: string = `${APIEndpoints.ZEUS_API_URL}/api/public/gateways`;
    public static readonly REMOVE_PRINTER: string = `${APIEndpoints.ZEUS_API_URL}/api/public/client-devices`;
}

async function handleApiRequest<T>(
    requestFn: () => Promise<T>,
    errorMessagePrefix: string
): Promise<T> {
    try {
        const response = await requestFn();
        return response;
    } catch (error) {
        let errorMessage = "An error occurred while processing the request.";
        let statusCode: any;
        if (error instanceof Error) {
            errorMessage = error?.message || errorMessage;
        } else if (typeof error === "string") {
            errorMessage = error;
        } else if (error instanceof TypeError) {
            errorMessage = "Network error. Please check your internet connection.";
        } else if (error instanceof Response) {
            statusCode = error?.status;
            try {
                errorMessage = await error.text();
            } catch (textError: any) {
                errorMessage = `Error retrieving error message: ${textError?.message}`;
            }
        }

        if (!!statusCode) {
            errorMessage = JSON.stringify({
                message: errorMessage,
                statusCode,
                errorMessagePrefix,
            });
        } else {
            errorMessage = JSON.stringify({ message: errorMessage, errorMessagePrefix });
        }

        throw error;
    }
}

export async function getGatewayList(printerSerial?: string): Promise<IGateway[]> {
    const errorMessagePrefix = "Failed to get gateways list";
    const ListGatewaysUrl = !!printerSerial
        ? `${APIEndpoints.GATEWAYS_LIST}?printerSerial=${printerSerial}`
        : APIEndpoints.GATEWAYS_LIST;
    const requestFn = async () => {
        return await apiGet<IGateway[]>(ListGatewaysUrl);
    };
    return handleApiRequest(requestFn, errorMessagePrefix);
}

export async function getPrinterList(): Promise<PrinterDetails[]> {
    const errorMessagePrefix = "Failed to get printer list";
    const requestFn = async () => {
        return await apiGet<PrinterDetails[]>(APIEndpoints.PRINTERS_LIST);
    };
    return handleApiRequest(requestFn, errorMessagePrefix);
}

export async function getGatewayInstallerUrl(
    coreDeviceThingName: string
): Promise<GetGatewayInstallerURL> {
    const errorMessagePrefix = "Failed to get gateway installer URL";
    const gatewayInstallerStatusURL = `${APIEndpoints.GET_GATEWAY_INSTALLER_STATUS}/${coreDeviceThingName}`;
    const requestFn = async () => {
        return await apiGet<GetGatewayInstallerURL>(gatewayInstallerStatusURL);
    };
    return handleApiRequest(requestFn, errorMessagePrefix);
}

export async function createGateway(site: string, region: string): Promise<CreateGatewayResult> {
    const errorMessagePrefix = "Failed to create gateway";
    const requestFn = async () => {
        const payload = {
            site: site.trim(),
            region: region.trim(),
        };
        return await apiPost<CreateGatewayResult>(APIEndpoints.CREATE_GATEWAY, payload);
    };
    return handleApiRequest(requestFn, errorMessagePrefix);
}

export async function requestGatewayInstaller(coreDeviceThingName: string): Promise<JobIdDto> {
    const errorMessagePrefix = "Failed to request gateway installer";
    const requestFn = async () => {
        return await apiPost<JobIdDto>(APIEndpoints.REQUEST_GATEWAY_INSTALLER, {
            coreDeviceThingName,
        });
    };
    return handleApiRequest(requestFn, errorMessagePrefix);
}

export async function removeGatewayInstaller(coreDeviceThingName: string): Promise<null> {
    const errorMessagePrefix = "Failed to remove gateway installer";
    const requestFn = async () => {
        return await apiDelete<null>(APIEndpoints.REMOVE_GATEWAY, {
            coreDeviceThingName,
        });
    };
    return handleApiRequest(requestFn, errorMessagePrefix);
}

export async function decommissionPrinters(clientDeviceThingName: string): Promise<null> {
    const errorMessagePrefix = "Failed to remove decommission printer";
    const requestFn = async () => {
        return await apiDelete<null>(APIEndpoints.REMOVE_PRINTER, {
            clientDeviceThingName,
        });
    };
    return handleApiRequest(requestFn, errorMessagePrefix);
}
