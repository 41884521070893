import { FunctionComponent, FormEvent, useContext, ReactText, useState, useEffect } from "react";
import { DocumentNode, ExecutionResult } from "graphql";
import { cloneDeep, omit } from "lodash";
import { PureQueryOptions, useLazyQuery, useMutation } from "@apollo/client";
import classnames from "classnames";
import { IScreenProps } from "../Screen";
import { Button, Form, FormField, Input, Modal, Header, Checkbox } from "grabcad-ui-elements";
import {
    CustomerInfo,
    ResellerInfo,
    User,
    Role,
    LIST_USERS,
    OperatingSchedule,
    DetailedLicenseData,
    CompanyAsAdmin,
    GET_COMPANY_AS_ADMIN,
    GET_COMPANY_GLOBAL_ADMIN,
    GetCompanyQueryAsGLobalAdminResult,
    CompanyAsGlobalAdmin,
    GetCompanyQueryResult,
    GetCompanyQueryAsGlobalAdminVariables,
    CompanyAccessOption,
} from "../../graphql";
import { match as routerMatch } from "react-router-dom";
import { parseEmails, emailsInvalidReason } from "../../utils/email";
import { Notifier } from "../../view/Notifier";
import { alphabetizeByProp } from "../../utils/sort";
import { UserContext } from "../../components/User/UserProvider";
import { config } from "frontend/src/config";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { DeleteEntityConfirmation } from "../../view/Modals/DeleteEntityConfirmation";
import { CompanyLicenseTable } from "../../components/license/LicenseTable";
import { ApplicationContext, type TranslationKey } from "../../components/ApplicationProvider";
import { TimezoneSelector } from "../../components/TimezoneSelector";
import { OperatingHoursEditor } from "../../components/Company/OperatingHoursEditor";
import { CompanyAccessOptionsTable } from "../../components/CompanyAccessOptionsTable";
import { InfoBox } from "../../components/Styles";
import { Properties } from "csstype";
import {
    CREATE_COMPANY,
    CompanyFormInput,
    DELETE_COMPANY,
    RESYNC_COMPANY_ACLS,
    UPDATE_COMPANY,
    UPDATE_COMPANY_GLOBAL_ADMIN,
} from "../../graphql/Mutations/Companies";
import { NotifyAdminsCheckBox } from "./NotifyAdminsCheckBox";
import {
    GetCompanyLicensesQueryVariables,
    useCompanyLicensesQuery,
} from "../../graphql/Queries/Licenses/GetCompanyLicenses";
import { USER_ROLES_QUERY } from "../../graphql/Queries/UserRoles";
import { omitDeepTypenames } from "../../utils/omitTypenames";
// import { deepEquals } from "../../utils/form";

/** FIXME GC-71488: Share max length const in server/src/services/companies.ts */
const COMPANY_NAME_MAX_LENGTH = 100;

interface DataFormField {
    required?: boolean;
    // Returns a localized string
    invalidator?: (string: any) => string | undefined;
    inputComponent?: string;
    labelKey?: TranslationKey;
    infoTextKey?: TranslationKey;
    labelDefault: string;
    type?: string;
    placeholder?: string;
    render?: (any: any) => string;
    parse?: (input: string) => any;
    disabled?: () => boolean;
    style?: Properties<string | number>;
}

interface CompanyDataFormField extends DataFormField {
    id: keyof CompanyFormInput;
}

interface CustomerDataFormField extends DataFormField {
    id: keyof CustomerInfo;
}

interface ResellerDataFormField extends DataFormField {
    id: keyof ResellerInfo;
}

interface IEditCompanyProps extends IScreenProps {
    match: routerMatch<{ companyId?: string }>;
}

export const CreateCompany: FunctionComponent<IScreenProps> = (props) => {
    return <CompanyForm mutation={CREATE_COMPANY} {...props} />;
};

export const EditCompany: FunctionComponent<IEditCompanyProps> = (props) => {
    const userContext = useContext(UserContext);
    const { t } = useContext(ApplicationContext);
    const [resyncCompanyACLsResponse, setResyncCompanyACLResponse] = useState<{
        nTotal: number;
        nMissing: number;
        nExtra: number;
    }>();
    const [resyncCompanyACLsMutation] = useMutation(RESYNC_COMPANY_ACLS, {
        // eslint-disable-next-line @typescript-eslint/typedef
        update(cache, { data: { resyncCompanyACLs } }): void {
            setResyncCompanyACLResponse(resyncCompanyACLs);
        },
        onError: (error) => Notifier.error(error),
    });

    const variables: GetCompanyLicensesQueryVariables = {};
    const companyId = parseInt((props as any).match.params.companyId, 10);
    if (userContext.role === Role.GlobalAdmin) {
        variables.companyId = companyId;
    }
    const { companyLicenses } = useCompanyLicensesQuery({ variables });
    const userRole = userContext.role;

    // This seems like the least convoluted way to conditionally use one of 2 strongly typed queries.
    const lazyGlobalAdminQuery = useLazyQuery<
        GetCompanyQueryAsGLobalAdminResult,
        GetCompanyQueryAsGlobalAdminVariables
    >(GET_COMPANY_GLOBAL_ADMIN, { variables: { id: companyId } });
    const lazyAdminQuery = useLazyQuery<GetCompanyQueryResult>(GET_COMPANY_AS_ADMIN);

    useEffect(() => {
        if (userRole === Role.GlobalAdmin) {
            void lazyGlobalAdminQuery[0]();
        } else {
            void lazyAdminQuery[0]();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Unclear why TS can't properly infer this type
    const company: CompanyAsAdmin | CompanyAsGlobalAdmin | undefined =
        lazyGlobalAdminQuery[1].data?.company || lazyAdminQuery[1].data?.company;

    if (!company) {
        return <div className="ui loader active" />;
    }

    const companyInput: CompanyFormInput = omit(omitDeepTypenames(company), [
        "admins", // Queried data returns User objects, but mutation variables input is a flat list of emails, added below.
        "accessOptions",
    ]) as Omit<CompanyAsAdmin | CompanyAsGlobalAdmin, "admins" | "accessOptions">;
    companyInput.admins = [...company.admins]
        .sort(alphabetizeByProp("email"))
        .map((admin: User) => admin.email);

    return (
        <div>
            <Breadcrumbs sections={[{ label: t("company.breadcrumb") }]} />
            <CompanyForm
                companyInput={companyInput}
                mutation={
                    userRole === Role.GlobalAdmin ? UPDATE_COMPANY_GLOBAL_ADMIN : UPDATE_COMPANY
                }
                refetchQueries={[{ query: LIST_USERS }]}
                editing={true}
                licenses={companyLicenses?.licenses}
                accessOptions={(company as CompanyAsGlobalAdmin).accessOptions}
                {...props}
            />
            {userRole === Role.GlobalAdmin && (
                <>
                    <hr />
                    <Button
                        id="qa-company-resync-acls"
                        type="button"
                        secondary
                        disabled={false}
                        className="primary left floated"
                        onClick={() => resyncCompanyACLsMutation({ variables: { companyId } })}
                    >
                        Resync ACLs
                    </Button>
                    {resyncCompanyACLsResponse && (
                        <div style={{ display: "inline-block" }}>
                            <div id="qa-resync-acl-total">{`Total number of printer / user ACL pairs: ${resyncCompanyACLsResponse.nTotal}`}</div>
                            <div id="qa-resync-acl-extra">{`Number extra found in cloud db: ${resyncCompanyACLsResponse.nExtra}`}</div>
                            <div id="qa-resync-acl-missing">{`Number missing on cloud db: ${resyncCompanyACLsResponse.nMissing}`}</div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

interface ICompanyFormProps extends IScreenProps {
    match: routerMatch<{ companyId?: string }>;
    mutation: DocumentNode; //TODO GC-91587 To see if this can be handled in a better way(earlier the type annotation used to be any)
    companyInput?: CompanyFormInput;
    refetch?: any;
    refetchQueries?: (string | PureQueryOptions)[];
    editing?: boolean;
    licenses?: DetailedLicenseData[];
    accessOptions?: CompanyAccessOption[];
}

export const CompanyForm: FunctionComponent<ICompanyFormProps> = ({
    companyInput,
    mutation,
    history,
    location,
    match,
    editing,
    licenses,
    accessOptions, // <DomainRequestList> also renders <CompanyAccessOptionsTable>, so it's not self contained.
    refetchQueries,
    refetch,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const { t } = useContext(ApplicationContext);
    const { role, email } = useContext(UserContext);
    const [company, setCompany] = useState<CompanyFormInput>(
        companyInput || ({} as CompanyFormInput)
    );

    // companyStateForDirtyTest should always reflect the state of the company in the backend, and is used
    // for the isDirty computation to tell whether the form contents differ from that.
    const [companyStateForDirtyTest, setCompanyStateForDirtyTest] = useState<CompanyFormInput>(
        cloneDeep(company)
    );
    const [emailsString, setEmailsString] = useState<string>(
        companyInput?.admins ? companyInput.admins.join(", ") : ""
    );
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [operatingHoursUpdated, setOperatingHoursUpdated] = useState<boolean>(false);
    const [confirmRemoveSelfAsAdmin, setConfirmRemoveSelfAsAdmin] = useState<boolean>(false);
    const [removingSelfAsAdmin, setRemovingSelfAsAdmin] = useState<boolean>(false);
    const [companyToDeleteId, setCompanyToDeleteId] = useState<number | undefined>();
    const [notifyAdmins, setNotifyAdmins] = useState<boolean>(true);

    const isCompanyCreation = mutation?.definitions?.some(
        (def) => "name" in def && def.name?.value === "createCompany" //TODO GC-91587 To see if this can be handled in a better way(this used to flag an implicit any error as a result of mutation type being any)
    );

    const [operatingSchedule, setOperatingSchedule] = useState<OperatingSchedule>(
        companyInput?.operatingSchedule || { enabled: false }
    );

    const urlInvalidReason = (url?: string): string | undefined => {
        const urlRegex = new RegExp(/^([a-z0-9]{1,20})$/);
        if (url && !urlRegex.test(url)) {
            return t("company.invalid_prefix");
        }
    };

    const companyNameField: CompanyDataFormField = {
        labelKey: "company.company_name",
        labelDefault: "Company Name",
        id: "name",
        required: true,
        style: { width: 350 },
        parse: (name: string) => {
            const trimmed = name.trim();

            return trimmed ? name : trimmed;
        },
        invalidator: (name: string) => {
            if (name.length > COMPANY_NAME_MAX_LENGTH) {
                return t("company.invalid_name_too_long", {
                    max_len: COMPANY_NAME_MAX_LENGTH,
                });
            }
        },
    };
    const companyAdminEmailsField: CompanyDataFormField = {
        labelKey: "company.admin_email",
        labelDefault: "Company Admin Emails",
        infoTextKey: "settings.email_disclaimer",
        placeholder: "email@domain.com, email2@domain.com...",
        render: () => emailsString,
        parse: (newEmailsString: string) => {
            setEmailsString(newEmailsString);
            return parseEmails(newEmailsString);
        },
        id: "admins",
        required: true,
        invalidator: (emails: string[]) =>
            !emails.length ? t("forms.field_required") : emailsInvalidReason(emails, t),
        style: {
            width: 530,
        },
    };
    const urlPrefixField: CompanyDataFormField = {
        labelKey: "company.url_prefix",
        labelDefault: "URL Prefix",
        id: "urlPrefix",
        required: false,
        parse: (url: string) => url.toLowerCase(),
        invalidator: urlInvalidReason,
        disabled: () => !!editing,
        style: {
            width: 150,
            marginRight: 40,
        },
    };
    const customerAccountNumberField: CustomerDataFormField = {
        labelDefault: "Ship to Customer Account #",
        id: "accountNumber",
        required: false,
        style: {
            width: 150,
            marginRight: 40,
        },
    };
    const customerNameField: CustomerDataFormField = {
        labelDefault: "Ship to Customer Name",
        id: "name",
        required: false,
        style: {
            width: 150,
            marginRight: 40,
        },
    };
    const customerPhoneField: CustomerDataFormField = {
        labelDefault: "Ship to Customer Phone",
        id: "phoneNumber",
        required: false,
        style: {
            width: 150,
            marginRight: 40,
        },
    };
    const customerEmailField: CustomerDataFormField = {
        labelDefault: "Ship to Customer Email",
        id: "email",
        required: false,
        style: {
            width: 150,
            marginRight: 40,
        },
    };
    const resellerNameField: ResellerDataFormField = {
        labelDefault: "Reseller Name",
        id: "name",
        required: false,
        style: {
            width: 150,
            marginRight: 40,
        },
    };
    const resellerPhoneNumberField: ResellerDataFormField = {
        labelDefault: "Reseller Phone #",
        id: "phoneNumber",
        required: false,
        style: {
            width: 150,
            marginRight: 40,
        },
    };
    const resellerEmailField: ResellerDataFormField = {
        labelDefault: "Reseller Email",
        id: "email",
        required: false,
        style: {
            width: 150,
            marginRight: 40,
        },
    };
    const resellerContactPersonField: ResellerDataFormField = {
        labelDefault: "Reseller Contact Person",
        id: "contactPerson",
        required: false,
        style: {
            width: 150,
            marginRight: 40,
        },
    };
    const resellerAccountNumberField: ResellerDataFormField = {
        labelDefault: "Reseller Account Number",
        id: "accountNumber",
        required: false,
        style: {
            width: 150,
            marginRight: 40,
        },
    };

    const validate = () => {
        const fields = [companyNameField, companyAdminEmailsField, urlPrefixField] as any;
        return !fields.some(
            (field: CompanyDataFormField) =>
                (field.required && !company[field.id]) ||
                (field.invalidator && !!field.invalidator(company[field.id]))
        );
    };

    const renderCustomerInfo = (userRole: string) => {
        if (userRole !== Role.GlobalAdmin) {
            return null;
        }
        const getCustomerFormField = (field: CustomerDataFormField) => {
            let renderedValue: ReactText = "";
            let invalidReason: string | false | undefined = "";
            if (company.customerInfo) {
                renderedValue = field.render
                    ? field.render(company.customerInfo[field.id])
                    : company.customerInfo[field.id] || "";
                invalidReason =
                    submitClicked && field.invalidator?.(company.customerInfo[field.id]);
            }
            const updateField = (id: keyof CustomerInfo, event: FormEvent<HTMLInputElement>) => {
                const parsedValue = field.parse
                    ? field.parse(event.currentTarget.value)
                    : event.currentTarget.value;
                let updatedCompany = { ...company };
                updatedCompany.customerInfo = { ...updatedCompany.customerInfo };
                updatedCompany.customerInfo[id] = parsedValue;
                setCompany(updatedCompany);
            };
            return (
                <FormField key={field.id}>
                    <label>{field.labelKey ? t(field.labelKey) : field.labelDefault}</label>
                    <Input
                        id={`qa-company-input-customer_${field.id}`}
                        className={`qa-company-input ${classnames({ error: !!invalidReason })}`}
                        placeholder={field.placeholder}
                        value={renderedValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateField(field.id, e)
                        }
                        type={field.type}
                        disabled={field.disabled ? field.disabled() : false}
                        style={field.style}
                    />
                    {invalidReason && (
                        <p
                            id={`qa-company-invalidReason_${field.id}`}
                            className="qa-company-invalidReason ui negative message"
                        >
                            {invalidReason}
                        </p>
                    )}
                </FormField>
            );
        };
        return (
            <div>
                <div style={{ display: "flex" }}>
                    {getCustomerFormField(customerAccountNumberField)}
                    {getCustomerFormField(customerNameField)}
                    {getCustomerFormField(customerPhoneField)}
                </div>
                <div style={{ display: "flex" }}>{getCustomerFormField(customerEmailField)}</div>
            </div>
        );
    };

    const handleOperatingHoursUpdate = (updatedSchedule: OperatingSchedule) => {
        if (company.operatingSchedule !== updatedSchedule) {
            setOperatingSchedule(updatedSchedule);
            let updatedCompany = { ...company };
            updatedCompany.operatingSchedule = updatedSchedule;
            setCompany(updatedCompany);
            setOperatingHoursUpdated(true);
        }
    };

    const renderOperatingHoursInfo = (userRole: string) => {
        if (userRole !== Role.CompanyAdmin) {
            return null;
        }

        return (
            <FormField>
                <OperatingHoursEditor
                    schedule={operatingSchedule}
                    onUpdate={handleOperatingHoursUpdate}
                />
            </FormField>
        );
    };

    const renderTimezoneInfo = () => {
        return (
            <FormField>
                <label>{t("settings.timezone")}</label>
                <TimezoneSelector
                    id="timezone"
                    disabled={false}
                    selectedTimezone={company.settings ? company.settings.timezone : ""}
                    onChange={(event: React.SyntheticEvent<HTMLElement>, timezone: string) => {
                        const updatedCompany = cloneDeep(company);
                        updatedCompany.settings = company.settings || {};
                        updatedCompany.settings.timezone = timezone;
                        setCompany(updatedCompany);
                    }}
                />
            </FormField>
        );
    };

    const renderResellerInfo = (userRole: string) => {
        if (userRole !== Role.GlobalAdmin) {
            return null;
        }
        const getResellerDataFormField = (field: ResellerDataFormField) => {
            let renderedValue: ReactText = "";
            let invalidReason: string | boolean | undefined = "";
            if (company.resellerInfo) {
                renderedValue = field.render
                    ? field.render(company.resellerInfo[field.id])
                    : company.resellerInfo[field.id] || "";
                invalidReason =
                    submitClicked &&
                    field.invalidator &&
                    field.invalidator(company.resellerInfo[field.id]);
            }
            const updateField = (id: keyof ResellerInfo, event: FormEvent<HTMLInputElement>) => {
                const parsedValue = field.parse
                    ? field.parse(event.currentTarget.value)
                    : event.currentTarget.value;
                let updatedCompany = { ...company };
                updatedCompany.resellerInfo = { ...updatedCompany.resellerInfo };
                updatedCompany.resellerInfo[id] = parsedValue;
                setCompany(updatedCompany);
            };
            return (
                <FormField key={field.id}>
                    <label>{field.labelKey ? t(field.labelKey) : field.labelDefault}</label>
                    <Input
                        id={`qa-company-input-reseller_${field.id}`}
                        className={`qa-company-input ${classnames({ error: !!invalidReason })}`}
                        placeholder={field.placeholder}
                        value={renderedValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateField(field.id, e)
                        }
                        type={field.type}
                        disabled={field.disabled ? field.disabled() : false}
                        style={field.style}
                    />
                    {invalidReason && (
                        <p
                            id={`qa-company-invalidReason_${field.id}`}
                            className="qa-company-invalidReason ui negative message"
                        >
                            {invalidReason}
                        </p>
                    )}
                </FormField>
            );
        };
        return (
            <div>
                <div style={{ display: "flex" }}>
                    {getResellerDataFormField(resellerNameField)}
                    {getResellerDataFormField(resellerPhoneNumberField)}
                    {getResellerDataFormField(resellerEmailField)}
                </div>
                <div style={{ display: "flex" }}>
                    {getResellerDataFormField(resellerContactPersonField)}
                    {getResellerDataFormField(resellerAccountNumberField)}
                </div>
            </div>
        );
    };

    const renderCompanyLicenseInfo = (userRole: string) => {
        if (userRole !== Role.GlobalAdmin) {
            // Don't show any license info here for regular users
            return;
        }

        const displayHeader = licenses?.length;
        const header = () => {
            if (displayHeader) {
                return <h3>{t("company.company_licenses")}</h3>;
            }
            return <div />;
        };
        const assignButton = () => {
            return (
                <Button
                    id="qa-company-assignLicense"
                    type="button"
                    secondary
                    disabled={!companyInput?.id}
                    className="primary right floated"
                    onClick={() => history.push(`/license/assign/${company.id}`)}
                >
                    Assign License
                </Button>
            );
        };

        return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {header()}
                {assignButton()}
            </div>
        );
    };

    const inputComponents: { [id: string]: any } = {
        input: Input,
    };
    const getFormField = (field: CompanyDataFormField) => {
        const InputClass = inputComponents[field.inputComponent || "input"];
        const invalidReason =
            submitClicked &&
            ((field.required && !company[field.id] && t("forms.field_required")) ||
                field.invalidator?.(company[field.id]));

        const renderedValue = field.render
            ? field.render(company[field.id])
            : company[field.id] || "";
        const updateField = (id: keyof CompanyFormInput, event: FormEvent<HTMLInputElement>) => {
            const parsedValue = field.parse
                ? field.parse(event.currentTarget.value)
                : event.currentTarget.value;
            const updatedCompany = { ...company, [id]: parsedValue }; // TODO GC-91587: Remove this hack!!
            setCompany(updatedCompany);
        };
        return (
            <FormField key={field.id}>
                <label>
                    {field.labelKey ? t(field.labelKey) : field.labelDefault}
                    {field.required && <span style={{ color: "red" }}>&nbsp;*</span>}
                </label>
                {field.infoTextKey && <InfoBox>{t(field.infoTextKey)}</InfoBox>}
                <InputClass
                    id={`qa-company-input_${field.id}`}
                    className={`qa-company-input ${classnames({ error: !!invalidReason })}`}
                    placeholder={field.placeholder}
                    value={renderedValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField(field.id, e)}
                    type={field.type}
                    disabled={field.disabled ? field.disabled() : false}
                    style={field.style}
                />
                {invalidReason && (
                    <p
                        id={`qa-company-invalidReason_${field.id}`}
                        className="qa-company-invalidReason ui negative message"
                    >
                        {invalidReason}
                    </p>
                )}
            </FormField>
        );
    };

    const renderInternalCheckbox = (userRole: string) => {
        if (userRole === Role.GlobalAdmin) {
            return (
                <FormField>
                    <Checkbox
                        id="qa-company-internal"
                        toggle
                        label="Internal Company"
                        checked={company.internal}
                        onChange={() => {
                            let updatedCompany = { ...company };
                            updatedCompany.internal = !updatedCompany.internal;
                            setCompany(updatedCompany);
                        }}
                        style={{ marginLeft: 25, marginTop: 27 }}
                    />
                </FormField>
            );
        }
        return <div />;
    };

    const renderDeleteButton = () => {
        if (role === Role.GlobalAdmin && mutation === UPDATE_COMPANY_GLOBAL_ADMIN) {
            return (
                <div style={{ display: "flex" }}>
                    <Button
                        id={`qa-company-delete`}
                        type="submit"
                        negative={true}
                        content={"Delete"}
                        onClick={() => {
                            setCompanyToDeleteId(company.id);
                        }}
                        disabled={isDirty || submitClicked}
                    />
                    <DeleteEntityConfirmation
                        entity={{ id: company.id, name: company.name }}
                        type="Company"
                        mutation={DELETE_COMPANY}
                        update={() => history?.push("/company")}
                        open={!!companyToDeleteId && companyToDeleteId === company.id}
                        onClose={() => setCompanyToDeleteId(undefined)}
                    />
                </div>
            );
        }
        return null;
    };

    const isDirty = JSON.stringify(company) !== JSON.stringify(companyStateForDirtyTest);
    const isValid = validate();
    const closeConfirmRemoveSelfAsAdmin = () => setConfirmRemoveSelfAsAdmin(false);
    const renderCompanyLicenseTable = () => {
        if (role === Role.GlobalAdmin) {
            return (
                <CompanyLicenseTable
                    role={role}
                    licenses={licenses}
                    companyId={company.id}
                    {...{ history, location, match }}
                />
            );
        }
    };
    const renderCompanyAccessOptionsTable = () => {
        if (role === Role.GlobalAdmin && accessOptions?.length) {
            return (
                <>
                    <h3>{t("company.company_access_options")}</h3>
                    <CompanyAccessOptionsTable
                        accessOptions={accessOptions.sort(alphabetizeByProp("url"))}
                        role={role}
                        refetch={refetch}
                        companyId={company.id || 0}
                        {...{ history, location, match }}
                    />
                </>
            );
        }
    };
    const [update, { loading: loadingMutation }] = useMutation(mutation, {
        refetchQueries: refetchQueries,
        update: (_cache, { data: { createCompany, updateCompany } }: ExecutionResult<any>) => {
            if (createCompany) {
                history.push(`/company/${createCompany.id}`);
                Notifier.success(t("company.successfully_created"));
            } else if (updateCompany) {
                if (!companyToDeleteId) {
                    let message = operatingHoursUpdated
                        ? t("company.successfully_updated_with_operatinghours")
                        : t("company.successfully_updated");
                    Notifier.success(message);
                    setSubmitClicked(false);
                    setOperatingHoursUpdated(false);
                    if (removingSelfAsAdmin) {
                        window.location.assign(`${config.REACT_APP_LOGOUT_URL}`);
                    }
                }
                // Make sure isDirty becomes false...
                setCompanyStateForDirtyTest(cloneDeep(company));
            }
        },
        onError: (error) => Notifier.error(error),
    });

    return (
        <Form
            style={{ maxWidth: 550, paddingBottom: 25 }}
            onSubmit={(event) => {
                if (!isDirty) {
                    return;
                }

                event.preventDefault();
                setSubmitClicked(true);
                if (isValid && !loadingMutation) {
                    if (
                        role === Role.CompanyAdmin &&
                        company.admins &&
                        !company.admins.includes(email)
                    ) {
                        setConfirmRemoveSelfAsAdmin(true);
                    } else {
                        const vars = isCompanyCreation
                            ? { ...company, notifyAdmins }
                            : { ...company };
                        void update({ variables: vars });
                    }
                }
            }}
        >
            <Modal
                basic
                size="small"
                open={confirmRemoveSelfAsAdmin}
                onClose={closeConfirmRemoveSelfAsAdmin}
            >
                <Header icon="exclamation triangle" content={t("company.remove_yourself.title")} />
                <Modal.Content>
                    <h3>
                        {t("company.remove_yourself.lost_privileges", {
                            email,
                            company_name: company?.name,
                        })}
                    </h3>
                    <p>{t("company.remove_yourself.logged_out")}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        id="qa-company-removeAdminModal-cancel"
                        secondary
                        onClick={closeConfirmRemoveSelfAsAdmin}
                    >
                        {t("forms.cancel")}
                    </Button>
                    <Button
                        id="qa-company-removeAdminModal-confirm"
                        negative
                        onClick={() => {
                            setRemovingSelfAsAdmin(true);
                            // Using an otherwise unnecessary refetchQuery here to brute force logout:
                            void update({
                                variables: company,
                                refetchQueries: [{ query: USER_ROLES_QUERY }],
                            });
                        }}
                    >
                        {t("company.remove_yourself.submit")}
                    </Button>
                </Modal.Actions>
            </Modal>

            <div style={{ display: "flex" }}>
                {getFormField(companyNameField)}
                {renderInternalCheckbox(role)}
            </div>
            {getFormField(companyAdminEmailsField)}
            {/* {getFormField(urlPrefixField)} */}
            {renderCustomerInfo(role)}
            {renderTimezoneInfo()}
            {renderOperatingHoursInfo(role)}
            {renderResellerInfo(role)}
            {renderCompanyLicenseInfo(role)}
            {renderCompanyLicenseTable()}
            {renderCompanyAccessOptionsTable()}
            <FormField key="buttons">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 25,
                    }}
                >
                    {renderDeleteButton()}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            id="qa-company-cancel"
                            type="button"
                            secondary
                            disabled={!isValid && submitClicked}
                            onClick={() => history.push("/company")}
                        >
                            {t("forms.cancel")}
                        </Button>
                        <Button
                            id="qa-company-submit"
                            type="submit"
                            className={classnames("primary right floated", {
                                "qa-loading": loadingMutation,
                            })}
                            disabled={!isDirty || (!isValid && submitClicked)}
                        >
                            {t("forms.submit")}
                        </Button>
                        {isCompanyCreation && (
                            <NotifyAdminsCheckBox
                                label={t("forms.notify_admins")}
                                checked={notifyAdmins}
                                onClick={() => setNotifyAdmins(!notifyAdmins)}
                            />
                        )}
                    </div>
                </div>
            </FormField>
        </Form>
    );
};
