/* eslint-disable max-classes-per-file */
import React from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryOptions, QueryResult, ApolloError } from "@apollo/client";
import { ClientData } from "..";

export const GET_CLIENT = gql`
    query client($id: Int!) {
        client(id: $id) {
            id
            name
            description
            clientId
            clientSecret
        }
    }
`;

export const LIST_CLIENTS = gql`
    query listClients {
        listClients {
            clients {
                id
                name
                description
                clientId
                clientSecret
            }
        }
    }
`;

export interface ListClientsQueryResult {
    listClients: {
        clients: ClientData[];
    };
}

export interface ListClientsQueryProps extends Omit<QueryOptions, "query"> {
    children: (result: QueryResult<ListClientsQueryResult>) => JSX.Element;
    displayName?: string;
    onCompleted?: (data: GetClientQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class ListClientsQuery extends React.Component<ListClientsQueryProps> {
    render() {
        const { children } = this.props;

        return (
            <Query<ListClientsQueryResult> query={LIST_CLIENTS} fetchPolicy="no-cache">
                {children}
            </Query>
        );
    }
}

export interface GetClientQueryResult {
    client: ClientData;
}

export interface GetClientQueryVariables {
    id: number;
}

export interface GetClientQueryProps extends Omit<QueryOptions<GetClientQueryVariables>, "query"> {
    children: (result: QueryResult<GetClientQueryResult, GetClientQueryVariables>) => JSX.Element;
    displayName?: string;
    onCompleted?: (data: GetClientQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class GetClientQuery extends React.Component<GetClientQueryProps> {
    render() {
        const { children, variables } = this.props;

        return (
            <Query<GetClientQueryResult, GetClientQueryVariables>
                variables={variables}
                query={GET_CLIENT}
            >
                {children}
            </Query>
        );
    }
}
