import styled, { Button } from "grabcad-ui-elements";

export const HighlightRow = styled.tr`
    &:hover {
        background-color: #deeeff;
        cursor: pointer;
    }
`;

export const TableHeaderNoWrap = styled.th`
    white-space: nowrap;
`;

export const TableDataNoWrap = styled.td`
    white-space: nowrap;
`;

// TODO: Move to grabcad-ui-elements if shared with Shop
export const InfoBox = styled.div`
    background: #ebeff7;
    border: 1px solid #003393;
    border-radius: 4px;
    padding: 10px 20px;
    margin: 10px 0 20px;
`;

export const StyledHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

// A button that appears in the StyledHeaderContainer
// Because buttons have a larger height than the container would otherwise have, we shift
// the button so that the overall container spacing is consistent with other pages
export const StyledHeaderButton = styled(Button)`
    margin-top: -8px !important;
`;

export const StyledDeleteButton = styled(Button)`
    &.ui.button.negative {
        border-bottom: 1px solid red;
        font-weight: 500;
    }
`;

export const LIGHT_GREEN = "rgb(162, 216, 162)";
export const LIGHT_YELLOW = "rgb(255, 190, 115)";
export const LIGHT_RED = "rgb(255, 152, 143)";
export const LIGHT_BLUE = "rgb(143, 208, 236)";
export const TRANSPARENT = "rgba(0, 0, 0, 0)";
