import { useState } from "react";
import { Dropdown } from "grabcad-ui-elements";
import { ProductData } from "../../graphql";
import { Redirect } from "react-router-dom";
import {
    LIST_FEATURES,
    ListFeaturesQueryResult,
} from "../../graphql/Queries/Features/ListFeatures";
import { useQueryWithCatch } from "../../utils/hooks/useQueryWithCatch";

interface FeaturesMultiSelectProps {
    /** initial ids. NB: Changing this will _not_ cause rerender */
    ids: number[];
    /** report all selected id's [when they change] */
    onChange: (ids: any[]) => void;
    applicationTypeId: string;
    product: Partial<ProductData>;
    disabled?: boolean;
}

export const FeaturesMultiSelect: React.FC<FeaturesMultiSelectProps> = (
    props: FeaturesMultiSelectProps
) => {
    const [selectedOptions, setSelectedOptions] = useState(props.ids);
    const { loading, error, data } = useQueryWithCatch<ListFeaturesQueryResult>(LIST_FEATURES);
    if (error) {
        return <Redirect to="/product" />;
    }
    if (loading || !data) {
        return <div className="ui loader active" />;
    }
    let features = data.listFeatures.features.filter((feature) => {
        const applicationTypeId = props.applicationTypeId;
        return (
            !feature.disabled &&
            (feature.applicationType.id === applicationTypeId || applicationTypeId === "any")
            // There is an "any" application type in order to select features of different application types
        );
    });
    const featureOptions = features.map((feature) => ({
        key: feature.id,
        text: feature.displayName,
        value: feature.id,
    }));

    let dropDownProps = {
        id: "featureIds",
        disabled: !features.length || props.product.disabled,
        placeholder: !features.length ? "No features defined" : "Multiple features can be selected",
        options: featureOptions,
        value: selectedOptions,
        fluid: true,
        multiple: true,
        selection: true,
    };

    return (
        <>
            <label>Features</label>
            <Dropdown
                {...dropDownProps}
                onChange={(e, newData) => {
                    const featureIds = features;
                    const selectedIds = newData.value as number[];
                    const selectedFeatures = featureIds.filter((f) => selectedIds.includes(f.id));
                    setSelectedOptions(selectedFeatures.map((f) => f.id));
                    props.onChange(selectedFeatures);
                }}
                disabled={props.disabled}
            />
        </>
    );
};
