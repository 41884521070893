import gql from "graphql-tag";

export const UPDATE_GLOBAL_ADMINS = gql`
    mutation updateGlobalAdmins($userEmailsToAdd: [String!], $userEmailsToRemove: [String!]) {
        updateGlobalAdmins(
            userEmailsToAdd: $userEmailsToAdd
            userEmailsToRemove: $userEmailsToRemove
        )
    }
`;
