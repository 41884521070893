import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Preference } from "../types";
import { Notifier } from "frontend/src/view/Notifier";

export const USER_PREFERENCES_QUERY = gql`
    {
        userRoles {
            user {
                id
                preferences {
                    id
                    key
                    value
                }
            }
        }
    }
`;

export interface UserPreferencesQueryResult {
    userRoles: {
        user: {
            id: number;
            preferences: Preference[];
        };
    };
}

export function useUserPreference(key: string) {
    const { data, loading } = useQuery<UserPreferencesQueryResult>(USER_PREFERENCES_QUERY, {
        onError: Notifier.error,
        fetchPolicy: "cache-first",
    });

    const preferences = data?.userRoles?.user?.preferences ?? [];
    const preference = preferences.find((pref) => pref.key === key);

    return {
        value: preference?.value,
        loading,
    };
}
