import { Component } from "react";
import { match, Redirect } from "react-router-dom";
import { CompanyAccessOptionsTable } from "../../components/CompanyAccessOptionsTable";
import { CompanyAccessOption, Role } from "../../graphql";
import { CompanyAccessOptionsGlobalQuery } from "../../graphql/Queries/CompanyAccessOptions";
import { alphabetizeByProp } from "../../utils/sort";
import { PaginationLinks } from "../../view/Navigation/PaginationLinks";
import { Notifier } from "../../view/Notifier";
import { IScreenProps } from "../Screen";

const ENTRIES_PER_PAGE = 20;

interface IDomainRequestListProps extends IScreenProps {
    match: match<{ id?: string }>;
}

interface IDomainRequestListState {
    companyAccessOptions: CompanyAccessOption[];
    accessOptionToApprove: 0;
    accessOptionToReject: 0;
    accessOptionToRemove: 0;
    searchString?: string;
    page: number;
}

export class DomainRequestList extends Component<IDomainRequestListProps, IDomainRequestListState> {
    constructor(props: IDomainRequestListProps) {
        super(props);

        this.state = {
            companyAccessOptions: [],
            accessOptionToApprove: 0,
            accessOptionToReject: 0,
            accessOptionToRemove: 0,
            searchString: "",
            page: 1,
        };
    }

    render() {
        return (
            <CompanyAccessOptionsGlobalQuery>
                {({ loading, error, data, refetch }) => {
                    if (error) {
                        Notifier.error(error);
                        return <Redirect to="/" />;
                    }
                    if (loading || !data) {
                        return <div className="ui loader active" />;
                    }

                    let companyAccessOptions = [...data.companyAccessOptionsGlobal.accessOptions];
                    let optionsCount = companyAccessOptions.length;
                    let filteredOptionsCount = companyAccessOptions.length;

                    if (this.state.searchString) {
                        companyAccessOptions = companyAccessOptions.filter(
                            (option: CompanyAccessOption) => {
                                let search = (this.state.searchString as string).toLowerCase();
                                return (
                                    option.company.name.toLowerCase().includes(search) ||
                                    option.url.toLowerCase().includes(search)
                                );
                            }
                        );
                    }

                    companyAccessOptions.sort(alphabetizeByProp("company.name"));
                    companyAccessOptions = companyAccessOptions.slice(
                        (this.state.page - 1) * ENTRIES_PER_PAGE,
                        this.state.page * ENTRIES_PER_PAGE
                    );

                    return (
                        <>
                            <div className="ui left icon input">
                                <i className="search icon" />
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    value={this.state.searchString}
                                    onChange={(event) =>
                                        this.setState({
                                            searchString: event.currentTarget.value,
                                            page: 1,
                                        })
                                    }
                                />
                            </div>
                            <h2 className="page-header">
                                Pending Domain Requests (
                                {filteredOptionsCount !== optionsCount
                                    ? `${filteredOptionsCount} of ${optionsCount}`
                                    : optionsCount}
                                )
                            </h2>
                            <CompanyAccessOptionsTable
                                accessOptions={companyAccessOptions}
                                role={Role.GlobalAdmin}
                                history={this.props.history}
                                match={this.props.match}
                                location={this.props.location}
                                refetch={refetch}
                            />
                            <PaginationLinks
                                total={filteredOptionsCount}
                                perPage={ENTRIES_PER_PAGE}
                                currentPage={this.state.page}
                                goToPage={(page) => this.setState({ page })}
                            />
                        </>
                    );
                }}
            </CompanyAccessOptionsGlobalQuery>
        );
    }
}
