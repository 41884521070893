import { Component, FunctionComponent, FormEvent } from "react";
import classnames from "classnames";
import { DataProxy } from "@apollo/client";
import { IScreenProps } from "../../screens/Screen";
import { Button, Form, FormField, Input } from "grabcad-ui-elements";
import { match, Redirect } from "react-router-dom";
import { deepEquals } from "../../utils/form";
import { Notifier } from "../../view/Notifier";
import {
    ClientData,
    ExistingClientData,
    GET_CLIENT,
    GetClientQuery,
    CreateClientMutation,
} from "../../graphql";
import { UpdateClientMutation } from "../../graphql/Mutations/Clients";

const UPDATE_CLIENT_MUTATION_TYPE = "updateClient";
const CREATE_CLIENT_MUTATION_TYPE = "createClient";

export const CreateClient: FunctionComponent<IScreenProps> = (props) => {
    return <ClientForm mutationType={CREATE_CLIENT_MUTATION_TYPE} {...props} />;
};

interface ClientDataFormField {
    id: keyof ClientData;
    required?: boolean;
    invalidator?: (string: any) => string | undefined;
    inputComponent?: string;
    label: string;
    type?: string;
    disabled?: boolean;
}

interface IEditClientProps extends IScreenProps {
    match: match<{ id?: string }>;
}

export const EditClient: FunctionComponent<IEditClientProps> = (props) => {
    const id: number = parseInt((props.match.params as any).id, 10);
    return (
        <div>
            <GetClientQuery variables={{ id }}>
                {({ loading, error, data, refetch }) => {
                    if (error) {
                        Notifier.error(error);
                        return <Redirect to="/" />;
                    }
                    if (loading || !data) {
                        return <div className="ui loader active" />;
                    }

                    const clientApp = { ...data.client };

                    return (
                        <ClientForm
                            clientApp={clientApp}
                            mutationType={UPDATE_CLIENT_MUTATION_TYPE}
                            refetch={refetch}
                            {...props}
                        />
                    );
                }}
            </GetClientQuery>
        </div>
    );
};

interface IClientFormProps extends IScreenProps {
    match: match<{ id?: string }>;
    clientApp?: ClientData;
    refetch?: any;
    mutationType?: "updateClient" | "createClient";
}
interface IClientFormState {
    clientApp: Partial<ClientData>;
    dirty: boolean;
    submitClicked: boolean;
}

export class ClientForm extends Component<IClientFormProps, IClientFormState> {
    fieldsCol1: ClientDataFormField[] = [
        { label: "Client Name", id: "name", required: true },
        {
            label: "Client Description",
            id: "description",
            required: true,
        },
        {
            label: "Client Id",
            id: "clientId",
            disabled: true,
        },
        {
            label: "Client Secret",
            id: "clientSecret",
            disabled: true,
        },
    ];

    constructor(props: IClientFormProps, context: []) {
        super(props, context);
        this.state = {
            clientApp: { ...props.clientApp } || {},
            dirty: false,
            submitClicked: false,
        };
    }

    updateField(id: keyof ClientData, event: FormEvent<HTMLInputElement>) {
        const { value } = event.target as HTMLInputElement;
        let clientApp = { ...this.state.clientApp };
        (clientApp[id] as string) = value;
        this.setState({ clientApp });
    }

    validate() {
        const fields = this.fieldsCol1 as any;
        return !fields.some(
            (field: ClientDataFormField) =>
                (field.required && !this.state.clientApp[field.id]) ||
                (field.invalidator && !!field.invalidator(this.state.clientApp[field.id]))
        );
    }

    render() {
        const inputComponents: { [id: string]: any } = {
            input: Input,
        };
        const getFormField = (field: ClientDataFormField) => {
            const InputClass = inputComponents[field.inputComponent || "input"];
            const invalidReason =
                this.state.submitClicked &&
                ((field.required && !this.state.clientApp[field.id] && "This field is required.") ||
                    field.invalidator?.(this.state.clientApp[field.id]));
            let formFieldClassnames = field.disabled
                ? classnames({ error: !!invalidReason, disabled: true })
                : classnames({ error: !!invalidReason });
            return this.props.mutationType === CREATE_CLIENT_MUTATION_TYPE &&
                field.disabled ? null : (
                <FormField key={field.id}>
                    <label>
                        {field.label}
                        {field.required && <span style={{ color: "red" }}>&nbsp;*</span>}
                    </label>
                    <InputClass
                        id={`qa-client-input_${field.id}`}
                        className={`qa-client-input ${formFieldClassnames}`}
                        value={(this.state.clientApp[field.id] as string) || ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.updateField(field.id, e)
                        }
                        type={field.type}
                    />
                    {invalidReason && <p className="ui negative message">{invalidReason}</p>}
                </FormField>
            );
        };

        const isDirty = !deepEquals(this.props.clientApp, this.state.clientApp);
        const isValid = this.validate();
        switch (this.props.mutationType) {
            case UPDATE_CLIENT_MUTATION_TYPE:
                return (
                    <UpdateClientMutation
                        update={(cache: DataProxy) => {
                            cache.writeQuery({
                                data: { client: this.state.clientApp },
                                query: GET_CLIENT,
                            });
                            Notifier.success(`Successfully updated client.`);
                        }}
                        onError={(error) => Notifier.error(error)}
                    >
                        {(update, { loading }) => (
                            <Form
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    this.setState({ submitClicked: true });
                                    if (isValid && !loading) {
                                        update({
                                            variables: this.state.clientApp as ExistingClientData,
                                        }).catch(Notifier.error);
                                    }
                                }}
                            >
                                <div className="ui one column grid">
                                    <div className="eight wide column">
                                        {this.fieldsCol1.map(getFormField)}
                                        <FormField key="submit">
                                            <Button
                                                id="qa-client-submit"
                                                type="submit"
                                                className="primary right floated"
                                                disabled={
                                                    !isDirty ||
                                                    (!isValid && this.state.submitClicked)
                                                }
                                            >
                                                Submit
                                            </Button>
                                        </FormField>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </UpdateClientMutation>
                );
            case CREATE_CLIENT_MUTATION_TYPE:
            default:
                return (
                    <CreateClientMutation
                        update={(cache: DataProxy, { data }) => {
                            if (!data?.createClient?.id) {
                                return `Error! Cannot retrieve data for create client.`;
                            }
                            this.props.history.push(`/client/${data.createClient.id}`);
                            Notifier.success(`Successfully created client.`);
                        }}
                        onError={(error) => Notifier.error(error)}
                    >
                        {(update, { loading }) => (
                            <Form
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    this.setState({ submitClicked: true });
                                    if (isValid && !loading) {
                                        update({ variables: this.state.clientApp }).catch(
                                            Notifier.error
                                        );
                                    }
                                }}
                            >
                                <div className="ui one column grid">
                                    <div className="eight wide column">
                                        {this.fieldsCol1.map(getFormField)}
                                        <FormField key="submit">
                                            <Button
                                                type="submit"
                                                className="primary right floated"
                                                disabled={
                                                    !isDirty ||
                                                    (!isValid && this.state.submitClicked)
                                                }
                                            >
                                                Submit
                                            </Button>
                                        </FormField>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </CreateClientMutation>
                );
        }
    }
}
