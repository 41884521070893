import { FormEvent, FunctionComponent, useContext, useState, useEffect } from "react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import styled, { Button, Form, FormField, Input } from "grabcad-ui-elements";
import { UpdateAnalyzeAddressOptions } from "../../graphql";
import { DocumentNode } from "graphql";
import { PureQueryOptions, useMutation } from "@apollo/client";
import { Notifier } from "../../view/Notifier";
import { fullAnalyzeUrl } from "../../Routes";

const AnalyzeConfigInfoContainer = styled.div`
    display: table;

    .formRow {
        display: table-row;
        table-layout: fixed;
    }

    .tableCell {
        display: table-cell;
        vertical-align: bottom;
    }

    .buttonGroup {
        display: flex;
        flex-direction: row;
        justify-content: start;
    }
`;

export interface IConfigAnalyzeProps {
    analyzeAddressUrl: UpdateAnalyzeAddressOptions["analyzeAddressUrl"];
    refetchQueries?: (string | PureQueryOptions)[];
    mutation: DocumentNode;
}

export const AnalyzeConfigInfo: FunctionComponent<IConfigAnalyzeProps> = ({
    analyzeAddressUrl: initialAnalyzeAddressUrl,
    refetchQueries,
    mutation,
}) => {
    const { t } = useContext(ApplicationContext);
    const [analyzeAddressUrl, setAnalyzeAddressUrl] = useState<string>(
        initialAnalyzeAddressUrl || ""
    );
    const [analyzeUrlInvalidReason, setAnalyzeUrlInvalidReason] = useState<string | undefined>();
    const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
    const [allowLaunch, setAllowLaunch] = useState<boolean>(!!initialAnalyzeAddressUrl);
    const [isSubmitNotAllowed, setIsSubmitNotAllowed] = useState<boolean>(true);

    const [analyzeAddressUrlForDirtyTest, setAnalyzeAddressUrlForDirtyTest] = useState<string>();

    useEffect(() => {
        setAnalyzeAddressUrlForDirtyTest(initialAnalyzeAddressUrl);
        setAllowLaunch(!!initialAnalyzeAddressUrl);
        setAnalyzeAddressUrl(initialAnalyzeAddressUrl || "");
    }, [initialAnalyzeAddressUrl]);

    const getAnalyzeUrlInvalidReason = (urlString: string | undefined): string | undefined => {
        if (urlString && urlString.indexOf("https") !== -1) {
            return t("company.https_not_supported_message");
        } else if (urlString && urlString.indexOf("http") === -1) {
            return t("company.url_not_starting_with_http");
        } else if (urlString && urlString.indexOf("://") === -1) {
            return t("company.invalid_analyze_ip");
        } else if (urlString) {
            try {
                const parsedUrl = new URL(urlString);
                if (!parsedUrl) {
                    throw new Error();
                }
                if (parsedUrl && !parsedUrl.port) {
                    return t("company.no_port_mentioned_error");
                }
            } catch (err) {
                return t("company.invalid_analyze_ip");
            }
        }
    };

    const [mutationFunction, { loading: loadingMutation }] = useMutation(mutation, {
        refetchQueries,
        update: (_cache) => {
            Notifier.success(t("company.successfully_updated"));
            setIsSubmitClicked(false);
            setAllowLaunch(true);
            setAnalyzeAddressUrlForDirtyTest(analyzeAddressUrl);
            setIsSubmitNotAllowed(false);
        },
        onError: (error) => Notifier.error(error),
    });

    const isDirty = (currentValue: string): boolean =>
        currentValue !== analyzeAddressUrlForDirtyTest;

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setIsSubmitClicked(true);
        const isNotValid = getAnalyzeUrlInvalidReason(analyzeAddressUrl);
        setAnalyzeUrlInvalidReason(isNotValid);
        if (!isNotValid && !loadingMutation) {
            void mutationFunction({ variables: { analyzeAddressUrl } });
        }
    };

    return (
        <AnalyzeConfigInfoContainer>
            <Form className="formRow" onSubmit={handleSubmit}>
                <FormField disabled={false} className="tableCell" style={{ width: "400px" }}>
                    <label>{t("configure_analyze.analyze_server_address")}</label>
                    <Input
                        id="qa-company-input_analyzeServerAddress"
                        className="qa-company-input"
                        value={analyzeAddressUrl}
                        placeholder={t("company.analyze_url_placeholder_text")}
                        onChange={(event) => {
                            const value = event.currentTarget.value;
                            setAnalyzeAddressUrl(value);

                            if (value && isDirty(value)) {
                                setIsSubmitNotAllowed(false);
                            } else {
                                setIsSubmitNotAllowed(true);
                            }

                            if (isSubmitClicked) {
                                setAnalyzeUrlInvalidReason(getAnalyzeUrlInvalidReason(value));
                            }
                        }}
                    />
                </FormField>
                <div className="buttonGroup">
                    <FormField key="submit" className="tableCell" style={{ margin: "1em 0 0 0" }}>
                        <Button
                            id="qa-submit"
                            className="ui button primary"
                            type="submit"
                            disabled={isSubmitNotAllowed}
                            style={{ margin: "1em 1em 0 1em" }}
                        >
                            {t("forms.submit")}
                        </Button>
                    </FormField>
                    <FormField
                        key="launchAnalyze"
                        className="tableCell"
                        style={{ margin: "1em 0 0 0" }}
                    >
                        <Button
                            id="qa-launch-analyze"
                            className="ui button primary"
                            disabled={!allowLaunch}
                            type="button"
                            style={{ margin: "1em 0 0 0" }}
                            onClick={() => {
                                if (analyzeAddressUrl) {
                                    window.location.href = fullAnalyzeUrl(analyzeAddressUrl);
                                }
                            }}
                        >
                            {t("configure_analyze.submit_action")}
                        </Button>
                    </FormField>
                </div>
            </Form>
            {analyzeUrlInvalidReason && (
                <p className="ui negative message tableCell qa-analyzeUrlInvalidReason">
                    {analyzeUrlInvalidReason}
                </p>
            )}
        </AnalyzeConfigInfoContainer>
    );
};
