import { FunctionComponent, useContext } from "react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import Parser from "html-react-parser";

interface PostInstallationSectionProps {
    system: "Windows" | "Linux";
}

export const PostInstallationInfo: FunctionComponent<PostInstallationSectionProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    const settingUpAnalyzeLink = `https://help.grabcad.com/article/291-setting-up-grabcad-analyze-continued-configuration`;

    return (
        <div style={{ marginTop: "26px" }}>
            <h2>{t("configure_analyze.post_installation_steps")}</h2>
            <hr />
            <ul>
                {props.system === "Linux" && (
                    <li>{t("configure_analyze.post_installation_section.linux_step_1")}</li>
                )}
                {props.system === "Windows" && (
                    <>
                        <li>{t("configure_analyze.post_installation_section.step_1")}</li>
                        <ul style={{ listStyleType: "circle" }}>
                            <li>{t("configure_analyze.post_installation_section.step_2")}</li>
                        </ul>
                    </>
                )}
                <li>{Parser(t("configure_analyze.post_installation_section.step_3"))}</li>
                {props.system === "Windows" && (
                    <li>{t("configure_analyze.post_installation_section.step_4")}</li>
                )}
                <li>{t("configure_analyze.post_installation_section.step_5")}</li>
                <li>
                    {Parser(
                        t("configure_analyze.post_installation_section.step_6", {
                            analyze_setting_link: settingUpAnalyzeLink,
                        })
                    )}
                </li>
            </ul>
        </div>
    );
};
