import gql from "graphql-tag";

export const UPDATE_USER_GROUP_PRINTER_GROUP_ACCESSES = gql`
    mutation updateUserGroupPrinterGroupAccesses(
        $toCreate: [UserGroupPrinterGroupAccessInput!]
        $toDelete: [Int!]
    ) {
        updateUserGroupPrinterGroupAccesses(toCreate: $toCreate, toDelete: $toDelete) {
            id
            userGroup {
                id
            }
            printerGroup {
                id
            }
        }
    }
`;
