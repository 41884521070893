import { DocumentNode, QueryHookOptions, useQuery } from "@apollo/client";
import { Notifier } from "../../view/Notifier";

export function useQueryWithCatch<R, V = void>(
    query: DocumentNode,
    options?: QueryHookOptions<R, V> | undefined
) {
    return useQuery<R, V>(query, {
        onError: (error) => Notifier.error(error),
        ...options,
    });
}
