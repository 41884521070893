import { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import styled, { Checkbox } from "grabcad-ui-elements";
import { PaginationLinks } from "../../view/Navigation/PaginationLinks";
import { FeatureData } from "../../graphql";
import { alphabetizeByProp } from "../../utils/sort";
import {
    LIST_FEATURES_WITH_PRODUCTS,
    ListFeaturesQueryResult,
} from "../../graphql/Queries/Features/ListFeatures";
import { PATHS } from "../../Routes";
import { useQueryWithCatch } from "../../utils/hooks/useQueryWithCatch";

const FEATURES_PER_PAGE = 20;

const HighlightRow = styled.tr`
    cursor: pointer;
    &:hover {
        background-color: #deeeff;
    }
`;

const ProductList = styled.span`
    span:not(:last-child)::after {
        content: ", ";
    }
`;

export const FeatureList: React.FC = () => {
    const [searchString, setSearchString] = useState("");
    const [page, setPage] = useState(1);

    const { loading, error, data } = useQueryWithCatch<ListFeaturesQueryResult>(
        LIST_FEATURES_WITH_PRODUCTS
    );

    if (error) {
        return <Redirect to="/" />;
    }
    if (loading || !data) {
        return <div className="ui loader active" />;
    }

    let features = [...data.listFeatures.features];
    let featureCount = features.length;
    let filteredFeatureCount = features.length;
    if (searchString) {
        features = features.filter((featureData: FeatureData) => {
            let search = (searchString as string).toLowerCase();
            return (
                featureData.key.toLowerCase().includes(search) ||
                featureData.displayName.toLowerCase().includes(search)
            );
        });
        filteredFeatureCount = features.length;
    }

    features.sort(alphabetizeByProp("key"));
    features = features.slice((page - 1) * FEATURES_PER_PAGE, page * FEATURES_PER_PAGE);

    return (
        <div>
            <div className="ui left icon input">
                <i className="search icon" />
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchString}
                    onChange={(event) => {
                        setSearchString(event.currentTarget.value);
                        setPage(1);
                    }}
                />
            </div>
            <h2 className="page-header">
                Features (
                {filteredFeatureCount !== featureCount
                    ? `${filteredFeatureCount} of ${featureCount}`
                    : featureCount}
                )
            </h2>

            <table className="ui table">
                <thead>
                    <tr>
                        <th>Feature Name</th>
                        <th>Display Name</th>
                        <th>Application</th>
                        <th>Printer Type</th>
                        <th>Material Type</th>
                        <th>Role Assigned</th>
                        <th>Products</th>
                    </tr>
                </thead>
                <tbody>
                    {features.map((feature: FeatureData) => (
                        <HighlightRow key={feature.id}>
                            <td data-label="Name">
                                {feature.key}
                                {feature.disabled ? <b> (disabled)</b> : ""}
                            </td>
                            <td data-label="DisplayName">{feature.displayName}</td>
                            <td data-label="Application">{feature.applicationType.description}</td>
                            <td data-label="PrinterType">
                                <Checkbox checked={feature.isPrinterType} disabled />
                            </td>
                            <td data-label="MaterialType">
                                <Checkbox checked={feature.isMaterialType} disabled />
                            </td>
                            <td data-label="RoleAssigned">
                                <Checkbox checked={feature.isAssignedViaRoles} disabled />
                            </td>
                            <td>
                                <ProductList>
                                    {feature.products?.map((product, index) => (
                                        <span key={index} className="product">
                                            <Link to={`${PATHS.product}/${product.id}`}>
                                                {product.id}
                                            </Link>
                                        </span>
                                    ))}
                                </ProductList>
                            </td>
                        </HighlightRow>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={7}>
                            <PaginationLinks
                                total={filteredFeatureCount}
                                perPage={FEATURES_PER_PAGE}
                                currentPage={page}
                                goToPage={(p) => setPage(p)}
                            />
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};
