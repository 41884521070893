export function extractGenericDomain(currentHostname: string) {
    const expectedHostname =
        currentHostname.split(".").length < 3
            ? currentHostname
            : currentHostname.split(".").slice(-3).join(".");

    if (currentHostname !== expectedHostname) {
        return `https://${expectedHostname}${window.location.pathname}${window.location.search}`;
    }
    return currentHostname;
}
