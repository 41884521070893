import { createContext, Component } from "react";
import { InjectedIntlProps, injectIntl, MessageValue } from "react-intl";
import type translationObj from "../i18n/locales/en.json";
import type { DeepKeyof } from "../../../shared/src/types";

// Need to use `typeof` here even though it's a type-only import
export type TranslationKey = DeepKeyof<typeof translationObj, ".">;
export type TranslationFunction = (
    key: TranslationKey,
    values?: { [key: string]: MessageValue }
) => string;

export interface IApplicationContext {
    t: TranslationFunction;
    formatDate: (date: string | undefined, formatDateProps?: IFormatDateProps) => string;
    formatTime: (date: string | undefined, formatDateProps?: IFormatDateProps) => string;
    // "none" indicates to simulate no printers being returned - useful for testing offline printer cases
    usePrinterFixtures?: boolean | "none";
}

interface IApplicationState {
    context: IApplicationContext;
}

// Note: Need to specify default context only,
// when testing do not mock with <ApplicationContext.Provider>,
// pass a mockedApplicationContext object as second parameter to createMount
const DEFAULT_CONTEXT: IApplicationContext = {
    t: (_key, _values) => "",
    formatDate: (date: string | undefined, formatDateProps?: IFormatDateProps) => "",
    formatTime: (date: string | undefined, formatDateProps?: IFormatDateProps) => "",
};

export const ApplicationContext = createContext(DEFAULT_CONTEXT);
ApplicationContext.displayName = "ApplicationContext";

export interface IFormatDateProps {
    ignoreTimezone?: boolean;
}

export class ApplicationProviderBase extends Component<InjectedIntlProps, IApplicationState> {
    constructor(props: InjectedIntlProps) {
        super(props);
        this.state = {
            context: {
                t: (id, values) => props.intl.formatMessage({ id }, values),
                formatDate: (date: string | undefined, formatDateProps?: IFormatDateProps) =>
                    this.formatDate(date, formatDateProps),
                formatTime: (date: string | undefined, formatDateProps?: IFormatDateProps) =>
                    this.formatTime(date, formatDateProps),
            },
        };
    }

    private formatDate = (
        date: string | undefined,
        { ignoreTimezone }: IFormatDateProps = { ignoreTimezone: false }
    ) => {
        const { intl } = this.props;
        if (!date) {
            return "";
        }
        const options = ignoreTimezone ? { timeZone: "UTC" } : {};
        return intl.formatDate(new Date(date).toUTCString(), options);
    };

    private formatTime = (
        date: string | undefined,
        { ignoreTimezone }: IFormatDateProps = { ignoreTimezone: false }
    ) => {
        const { intl } = this.props;
        if (!date) {
            return "";
        }
        const options = ignoreTimezone ? { timeZone: "UTC" } : {};
        return intl.formatTime(new Date(date).toUTCString(), options);
    };

    public render() {
        const { children } = this.props;
        const { context } = this.state;
        // Note: Must keep AbilityContext separate from ApplicationContext because
        // createContextualCan() assumes context only has one value, the Ability.
        return (
            <ApplicationContext.Provider value={context}>{children}</ApplicationContext.Provider>
        );
    }
}

export const ApplicationProvider = injectIntl(ApplicationProviderBase);
