import { useUserPreference } from "../../graphql/Queries/UserPreferences";
import { useUpdateUserPreference } from "../../graphql/Mutations/UserPreferences";
import { UserPreferenceTypes } from "../../graphql";
import { USER_ROLES } from "../../components/roles/RoleSelector";
import { USERS, USER_GROUPS } from "../../screens/Screen";
import { ASSIGN_USER_GROUPS } from "../../screens/Company/CompanyUsers";

export function useOnboarding() {
    const { value, loading } = useUserPreference(UserPreferenceTypes.COMPLETED_ONBOARDING);
    const { updateValue } = useUpdateUserPreference(UserPreferenceTypes.COMPLETED_ONBOARDING);

    return {
        isCompleted: (key: string) => loading || (value && (value as string[]).includes(key)),
        setCompleted: (key: string) => updateValue([...((value as string[]) ?? []), key]),
        someCompleted: !!(value && (value as string[]).length),
        resetAllCompleted: () => updateValue([]),
    };
}

export function disableOnboarding() {
    /* eslint-disable react-hooks/rules-of-hooks */
    const { value, loading } = useUserPreference(UserPreferenceTypes.COMPLETED_ONBOARDING);
    const { updateValue } = useUpdateUserPreference(UserPreferenceTypes.COMPLETED_ONBOARDING);
    /* eslint-enable react-hooks/rules-of-hooks */

    return {
        isCompleted: (key: string) => loading || (value && (value as string[]).includes(key)),
        setCompleted: (key: string) => updateValue([...((value as string[]) ?? []), key]),
        someCompleted: ((value ?? []) as string[]).length,
        resetAllCompleted: () =>
            updateValue([USERS, USER_ROLES, USER_GROUPS, USERS, ASSIGN_USER_GROUPS]),
    };
}
