import { FunctionComponent, useContext } from "react";
import styled, {
    Image,
    GrabCADApplicationColors,
    ColorLabel,
    IBackgroundColor,
} from "grabcad-ui-elements";
import { IScreenProps } from "../../screens/Screen";
import UsersSvg from "../../images/home/users.svg";
import PrintersSvg from "../../images/home/printers.svg";
import LicensesSvg from "../../images/home/licenses.svg";
import HomePageSvg from "../../images/home/network.svg";
import PlayBtnSvg from "../../images/play_btn.svg";
import UserGroupsSvg from "../../images/thumb_user_groups.svg";
import UsersSvgOld from "../../images/thumb_users.svg";
import LicensesSvgOld from "../../images/thumb_licenses.svg";
import { PATHS } from "../../Routes";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { useCompanyAsAdminQuery, useUsersQuery } from "../../graphql";
import { useGetAllPrinters } from "../Printer/UseGetAllPrinters";
import { MappedPrinterStatus } from "../../utils/printer_utils";
import { useCompanyLicensesQuery } from "../../graphql/Queries/Licenses/GetCompanyLicenses";

enum HomePageColor {
    LABEL_NEUTRAL = "#858585",
}

const HomePageLabel = styled(ColorLabel)<IBackgroundColor>`
    font-size: 14px;
`;
const cardMargin = "10px";

const HomePage = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    animation: fadeIn 0.5s linear;
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    > div {
        max-width: 1080px;
        width: 1080px;
        margin: auto;
    }
    h1 {
        font-size: 24px;
        color: #333333;
    }
    h2 {
        font-size: 18px;
    }
    .hero {
        > img {
            z-index: 100;
            float: right;
            position: relative;
            top: -4.5rem;
            right: 20px;
            margin-bottom: -8rem;
        }
        > div {
            padding: 2rem;
        }
    }
    p {
        margin: 10px 0;
        font-size: 16px;
        line-height: 125%;
    }
    .card {
        width: calc(100% - 2 * ${cardMargin});
        min-width: 250px;
        margin: ${cardMargin};
        color: rgba(0, 0, 0, 0.87);
        background: #fff;
        border: none;
        border-radius: 0.28571429rem;
        box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    }
    .linkCards {
        display: flex;
        width: 100%;
        .card {
            flex: 1 1 250px;
            padding: 20px;
            cursor: pointer;
            &:hover {
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
            }
            h2 {
                margin-top: 16px;
                margin-bottom: 16px;
            }
            .labelContainer {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0.4rem 0.3rem;
            }
        }
    }
`;

const HomePageOld = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    animation: fadeIn 0.5s linear;
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    > div {
        max-width: 800px;
        margin: auto;
    }
    h1 {
        font-size: 24px;
        color: #003393;
        margin: 1rem;
    }
    h2 {
        font-size: 18px;
        line-height: 125%;
    }
    .hero {
        display: flex;
        flex-direction: row;
        > a {
            display: block;
            flex: 0 0 calc(33.333% - 17px);
            border-right: 1px solid #d4d4d5;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
        > div {
            padding: 2rem;
        }
    }
    p {
        margin: 10px 0;
        font-size: 14px;
        line-height: 125%;
    }
    .card {
        width: calc(100% - 2rem);
        margin: 1rem;
        color: rgba(0, 0, 0, 0.87);
        background: #fff;
        border: none;
        border-radius: 0.28571429rem;
        box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    }
    .linkCards {
        display: flex;
        .card {
            width: 33.333%;
            padding: 1rem;
            cursor: pointer;
            &:hover {
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
            }
            h2 {
                text-align: center;
            }
            .imgHolder {
                height: 104px;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }
    }
`;

export const CompanyAdminHomePage: FunctionComponent<IScreenProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    const { companyLoading, company } = useCompanyAsAdminQuery();
    const { companyLicenses } = useCompanyLicensesQuery();
    const { users, loadingUsers } = useUsersQuery();
    const { loadingPrinters, printers, errorOccurred } = useGetAllPrinters();
    let idlePrinterCount = 0;
    let workPrinterCount = 0;
    let errPrinterCount = 0;
    let otherPrinterCount = 0;

    if (!(loadingPrinters || errorOccurred)) {
        printers.forEach((printer) => {
            const mappedStatus = new MappedPrinterStatus(printer);
            switch (mappedStatus.statusText) {
                case `Harvesting`:
                case `Printing`:
                    workPrinterCount++;
                    break;
                case `Idle`:
                    idlePrinterCount++;
                    break;
                case "Paused":
                case "Error":
                case "Maintenance":
                case "Warning":
                case "OutOfMaterial":
                case "PendingStart":
                case "Disconnected":
                case "Cancelled":
                case "Scheduled":
                case "Missing":
                    errPrinterCount++;
                    break;
                default:
                    otherPrinterCount++;
            }
        });
    }

    let activeLicenseCount = 0;
    let expiredLicenseCount = 0;
    let expiringLicenseCount = 0;
    companyLicenses?.licenses.forEach((license) => {
        if (license.state === "active") {
            activeLicenseCount++;
        } else if (license.state === "expired") {
            expiredLicenseCount++;
        } else if (license.state === "expiring") {
            expiringLicenseCount++;
        }
    });

    return (
        <HomePage>
            <div>
                <div className="card hero">
                    <img src={HomePageSvg} />
                    <div>
                        <h1>{t("homepage.header")}</h1>
                        <p>{t("homepage.company_admin_ctrl")}</p>
                    </div>
                </div>
                <div className="linkCards">
                    <div className="card" onClick={() => props.history.push(PATHS.users)}>
                        <Image src={UsersSvg} />
                        <h2>{t("homepage.users.title")}</h2>
                        <div className="labelContainer">
                            {loadingUsers ? (
                                <i className="notched circle loading icon"></i>
                            ) : (
                                <HomePageLabel color={HomePageColor.LABEL_NEUTRAL}>
                                    {t("homepage.users.count", { userCount: users.length })}
                                </HomePageLabel>
                            )}
                            {companyLoading ? (
                                <i className="notched circle loading icon"></i>
                            ) : (
                                <HomePageLabel color={HomePageColor.LABEL_NEUTRAL}>
                                    {t("homepage.users.count_admins", {
                                        adminCount: company?.admins.length,
                                    })}
                                </HomePageLabel>
                            )}
                        </div>
                    </div>
                    <div
                        className="card"
                        onClick={() => {
                            props.history.push(PATHS.companyPrinters);
                        }}
                    >
                        <Image src={PrintersSvg} />
                        <h2>{t("homepage.printers.title")}</h2>
                        <div className="labelContainer">
                            {errorOccurred ? (
                                <HomePageLabel color={GrabCADApplicationColors.GC_ERROR_SEMANTIC}>
                                    {t("printers.home_error_text")}
                                </HomePageLabel>
                            ) : loadingPrinters ? (
                                <i className="notched circle loading icon"></i>
                            ) : (
                                <HomePageLabel color={GrabCADApplicationColors.GC_SUCCESS_SEMANTIC}>
                                    {t("homepage.printers.count_printing", {
                                        printerCount: workPrinterCount,
                                    })}
                                </HomePageLabel>
                            )}
                            {errorOccurred || loadingPrinters ? null : (
                                <HomePageLabel color={HomePageColor.LABEL_NEUTRAL}>
                                    {t("homepage.printers.count_idle", {
                                        printerCount: idlePrinterCount,
                                    })}
                                </HomePageLabel>
                            )}
                            {errorOccurred || loadingPrinters || otherPrinterCount < 1 ? null : (
                                <HomePageLabel color={HomePageColor.LABEL_NEUTRAL}>
                                    {t("homepage.printers.count_other", {
                                        printerCount: otherPrinterCount,
                                    })}
                                </HomePageLabel>
                            )}
                            {loadingPrinters || errPrinterCount < 1 ? null : (
                                <HomePageLabel color={GrabCADApplicationColors.GC_WARNING_SEMANTIC}>
                                    {t("homepage.printers.count_error", {
                                        printerCount: errPrinterCount,
                                    })}
                                </HomePageLabel>
                            )}
                        </div>
                    </div>
                    <div className="card" onClick={() => props.history.push(PATHS.licenses)}>
                        <Image src={LicensesSvg} />
                        <h2>{t("homepage.licenses.title")}</h2>
                        <div className="labelContainer">
                            {companyLoading ? (
                                <i className="notched circle loading icon"></i>
                            ) : activeLicenseCount < 1 ? null : (
                                <HomePageLabel color={GrabCADApplicationColors.GC_SUCCESS_SEMANTIC}>
                                    {t("homepage.licenses.count_active", {
                                        licenseCount: activeLicenseCount,
                                    })}
                                </HomePageLabel>
                            )}
                            {expiringLicenseCount < 1 ? null : (
                                <HomePageLabel color={GrabCADApplicationColors.GC_WARNING_SEMANTIC}>
                                    {t("homepage.licenses.count_expiring", {
                                        licenseCount: expiringLicenseCount,
                                    })}
                                </HomePageLabel>
                            )}
                            {expiredLicenseCount < 1 ? null : (
                                <HomePageLabel color={GrabCADApplicationColors.GC_ERROR_SEMANTIC}>
                                    {t("homepage.licenses.count_expired", {
                                        licenseCount: expiredLicenseCount,
                                    })}
                                </HomePageLabel>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </HomePage>
    );
};

export const CompanyAdminHomePageOld: FunctionComponent<IScreenProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    return (
        <HomePageOld>
            <div>
                <h1>{t("homepage.header")}</h1>
                <div className="card hero">
                    <a
                        href="https://grabcad-1.wistia.com/medias/au0rupg6fd"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Image src={PlayBtnSvg} />
                    </a>
                    <div>
                        <h2>GrabCAD Control</h2>
                        <p>{t("homepage.company_admin")}</p>
                        <p>{t("homepage.company_admin2")}</p>
                    </div>
                </div>
                <div className="linkCards">
                    <div className="card" onClick={() => props.history.push(PATHS.userGroups)}>
                        <h2>{t("homepage.user_groups.title")}</h2>
                        <div className="imgHolder">
                            <Image src={UserGroupsSvg} />
                        </div>
                        <p>{t("homepage.user_groups.copy")}</p>
                    </div>
                    <div className="card" onClick={() => props.history.push(PATHS.users)}>
                        <h2>{t("homepage.users.title")}</h2>
                        <div className="imgHolder">
                            <Image src={UsersSvgOld} />
                        </div>
                        <p>{t("homepage.users.copy")}</p>
                    </div>
                    <div className="card" onClick={() => props.history.push(PATHS.licenses)}>
                        <h2>{t("homepage.licenses.title")}</h2>
                        <div className="imgHolder">
                            <Image src={LicensesSvgOld} />
                        </div>
                        <p>{t("homepage.licenses.copy")}</p>
                    </div>
                </div>
            </div>
        </HomePageOld>
    );
};
