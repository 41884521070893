import { Component, FunctionComponent, FormEvent, useContext } from "react";
import { IScreenProps } from "../screens/Screen";
import { Redirect } from "react-router-dom";
import { Mutation } from "@apollo/client/react/components";
import { FetchResult, PureQueryOptions } from "@apollo/client";
import { GlobalAdminsQuery, GET_GLOBAL_ADMINS } from "../graphql/Queries";
import { IErrorObject, Notifier } from "../view/Notifier";
import { parseEmails, emailsInvalidReason } from "../utils/email";
import { deepEquals } from "../utils/form";
import classnames from "classnames";
import { UPDATE_GLOBAL_ADMINS, RoleInfo, User } from "../graphql";
import { config } from "../config";
import { UserContext } from "../components/User/UserProvider";
import { FormField, TextArea, Form, Header, Button, Modal } from "grabcad-ui-elements";
import { ApplicationContext } from "../components/ApplicationProvider";

export const EditGlobalAdmins: FunctionComponent<IScreenProps> = (props) => {
    const userContext = useContext(UserContext);
    return (
        <GlobalAdminsQuery>
            {({ loading, error, data }) => {
                if (loading) {
                    return <div className="ui loader active" />;
                }
                if (error) {
                    Notifier.error(error);
                    return <Redirect to="/" />;
                }
                if (!data) {
                    Notifier.error(new Error("Failed to load global admin data."));
                    return <Redirect to="/" />;
                }
                const globalAdmins = data.globalAdmins.map((user) => user.email);
                return (
                    <GlobalAdminsForm
                        globalAdmins={globalAdmins}
                        userContext={userContext}
                        {...props}
                    />
                );
            }}
        </GlobalAdminsQuery>
    );
};

interface IGlobalAdminsFormProps extends IScreenProps {
    userContext: RoleInfo;
    globalAdmins: string[];
    refetch?: any;
    refetchQueries?: (string | PureQueryOptions)[];
}

interface IGlobalAdminsFormState {
    globalAdmins: string[];
    emailsString: string;
    dirty: boolean;
    submitClicked: boolean;
    confirmRemoveSelfAsAdmin?: boolean;
    removingSelfAsAdmin?: boolean;
}

export class GlobalAdminsForm extends Component<IGlobalAdminsFormProps, IGlobalAdminsFormState> {
    static contextType = ApplicationContext;
    context!: React.ContextType<typeof ApplicationContext>;

    constructor(props: IGlobalAdminsFormProps, context: []) {
        super(props, context);
        this.state = {
            globalAdmins: props.globalAdmins,
            emailsString: props.globalAdmins.join(",\n"),
            dirty: false,
            submitClicked: false,
        };
    }

    render() {
        const invalidReason =
            (!this.state.globalAdmins.length &&
                "You must specify at least one Global Administrator") ||
            emailsInvalidReason(this.state.globalAdmins, this.context.t);

        const updateField = (event: FormEvent<HTMLTextAreaElement>) => {
            const parsedValue = parseEmails(event.currentTarget.value);
            this.setState({
                emailsString: event.currentTarget.value,
                globalAdmins: parsedValue,
            });
        };

        const isDirty = !deepEquals(this.props.globalAdmins, this.state.globalAdmins);
        const closeConfirmRemoveSelfAsAdmin = () =>
            this.setState({ confirmRemoveSelfAsAdmin: false });
        const { email } = this.props.userContext;
        return (
            <Mutation<User[], { userEmailsToAdd: string[]; userEmailsToRemove: string[] }>
                mutation={UPDATE_GLOBAL_ADMINS}
                refetchQueries={[{ query: GET_GLOBAL_ADMINS }]}
                update={(_cache, { data }: FetchResult<User[]>) => {
                    this.setState({ submitClicked: false });
                    if (data) {
                        Notifier.success(`Successfully updated Global Administrators.`);
                        if (this.state.removingSelfAsAdmin) {
                            window.location.assign(`${config.REACT_APP_LOGOUT_URL}`);
                        }
                    }
                }}
                onError={(error: Partial<IErrorObject>) => Notifier.error(error)}
            >
                {(update, { loading }) => (
                    <Form
                        style={{ maxWidth: 550 }}
                        onSubmit={(event) => {
                            event.preventDefault();
                            this.setState({ submitClicked: true });
                            if (!invalidReason && !loading) {
                                if (
                                    this.state.globalAdmins &&
                                    !this.state.globalAdmins.includes(email)
                                ) {
                                    this.setState({ confirmRemoveSelfAsAdmin: true });
                                } else {
                                    const userEmailsToAdd = this.state.globalAdmins.filter(
                                        (userEmail) => !this.props.globalAdmins.includes(userEmail)
                                    );
                                    const userEmailsToRemove = this.props.globalAdmins.filter(
                                        (userEmail) => !this.state.globalAdmins.includes(userEmail)
                                    );
                                    update({
                                        variables: { userEmailsToAdd, userEmailsToRemove },
                                    }).catch((error) => {
                                        Notifier.error(error);
                                    });
                                }
                            }
                        }}
                    >
                        <Modal
                            basic
                            size="small"
                            open={this.state.confirmRemoveSelfAsAdmin}
                            onClose={closeConfirmRemoveSelfAsAdmin}
                        >
                            <Header
                                icon="exclamation triangle"
                                content={`Are you sure you want to remove yourself as global admin?`}
                            />
                            <Modal.Content>
                                <h3>{`${email} will no longer be a global administrator.`}</h3>
                                <p>{"If you proceed you will be logged out."}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    id="qa-globalAdmins-cancel"
                                    secondary
                                    onClick={closeConfirmRemoveSelfAsAdmin}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    id="qa-globalAdmins-confirm"
                                    negative
                                    onClick={() => {
                                        this.setState({ removingSelfAsAdmin: true });
                                        const userEmailsToAdd = this.state.globalAdmins.filter(
                                            (userEmail) =>
                                                !this.props.globalAdmins.includes(userEmail)
                                        );
                                        const userEmailsToRemove = this.props.globalAdmins.filter(
                                            (userEmail) =>
                                                !this.state.globalAdmins.includes(userEmail)
                                        );
                                        update({
                                            variables: { userEmailsToAdd, userEmailsToRemove },
                                        }).catch((error) => {
                                            Notifier.error(error);
                                        });
                                    }}
                                >
                                    Yes. Remove myself as admin.
                                </Button>
                            </Modal.Actions>
                        </Modal>

                        <FormField key="globalAdmins" className="required">
                            <label>Global Administrator Emails</label>
                            <TextArea
                                id="qa-globalAdmins-emails"
                                className={classnames({
                                    error: this.state.submitClicked && !!invalidReason,
                                })}
                                rows="10"
                                placeholder="email@domain.com, email2@domain.com..."
                                value={this.state.emailsString}
                                onChange={updateField}
                            />
                            {this.state.submitClicked && !!invalidReason && (
                                <p
                                    id="qa-globalAdmins-invalidReason"
                                    className="ui negative message"
                                >
                                    {invalidReason}
                                </p>
                            )}
                        </FormField>
                        <FormField key="submit">
                            <Button
                                id="qa-globalAdmins-submit"
                                type="submit"
                                className="primary right floated"
                                disabled={!isDirty || (this.state.submitClicked && !!invalidReason)}
                            >
                                Submit
                            </Button>
                        </FormField>
                    </Form>
                )}
            </Mutation>
        );
    }
}
