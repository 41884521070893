import gql from "graphql-tag";

export const ADD_USERS = gql`
    mutation createUsers(
        $users: [CreateUserInput!]!
        $group_ids: [Int]
        $sendEmails: Boolean
        $adminName: String
    ) {
        createUsers(
            users: $users
            group_ids: $group_ids
            sendEmails: $sendEmails
            adminName: $adminName
        ) {
            users {
                id
                name
                email
            }
            existingUsers {
                id
                name
                email
            }
        }
    }
`;

export const UPDATE_USER = gql`
    mutation updateUser(
        $currentEmail: String!
        $email: String
        $name: String
        $groupIdsToAdd: [Int]
        $groupIdsToRemove: [Int]
        $sendEmails: Boolean
        $adminName: String
    ) {
        updateUser(
            email: $currentEmail
            data: {
                email: $email
                name: $name
                groupIdsToAdd: $groupIdsToAdd
                groupIdsToRemove: $groupIdsToRemove
            }
            sendEmails: $sendEmails
            adminName: $adminName
        ) {
            name
            email
            groups {
                id
                name
            }
        }
    }
`;

export const DELETE_USER = gql`
    mutation deleteUser($id: Int!) {
        deleteUser(id: $id)
    }
`;

export const REMOVE_USER_FROM_COMPANY = gql`
    mutation removeUserFromCompany($userId: Int!) {
        removeUserFromCompany(userId: $userId) {
            id
            admins {
                id
            }
        }
    }
`;
