import { useContext, FunctionComponent } from "react";
import { UserContext } from "../../components/User/UserProvider";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { Notifier } from "../../view/Notifier";
import { Redirect } from "react-router-dom";
import { IScreenProps } from "../Screen";
import { SdkData, SdkIcon } from "../../graphql";
import { InfoBox } from "../../components/Styles";
import { ApplicationContext } from "../../components/ApplicationProvider";
import PrinterConnectivityIcon from "../../images/printer_connectivity_sdk.svg";
import PrintIcon from "../../images/print_sdk.svg";
import { GetCompanySdksQuery } from "../../graphql/Queries/Sdk";
import { SdkStigCard } from "./SdkStigCard";

const REDIRECT_TO = "https://www.grabcad.com/software-development-kit";

type SdkIconMap = {
    [icon in SdkIcon]: string;
};

export const ICON_MAP: SdkIconMap = {
    "printer_connectivity_sdk.svg": PrinterConnectivityIcon,
    "print_sdk.svg": PrintIcon,
};

export const SdksOverview: FunctionComponent<IScreenProps> = (props) => {
    const { t } = useContext(ApplicationContext);

    const renderSdk = (sdk: SdkData) => (
        <SdkStigCard
            id={sdk.id}
            key={sdk.id}
            name={sdk.productName}
            description={t(`sdks.${sdk.name}.description`)}
            url={sdk.url}
            version={sdk.version}
            lastModified={sdk.lastModified}
            imageSrc={ICON_MAP[sdk.icon]}
            showVersionAndDetails={true}
        />
    );
    return (
        <UserContext.Consumer>
            {(context) => {
                return (
                    <div style={{ paddingBottom: 25 }}>
                        <Breadcrumbs sections={[{ label: t("sdks_overview.breadcrumb") }]} />
                        <GetCompanySdksQuery>
                            {({ loading, error, data, refetch }) => {
                                if (error) {
                                    Notifier.error(error);
                                    return <Redirect to="/" />;
                                }
                                if (loading || !data) {
                                    return <div className="ui loader active" />;
                                }

                                const title = (
                                    <h2 className="page-header">{t("sdks_overview.header")}</h2>
                                );
                                const sdks = data.companySdks.sdks;
                                if (sdks.length === 0) {
                                    window.location.href = REDIRECT_TO;
                                }
                                const overviewCards = sdks.map((sdk) => {
                                    return renderSdk(sdk);
                                });

                                return (
                                    <>
                                        {title}
                                        <InfoBox>{t("sdks_overview.info")}</InfoBox>
                                        {overviewCards}
                                    </>
                                );
                            }}
                        </GetCompanySdksQuery>
                    </div>
                );
            }}
        </UserContext.Consumer>
    );
};
