/* eslint-disable max-classes-per-file */
import React from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryOptions, QueryResult, ApolloError } from "@apollo/client";
import { CompanyAccessOption } from "../types";

export const LIST_COMPANY_ACCESS_OPTIONS = gql`
    query companyAccessOptions {
        companyAccessOptions {
            accessOptions {
                id
                url
                state
                groups {
                    id
                    name
                }
            }
        }
    }
`;

export const LIST_COMPANY_ACCESS_OPTIONS_GLOBAL_ADMIN = gql`
    query companyAccessOptionsGlobal {
        companyAccessOptionsGlobal {
            accessOptions {
                id
                url
                state
                groups {
                    id
                    name
                }
                company {
                    id
                    name
                }
                companyWithSameUrl {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_COMPANY_ACCESS_OPTION = gql`
    query companyAccessOption($id: Int!) {
        companyAccessOption(id: $id) {
            id
            url
            state
            groups {
                id
                name
            }
        }
    }
`;

export interface CompanyAccessOptionsGlobalQueryResult {
    companyAccessOptionsGlobal: {
        accessOptions: CompanyAccessOption[];
    };
}

export interface CompanyAccessOptionsGlobalQueryProps extends Omit<QueryOptions, "query"> {
    children: (result: QueryResult<CompanyAccessOptionsGlobalQueryResult>) => JSX.Element;
    displayName?: string;
    skip?: boolean;
    onCompleted?: (data: CompanyAccessOptionsGlobalQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class CompanyAccessOptionsGlobalQuery extends React.Component<CompanyAccessOptionsGlobalQueryProps> {
    render() {
        const { children, ...props } = this.props;
        return (
            <Query<CompanyAccessOptionsGlobalQueryResult>
                query={LIST_COMPANY_ACCESS_OPTIONS_GLOBAL_ADMIN}
                fetchPolicy="no-cache"
                {...props}
            >
                {children}
            </Query>
        );
    }
}

export interface CompanyAccessOptionsQueryResult {
    companyAccessOptions: {
        accessOptions: CompanyAccessOption[];
    };
}

export interface CompanyAccessOptionsQueryProps extends Omit<QueryOptions, "query"> {
    children: (result: QueryResult<CompanyAccessOptionsQueryResult>) => JSX.Element;
    displayName?: string;
    skip?: boolean;
    onCompleted?: (data: CompanyAccessOptionsQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class CompanyAccessOptionsQuery extends React.Component<CompanyAccessOptionsQueryProps> {
    render() {
        const { children, ...props } = this.props;

        return (
            <Query<CompanyAccessOptionsQueryResult>
                query={LIST_COMPANY_ACCESS_OPTIONS}
                fetchPolicy="no-cache"
                {...props}
            >
                {children}
            </Query>
        );
    }
}

export interface CompanyAccessOptionQueryVariables {
    id: number;
}

export interface CompanyAccessOptionQueryResult {
    companyAccessOption: CompanyAccessOption;
}

export interface CompanyAccessOptionQueryProps
    extends Omit<QueryOptions<CompanyAccessOptionQueryVariables>, "query"> {
    children: (
        result: QueryResult<CompanyAccessOptionQueryResult, CompanyAccessOptionQueryVariables>
    ) => JSX.Element;
    displayName?: string;
    skip?: boolean;
    onCompleted?: (data: CompanyAccessOptionQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class CompanyAccessOptionQuery extends React.Component<CompanyAccessOptionQueryProps> {
    render() {
        const { children, variables, ...props } = this.props;
        return (
            <Query<CompanyAccessOptionQueryResult, CompanyAccessOptionQueryVariables>
                query={GET_COMPANY_ACCESS_OPTION}
                variables={variables}
                fetchPolicy="no-cache"
                {...props}
            >
                {children}
            </Query>
        );
    }
}
