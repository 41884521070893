import { Component, FunctionComponent, FormEvent, useContext } from "react";
import { IScreenProps } from "../Screen";
import { match, Redirect } from "react-router-dom";
import { CompanyAccessOption } from "../../graphql";
import { PATHS } from "../../Routes";
import { UserGroupMultiSelect } from "../../view/UserGroupMultiSelect";
import { deepEquals } from "../../utils/form";
import { Notifier } from "../../view/Notifier";
import { Button, Form, FormField } from "grabcad-ui-elements";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { UpdateCompanyAccessOptionGroupsMutation } from "../../graphql/Mutations/CompanyAccessOptions";
import { CompanyAccessOptionQuery } from "../../graphql/Queries/CompanyAccessOptions";
import { UserContext } from "../../components/User/UserProvider";
import { parse } from "query-string";

interface IEditCompanyAccessOptionProps extends IScreenProps {
    match: match<{ id?: string; company_id?: string }>;
}

export const EditCompanyAccessOption: FunctionComponent<IEditCompanyAccessOptionProps> = (
    props
) => {
    const accessOptionId = parseInt(props.match.params.id as any, 10);
    const userContext = useContext(UserContext);
    const companyId =
        props.match.params.company_id !== undefined
            ? parseInt(props.match.params.company_id as any, 10)
            : userContext.companyId || 0;

    return (
        <div style={{ maxWidth: 550 }}>
            <CompanyAccessOptionQuery variables={{ id: accessOptionId }}>
                {({ loading, error, data }) => {
                    if (error) {
                        Notifier.error(error);
                        return <Redirect to="/" />;
                    }
                    if (loading || !data) {
                        return <div className="ui loader active" />;
                    }

                    return (
                        <CompanyAccessOptionForm
                            accessOption={data.companyAccessOption}
                            companyId={companyId}
                            {...props}
                        />
                    );
                }}
            </CompanyAccessOptionQuery>
        </div>
    );
};

interface ICompanyAccessOptionFormProps extends IScreenProps {
    accessOption: CompanyAccessOption;
    companyId: number;
}

interface CompanyAccessOptionState {
    id: number;
    url: string;
    groupIds: number[];
}

interface ICompanyAccessOptionFormState {
    accessOption: CompanyAccessOptionState;
    accessOptionOG: CompanyAccessOptionState;
    dirty?: boolean;
    submitClicked?: boolean;
    returnTo?: string;
}

class CompanyAccessOptionForm extends Component<
    ICompanyAccessOptionFormProps,
    ICompanyAccessOptionFormState
> {
    static contextType = ApplicationContext;
    context!: React.ContextType<typeof ApplicationContext>;
    constructor(props: ICompanyAccessOptionFormProps) {
        super(props);
        const groupIds = props.accessOption.groups.map((group) => group.id);
        let accessOptionState = {
            groupIds,
            url: props.accessOption.url,
            id: props.accessOption.id,
        };
        let accessOptionStateOG = { ...accessOptionState };
        accessOptionStateOG.groupIds = [...accessOptionStateOG.groupIds];

        const queryObj = parse(this.props.location.search);
        this.state = {
            accessOption: accessOptionState,
            accessOptionOG: accessOptionStateOG,
            returnTo: queryObj.return_to as string | undefined,
        };
    }

    updateField(id: keyof CompanyAccessOptionState, event: FormEvent<HTMLInputElement>) {
        const { value } = event.target as HTMLInputElement;
        let accessOption = { ...this.state.accessOption };
        (accessOption[id] as string) = value;
        this.setState({ accessOption });
    }

    render() {
        const { t } = this.context;
        const isDirty = !deepEquals(this.state.accessOptionOG, this.state.accessOption);

        return (
            <UpdateCompanyAccessOptionGroupsMutation
                update={() => {
                    this.props.history.push(this.state.returnTo || PATHS.users);
                    Notifier.success(
                        t("users.invitation_settings.successfully_updated_groups", {
                            url: this.props.accessOption.url,
                        })
                    );
                }}
                variables={{ id: this.props.accessOption.id, companyId: this.props.companyId }}
                onError={(error) => Notifier.error(error)}
            >
                {(update, { loading, error }) => (
                    <>
                        <div className="ui two column grid">
                            <div className="eight wide column">{this.props.accessOption.url}</div>
                        </div>
                        <Form
                            onSubmit={(event) => {
                                event.preventDefault();
                                this.setState({ submitClicked: true });
                                if (!loading) {
                                    const groupIdsToAdd = this.state.accessOption.groupIds.filter(
                                        (id) => !this.state.accessOptionOG.groupIds.includes(id)
                                    );
                                    const groupIdsToRemove =
                                        this.state.accessOptionOG.groupIds.filter(
                                            (id) => !this.state.accessOption.groupIds.includes(id)
                                        );
                                    update({
                                        variables: {
                                            groupIdsToAdd,
                                            groupIdsToRemove,
                                            id: this.state.accessOption.id,
                                            companyId: this.props.companyId,
                                        },
                                    }).catch(Notifier.error);
                                }
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    marginTop: "1em",
                                }}
                            >
                                <FormField style={{ flexGrow: 1 }}>
                                    <UserGroupMultiSelect
                                        companyId={this.props.companyId}
                                        ids={this.state.accessOption.groupIds}
                                        onChange={(ids) => {
                                            let accessOption = this.state.accessOption;
                                            accessOption.groupIds = ids;
                                            this.setState({ accessOption });
                                        }}
                                    />
                                </FormField>
                            </div>
                            <FormField key="buttons">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: 25,
                                    }}
                                >
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            id="qa-companyAccessOption-cancel"
                                            type="button"
                                            secondary
                                            disabled={this.state.submitClicked}
                                            onClick={() =>
                                                this.props.history.push(
                                                    this.state.returnTo || PATHS.users
                                                )
                                            }
                                        >
                                            {t("forms.cancel")}
                                        </Button>
                                        <Button
                                            id="qa-companyAccessOption-submit"
                                            type="submit"
                                            className="primary right floated"
                                            disabled={!isDirty || this.state.submitClicked}
                                        >
                                            {t("forms.submit")}
                                        </Button>
                                    </div>
                                </div>
                            </FormField>
                            {error && <p className="ui negative message">{error.message}</p>}
                        </Form>
                    </>
                )}
            </UpdateCompanyAccessOptionGroupsMutation>
        );
    }
}
