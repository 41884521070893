import { useState } from "react";
import gql from "graphql-tag";
import styled, { Button } from "grabcad-ui-elements";
import { PaginationLinks } from "../../view/Navigation/PaginationLinks";
import { LicensePackage } from "../../graphql";
import { alphabetizeByProp } from "../../utils/sort";
import { PATHS } from "../../Routes";
import { useQueryWithCatch } from "../../utils/hooks/useQueryWithCatch";
import { IScreenProps } from "../Screen";
import { match } from "react-router-dom";
import { ProductFragments } from "../../graphql/Fragments/Product";

const PACKAGES_PER_PAGE = 20;

export const LIST_PACKAGES = gql`
    query listLicensePackages {
        listLicensePackages {
            packages {
                id
                name
                disabled
                product {
                    ...productFields
                }
                companies {
                    id
                    name
                }
            }
            totalCount
        }
    }
    ${ProductFragments.productFields}
`;

export interface ListLicensePackagesQueryResult {
    listLicensePackages: {
        packages: LicensePackage[];
        totalCount: number;
    };
}

const HighlightRow = styled.tr`
    cursor: pointer;
    &:hover {
        background-color: #deeeff;
    }
`;

interface ILicensePackageListProps extends IScreenProps {
    match: match<{ id?: string }>;
}

export const LicensePackageList: React.FC<ILicensePackageListProps> = (props) => {
    const [searchString, setSearchString] = useState("");
    const [page, setPage] = useState(1);

    const { loading, error, data } =
        useQueryWithCatch<ListLicensePackagesQueryResult>(LIST_PACKAGES);

    if (error) {
        return null;
    }
    if (loading || !data) {
        return <div className="ui loader active" />;
    }

    const { listLicensePackages } = data;

    let packages = [...listLicensePackages.packages];
    let packageCount = listLicensePackages.totalCount;
    let filteredPackageCount = listLicensePackages.totalCount;
    if (searchString) {
        packages = packages.filter((licensePackage: LicensePackage) => {
            let search = (searchString as string).toLowerCase();
            return (
                licensePackage.name.toLowerCase().includes(search) ||
                licensePackage.product?.name.toLowerCase().includes(search)
            );
        });
        filteredPackageCount = packages.length;
    }

    packages.sort(alphabetizeByProp("name"));
    packages = packages.slice((page - 1) * PACKAGES_PER_PAGE, page * PACKAGES_PER_PAGE);

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="page-header">
                    License Packages (
                    {filteredPackageCount !== packageCount
                        ? `${filteredPackageCount} of ${packageCount}`
                        : packageCount}
                    )
                </h2>
                <Button
                    id="qa-licensePackageList-newCompany"
                    className="ui button primary right floated"
                    type="submit"
                    onClick={() => props.history?.push(`${PATHS.licensePackage}/new`)}
                    style={{ height: "39px" }}
                >
                    New Package
                </Button>
            </div>
            <div className="ui left icon input">
                <i className="search icon" />
                <input
                    id="qa-licensePackageList-search"
                    type="text"
                    placeholder="Search..."
                    value={searchString}
                    onChange={(event) => {
                        setSearchString(event.currentTarget.value);
                        setPage(1);
                    }}
                />
            </div>

            <table className="ui table">
                <thead>
                    <tr>
                        <th>License Package Name</th>
                        <th>Product Name</th>
                        <th>Company Total</th>
                    </tr>
                </thead>
                <tbody>
                    {packages.map((licensePackage: LicensePackage) => (
                        <HighlightRow
                            data-testid="package-item"
                            key={licensePackage.id}
                            onClick={() =>
                                props.history?.push(`${PATHS.licensePackage}/${licensePackage.id}`)
                            }
                        >
                            <td data-label="license_package_name">
                                {licensePackage.name}
                                {licensePackage.disabled ? <b> (disabled)</b> : ""}
                            </td>
                            <td data-label="product_name">{licensePackage.product?.name}</td>
                            <td data-testid="company-total">{licensePackage.companies?.length}</td>
                        </HighlightRow>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={3}>
                            <PaginationLinks
                                total={filteredPackageCount}
                                perPage={PACKAGES_PER_PAGE}
                                currentPage={page}
                                goToPage={(p) => setPage(p)}
                            />
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};
