/**
 * Class representing API endpoints for interacting with Zeus.
 *
 * By sending the credentials with the request, the server will have access to the user's
 * authentication token (grabcad_jwt), which can be used for authentication and authorization.
 * However, it is already ensured that the server (Zeus) is configured to handle authentication and authorization logic based on the provided token.
 * This includes forwarding the token to the appropriate endpoints for authentication and
 * authorization checks.
 *
 * For more details, refer to the Zeus repository documentation:
 * [https://github.com/GrabCAD/zeus-gateway/blob/main/docs/control-api.md]
 *
 */

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

interface RequestOptions {
    method: HttpMethod;
    headers?: Record<string, string>;
    body?:
        | string
        | FormData
        | URLSearchParams
        | Blob
        | ArrayBufferView
        | ArrayBuffer
        | ReadableStream<Uint8Array>
        | null;
}

async function makeRequest<T>(url: string, method: HttpMethod, payload?: any): Promise<T> {
    const options: RequestOptions = {
        method,
        headers: {
            "Content-Type": "application/json",
        },
        body: payload ? JSON.stringify(payload) : undefined,
    };

    try {
        const response: Response = await fetch(url, { credentials: "include", ...options });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return (await response.json()) as T;
    } catch (error) {
        if (error instanceof TypeError) {
            // Handle network errors (e.g., fetch failed)
            throw new Error("Network error. Please check your internet connection.");
        } else {
            throw error; // Re-throw other errors
        }
    }
}

async function apiGet<T>(url: string): Promise<T> {
    return await makeRequest<T>(url, "GET");
}

async function apiPost<T>(url: string, payload: any): Promise<T> {
    return await makeRequest<T>(url, "POST", payload);
}

async function apiPut<T>(url: string, payload: any): Promise<T> {
    return await makeRequest<T>(url, "PUT", payload);
}

async function apiDelete<T>(url: string, payload?: any): Promise<T> {
    return await makeRequest<T>(url, "DELETE", payload);
}

export { apiGet, apiPost, apiPut, apiDelete };
