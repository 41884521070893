import { has } from "lodash";

import de from "./locales/de.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
import fr from "./locales/fr.json";
import it from "./locales/it.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import ru from "./locales/ru.json";
import zhCN from "./locales/zh-CN.json";
import zhTW from "./locales/zh-TW.json";

export const translations: { [key: string]: unknown } = {
    de,
    es,
    en,
    fr,
    it,
    ja,
    ko,
    ru,
    zh: zhCN,
    "zh-TW": zhTW,
};

export const isLanguageSupported = (languageCode: string): boolean => {
    return has(translations, languageCode);
};
