import gql from "graphql-tag";
import {
    LicenseData,
    LicenseAssignment,
    GroupData,
    User,
    ApplicationType,
    FeatureData,
} from "../../types";
import { LicenseFragments } from "../../Fragments/License";

export const GET_PRODUCT_WITH_LICENSES = gql`
    query product($id: Int!) {
        product(id: $id) {
            id
            name
            applicationType {
                id
            }
            licenses {
                ...licenseFields
                package {
                    id
                    name
                    unlimitedMaxUsers
                    companyWide
                }
            }
            licenseAssignments {
                user {
                    id
                    name
                    email
                }
                dateAssigned
                dateRevoked
            }
            groups {
                id
                name
                roles {
                    application {
                        name
                        dateDeleted
                    }
                    applicationRole {
                        name
                    }
                }
                users {
                    id
                    name
                    email
                }
            }
            restrictedUsers {
                id
                name
                email
            }
            features {
                id
                key
                isPrinterType
                isMaterialType
                isAssignedViaRoles
            }
        }
    }
    ${LicenseFragments.licenseFields}
`;

export interface GetProductLicensesQueryVariables {
    id: number;
}

export interface GetProductLicensesQueryResult {
    product: {
        id: number;
        name: string;
        applicationType?: ApplicationType;
        licenses: LicenseData[];
        licenseAssignments: LicenseAssignment[];
        groups: GroupData[];
        restrictedUsers: User[];
        features: Pick<
            FeatureData,
            "id" | "key" | "isPrinterType" | "isMaterialType" | "isAssignedViaRoles"
        >[];
    };
}
