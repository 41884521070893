import { CloudUnauthorizedProvider, getPrintersApi } from "grabcad-printers-api";
import { useContext } from "react";
import { UserContext } from "../../components/User/UserProvider";
import { config } from "../../config";

const WORKBENCH_COMPANY_ID_OFFSET = 1000000000;
export const usePrintersApi = () => {
    const { companyId } = useContext(UserContext);

    const workbenchifiedCompanyId =
        +((config.REACT_APP_SPOOFED_COMPANY_ID || companyId) as number) +
        WORKBENCH_COMPANY_ID_OFFSET;

    const provider = new CloudUnauthorizedProvider(
        config.REACT_APP_CLOUD_API_BASE_URL as string,
        workbenchifiedCompanyId
    );

    const api = getPrintersApi(provider);

    const renamePrinter = async (printerId: string, newName: string): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            await api
                .renamePrinter(printerId, newName)
                .then(() => resolve())
                .catch((error) => {
                    reject(error);
                });
        });
    };

    return [renamePrinter];
};
