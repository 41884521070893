import gql from "graphql-tag";
import React from "react";
import { StigData } from "../types";
import { Query } from "@apollo/client/react/components";
import { QueryOptions, QueryResult, ApolloError } from "@apollo/client";

const GET_COMPANY_STIGS = gql`
    query companyStigs {
        companyStigs {
            stigs {
                name
                url
                version
                lastModified
                sha256
                releaseNotesLink
            }
        }
    }
`;

export interface GetCompanyStigsQueryResult {
    companyStigs: {
        stigs: StigData[];
    };
}

export interface GetCompanyStigsQueryProps extends Omit<QueryOptions, "query"> {
    children: (result: QueryResult<GetCompanyStigsQueryResult>) => JSX.Element;
    displayName?: string;
    skip?: boolean;
    onCompleted?: (data: GetCompanyStigsQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class GetCompanyStigsQuery extends React.Component<GetCompanyStigsQueryProps> {
    render() {
        const { children, variables, ...props } = this.props;
        return (
            <Query<GetCompanyStigsQueryResult>
                query={GET_COMPANY_STIGS}
                fetchPolicy="no-cache"
                variables={variables}
                {...props}
            >
                {children}
            </Query>
        );
    }
}
