import { FunctionComponent, useContext } from "react";
import { DropdownProps, DropdownItemProps } from "semantic-ui-react";
import { Dropdown, StyledJoyride } from "grabcad-ui-elements";
import { Application, UserGroupRoleInput, LicenseData, GET_COMPANY_APPS } from "../../graphql";
import { ApplicationContext, type TranslationFunction } from "../ApplicationProvider";
import { useOnboarding } from "../../utils/hooks/onboarding";
import { useCompanyLicensesQuery } from "../../graphql/Queries/Licenses/GetCompanyLicenses";
import { useQueryWithCatch } from "../../utils/hooks/useQueryWithCatch";
import { uniqBy } from "lodash";

export interface RoleSelectorProps {
    id: string;
    roles: UserGroupRoleInput[];
    licenseIds: number[];
    groupLicenses: number[] | undefined;
    onChange(roles: UserGroupRoleInput[], licenseIds: number[]): void;
}

interface DropdownOption {
    value: string;
    label: string;
}

// MAXTODO: Make this less hacky
const NOT_APPLICATION_ID = "NOT_APPLICATION_ID";

function defaultValues(roles: UserGroupRoleInput[], licenseIds: number[]): string[] {
    const roleDefaults = roles.map((roleInput) => `${roleInput.applicationId} ${roleInput.role}`);
    const licenseDefaults = licenseIds.map((licenseId) => `${NOT_APPLICATION_ID} ${licenseId}`);
    const values = [...roleDefaults, ...licenseDefaults];
    return values;
}

interface productLicensesAndSeats {
    productName: string | undefined;
    licenseIds: Set<number>;
    availableSeats: number;
    unlimitedMaxUsers: boolean | undefined;
}

let productLicensesAndSeatsMap: { [productId: number]: productLicensesAndSeats };

function dropdownOptionsForApplications(
    applications: Application[],
    licenses: LicenseData[],
    t: TranslationFunction
): DropdownOption[] {
    let inputs: DropdownOption[] = [];
    // TODO: Maintain this list of not relevant ApplicationTypes in a more central location
    const applicationTypesToHide = ["api", "shop"];
    applications.forEach((application) => {
        const appName = application.name;
        application.applicationType.roles.forEach((role) => {
            inputs.push({
                value: `${application.id} ${role.name}`,
                label: `${appName} - ${role.name}`,
            });
        });
        // Don't show application types that have specific instances
        if (!applicationTypesToHide.includes(application.applicationType.id)) {
            applicationTypesToHide.push(application.applicationType.id);
        }
    });

    productLicensesAndSeatsMap = aggregateProductLicensesAndSeats(licenses, t);
    const uniqueLicenses = deduplicateLicesesByProductName(licenses);

    for (const license of uniqueLicenses) {
        if (
            license.package.product?.applicationType &&
            !applicationTypesToHide.includes(license.package.product?.applicationType?.id)
        ) {
            let name = license.package.product.name;

            const availableSeats =
                productLicensesAndSeatsMap[license.package.product.id].availableSeats;

            const seatInfo = productLicensesAndSeatsMap[license.package.product.id]
                .unlimitedMaxUsers
                ? t("license.unlimited")
                : `${availableSeats} ${t("license.available")}`;
            const isExpired =
                license.state === "expired" ? ` - ${t("license.states.expired")}` : "";
            inputs.push({
                value: `${NOT_APPLICATION_ID} ${license.id}`,
                label: `${name} (${seatInfo})${isExpired}`,
            });
        }
    }
    return inputs;
}

function roleInputsForData(data: DropdownProps): UserGroupRoleInput[] {
    const values = (data.value || []) as string[];
    const results: UserGroupRoleInput[] = [];
    for (const value of values) {
        const sep = value.indexOf(" ");
        const applicationId = value.substr(0, sep);
        if (applicationId === NOT_APPLICATION_ID) {
            continue;
        }
        const role = value.substr(sep + 1);
        results.push({
            applicationId,
            role,
        });
    }
    return results;
}

function productLicenseIdsFromData(data: DropdownProps): number[] {
    const values = (data.value || []) as string[];
    const results: number[] = [];
    for (const value of values) {
        const sep = value.indexOf(" ");
        const applicationId = value.substr(0, sep);
        if (applicationId !== NOT_APPLICATION_ID) {
            continue;
        }
        const licenseId = Number(value.substr(sep + 1));
        results.push(licenseId);
    }
    return results;
}

export const USER_ROLES = "user-roles";
const UserRoleOnboarding: FunctionComponent = () => {
    const { t } = useContext(ApplicationContext);
    const onboarding = useOnboarding();
    return onboarding.isCompleted(USER_ROLES) ? null : (
        <StyledJoyride
            steps={[
                {
                    target: ".role-selector",
                    title: t("onboarding.assign_roles.title"),
                    content: t("onboarding.assign_roles.content"),
                    placement: "bottom",
                },
            ]}
            dismissCopy={t("onboarding.dismiss")}
            callback={({ lifecycle }) => {
                if (lifecycle === "complete") {
                    void onboarding.setCompleted(USER_ROLES);
                }
            }}
        />
    );
};

export const RoleSelector: FunctionComponent<RoleSelectorProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    const { companyLicenses } = useCompanyLicensesQuery();
    const { data } = useQueryWithCatch<{ company: { applications: Application[] } }>(
        GET_COMPANY_APPS
    );
    if (!companyLicenses || !data?.company) {
        return null;
    }
    const applications = data.company.applications || [];
    const activeApplications = applications.filter((x) => x.visible && x.dateDeleted === null);

    const isAssignedToGroup = (id: number): boolean | undefined =>
        props.groupLicenses?.includes(id);
    const assignableLicenses =
        companyLicenses?.licenses.filter(
            (license) =>
                !license.package.companyWide &&
                (license.state !== "expired" || isAssignedToGroup(license.id))
        ) || [];
    const options = dropdownOptionsForApplications(activeApplications, assignableLicenses, t);
    const optionValues = options.map((o) => o.value);
    const defaultValue = defaultValues(props.roles, props.licenseIds).filter((d) =>
        optionValues.includes(d)
    );

    const dropdownProps = {
        id: props.id,
        className: "role-selector",
        disabled: !activeApplications.length && !assignableLicenses.length,
        placeholder:
            !activeApplications.length && !assignableLicenses.length
                ? t("roles.no_shops")
                : t("roles.multiple_shops"),
        defaultValue: defaultValue.length > 0 ? defaultValue : undefined,
        options: options,
        fluid: true,
        multiple: true,
        selection: true,
        onChange: (event: React.SyntheticEvent<HTMLElement>, _data: DropdownProps) => {
            /*The function productLicenseIdsFromData retrieves the license IDs from the selected dropdown options. 
            However, it doesn’t return all the associated license IDs for a product that has multiple licenses. 
            This is because the dropdown option values only contain unique license IDs. */
            const selectedLicenseIds = productLicenseIdsFromData(_data);
            /*The function licenseIdsAssociatedWithSelectedProducts retrieves all license IDs related 
            to the selected products. This includes scenarios where a single product has multiple licenses. */
            props.onChange(
                roleInputsForData(_data),
                licenseIdsAssociatedWithSelectedProducts(selectedLicenseIds)
            );
        },
        renderLabel: (item: DropdownItemProps) => item.label,
    };
    return (
        <>
            <Dropdown {...dropdownProps} />
            <UserRoleOnboarding />
        </>
    );
};

function aggregateProductLicensesAndSeats(
    licenses: LicenseData[],
    t: TranslationFunction
): { [productId: number]: productLicensesAndSeats } {
    const aggregatedProductLicensesAndSeatsMap: { [productId: number]: productLicensesAndSeats } =
        licenses.reduce(
            (
                accumulator: { [productId: number]: productLicensesAndSeats },
                license: LicenseData
            ) => {
                const unlimitedMaxUsers = license.package.unlimitedMaxUsers;
                const count = license.maxUsers - license.seatsUsed;
                const productName = license.package.product?.name;
                const licenseId = license.id;
                const productId = license.package.product?.id;

                const existingEntry = accumulator[productId!];

                if (existingEntry) {
                    existingEntry.availableSeats = existingEntry.availableSeats + count;
                    existingEntry.licenseIds.add(licenseId);
                    existingEntry.unlimitedMaxUsers =
                        existingEntry.unlimitedMaxUsers || unlimitedMaxUsers;
                } else {
                    accumulator[productId!] = {
                        productName: productName,
                        licenseIds: new Set([licenseId]),
                        availableSeats: count,
                        unlimitedMaxUsers: unlimitedMaxUsers,
                    };
                }
                return accumulator;
            },
            {}
        );
    return aggregatedProductLicensesAndSeatsMap;
}

function deduplicateLicesesByProductName(licenses: LicenseData[]): LicenseData[] {
    return uniqBy(licenses, (license: LicenseData) => license.package.product?.id);
}

function licenseIdsAssociatedWithSelectedProducts(uniqueLicenseIds: number[]): number[] {
    let licenseIdsList: Set<number> = new Set();

    for (const licenseId of uniqueLicenseIds) {
        for (const productLicensesAndSeats of Object.values(productLicensesAndSeatsMap)) {
            if (productLicensesAndSeats.licenseIds.has(licenseId)) {
                productLicensesAndSeats.licenseIds.forEach((v) => licenseIdsList.add(v));
                break;
            }
        }
    }

    return Array.from(licenseIdsList);
}
