import styled, { Button, Checkbox, Image, Table } from "grabcad-ui-elements";
import { useContext, useState } from "react";
import { ButtonProps } from "semantic-ui-react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import {
    DecoratedHeader,
    FieldPlaceholderValue,
    FormEditableField,
    FormEditableFieldProps,
    FormFieldMeta,
    notEmptyValidator,
    OnFormEditableFieldChange,
} from "../../components/FormUtils";
import {
    MaterialEntitlementRowState,
    PrinterVendorType,
    stringToPrinterVendorType,
} from "../../graphql/types";
import TrashIcon from "../../images/trash.svg";

const TableData = styled.td``;

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 4px;

    :hover {
        background: #e0ecfc;
        fill: #f00;
        cursor: pointer;
    }
`;

type MaterialFieldMeta = FormFieldMeta<MaterialEntitlementRowState>;

type MaterialState = Partial<MaterialEntitlementRowState>;

type OnMaterialChange = OnFormEditableFieldChange<MaterialEntitlementRowState>;
type OnDeleteRequest = (index: number) => void;

interface IEditMaterialListProps {
    title: string;
    materialEntitlements?: MaterialState[];
    onChange?: OnMaterialChange;

    // Omit this if Add button should _not_ be shown
    onAddButtonPressed?: () => void;

    // Omit this if Delete button should _not_ be shown
    onDeleteButtonPressed?: OnDeleteRequest;
    isMatchAny?: boolean;
    toggleMatchAny?: () => void;
}

// Copied from ILicenseFormProps in License.tsx
export interface IMaterialListProps extends IEditMaterialListProps {
    // role: Role;
    readOnly?: boolean;
}

// Adapted from License.tsx <LicenseForm> -> getMachineForm()
/**
 * React component to show/edit materialEntitlements associated with a License [assigned to company]
 */
export const MaterialEntitlementList: React.FunctionComponent<IMaterialListProps> = ({
    title,
    materialEntitlements,
    readOnly,
    onChange,
    onAddButtonPressed,
    onDeleteButtonPressed,
    isMatchAny,
    toggleMatchAny,
}: IMaterialListProps) => {
    const { t } = useContext(ApplicationContext);
    const renderButton = () => {
        if (!readOnly) {
            return <AddButton disabled={isMatchAny} onClick={onAddButtonPressed} />;
        }
    };
    // NB: Bottom margin needed to make sure there is gutter spacing between LicenseForm.notesField + MaterialEntitlementList for printer-type features
    return (
        <div style={{ margin: "20px 0", paddingTop: 0 }}>
            {renderButton()}
            {toggleMatchAny && (
                <Checkbox
                    toggle
                    label="Any Material"
                    checked={isMatchAny}
                    className="ui right floated"
                    style={{ float: "right", padding: "0.5em" }}
                    onChange={toggleMatchAny}
                />
            )}
            <h3>{title}</h3>
            <Table>
                <thead>
                    <tr>
                        <DecoratedHeader meta={TECHNOLOGY_FIELD_META}>
                            {t("materials.material_vendor")}
                        </DecoratedHeader>
                        <DecoratedHeader meta={NAME_FIELD_META}>
                            {t("materials.material_name")}
                        </DecoratedHeader>
                        {onDeleteButtonPressed && <DeleteColumnHeader />}
                    </tr>
                </thead>
                <tbody>
                    {!isMatchAny && (
                        <MaterialRows
                            materialEntitlements={materialEntitlements}
                            readOnly={readOnly}
                            onChange={onChange}
                            onDeleteButtonPressed={onDeleteButtonPressed}
                        />
                    )}
                </tbody>
            </Table>
        </div>
    );
};

const AddButton = (props: ButtonProps) => {
    const { t } = useContext(ApplicationContext);
    return (
        <Button
            id="qa-materialEntitlementList-newMaterial"
            className="ui button primary right floated"
            style={{ margin: "0 0 5px" }}
            type="button"
            {...props}
        >
            {t("materials.add_material")}
        </Button>
    );
};

const DeleteColumnHeader = () => {
    return <th style={{ width: "35px", paddingLeft: "0" }} />;
};

const EditableMaterialField: React.FunctionComponent<
    FormEditableFieldProps<MaterialEntitlementRowState>
> = (props) => (
    <FormEditableField<MaterialEntitlementRowState> idPrefix="qa-material-input-" {...props} />
);

const ReadonlyMaterialField = ({ value = "" }: { value: string | null }) => {
    const { t } = useContext(ApplicationContext);
    return (
        <TableData>
            {value === null ? (
                <FieldPlaceholderValue value={t("materials.any")} />
            ) : value ? (
                value
            ) : (
                <FieldPlaceholderValue value={t("materials.unknown")} />
            )}
        </TableData>
    );
};

const MaterialFormField = ({
    index,
    value = "",
    meta,
    readOnly,
    isPlaceholder,
    isDuplicate,
    onChange,
    resetStateCounter,
}: {
    index: number;
    value?: string | null;
    meta: MaterialFieldMeta;
    readOnly?: boolean;
    isPlaceholder?: boolean;
    isDuplicate?: boolean;
    onChange?: OnMaterialChange;
    resetStateCounter?: number;
}) => {
    const fieldMeta = { ...meta };
    if (isPlaceholder) {
        fieldMeta.validator = undefined;
    }

    return readOnly ? (
        <ReadonlyMaterialField value={value} />
    ) : (
        <EditableMaterialField
            index={index}
            value={value}
            meta={fieldMeta}
            onChange={onChange}
            resetStateCounter={resetStateCounter}
            isDuplicate={isDuplicate}
        />
    );
};

const TECHNOLOGY_FIELD_META: MaterialFieldMeta = {
    name: "technology",
    // "E.g., pj, stratasys, pbf"
    placeholder: `Any e.g., ${Object.keys(PrinterVendorType).join(", ")}`,
    isRequired: true,
    validator: (val, isDup) =>
        !isDup && (val === null || val === "" || !!stringToPrinterVendorType(val)),
};

const NAME_FIELD_META: MaterialFieldMeta = {
    name: "name",
    placeholder: "Any e.g., bonematrix",
    isRequired: true,
    validator: (val, isDup) => !isDup && notEmptyValidator(val),
};

const MaterialRow = ({
    index,
    material,
    readOnly,
    onChange,
    onDeleteButtonPressed,
    disableDeleteButton,
}: {
    index: number;
    material: MaterialState;
    readOnly?: boolean;
    onChange?: OnMaterialChange;
    onDeleteButtonPressed?: OnDeleteRequest;
    disableDeleteButton?: boolean;
}): JSX.Element => {
    const [resetStateCounter, setResetStateCounter] = useState(0);
    return (
        <tr key={index}>
            <MaterialFormField
                index={index}
                value={material.technology}
                meta={TECHNOLOGY_FIELD_META}
                readOnly={readOnly}
                isPlaceholder={material.isPlaceholder}
                onChange={onChange}
                resetStateCounter={resetStateCounter}
                isDuplicate={material.isDuplicate}
            />
            <MaterialFormField
                index={index}
                value={material.name}
                meta={NAME_FIELD_META}
                readOnly={readOnly}
                isPlaceholder={material.isPlaceholder}
                onChange={onChange}
                resetStateCounter={resetStateCounter}
                isDuplicate={material.isDuplicate}
            />
            {onDeleteButtonPressed && (
                <DeleteButton
                    id={`qa-material-input-delete-${index}`}
                    onClick={
                        !onDeleteButtonPressed || disableDeleteButton
                            ? undefined
                            : () => {
                                  setResetStateCounter(resetStateCounter + 1);
                                  onDeleteButtonPressed(index);
                              }
                    }
                />
            )}
        </tr>
    );
};

const MaterialRows = ({
    materialEntitlements,
    readOnly,
    onChange,
    onDeleteButtonPressed,
}: {
    materialEntitlements?: MaterialState[];
    readOnly?: boolean;
    onChange?: OnMaterialChange;
    onDeleteButtonPressed?: OnDeleteRequest;
}) => {
    let disableDeleteButton = false;
    if (!materialEntitlements || (materialEntitlements.length === 0 && !readOnly)) {
        // Always show at least 1 placeholder item [even when data model is empty].
        // Saves the user (global admin) the effort of clicking on "Add Material".
        materialEntitlements = [{ isPlaceholder: true }];

        // placeholder material cannot be deleted (there's nothing in data model to delete)
        // => disable delete button [if it exists]
        disableDeleteButton = true;
    }
    const rows: JSX.Element[] = materialEntitlements.map((material, i) => (
        <MaterialRow
            key={i}
            index={i}
            material={material}
            // Do not allow existing materialEntitlements (i.e., those with IDs) to be
            // editable as server expects them to be immutable. Only allow for
            // material add/deleted. NB: At the time of this writing, we echo
            // back all values to server on no-change. This will "look" like a
            // GQL modification/update, but since all values match the original
            // values, the server will treat as no-op for those materialEntitlements with IDs.
            readOnly={readOnly || material.id !== undefined}
            onChange={onChange}
            onDeleteButtonPressed={onDeleteButtonPressed}
            disableDeleteButton={disableDeleteButton}
        />
    ));

    return <>{rows}</>;
};

const DeleteButton = ({ id, onClick }: { id?: string; onClick?: () => void }) => {
    const disabled = !onClick;
    return (
        <td style={{ paddingLeft: "0", width: "32px" }}>
            <ImageContainer onClick={onClick}>
                <Image id={id} src={TrashIcon} disabled={disabled} />
            </ImageContainer>
        </td>
    );
};
