import gql from "graphql-tag";

export const CREATE_USER_GROUP = gql`
    mutation createUserGroup(
        $name: String!
        $userIds: [Int!]!
        $roles: [UserGroupRoleInput!]
        $licenseIds: [Int!]
        $sendEmails: Boolean
        $adminName: String
    ) {
        createUserGroup(
            name: $name
            userIds: $userIds
            roles: $roles
            licenseIds: $licenseIds
            sendEmails: $sendEmails
            adminName: $adminName
        ) {
            id
            name
            users {
                id
                hasRegistered
            }
        }
    }
`;

export const UPDATE_USER_GROUP = gql`
    mutation updateUserGroup(
        $id: Int!
        $userIdsToAdd: [Int!]
        $userIdsToRemove: [Int!]
        $name: String
        $licenseIdsToAdd: [Int!]
        $licenseIdsToRemove: [Int!]
        $rolesToAdd: [UserGroupRoleInput!]
        $rolesToRemove: [UserGroupRoleInput!]
    ) {
        updateUserGroup(
            id: $id
            userIdsToAdd: $userIdsToAdd
            userIdsToRemove: $userIdsToRemove
            name: $name
            licenseIdsToAdd: $licenseIdsToAdd
            licenseIdsToRemove: $licenseIdsToRemove
            rolesToAdd: $rolesToAdd
            rolesToRemove: $rolesToRemove
        ) {
            id
            name
            users {
                id
            }
            licenses {
                id
            }
            roles {
                application {
                    id
                }
                applicationRole {
                    name
                }
            }
        }
    }
`;

export const SEND_NOTIFICATIONS = gql`
    mutation sendNotifications(
        $id: Int!
        $name: String
        $userIdsToAdd: [Int!]
        $licenseIdsToAdd: [Int!]
        $rolesToAdd: [UserGroupRoleInput!]
        $adminName: String
    ) {
        sendNotifications(
            id: $id
            name: $name
            userIdsToAdd: $userIdsToAdd
            licenseIdsToAdd: $licenseIdsToAdd
            rolesToAdd: $rolesToAdd
            adminName: $adminName
        )
    }
`;

export const DELETE_USER_GROUP = gql`
    mutation deleteUserGroup($id: Int!) {
        deleteUserGroup(id: $id)
    }
`;
