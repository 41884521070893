import React from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationOptions, MutationFunction, MutationResult, ApolloError } from "@apollo/client";

export const REMOVE_USER_FROM_GROUPS = gql`
    mutation updateUser($email: String!, $groupIdsToRemove: [Int!]!) {
        updateUser(email: $email, data: { groupIdsToRemove: $groupIdsToRemove }) {
            id
        }
    }
`;

export interface RemoveUserFromGroupsVariables {
    email: string;
    groupIdsToRemove: number[];
}

export interface RemoveUserFromGroupsResult {
    updateUser: {
        id: number;
    };
}

export interface RemoveUserFromGroupsProps
    extends Omit<
        MutationOptions<RemoveUserFromGroupsResult, RemoveUserFromGroupsVariables>,
        "mutation"
    > {
    children: (
        mutateFn: MutationFunction<RemoveUserFromGroupsResult, RemoveUserFromGroupsVariables>,
        result: MutationResult<RemoveUserFromGroupsResult>
    ) => JSX.Element;
    onError?: (error: ApolloError) => void;
}

export class RemoveUserFromGroupsMutation extends React.Component<RemoveUserFromGroupsProps> {
    render() {
        const { children, ...props } = this.props;

        return (
            <Mutation<RemoveUserFromGroupsResult, RemoveUserFromGroupsVariables>
                mutation={REMOVE_USER_FROM_GROUPS}
                {...props}
            >
                {children}
            </Mutation>
        );
    }
}
