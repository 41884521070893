import { FunctionComponent, useContext } from "react";
import { Dropdown } from "grabcad-ui-elements";
import { DropdownProps, DropdownItemProps } from "semantic-ui-react";
import { ApplicationContext } from "./ApplicationProvider";
import { timezones } from "../utils/timezones";
import moment from "moment-timezone";
import { padStart } from "lodash";

function computeOffsetString(zone: string): string {
    const theTz = moment().tz(zone);
    const utcOffsetHours = Math.floor(theTz.utcOffset() / 60);
    const utcOffsetMinutes = theTz.utcOffset() % 60;
    const prefix = utcOffsetHours < 0 ? "-" : "+";
    const hourString = padStart(`${Math.abs(utcOffsetHours)}`, 2, "0");
    const minuteString = padStart(`${Math.abs(utcOffsetMinutes)}`, 2, "0");
    return `(UTC${prefix}${hourString}:${minuteString})`;
}

const TIMEZONE_OPTIONS: { text: string; value: string }[] = Object.keys(timezones)
    .sort()
    .sort((left, right) => {
        const leftTz = moment().tz(left);
        const rightTz = moment().tz(right);
        if (leftTz.utcOffset() < rightTz.utcOffset()) {
            return -1;
        }
        if (leftTz.utcOffset() > rightTz.utcOffset()) {
            return 1;
        }
        return 0;
    })
    .map((zone) => {
        return { text: `${computeOffsetString(zone)} ${zone.replace("_", " ")}`, value: zone };
    });

export interface TimezoneSelectorProps {
    id: string;
    disabled: boolean;
    selectedTimezone: string | undefined;
    onChange(event: React.SyntheticEvent<HTMLElement>, data: string): void;
}

export const TimezoneSelector: FunctionComponent<TimezoneSelectorProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    const dropdownProps = {
        id: props.id,
        className: "role-selector",
        disabled: props.disabled,
        placeholder: t("settings.select_timezone"),
        options: TIMEZONE_OPTIONS,
        fluid: true,
        multiple: false,
        selection: true,
        onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
            props.onChange(event, data.value as string);
        },
        renderLabel: (item: DropdownItemProps) => item.label,
        value: props.selectedTimezone,
    };
    return (
        <>
            <Dropdown {...dropdownProps} />
        </>
    );
};
