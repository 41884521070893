import { useState } from "react";
import styled, { Button, Checkbox } from "grabcad-ui-elements";
import { Notifier } from "../../view/Notifier";
import { useListStaleCompanies } from "../../graphql/Queries/Companies/ListStaleCompanies";
import { DeleteCompaniesConfirmation } from "../../view/Modals/DeleteCompaniesConfirmation";

const HighlightRow = styled.tr`
    &:hover {
        background-color: #deeeff;
    }
`;

const HighlightEntry = styled.td`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: #4183c4;
    }
`;

const MonthsInput = styled.input`
    width: 48px;
`;

const HardDeleteOption = styled(Checkbox)`
    margin-top: 12px;
    display: inline-block;
`;

export const StaleCompanies = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [hardDelete, setHardDelete] = useState(true);

    const [selectedCompanyIds, setSelectedCompanyIds] = useState<number[]>([]);
    const [trialMonthsExpired, setTrialMonthsExpired] = useState<number>(6);
    const { loading, error, data, refetch } = useListStaleCompanies(trialMonthsExpired);
    if (error) {
        Notifier.error(error);
        return null;
    }
    const companies = data?.listCompanies.companies ?? [];

    const allSelected = selectedCompanyIds.length === companies.length;
    const someSelected =
        !!selectedCompanyIds.length && selectedCompanyIds.length < companies.length;
    const deselectAll = () => setSelectedCompanyIds([]);
    const toggleAllSelected = () =>
        allSelected ? deselectAll() : setSelectedCompanyIds(companies.map((c) => c.id));
    const selectCompany = (companyId: number) =>
        selectedCompanyIds.includes(companyId)
            ? setSelectedCompanyIds(selectedCompanyIds.filter((id) => id !== companyId))
            : setSelectedCompanyIds([...selectedCompanyIds, companyId]);

    const onDelete = () => {
        Notifier.success("Successfully deleted stale companies");
        deselectAll();
        void refetch();
    };

    return (
        <>
            <h1>Stale Companies</h1>
            <>
                {"Includes companies with no licenses or trial licenses expired more than "}
                <MonthsInput
                    type="number"
                    min={0}
                    value={trialMonthsExpired}
                    onChange={(e) => {
                        const value = parseInt(e.target.value || "0", 10);
                        setTrialMonthsExpired(value);
                        deselectAll();
                    }}
                />
                {" months ago."}
            </>

            <table className="ui table">
                <thead>
                    <tr>
                        <th>
                            <Checkbox
                                onChange={toggleAllSelected}
                                checked={allSelected}
                                indeterminate={someSelected}
                            />
                        </th>
                        <th>Name</th>
                    </tr>
                </thead>

                {loading || !data ? (
                    <tbody>
                        <tr>
                            <td>
                                <div className="ui loader active" />
                            </td>
                        </tr>
                    </tbody>
                ) : (
                    <>
                        <tbody>
                            {companies.map((company) => (
                                <HighlightRow
                                    key={company.id}
                                    onClick={() => selectCompany(company.id)}
                                >
                                    <HighlightEntry>
                                        <Checkbox
                                            checked={selectedCompanyIds.includes(company.id)}
                                        />
                                    </HighlightEntry>
                                    <HighlightEntry data-label="Name">
                                        {company.name}
                                    </HighlightEntry>
                                </HighlightRow>
                            ))}
                        </tbody>

                        <tfoot>
                            <tr>
                                <th colSpan={2}>
                                    <HardDeleteOption
                                        label="Purge (Hard Delete)"
                                        checked={hardDelete}
                                        onChange={() => setHardDelete((d) => !d)}
                                    />
                                    <Button
                                        className="ui button negative right floated"
                                        type="submit"
                                        onClick={() => setModalOpen(true)}
                                        disabled={!selectedCompanyIds.length}
                                    >
                                        Delete
                                    </Button>

                                    <DeleteCompaniesConfirmation
                                        ids={selectedCompanyIds}
                                        hardDelete={hardDelete}
                                        update={onDelete}
                                        open={modalOpen}
                                        onClose={() => setModalOpen(false)}
                                    />
                                </th>
                            </tr>
                        </tfoot>
                    </>
                )}
            </table>
        </>
    );
};
