import gql from "graphql-tag";

/**
 * List all user group printer group relations (for
 * the current company). We deliberately do not select any properties
 * other than ids of the printerGroups and userGroups, in order to avoid
 * cache update problems
 */
export const USER_GROUP_PRINTER_GROUP_ACCESSES = gql`
    query userGroupPrinterGroupAccesses {
        userGroupPrinterGroupAccesses {
            id
            printerGroup {
                id
            }
            userGroup {
                id
            }
        }
    }
`;
