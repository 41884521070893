import React from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryOptions, QueryResult, ApolloError } from "@apollo/client";

export const USER_ROLES_QUERY = gql`
    {
        userRoles {
            isGlobalAdmin
            isCompanyAdmin
            user {
                id
                name
                email
            }
            company {
                id
                urlPrefix
            }
        }
    }
`;
export interface UserRolesQueryResult {
    userRoles: {
        id: number; // This is the user's ID... seems unnecessary to pretend userRoles is an Entity?
        isGlobalAdmin: boolean;
        isCompanyAdmin: boolean;
        user: {
            id: number;
            name: string;
            email: string;
        };
        company?: {
            id: number;
            urlPrefix?: string; // This is largely tech debt, used in <Routes>, probably unnecessarily.
        };
    };
}

export interface UserRolesQueryProps extends Omit<QueryOptions, "query"> {
    children: (result: QueryResult<UserRolesQueryResult>) => JSX.Element | null;
    displayName?: string;
    skip?: boolean;
    onCompleted?: (data: UserRolesQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class UserRolesQuery extends React.Component<UserRolesQueryProps> {
    render() {
        const { children, ...props } = this.props;

        return (
            <Query<UserRolesQueryResult> query={USER_ROLES_QUERY} {...props}>
                {children}
            </Query>
        );
    }
}
