import styled, { Checkbox } from "grabcad-ui-elements";

export const NotifyAdminsCheckBox = styled(Checkbox)`
    display: flex !important;
    justify-content: center;
    justify-items: center;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
`;
