import gql from "graphql-tag";
import {
    useMutation,
    MutationUpdaterFunction,
    ApolloCache,
    InMemoryCache,
    DataProxy,
    Context,
} from "@apollo/client";
import { Preference } from "../types";
import { UserPreferencesQueryResult, USER_PREFERENCES_QUERY } from "../Queries/UserPreferences";
import { cloneDeep } from "lodash";

export const UPDATE_PREFERENCE = gql`
    mutation updatePreference($key: String!, $value: Any!) {
        updatePreference(key: $key, value: $value) {
            id
            key
            value
        }
    }
`;

interface UpdatePreferencesMutationResult {
    updatePreference: Preference;
}

const updateCache: MutationUpdaterFunction<
    UpdatePreferencesMutationResult,
    Preference,
    Context,
    ApolloCache<InMemoryCache>
> = (cache: DataProxy, { data }) => {
    const userPreferencesQueryResult = cache.readQuery<UserPreferencesQueryResult>({
        query: USER_PREFERENCES_QUERY,
    });
    if (data?.updatePreference && userPreferencesQueryResult) {
        const newData = cloneDeep(userPreferencesQueryResult);
        newData.userRoles.user.preferences = [
            ...(userPreferencesQueryResult.userRoles.user.preferences.filter(
                (pref: Preference) => pref.key !== data.updatePreference.key
            ) ?? []),
            data.updatePreference,
        ];

        cache.writeQuery<UserPreferencesQueryResult>({
            data: newData,
            query: USER_PREFERENCES_QUERY,
        });
    }
};

export function useUpdateUserPreference(key: string) {
    const [update] = useMutation<UpdatePreferencesMutationResult, Preference>(UPDATE_PREFERENCE, {
        update: updateCache,
    });

    return {
        updateValue: (value: Preference["value"]) => update({ variables: { key, value } }),
    };
}
