import React from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryOptions, QueryResult, ApolloError } from "@apollo/client";
import { ProductData } from "../../types";

export const GET_PRODUCT = gql`
    query product($id: Int!) {
        product(id: $id) {
            id
            name
            disabled
            applicationType {
                id
                description
            }
            features {
                id
                key
                applicationType {
                    id
                }
                displayName
            }
        }
    }
`;

export interface GetProductQueryVariables {
    id: number;
}

export interface GetProductQueryResult {
    product: ProductData;
}

export interface GetProductQueryProps
    extends Omit<QueryOptions<GetProductQueryVariables>, "query"> {
    children: (result: QueryResult<GetProductQueryResult, GetProductQueryVariables>) => JSX.Element;
    displayName?: string;
    skip?: boolean;
    onCompleted?: (data: GetProductQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class GetProductQuery extends React.Component<GetProductQueryProps> {
    render(): JSX.Element {
        const { children, variables, ...props } = this.props;

        return (
            <Query<GetProductQueryResult, GetProductQueryVariables>
                query={GET_PRODUCT}
                variables={variables}
                {...props}
            >
                {children}
            </Query>
        );
    }
}
