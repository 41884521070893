import React, { useContext } from "react";
import moment from "moment";
import styled, { Icon, Image as GrabcadImage, Progress } from "grabcad-ui-elements";
import { getPrinterByName } from "@grabcad/printer-information";
import { IPrinter } from "grabcad-printers-api";
import { ApplicationContext } from "../../components/ApplicationProvider";
import DefaultPrinterImage from "../../images/default.png";
import { PrinterStatusLabel } from "./PrinterStatusLabel";
import { PrinterStatus } from "../../graphql/types";

interface HighlightableTextProps {
    text?: string;
    highlights?: string;
}

// TODO: Share via grabcad-ui-components
export class HighlightableText extends React.PureComponent<HighlightableTextProps> {
    static defaultProps: Partial<HighlightableTextProps> = {
        highlights: "",
        text: "",
    };

    render(): React.ReactNode[] {
        let text = this.props.text;
        if (text === undefined) {
            return [text];
        }

        if (this.props.highlights === undefined) {
            return [text];
        }
        let textLower = text.toLowerCase();
        let hiliteChars: boolean[] = [];
        let i;
        for (i = 0; i < text.length; i++) {
            hiliteChars.push(false);
        }

        if (textLower.includes(this.props.highlights.toLowerCase())) {
            let hiliteStart = textLower.indexOf(this.props.highlights.toLowerCase());
            for (i = 0; i < this.props.highlights.length; i++) {
                hiliteChars[hiliteStart + i] = true;
            }
        }

        let output: React.ReactNode[] = [];
        i = 0;
        while (i < text.length) {
            if (hiliteChars[i]) {
                let hilitedText = "";
                while (hiliteChars[i] && i < text.length) {
                    hilitedText += text[i];
                    i++;
                }
                output.push(
                    <span style={{ background: "#82CEEF" }} key={i + hilitedText}>
                        {hilitedText}
                    </span>
                );
            } else {
                let unhilitedText = "";
                while (!hiliteChars[i] && i < text.length) {
                    unhilitedText += text[i];
                    i++;
                }
                output.push(unhilitedText);
            }
        }

        return output;
    }
}

interface PrinterCardProps {
    printer: IPrinter;
    selected?: boolean;
    searchString?: string;
    onClick?: (printerId: string) => void;
}

export const PrinterCard = ({ printer, selected, searchString, onClick }: PrinterCardProps) => {
    const { t } = useContext(ApplicationContext);
    // printer details
    const printerId = printer.getId();
    const printerType = printer.getModelName() || t("machines.unknown");
    const printerName = printer.getName() || printerType;
    const printerImage = getPrinterByName(printerType)?.imageUri || DefaultPrinterImage;
    const printerStatus = printer.getStatus().getDisplayState();

    // printer job details
    const job = printer.getJobs().getCurrent();
    const currentJob = {
        name: job?.getName(),
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        progress: Math.floor(job?.getProgress()! * 100),
        endTime: moment(job?.getEndDate()).format("MMMM D h:mm A"),
    };

    // TODO UI tags/indicators for printer groups

    return (
        <StyledContainer
            onClick={() => onClick?.(printerId)}
            selected={selected}
            className="qa-printer-card"
        >
            <StyledMainContent>
                <StyledImage src={printerImage} />

                <StyledInformation>
                    <div>
                        <StyledName>
                            <HighlightableText text={printerName} highlights={searchString} />
                        </StyledName>

                        <StyledType className="subheading">
                            <HighlightableText text={printerType} highlights={searchString} />
                        </StyledType>
                    </div>

                    <PrinterStatusLabel printer={printer} />
                </StyledInformation>
            </StyledMainContent>

            {/* only render printer job status if proper statuses are set AND all job data is valid */}
            <StyledJobContainer>
                {printerStatus !== PrinterStatus.Idle &&
                    printerStatus !== PrinterStatus.PendingStart &&
                    Object.values(currentJob).every(
                        // verify all job details are truthy or a number
                        // the number check is because '0' (number) is falsy in JavaScript
                        (jobDetail) => Boolean(jobDetail) || typeof jobDetail === "number"
                    ) && (
                        <>
                            <StyledJobDetails>
                                <StyledJobName>{currentJob.name}</StyledJobName>

                                <StyledJobProgress>{currentJob.progress}%</StyledJobProgress>
                            </StyledJobDetails>

                            <StyledJobProgressBar
                                percent={currentJob.progress}
                                size="tiny"
                                color="blue"
                            />

                            <StyledJobEndTime>
                                {t("printers.end_time")}
                                {currentJob.endTime}
                            </StyledJobEndTime>
                        </>
                    )}
            </StyledJobContainer>

            <StyledSelectedIndicator>
                {selected && <StyledCheckIcon name="check circle" size="large" />}
            </StyledSelectedIndicator>
        </StyledContainer>
    );
};

const StyledContainer = styled.div<{ selected?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 262px;
    background: ${({ selected }) => (selected ? "#f2f8ff" : "#fff")};
    border: ${({ selected }) => (selected ? "2px solid #91a9d4" : "1px solid #ddd")};
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    max-width: 450px;

    &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }
`;

const StyledMainContent = styled.div`
    display: flex;
    flex-basis: 60%;
    padding: 20px;

    .heading {
        font-weight: bold;
        font-size: 16px;
        color: #444;
    }

    .subheading {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #999;
    }
`;

const StyledImage = styled(GrabcadImage)`
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 130px;
    height: 130px;
    min-width: 130px;
    min-height: 130px;
`;

const StyledInformation = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1rem;
    overflow: auto;
`;

const StyledName = styled.p.attrs({ className: "qa-printer-name" })`
    margin-bottom: 2px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledType = styled.p``;

const StyledJobContainer = styled.div`
    flex-basis: 20%;
    padding: 0 20px;
`;

const StyledJobDetails = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledJobName = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 12px;
`;

const StyledJobProgress = styled.span``;

export const StyledJobProgressBar = styled(Progress)`
    margin: 0.4rem 0 !important;
    flex: 1 1 auto;
    &.ui.progress {
        .bar {
            /* unset default minimum width from library */
            min-width: 0;
            background: #003393;
        }
    }
`;

const StyledJobEndTime = styled.span``;

const StyledSelectedIndicator = styled.div`
    display: flex;
    flex-direction: row-reverse;
    flex-basis: 20%;
    align-items: flex-end;
`;

const StyledCheckIcon = styled(Icon)`
    margin: 0 10px 10px 0 !important;
    color: #003393;
`;
