import { FunctionComponent, useContext } from "react";
import { DocumentNode } from "graphql";
import { Mutation } from "@apollo/client/react/components";
import {
    ApolloCache,
    Context,
    InMemoryCache,
    MutationUpdaterFunction,
    PureQueryOptions,
} from "@apollo/client";
import { Button, Header, Modal } from "grabcad-ui-elements";
import { IErrorObject, Notifier } from "../Notifier";
import { ApplicationContext } from "../../components/ApplicationProvider";

interface IPaginationLinksProps {
    entity: { id: number | undefined; name: string | undefined };
    open: boolean;
    onClose: () => void;
    type?: string;
    mutation: DocumentNode;
    update?: MutationUpdaterFunction<any, any, Context, ApolloCache<InMemoryCache>>;
    refetchQueries?: (string | PureQueryOptions)[];
    header?: string;
}
export const DeleteEntityConfirmation: FunctionComponent<IPaginationLinksProps> = (props) => {
    const { t } = useContext(ApplicationContext);

    return (
        <Mutation<void, { id: number | undefined }>
            mutation={props.mutation}
            update={props.update}
            refetchQueries={props.refetchQueries}
            onError={(error: Partial<IErrorObject>) => Notifier.error(error)}
        >
            {(update) => (
                <Modal basic size="small" open={props.open} onClose={props.onClose}>
                    <Header
                        icon="trash"
                        content={
                            props.header ??
                            t("delete_entity.header", {
                                type: props.type,
                            })
                        }
                    />
                    <Modal.Content>
                        <h3>{props.entity.name}</h3>
                        <p>{t("delete_entity.warning")}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            id="qa-deleteEntityConfirmation-cancel"
                            secondary
                            onClick={props.onClose}
                        >
                            {t("forms.cancel")}
                        </Button>
                        <Button
                            id="qa-deleteEntityConfirmation-delete"
                            negative
                            onClick={() => {
                                update({
                                    variables: {
                                        id: props.entity.id,
                                    },
                                }).catch(Notifier.error);
                                props.onClose();
                            }}
                        >
                            {t("delete_entity.delete")}
                        </Button>
                    </Modal.Actions>
                </Modal>
            )}
        </Mutation>
    );
};
