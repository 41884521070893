import { FunctionComponent, useContext } from "react";
import { match } from "react-router-dom";
import styled, { Image, Button, Loader } from "grabcad-ui-elements";
import { IScreenProps } from "../../screens/Screen";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { useUserGroupsQuery } from "../../graphql";
import { PATHS } from "../../Routes";
import PlaceholderSvg from "../../images/placeholder_user_groups.svg";
import {
    HighlightRow,
    InfoBox,
    StyledHeaderButton,
    StyledHeaderContainer,
} from "../../components/Styles";
import { ApplicationContext } from "../../components/ApplicationProvider";

// TODO: Shared placeholder wrapper
const Placeholder = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    img,
    p {
        margin: 0 auto;
    }
    img {
        height: 202px;
    }
    p {
        max-width: 300px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 18px;
    }
    p,
    h2 {
        color: #666666;
    }
`;

const PlaceholderWrapper = styled.div`
    position: relative;
    // Todo: Refactor parent in Screen.tsx to avoid hard-coded pixel height
    height: calc(100vh - 100px);
`;

interface ICompanyUserGroupsProps extends IScreenProps {
    match: match<{ id?: string }>;
}

export const CompanyUserGroups: FunctionComponent<ICompanyUserGroupsProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    const { loadingUserGroups, userGroups } = useUserGroupsQuery();
    // TODO: Make single instance of <Breadcrumbs> in Shell? (See Shop's breadcrumbs)
    const breadcrumbs = <Breadcrumbs sections={[{ label: t("user_groups.breadcrumb") }]} />;

    const userGroupOptions = { number_of_groups: userGroups.length };
    const userGroupsTitle = (
        <h2 className="page-header">{t("user_groups.user_groups", userGroupOptions)}</h2>
    );

    if (loadingUserGroups) {
        return (
            <>
                {breadcrumbs}
                <Loader active />
            </>
        );
    }

    if (userGroups.length === 0) {
        return (
            <>
                {breadcrumbs}
                <PlaceholderWrapper>
                    {userGroupsTitle}
                    <Placeholder id="qa-companyUserGroups-placeholder">
                        <Image src={PlaceholderSvg} />
                        <h2>{t("user_groups.no_groups.title")}</h2>
                        <p>{t("user_groups.no_groups.content")}</p>
                        <Button
                            id="qa-companyUserGroups-noGroups-createNewGroup"
                            className="ui button primary"
                            onClick={() => props.history.push(PATHS.newUserGroup)}
                        >
                            {t("user_groups.create_user_group")}
                        </Button>
                    </Placeholder>
                </PlaceholderWrapper>
            </>
        );
    }

    return (
        <div style={{ paddingBottom: 25 }}>
            {breadcrumbs}
            <StyledHeaderContainer>
                {userGroupsTitle}
                <StyledHeaderButton
                    id="qa-companyUserGroups-createNewGroup"
                    className="ui right button primary"
                    type="submit"
                    onClick={() => props.history.push(PATHS.newUserGroup)}
                >
                    {t("user_groups.new_user_group")}
                </StyledHeaderButton>
            </StyledHeaderContainer>
            <InfoBox>{t("user_groups.info")}</InfoBox>
            <table className="ui table qa-userGroups">
                <thead>
                    <tr>
                        <th>{t("user_groups.name")}</th>
                        <th>{t("user_groups.users")}</th>
                    </tr>
                </thead>
                <tbody>
                    {userGroups.map((userGroup) => (
                        <HighlightRow
                            key={userGroup.id}
                            onClick={() => props.history.push(`${PATHS.userGroup}/${userGroup.id}`)}
                        >
                            <td data-label="User Group Name">{userGroup.name}</td>
                            <td data-label="Users">{userGroup.users.length}</td>
                        </HighlightRow>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
