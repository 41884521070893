import { Component, FunctionComponent, FormEvent } from "react";
import gql from "graphql-tag";
import classnames from "classnames";
import _ from "lodash";
import { Mutation } from "@apollo/client/react/components";
import { PureQueryOptions } from "@apollo/client";
import { IScreenProps } from "../../screens/Screen";
import { Button, Form, FormField, Input } from "grabcad-ui-elements";
import { Product, ProductData } from "../../graphql";
import { match, Redirect } from "react-router-dom";
import { deepEquals } from "../../utils/form";
import { IErrorObject, Notifier } from "../../view/Notifier";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { ApplicationTypeDropdown } from "../Feature/ApplicationTypeDropdown";
import { FeaturesMultiSelect } from "../Feature/FeaturesMultiSelect";
import { PATHS } from "../../Routes";
import { GET_PRODUCT, GetProductQuery } from "../../graphql/Queries/Products/GetProduct";
import { LIST_FEATURES } from "../../graphql/Queries/Features/ListFeatures";

const CREATE_PRODUCT = gql`
    mutation createProduct($name: String!, $applicationTypeId: String, $featureIdsToAdd: [Int!]) {
        createProduct(
            name: $name
            applicationTypeId: $applicationTypeId
            featureIdsToAdd: $featureIdsToAdd
        ) {
            id
        }
    }
`;

const UPDATE_PRODUCT = gql`
    mutation updateProduct(
        $id: Int!
        $name: String
        $applicationTypeId: String
        $disabled: Boolean
        $featureIdsToAdd: [Int!]
    ) {
        updateProduct(
            id: $id
            data: {
                name: $name
                applicationTypeId: $applicationTypeId
                disabled: $disabled
                featureIdsToAdd: $featureIdsToAdd
            }
        ) {
            id
        }
    }
`;

export const CreateProduct: FunctionComponent<IScreenProps> = (props) => {
    return <ProductForm mutation={CREATE_PRODUCT} {...props} />;
};

interface ProductDataFormField {
    id: keyof ProductData;
    required?: boolean;
    invalidator?: (string: any) => string | undefined;
    inputComponent?: string;
    label: string;
    type?: string;
    placeholder?: string;
    render?: (any: any) => string;
    parse?: (input: string) => any;
    disabled?: () => boolean;
}

interface IEditProductProps extends IScreenProps {
    match: match<{ id?: string }>;
}

export const EditProduct: FunctionComponent<IEditProductProps> = (props) => {
    const productId = parseInt((props as any).match.params.id, 10);

    return (
        <GetProductQuery variables={{ id: productId }}>
            {({ loading, error, data, refetch }) => {
                if (error) {
                    Notifier.error(error);
                    return <Redirect to="/product" />;
                }
                if (loading || !data) {
                    return <div className="ui loader active" />;
                }
                const product = { ...data.product };
                return (
                    <div>
                        <Breadcrumbs sections={[{ label: "Product" }]} />
                        <ProductForm
                            product={product}
                            mutation={UPDATE_PRODUCT}
                            refetchQueries={[{ query: GET_PRODUCT, variables: { id: productId } }]}
                            refetch={refetch}
                            editing={true}
                            {...props}
                        />
                    </div>
                );
            }}
        </GetProductQuery>
    );
};

interface IProductFormProps extends IScreenProps {
    match: match<{ id?: string }>;
    mutation: any;
    product?: ProductData;
    refetch?: any;
    refetchQueries?: (string | PureQueryOptions)[];
    editing?: boolean;
}

interface IProductFormState {
    product: Partial<ProductData>;
    applicationTypeId: string;
    featureIdsToAdd: any[];
    allSelectedFeatures: any[];
    dirty: boolean;
    editing: boolean;
    submitClicked: boolean;
}

type CreateProductMutationResult = {
    createProduct: {
        id: number;
    };
};

type UpdateProductMutationResult = {
    updateProduct: {
        id: number;
    };
};

type ProductMutationResult = CreateProductMutationResult | UpdateProductMutationResult;
export class ProductForm extends Component<IProductFormProps, IProductFormState> {
    fieldsCol1: ProductDataFormField[] = [
        {
            label: "Product Name",
            id: "name",
            required: true,
            disabled: () =>
                this.state.product.disabled ||
                (!this.isCreatingProduct() && !this.isEditingProduct()) ||
                false,
        },
    ];

    constructor(props: IProductFormProps) {
        super(props);
        this.state = {
            product: props.product || {},
            applicationTypeId: props.product?.applicationType?.id || "0",
            featureIdsToAdd: [],
            allSelectedFeatures: props.product?.features || [],
            dirty: false,
            editing: false,
            submitClicked: false,
        };
    }

    isCreatingProduct(): boolean {
        return this.props.mutation === CREATE_PRODUCT;
    }

    isEditingProduct(): boolean {
        return this.props.mutation === UPDATE_PRODUCT && this.state.editing;
    }

    hasNoFeatures(): boolean {
        return (
            this.state.applicationTypeId !== this.state.product?.applicationType?.id &&
            this.state.featureIdsToAdd.length === 0
        );
    }

    validate() {
        const fields = this.fieldsCol1 as any;
        const simpleFieldsValid = !fields.some(
            (field: ProductDataFormField) =>
                (field.required && !this.state.product[field.id]) ||
                (field.invalidator && !!field.invalidator(this.state.product[field.id]))
        );
        return simpleFieldsValid && this.state.applicationTypeId !== "0" && !this.hasNoFeatures();
    }

    render() {
        const inputComponents: { [id: string]: any } = {
            input: Input,
        };
        const getFormField = (field: ProductDataFormField) => {
            const InputClass = inputComponents[field.inputComponent || "input"];
            const invalidReason =
                this.state.submitClicked &&
                ((field.required && !this.state.product[field.id] && "This field is required.") ||
                    field.invalidator?.(this.state.product[field.id]));

            const renderedValue = field.render
                ? field.render(this.state.product[field.id])
                : this.state.product[field.id] || "";
            const updateField = (id: keyof ProductData, event: FormEvent<HTMLInputElement>) => {
                const parsedValue = field.parse
                    ? field.parse(event.currentTarget.value)
                    : event.currentTarget.value;
                let product = { ...this.state.product };
                product[id] = parsedValue;
                this.setState({ product });
            };
            return (
                <FormField key={field.id}>
                    <label>
                        {field.label}
                        {field.required && <span style={{ color: "red" }}>&nbsp;*</span>}
                    </label>
                    <InputClass
                        id={`qa-form-field-${field.id}`}
                        className={classnames({ error: !!invalidReason })}
                        placeholder={field.placeholder}
                        value={renderedValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateField(field.id, e)
                        }
                        type={field.type}
                        disabled={field.disabled ? field.disabled() : false}
                    />
                    {invalidReason && <p className="ui negative message">{invalidReason}</p>}
                </FormField>
            );
        };

        const getProductFeatureIds = () => {
            const features = this.state.product.features?.filter((f) => !!f) || [];
            return features.map((feature) => feature.id);
        };

        let isDirty = !deepEquals(this.props.product, this.state.product);
        if (!isDirty) {
            const featureIdsToAdd = this.state.featureIdsToAdd;
            isDirty =
                featureIdsToAdd.length > 0 ||
                this.state.applicationTypeId !== this.props.product?.applicationType?.id;
        }
        const isValid = this.validate();

        return (
            <Mutation<ProductMutationResult, Partial<Product> | Partial<ProductData>>
                mutation={this.props.mutation}
                refetchQueries={[{ query: LIST_FEATURES }, ...(this.props.refetchQueries ?? [])]}
                update={(_cache, { data }) => {
                    if (data) {
                        if ("createProduct" in data) {
                            this.props.history.push(`/product/${data.createProduct.id}`);
                            Notifier.success(`Successfully created product.`);
                        } else if ("updateProduct" in data) {
                            Notifier.success(`Successfully updated product.`);
                            this.setState({ submitClicked: false });
                            this.props.history.push(PATHS.product);
                        }
                    }
                }}
                onError={(error: Partial<IErrorObject>) => Notifier.error(error)}
            >
                {(update, { loading }) => (
                    <Form
                        style={{ maxWidth: 550 }}
                        onSubmit={(event) => {
                            event.preventDefault();
                            this.setState({ submitClicked: true });
                            if (isValid && !loading) {
                                update({
                                    variables: {
                                        id: this.state.product.id,
                                        name: this.state.product.name,
                                        disabled: this.state.product.disabled,
                                        applicationTypeId: this.state.applicationTypeId,
                                        featureIdsToAdd: this.state.featureIdsToAdd.map(
                                            (f) => f.id
                                        ),
                                    },
                                }).catch(Notifier.error);
                            }
                        }}
                    >
                        {this.fieldsCol1.map(getFormField)}
                        <FormField style={{ flexGrow: 1 }}>
                            <ApplicationTypeDropdown
                                applicationTypeId={this.state.applicationTypeId}
                                onChange={(id) => {
                                    const appFeatures = this.state.allSelectedFeatures.filter((f) =>
                                        id !== "any" ? f.applicationType.id === id : !!f
                                    );
                                    this.setState({ featureIdsToAdd: appFeatures });
                                    this.setState({ applicationTypeId: id });
                                }}
                                disabled={
                                    this.state.product.disabled ||
                                    (!this.isCreatingProduct() && !this.isEditingProduct()) ||
                                    false
                                }
                            />
                        </FormField>
                        <FormField style={{ flexGrow: 1 }}>
                            <FeaturesMultiSelect
                                ids={getProductFeatureIds()}
                                product={this.state.product}
                                applicationTypeId={this.state.applicationTypeId}
                                onChange={(ids) => {
                                    this.setState({ allSelectedFeatures: ids });
                                    if (!_.isEqual(this.state.featureIdsToAdd, ids)) {
                                        this.setState({ featureIdsToAdd: ids });
                                    }
                                }}
                                disabled={
                                    (!this.isCreatingProduct() && !this.isEditingProduct()) ||
                                    (!this.state.applicationTypeId &&
                                        !this.state.product.applicationType?.id)
                                }
                            />
                        </FormField>
                        <FormField key="buttons">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                {!this.isCreatingProduct() && (
                                    <div>
                                        <Button
                                            id="qa-product-disable"
                                            type="submit"
                                            negative={!this.state.product.disabled}
                                            content={
                                                this.state.product.disabled ? "Enable" : "Disable"
                                            }
                                            onClick={() => {
                                                let product = { ...this.state.product };
                                                product.disabled = !product.disabled;
                                                this.setState({ product });
                                                update({ variables: product }).catch(
                                                    Notifier.error
                                                );
                                            }}
                                            disabled={
                                                isDirty || (!isValid && this.state.submitClicked)
                                            }
                                        />
                                        <Button
                                            id="qa-product-edit"
                                            type="button"
                                            content={"Edit"}
                                            onClick={() => {
                                                this.setState({
                                                    editing: !this.state.editing,
                                                });
                                            }}
                                            disabled={
                                                isDirty ||
                                                this.state.product.disabled ||
                                                (!isValid && this.state.submitClicked)
                                            }
                                        />
                                    </div>
                                )}
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        id="qa-product-cancel"
                                        type="button"
                                        secondary
                                        disabled={!isValid && this.state.submitClicked}
                                        onClick={() => this.props.history.push("/product")}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        className="primary right floated"
                                        disabled={!isDirty || !isValid}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </FormField>
                    </Form>
                )}
            </Mutation>
        );
    }
}
