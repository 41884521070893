import { UserContext } from "frontend/src/components/User/UserProvider";
import { Breadcrumbs } from "frontend/src/view/Navigation/BreadCrumbs";
import { Notifier } from "frontend/src/view/Notifier";
import { useContext, useState } from "react";
import { match } from "react-router-dom";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { PrinterGroup, Role } from "../../graphql";
import { PRINTER_GROUPS } from "../../../../shared/src/graphql/Queries/PrinterGroups";
import { PATHS } from "../../Routes";
import { IScreenProps } from "../Screen";
import styled from "grabcad-ui-elements";
import { useQuery } from "@apollo/client";
import PrinterGroupModal, { PrinterGroupModalType } from "./PrinterGroupModal";
import { alphabetizeByProp } from "../../utils/sort";
import { mapPrinterTechnology } from "../../utils/printer_utils";
import { InfoBox, StyledHeaderButton, StyledHeaderContainer } from "../../components/Styles";
import { useGetAllPrinters } from "./UseGetAllPrinters";

const HighlightRow = styled.tr`
    cursor: pointer;
    &:hover {
        background-color: #deeeff;
    }
`;

export interface PrinterGroupsListProps extends IScreenProps {
    match: match<{ id?: string }>;
}

export const PrinterGroupsList: React.FC<PrinterGroupsListProps> = ({ history }) => {
    const { t } = useContext(ApplicationContext);
    const { role } = useContext(UserContext);

    const { printers } = useGetAllPrinters();

    const [createPrinterGroupModalOpen, setCreatePrinterGroupModalOpen] = useState<boolean>(false);

    const { loading, data } = useQuery<{ printerGroups: PrinterGroup[] }>(PRINTER_GROUPS, {
        onError: (error) => Notifier.error(error),
    });

    if (role !== Role.CompanyAdmin) {
        history.push(PATHS.adminHome);
        return <></>;
    }

    if (loading || !data) {
        return <div className="ui loader active" />;
    }

    const printerGroupOptions = {
        number_of_groups: data.printerGroups.length,
    };

    return (
        <div style={{ paddingBottom: 25 }}>
            <Breadcrumbs sections={[{ label: t("printer_groups.breadcrumb") }]} />

            <StyledHeaderContainer>
                <h2 className="page-header">
                    {t("printer_groups.printer_groups", printerGroupOptions)}
                </h2>

                <StyledHeaderButton
                    className="qa-printerGroups-createNewPrinterGroup ui right button primary"
                    onClick={() => {
                        // open create printer group modal
                        setCreatePrinterGroupModalOpen(!createPrinterGroupModalOpen);
                    }}
                >
                    {t("printer_groups.new_printer_group")}
                </StyledHeaderButton>
            </StyledHeaderContainer>
            <InfoBox>{t("printer_groups.info")}</InfoBox>

            <table className="ui table">
                <thead>
                    <tr>
                        <th style={{ width: "73%" }}>{t("printer_groups.group_name")}</th>
                        <th style={{ width: "13.5%" }}>{t("printer_groups.technology")}</th>
                        <th style={{ width: "13.5%" }}>{t("printer_groups.num_printers")}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.printerGroups
                        // create new array (for immutability)
                        .slice()
                        // sort by printer group name
                        .sort(alphabetizeByProp("name"))
                        .map((group) => (
                            <HighlightRow
                                key={group.id}
                                onClick={() => history.push(`${PATHS.printerGroup}/${group.id}`)}
                            >
                                <td className="qa-name">{group.name}</td>
                                <td className="qa-technology">
                                    {printers
                                        // obtain printers in group
                                        .filter((printer) =>
                                            group.printerIds.includes(printer.getId())
                                        )
                                        // obtain printer technologies from printers
                                        .map((printer) => mapPrinterTechnology(printer))
                                        // remove duplicates
                                        .filter(
                                            (technology, idx, technologies) =>
                                                technologies.indexOf(technology) === idx
                                        )
                                        // convert to string with comma-separated values
                                        .join(", ")}
                                </td>
                                <td className="qa-count">{group.printerIds.length}</td>
                            </HighlightRow>
                        ))}
                </tbody>
            </table>

            {/* create printer group modal */}
            <PrinterGroupModal
                type={PrinterGroupModalType.Create}
                open={createPrinterGroupModalOpen}
                onClose={() => {
                    setCreatePrinterGroupModalOpen(false);
                }}
            />
        </div>
    );
};
