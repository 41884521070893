import { FunctionComponent, useState, useContext } from "react";
import { OperatingSchedule, OperatingHours, TimeRange, DayValues, Day } from "../../graphql";
import { END_OF_DAY, incrementTime, MIDNIGHT, parseTimeString, TimeDropdown } from "./TimeDropdown";
import * as Components from "./OperatingHoursEditor.styled";
import { ApplicationContext } from "../ApplicationProvider";
import { InfoBox } from "../../components/Styles";

export interface OperatingHoursProps {
    onUpdate: (updatedDays: OperatingSchedule) => void;
    schedule: OperatingSchedule;
}

const initialOperatingHours: OperatingSchedule = {
    days: {
        monday: {
            enabled: true,
            periods: [{ start: "9:00", end: "17:30" }],
        },
        tuesday: {
            enabled: true,
            periods: [{ start: "9:00", end: "17:30" }],
        },
        wednesday: {
            enabled: true,
            periods: [{ start: "9:00", end: "17:30" }],
        },
        thursday: {
            enabled: true,
            periods: [{ start: "9:00", end: "17:30" }],
        },
        friday: {
            enabled: true,
            periods: [{ start: "9:00", end: "17:30" }],
        },
        saturday: {
            enabled: false,
            periods: [{ start: "9:00", end: "17:30" }],
        },
        sunday: {
            enabled: false,
            periods: [{ start: "9:00", end: "17:30" }],
        },
    },
    enabled: false,
};

export const OperatingHoursEditor: FunctionComponent<OperatingHoursProps> = ({
    onUpdate,
    schedule,
}) => {
    const { t } = useContext(ApplicationContext);
    const [currentSchedule, setOperatingDays] = useState<OperatingSchedule>({
        ...schedule,
        days: schedule.days || initialOperatingHours.days,
    });

    const handleOperatingHoursUpdate = (updatedHours: OperatingHours, enabled: boolean) => {
        const updated = {
            enabled: enabled,
            days: updatedHours,
        };

        setOperatingDays(updated);
        onUpdate(updated);
    };

    const handleDayToggle = (dayToUpdate: Day) => {
        const updatedDays = currentSchedule.days!;

        updatedDays[dayToUpdate].enabled = !updatedDays[dayToUpdate].enabled;
        handleOperatingHoursUpdate(updatedDays, !!currentSchedule.enabled);
    };

    const handleEnabledToggle = () => {
        const enabled = !currentSchedule.enabled;
        handleOperatingHoursUpdate(currentSchedule.days!, enabled);
    };

    const handleStartTimeUpdate = (dayToUpdate: Day, rangeIndex: number, selectedTime: string) => {
        const currentDays = currentSchedule.days!;

        currentDays[dayToUpdate].periods[rangeIndex].start = selectedTime;

        if (
            parseTimeString(selectedTime) >=
                parseTimeString(currentDays[dayToUpdate].periods[rangeIndex].end) &&
            !END_OF_DAY.includes(selectedTime)
        ) {
            currentDays[dayToUpdate].periods[rangeIndex].end = incrementTime(selectedTime);
        }

        currentDays[dayToUpdate] = rebuildSchedule(currentDays[dayToUpdate]);
        handleOperatingHoursUpdate(currentDays, !!currentSchedule.enabled);
    };

    const handleEndTimeUpdate = (dayToUpdate: Day, rangeIndex: number, selectedTime: string) => {
        const currentDays = currentSchedule.days!;

        currentDays[dayToUpdate].periods[rangeIndex].end = selectedTime;

        if (
            rangeIndex === 0 &&
            END_OF_DAY.includes(selectedTime) &&
            currentDays[dayToUpdate].periods.length > 1
        ) {
            handleRemoveRange(dayToUpdate, rangeIndex + 1);
        }

        currentDays[dayToUpdate] = rebuildSchedule(currentDays[dayToUpdate], true);
        handleOperatingHoursUpdate(currentDays, !!currentSchedule.enabled);
    };

    const rebuildSchedule = (day: DayValues, isRangeEnd?: boolean): DayValues => {
        for (let i = 1; i < day.periods.length; i++) {
            day.periods[i] = validateAndAdjustPeriod(
                day.periods[i],
                day.periods[i - 1],
                isRangeEnd
            );
        }

        return day;
    };

    const validateAndAdjustPeriod = (
        current: TimeRange,
        previous: TimeRange,
        isRangeEnd?: boolean
    ): TimeRange => {
        let range = { ...current };

        if (parseTimeString(previous.end) > parseTimeString(current.start)) {
            range.start = incrementTime(previous.end);
        }

        if (
            !isRangeEnd &&
            parseTimeString(range.end, range.end === MIDNIGHT) < parseTimeString(range.start)
        ) {
            range.end = incrementTime(range.start);
        }

        return range;
    };

    const handleAddRange = (dayToUpdate: Day, start: string, end: string) => {
        const updatedDays = currentSchedule.days!;

        updatedDays[dayToUpdate].periods.push({ start, end });
        handleOperatingHoursUpdate(updatedDays, !!currentSchedule.enabled);
    };

    const handleRemoveRange = (dayToUpdate: Day, rangeIndex: number) => {
        const updatedDays = currentSchedule.days!;

        updatedDays[dayToUpdate].periods.splice(rangeIndex, 1);
        handleOperatingHoursUpdate(updatedDays, !!currentSchedule.enabled);
    };

    // Mapping hardcoded days omits `__typename` as an enumerable property,
    // and would also allow us to change the starting day to Sunday if desired.
    const WEEK: Day[] = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
    ];

    return (
        <>
            <Components.EnabledBox>
                <label>{t("settings.operatingHours.label")}</label>
                <Components.Checkbox
                    checked={currentSchedule.enabled}
                    id="operating-hours-enabled"
                    onChange={handleEnabledToggle}
                    data-testid="enable-operating-hours"
                />
                <label htmlFor="operating-hours-enabled">
                    {t(`settings.operatingHours.enabled`)}
                </label>
            </Components.EnabledBox>
            <InfoBox>{t("settings.operatingHours.disclaimer")}</InfoBox>
            <Components.OperatingHoursContainer disabled={!currentSchedule.enabled}>
                {WEEK.map((day) => {
                    const { enabled, periods } = currentSchedule.days![day];
                    return (
                        <Components.DayRow key={day}>
                            <Components.DayBox>
                                <Components.Checkbox
                                    checked={enabled}
                                    id={day}
                                    onChange={() => handleDayToggle(day)}
                                    data-testid="enable-day"
                                />
                                <label htmlFor={day}>
                                    {t(`settings.operatingHours.days.${day}.label`)}
                                </label>
                            </Components.DayBox>
                            <Components.RangeList className="qa-range-list">
                                {periods.map((range, rangeIndex) => {
                                    let previous: TimeRange | undefined;

                                    if (rangeIndex >= 0) {
                                        previous = periods[rangeIndex - 1];
                                    }

                                    return (
                                        <Components.RangeRow key={`range_${day}_${rangeIndex}`}>
                                            <TimeDropdown
                                                value={range.start}
                                                disabled={!enabled}
                                                onChange={(selectedTime) =>
                                                    handleStartTimeUpdate(
                                                        day,
                                                        rangeIndex,
                                                        selectedTime
                                                    )
                                                }
                                                minTime={previous?.end}
                                                includeTomorrow={false}
                                                testid="timerange-start"
                                            ></TimeDropdown>
                                            <span>-</span>
                                            <TimeDropdown
                                                value={range.end}
                                                disabled={!enabled}
                                                onChange={(selectedTime) =>
                                                    handleEndTimeUpdate(
                                                        day,
                                                        rangeIndex,
                                                        selectedTime
                                                    )
                                                }
                                                minTime={range.start}
                                                includeTomorrow={true}
                                                testid="timerange-end"
                                            ></TimeDropdown>

                                            {periods.length > 1 && (
                                                <Components.IconButton
                                                    icon="minus"
                                                    size="mini"
                                                    disabled={!enabled}
                                                    onClick={() =>
                                                        handleRemoveRange(day, rangeIndex)
                                                    }
                                                    data-testid="remove-timerange-button"
                                                ></Components.IconButton>
                                            )}

                                            {periods.length <= 1 &&
                                                !END_OF_DAY.includes(periods[0].end) && (
                                                    <Components.IconButton
                                                        icon="plus"
                                                        size="mini"
                                                        disabled={!enabled}
                                                        onClick={() =>
                                                            handleAddRange(
                                                                day,
                                                                incrementTime(range.end),
                                                                incrementTime(range.end, 2)
                                                            )
                                                        }
                                                        data-testid="add-timerange-button"
                                                    ></Components.IconButton>
                                                )}
                                        </Components.RangeRow>
                                    );
                                })}
                            </Components.RangeList>
                        </Components.DayRow>
                    );
                })}
            </Components.OperatingHoursContainer>
        </>
    );
};
