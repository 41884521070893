import { FunctionComponent, useContext } from "react";
import { DocumentNode } from "graphql";
import { Mutation } from "@apollo/client/react/components";
import {
    Context,
    InMemoryCache,
    MutationFunction,
    MutationUpdaterFunction,
    ApolloCache,
} from "@apollo/client";
import { IErrorObject, Notifier } from "../../view/Notifier";
import { Button, Modal, Header } from "grabcad-ui-elements";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { LIST_COMPANY_ACCESS_OPTIONS } from "../../graphql/Queries/CompanyAccessOptions";
import {
    DeleteAccessOptionMutation,
    DeleteAccessOptionMutationVariables,
} from "../../graphql/Mutations/CompanyAccessOptions";

interface IDeleteAccessOptionModalProps {
    id: number;
    companyId: number;
    onClose?: () => void;
    onUpdate?: () => void;
    open: boolean;
}

export const DeleteAccessOptionModal: FunctionComponent<IDeleteAccessOptionModalProps> = (
    props
) => {
    const { t } = useContext(ApplicationContext);

    return (
        <DeleteAccessOptionMutation
            refetchQueries={[{ query: LIST_COMPANY_ACCESS_OPTIONS }]}
            onError={(error) => Notifier.error(error)}
            update={() => {
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }}
        >
            {(mutateFn: MutationFunction<boolean, DeleteAccessOptionMutationVariables>) => {
                return (
                    <Modal basic size="small" open={props.open} onClose={props.onClose}>
                        <Header
                            icon="trash"
                            content={t("delete_company_access_option_modal.header")}
                        />
                        <Modal.Content>
                            <p>{t("delete_company_access_option_modal.content")}</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                id="qa-company-deleteAccessOptionModal-cancel"
                                secondary
                                onClick={props.onClose}
                            >
                                {t("forms.cancel")}
                            </Button>
                            <Button
                                id="qa-company-deleteAccessOptionModal-remove"
                                negative
                                onClick={() => {
                                    mutateFn({
                                        variables: {
                                            id: props.id,
                                            companyId: props.companyId,
                                        },
                                    }).catch(Notifier.error);
                                    if (props.onClose) {
                                        props.onClose();
                                    }
                                }}
                            >
                                {t("delete_company_access_option_modal.delete")}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                );
            }}
        </DeleteAccessOptionMutation>
    );
};

interface IUpdateAccessOptionModalProps {
    id: number;
    onClose?: () => void;
    onUpdate?: () => void;
    open: boolean;
    newState: string;
    mutation: DocumentNode;
    update?: MutationUpdaterFunction<any, any, Context, ApolloCache<InMemoryCache>>;
}

export const UpdateAccessOptionModal: FunctionComponent<IUpdateAccessOptionModalProps> = (
    props
) => {
    const { t } = useContext(ApplicationContext);
    const header =
        props.newState === "APPROVED"
            ? t("update_company_access_option_modal.header_approve")
            : t("update_company_access_option_modal.header_reject");

    return (
        <Mutation<void, { id: number; newState: string }>
            mutation={props.mutation}
            update={props.update}
            refetchQueries={[{ query: LIST_COMPANY_ACCESS_OPTIONS }]}
            onError={(error: Partial<IErrorObject>) => Notifier.error(error)}
        >
            {(update) => {
                return (
                    <Modal basic size="small" open={props.open} onClose={props.onClose}>
                        <Header content={header} />
                        <Modal.Content>
                            <p>{t("update_company_access_option_modal.content")}</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                id="qa-company-updateAccessOptionModal-cancel"
                                secondary
                                onClick={props.onClose}
                            >
                                {t("forms.cancel")}
                            </Button>
                            <Button
                                id="qa-company-updateAccessOptionModal-update"
                                onClick={() => {
                                    update({
                                        variables: {
                                            id: props.id,
                                            newState: props.newState,
                                        },
                                    }).catch(Notifier.error);
                                    if (props.onClose) {
                                        props.onClose();
                                    }
                                }}
                            >
                                {t("update_company_access_option_modal.update")}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                );
            }}
        </Mutation>
    );
};
