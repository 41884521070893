import { FunctionComponent } from "react";
import { Pagination } from "semantic-ui-react";

interface IPaginationLinksProps {
    total: number;
    perPage: number;
    currentPage: number;
    goToPage: (page: number) => void;
    fetchPageFromServer?: (page: number) => void;
}
export const PaginationLinks: FunctionComponent<IPaginationLinksProps> = (props) => {
    const pages = Math.ceil(props.total / props.perPage);
    if (pages <= 1) {
        return null;
    }

    return (
        <Pagination
            id="qa-paginationLinks"
            className="qa-paginationLinks"
            activePage={props.currentPage}
            totalPages={pages}
            onPageChange={(_evt, data) => {
                props.goToPage(data.activePage as number);

                if (props.fetchPageFromServer) {
                    props.fetchPageFromServer!(data.activePage as number);
                }
            }}
        />
    );
};
