import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const LIST_STALE_COMPANIES = gql`
    query listStaleCompanies($trialMonthsExpired: Int!) {
        listCompanies(trialMonthsExpired: $trialMonthsExpired) {
            companies {
                id
                name
            }
        }
    }
`;

interface ListStaleCompaniesVariables {
    trialMonthsExpired: number;
}

interface ListStaleCompaniesResult {
    listCompanies: {
        companies: {
            id: number;
            name: string;
        }[];
    };
}

export const useListStaleCompanies = (trialMonthsExpired: number) =>
    useQuery<ListStaleCompaniesResult, ListStaleCompaniesVariables>(LIST_STALE_COMPANIES, {
        variables: {
            trialMonthsExpired,
        },
    });
