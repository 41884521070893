import { FunctionComponent, useEffect } from "react";
import { disableOnboarding } from "../utils/hooks/onboarding";
import { Notifier } from "../view/Notifier";

/*
  This component is not end-user-facing. It's for internal use by QA & Product.
  Accessed via "secret" route: /obscure_tools/disable_onboarding
*/

export const DisableOnboarding: FunctionComponent = () => {
    const { someCompleted, resetAllCompleted } = disableOnboarding();

    useEffect(() => {
        if (!someCompleted) {
            void resetAllCompleted().then(() => Notifier.success("Onboarding status reset!"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [someCompleted]);

    return <h1>{someCompleted ? "Reset onboarding status..." : "Onboarding status reset!"}</h1>;
};
