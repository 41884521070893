import React from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { ApolloError, QueryOptions, QueryResult } from "@apollo/client";
import { User } from "../types";

export const GET_GLOBAL_ADMINS = gql`
    query globalAdmins {
        globalAdmins {
            id
            email
        }
    }
`;

export interface GlobalAdminsQueryResultData {
    globalAdmins: User[];
}

export interface GlobalAdminsQueryProps extends Omit<QueryOptions, "query"> {
    children: (result: QueryResult<GlobalAdminsQueryResultData>) => JSX.Element;
    displayName?: string;
    skip?: boolean;
    onCompleted?: (data: GlobalAdminsQueryResultData) => void;
    onError?: (error: ApolloError) => void;
}

export class GlobalAdminsQuery extends React.Component<GlobalAdminsQueryProps> {
    render() {
        const { children, ...props } = this.props;

        return (
            <Query<GlobalAdminsQueryResultData>
                query={GET_GLOBAL_ADMINS}
                {...props}
                fetchPolicy="no-cache"
            >
                {children}
            </Query>
        );
    }
}
