import { useContext, useEffect, useState } from "react";
import {
    CloudUnauthorizedProvider,
    FixturesProvider,
    getPrintersApi,
    IPrinter,
    PrintersApiProvider,
} from "grabcad-printers-api";
import { UserContext } from "../../components/User/UserProvider";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { config } from "../../config";
import fakePrinterData from "../../test/ep_printers_reply.json";
import { workbenchifyComanyId, Role } from "../../../../shared/src/types";
import { NO_AGENTS_MESSAGE } from "./NoPrinter";
import { Notifier } from "../../view/Notifier";

export const useGetAllPrinters = () => {
    const [printers, setPrinters] = useState<IPrinter[]>([]);
    const [loadingPrinters, setLoadingPrinters] = useState(true);
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { role, companyId } = useContext(UserContext);

    // This is passed to tests via context to simplify components that use this hook in nested components,
    // AND more critically when tests result in changing the route to a screen using this hook.
    const { usePrinterFixtures } = useContext(ApplicationContext);
    const { t } = useContext(ApplicationContext);

    useEffect(() => {
        let provider: PrintersApiProvider;
        if (!usePrinterFixtures) {
            // Company IDs are communicated to cloud from cg.com alongside WorkBench Ids.
            // cg.com gets these from company-server, but increments them by 1 billion so they can be differentiated,
            // so we need to do the same:
            const workbenchifiedCompanyId = workbenchifyComanyId(
                (config.REACT_APP_SPOOFED_COMPANY_ID || companyId) as number
            );
            provider = new CloudUnauthorizedProvider(
                config.REACT_APP_CLOUD_API_BASE_URL as string,
                workbenchifiedCompanyId
            );
        } else {
            provider = new FixturesProvider(
                usePrinterFixtures === "none" ? ([] as any) : fakePrinterData
            );
        }

        const api = getPrintersApi(provider);
        const apiPrintersPromise =
            role === Role.CompanyAdmin || role === Role.GlobalAdmin
                ? api.getAllWithoutAcl()
                : api.getAll();

        void apiPrintersPromise
            .then((allPrinters) => {
                setPrinters(allPrinters);
                setLoadingPrinters(false);
            })
            .catch((error) => {
                setPrinters([]);
                setLoadingPrinters(false);
                setErrorOccurred(true);
                if (error) {
                    console.log(JSON.stringify(error));
                    setErrorMessage(error.message);
                    if (error.message !== NO_AGENTS_MESSAGE) {
                        Notifier.error(
                            new Error(t("printer_groups.no_printer.cloud_unavailable_message"))
                        );
                    } else {
                        Notifier.error(new Error(t("printer_groups.no_printer.no_agents_message")));
                    }
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingPrinters]);

    return { loadingPrinters, setLoadingPrinters, printers, errorOccurred, errorMessage };
};
