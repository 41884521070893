import React, { useContext, useState } from "react";
import { ApplicationContext, type TranslationFunction } from "../../components/ApplicationProvider";
import { useUserPreference } from "../../graphql/Queries/UserPreferences";
import { UserPreferenceTypes, useUpdateUserPreference } from "../../graphql";
import styled, { Button, Checkbox, Input, Loader, Tab, Table } from "grabcad-ui-elements";
import { StrictTabProps } from "semantic-ui-react";
import { mapPrinterTechnology } from "../../utils/printer_utils";
import { IPrinter } from "grabcad-printers-api";
import { TableHeaderNoWrap } from "../../components/Styles";
import { PrinterRow } from "./PrinterRow";
import { PrinterCard } from "./PrinterCard";
import _ from "lodash";

const StyledPrinterTabs = styled(Tab)`
    flex: 1 1 auto;
    min-height: 75%;
    display: flex;
    flex-direction: column;

    .tab-header {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        justify-content: space-between;

        .search.input {
            max-width: 368px;
            position: relative;
            z-index: 101;
        }

        .display-modes {
            display: flex;

            .ui.button {
                width: 38px;
                height: 38px;
                padding: 0;

                rect {
                    fill: #cccccc;
                }

                &:not(.primary) {
                    cursor: pointer;

                    &:hover rect {
                        fill: #444;
                    }
                }

                &.primary {
                    cursor: default;

                    rect {
                        fill: white;
                    }
                }

                > div {
                    // Override some .flex-button fanciness
                    overflow: visible;
                    padding: 9px 0;
                }
            }
        }

        position: relative;

        &:after {
            // Fade to white as cards scroll up
            width: 100%;
            display: block;
            content: "";
            position: absolute;
            height: 10px;
            top: 38px;
            z-index: 100;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 255) 0%,
                rgba(255, 255, 255, 0) 100%
            );
        }
    }

    .printer-list {
        // Negative margin + padding to prevent cards' drop-shadow :hover style from being cropped,
        // and to push scrollbar to right edge of screen.
        margin: -15px -25px 0 -25px;
        padding: 20px 25px 25px;
        overflow: auto; // Fallback for Firefox
        overflow: overlay; // Scrollbar will not cause layout shift!
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 7px;
        }

        &.table {
            grid-template-columns: 1fr;
            margin-top: 0;
            padding-top: 0;
            position: relative;

            .ui.table {
                border-top: none !important;
            }

            thead {
                position: sticky;
                top: 0;
                z-index: 10;

                th {
                    border-top: 1px solid rgba(34, 36, 38, 0.1);
                    position: relative;
                    z-index: 10;
                }

                &:before {
                    // Hides tbody side borders when scrolling behind rounded sticky thead
                    width: 100%;
                    display: block;
                    content: "";
                    position: absolute;
                    top: 0;
                    height: 10px;
                    z-index: 9;
                    background: white;
                }
            }

            tr:hover {
                background-color: #deeeff;
                cursor: pointer;
            }

            .flex-row {
                display: flex;
                align-items: center;
            }

            .checkbox {
                margin-right: 12px;
            }

            img {
                width: 40px;
                height: 40px;
                border: 1px solid rgba(34, 36, 38, 0.15);
                border-radius: 0.28571429rem;
                margin-right: 10px;
                background: white;
            }

            .subheading {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #999;
            }

            .percentage {
                width: 34px;
                text-align: right;
                margin-right: 8px;
            }
        }
    }
`;

export interface PrintersProps {
    printers: IPrinter[];
    onClick?: (printerId: string) => void;
    selectedPrinterIds?: string[];
    setSelectedPrinterIds?: (ids: string[]) => void;
    // NB! Update the .memo compare part if adding new properties!
}

export const Printers = React.memo(
    (props: PrintersProps) => {
        return <_printers {...props} />;
    },
    (prevProps: PrintersProps, nextProps: PrintersProps) =>
        _.isEqual(prevProps.selectedPrinterIds, nextProps.selectedPrinterIds) &&
        _.isEqual(prevProps.printers, nextProps.printers)
);

const _printers = ({
    printers,
    selectedPrinterIds,
    onClick,
    setSelectedPrinterIds,
}: PrintersProps) => {
    const { t } = useContext(ApplicationContext);
    const [searchString, setSearchString] = useState("");
    const { value: tablePrintersView, loading } = useUserPreference(
        UserPreferenceTypes.PRINTERS_TABLE_VIEW
    );
    const tableView = tablePrintersView && tablePrintersView !== "false"; // TODO: Fixme
    const { updateValue: setTableView } = useUpdateUserPreference(
        UserPreferenceTypes.PRINTERS_TABLE_VIEW
    );

    if (loading) {
        return <Loader active />;
    }

    let filtered = printers;
    if (searchString) {
        filtered = printers.filter((printer) => {
            const printerName = printer.getName();
            const printerType = printer.getTechnology();
            const searchLowercase = searchString.toLocaleLowerCase();
            const inName = printerName?.toLocaleLowerCase().includes(searchLowercase);
            const inType = printerType?.toLocaleLowerCase().includes(searchLowercase);

            return inName || inType;
        });
    }

    const tabHeader = (
        <div className="tab-header">
            <div className="ui left icon input search">
                <i className="search icon" />
                <Input
                    className="qa-companyPrinters-search"
                    // override as HTML 'input' element to remove Semantic UI wrapper and fix search icon overlap
                    as="input"
                    type="text"
                    placeholder="Search..."
                    value={searchString}
                    onChange={(event) => {
                        setSearchString(event.currentTarget.value);
                    }}
                />
            </div>
            <div className="display-modes">
                <Button
                    className="qa-printers-cards-button"
                    type="button"
                    primary={!tableView}
                    secondary={!!tableView}
                    onClick={() => setTableView(false)}
                >
                    <svg
                        width="17"
                        height="18"
                        viewBox="0 0 17 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect x="10" y="0.5" width="7" height="7" rx="1" fill="#CCCCCC" />
                        <rect y="0.5" width="7" height="7" rx="1" fill="#CCCCCC" />
                        <rect x="10" y="10.5" width="7" height="7" rx="1" fill="#CCCCCC" />
                        <rect y="10.5" width="7" height="7" rx="1" fill="#CCCCCC" />
                    </svg>
                </Button>
                <Button
                    className="qa-printers-table-button"
                    type="button"
                    primary={!!tableView}
                    secondary={!tableView}
                    onClick={() => setTableView(true)}
                >
                    <svg
                        width="17"
                        height="18"
                        viewBox="0 0 17 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect y="7.5" width="17" height="3" rx="1" fill="white" />
                        <rect y="14.5" width="17" height="3" rx="1" fill="white" />
                        <rect y="0.5" width="17" height="3" rx="1" fill="white" />
                    </svg>
                </Button>
            </div>
        </div>
    );

    const tabPanes: StrictTabProps["panes"] = [
        {
            menuItem: getPrintersHeader(
                t("printers.all_printers"),
                printers.length,
                filtered.length,
                !!searchString
            ),
            render: () =>
                getTabContent(
                    t,
                    filtered,
                    searchString,
                    tabHeader,
                    !!tableView,
                    onClick,
                    selectedPrinterIds,
                    setSelectedPrinterIds
                ),
        },
    ];

    const technologies = printers
        .map((printer) => mapPrinterTechnology(printer))
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();

    technologies.forEach((technology) => {
        const printersTechnology = printers.filter(
            (printer) => mapPrinterTechnology(printer) === technology
        );
        const filteredPrintersTechnology = filtered.filter(
            (printer) => mapPrinterTechnology(printer) === technology
        );
        const headerName = technology;

        tabPanes.push({
            menuItem: getPrintersHeader(
                headerName,
                printersTechnology.length,
                filteredPrintersTechnology.length,
                !!searchString
            ),
            render: () =>
                getTabContent(
                    t,
                    filteredPrintersTechnology,
                    searchString,
                    tabHeader,
                    !!tableView,
                    onClick,
                    selectedPrinterIds,
                    setSelectedPrinterIds
                ),
        });
    });

    return <StyledPrinterTabs menu={{ secondary: true, pointing: true }} panes={tabPanes} />;
};

export const getPrintersHeader = (
    headerName: string,
    printersCount: number,
    filteredCount: number,
    searchActive: boolean
) => {
    if (!searchActive) {
        return `${headerName} (${printersCount})`;
    }
    return `${headerName} (${filteredCount} of ${printersCount})`;
};

const getTabContent = (
    t: TranslationFunction,
    printers: IPrinter[],
    searchString: string,
    tabHeader: JSX.Element,
    tableView: boolean,
    onClick?: (printerId: string) => void,
    selectedPrinterIds?: string[],
    setSelectedPrinterIds?: (ids: string[]) => void
) => {
    return (
        <>
            {tabHeader}
            {tableView ? (
                <div className="printer-list table">
                    <Table>
                        <thead>
                            <tr>
                                <TableHeaderNoWrap>
                                    <div className="flex-row">
                                        {selectedPrinterIds && setSelectedPrinterIds && (
                                            <Checkbox
                                                checked={printers.every((printer) =>
                                                    selectedPrinterIds.includes(printer.getId())
                                                )}
                                                indeterminate={
                                                    !!selectedPrinterIds.length &&
                                                    !printers.every((printer) =>
                                                        selectedPrinterIds.includes(printer.getId())
                                                    )
                                                }
                                                onClick={() => {
                                                    if (
                                                        !printers.every((printer) =>
                                                            selectedPrinterIds.includes(
                                                                printer.getId()
                                                            )
                                                        )
                                                    ) {
                                                        setSelectedPrinterIds(
                                                            printers.map((printer) =>
                                                                printer.getId()
                                                            )
                                                        );
                                                    } else {
                                                        setSelectedPrinterIds([]);
                                                    }
                                                }}
                                            />
                                        )}
                                        {t("printers.table.printer_info")}
                                    </div>
                                </TableHeaderNoWrap>
                                <TableHeaderNoWrap>{t("printers.table.status")}</TableHeaderNoWrap>
                                <TableHeaderNoWrap>
                                    {t("printers.table.current_job")}
                                </TableHeaderNoWrap>
                                <TableHeaderNoWrap>
                                    {t("printers.table.progress")}
                                </TableHeaderNoWrap>
                            </tr>
                        </thead>
                        <tbody>
                            {printers.map((printer, i) => (
                                <PrinterRow
                                    key={i}
                                    printer={printer}
                                    searchString={searchString}
                                    onClick={onClick}
                                    selectedPrinterIds={selectedPrinterIds}
                                />
                            ))}
                        </tbody>
                    </Table>
                </div>
            ) : (
                <div className="printer-list">
                    {printers.map((printer, i) => {
                        const isSelected = !!selectedPrinterIds?.includes(printer.getId());
                        return (
                            <PrinterCard
                                key={i}
                                printer={printer}
                                selected={isSelected}
                                searchString={searchString}
                                onClick={onClick}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};
