import gql from "graphql-tag";
import { User } from "..";
import { useQueryWithCatch } from "../../utils/hooks/useQueryWithCatch";
import { alphabetizeByProp } from "../../utils/sort";

export const LIST_USERS = gql`
    query users {
        users {
            users {
                id
                name
                email
                joinDate
                hasRegistered
            }
        }
    }
`;
interface UsersQueryResult {
    users: {
        users: User[];
    };
}

export function useUsersQuery() {
    const { data, loading } = useQueryWithCatch<UsersQueryResult>(LIST_USERS);

    return {
        loadingUsers: loading,
        users: data ? [...data.users.users].sort(alphabetizeByProp("name")) : [],
    };
}
