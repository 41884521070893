export const alphabetizeByProp = (prop: string) => (a: any, b: any) => {
    if (a[prop] < b[prop]) {
        return -1;
    }
    if (a[prop] > b[prop]) {
        return 1;
    }
    return 0;
};

export const alphabetize = (a: string, b: string) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};

/**
 * Given sorted array, remove all duplicates.
 * Original array is modified.
 *
 * e.g., [1, 1, 2, 10, 50, 50, 50, 77, 77] -> [1, 2, 10, 50, 77]
 * e.g., [77, 77, 50, 50, 50, 10, 2, 1, 1] -> [77, 50, 10, 2, 1]
 */
export const removeDuplicatesFromSortedArray = <T>(prop: keyof T, entries: T[]): number => {
    const len = entries.length;
    // last dst value written; is also the most recent unique value seen in src
    let last: any | undefined;
    // dst ptr
    let count = 0;
    for (let i = 0; i < len; i++) {
        const item = entries[i];
        if (item[prop] !== last || item[prop] == null) {
            // at 1st src element or src value changed => advance both dst + src ptr
            entries[count] = item;
            last = item[prop];
            count++;
        }
        // else dupe value in src => just advance src ptr
    }
    entries.length = count;
    return count;
};
