import { Technology } from "@grabcad/printer-information";
import { ApiPrinterStatus, IPrinter } from "grabcad-printers-api";
import { PrinterStatusColor } from "../../../shared/src/types";

/**
 * Translate the printer technology to a more human-readable representation.
 *
 * @param {Printer} printer printer to read technology from
 *
 * @returns human-readable representation of printer technology
 */
export const mapPrinterTechnology = (printer: IPrinter): Technology => {
    const printerTechnology = printer.getTechnology();

    switch (printerTechnology) {
        case "fdm":
            return Technology.fdm;
        case "PolyJet":
            return Technology.polyjet;
        case "saf":
            return Technology.saf;
        default:
            // throwing an error here can make React go crazy
            console.warn(`Unknown printer technology ${printerTechnology} - defaulting to fdm`);
            return Technology.fdm;
    }
};

// WaitingForPart is a special case for idle FDM printers
export type DisplayPrinterStatus = ApiPrinterStatus | "WaitingForPart";

export class MappedPrinterStatus {
    statusText: DisplayPrinterStatus;
    statusColor: PrinterStatusColor;

    constructor(printer: IPrinter) {
        const technology = mapPrinterTechnology(printer);
        const reportedStatus = printer.getStatus().getTechnologyStatus() || "";

        let text: DisplayPrinterStatus = "Error";

        switch (technology) {
            case Technology.fdm:
                text = getFdmPrinterStatus(reportedStatus);
                break;
            case Technology.saf:
                text = getSafPrinterStatus(reportedStatus);
                break;
            case Technology.polyjet:
                text = getPolyJetPrinterStatus(reportedStatus);
                break;
            default:
                break;
        }

        this.statusText = text;
        this.statusColor = getPrinterStatusColor(text);
    }
}

// mapping taken from https://github.com/GrabCAD/eagle-schedule-ui/blob/master/src/adapters/grabcad/grabcadSchedulePrinter.ts

const getPolyJetPrinterStatus = (reported_state: string): DisplayPrinterStatus => {
    const formattedState = reported_state.toLocaleUpperCase();
    switch (formattedState) {
        case "READY":
            return "PendingStart";
        case "IDLE":
        case "ARCHIVE":
        case "FINISH":
            return "Idle";
        case "PROCESSING":
        case "PRINT":
        case "QUEUE":
            return "Printing";
        case "STOP":
        case "STOPPING":
            return "Paused";
        default:
            return "Error";
    }
};

// const getSafPrinterStatus = (reported_state: string): DisplayPrinterStatus => {
//     return getFdmPrinterStatus(reported_state); // pretty sure its the same as fdm
// };

// taken from https://github.com/GrabCAD/eagle-printer-service/blob/master/PBF/PrinterDriverPBF/Api/StatusUtilsV2.cs#L141
const getSafPrinterStatus = (reported_state: string): DisplayPrinterStatus => {
    const formattedState = reported_state.toLocaleUpperCase();
    switch (formattedState) {
        case "IDLE":
            return "Idle";
        case "PRINTING":
            return "Printing";
        case "PAUSED":
            return "Paused";
        case "HARVESTING":
            return "Harvesting";
        case "MAINTENANCE":
            return "Maintenance";
        case "UPDATE":
            return "Update";
        case "PENDINGSTART":
            return "PendingStart";
        case "OFFLINE":
            return "Disconnected";
        case "OUTOFMATERIAL":
            return "OutOfMaterial";
        case "CANCELLED":
            return "Cancelled";
        case "SCHEDULED":
            return "Scheduled";
        case "WARNING":
            return "Warning";
        case "UNKNOWN":
        case "ERROR":
        default:
            return "Error";
    }
};

const getFdmPrinterStatus = (reported_state: string): DisplayPrinterStatus => {
    const formattedState = reported_state.toLocaleUpperCase();
    switch (formattedState) {
        case "READY":
            return "Idle";
        case "WAITING_FOR_PART":
            return "WaitingForPart";
        case "PRINTING":
        case "CALIBRATING":
            return "Printing";
        case "PAUSED":
            return "Paused";
        case "MAINTENANCE": // idle for Maxums; indicates in maintenance menu for Dimensions, uPrints,250mcs
        case "AUTO_CHANGEOVER": // Fortus 400mc's have a specific state for auto changeover.
        case "REBOOTING":
        case "UPGRADE":
            return "Maintenance";
        case "PART_DONE":
        case "COOL_DOWN":
            return "Harvesting";
        case "DISCONNECTED":
            return "Disconnected";
        case "OUT_OF_MATERIAL": // could treat as paused if it happens during a build
            return "OutOfMaterial";
        case "UNKNOWN":
            return "Error";
        default:
            return "Error";
    }
};

/**
 * Obtain UI color from printer status.
 */
const getPrinterStatusColor = (printerStatus: DisplayPrinterStatus): PrinterStatusColor => {
    switch (printerStatus) {
        case "Idle":
        case "Disconnected":
            return PrinterStatusColor.Gray;
        case "PendingStart":
        case "Printing":
            return PrinterStatusColor.Blue;
        case "Harvesting": // print completed
        case "Scheduled":
            return PrinterStatusColor.Green;
        case "OutOfMaterial":
        case "Paused":
        case "Update":
        case "Maintenance":
        case "Warning":
            return PrinterStatusColor.Orange;
        case "Cancelled":
        case "Error":
            return PrinterStatusColor.Red;
        default:
            return PrinterStatusColor.Blue;
    }
};
