import { FunctionComponent, useContext } from "react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { SdkStigCard } from "../SDK/SdkStigCard";
import AnalyzeIcon from "../../images/Analyze-logo.svg";
import Parser from "html-react-parser";

export const InstallingAnalyzeAgentInfo: FunctionComponent = () => {
    const { t } = useContext(ApplicationContext);
    const url = `https://gc-analyze-installers.s3.amazonaws.com/latestrelease/GrabCADAnalyzeAgentInstaller.exe`;
    const version = "";
    const lastModified = "";
    const helpSystemRequirementLink = `https://help.grabcad.com/article/262-system-requirements-for-streamline-pro`;
    const helpInstallationGuideLink = `https://help.grabcad.com/article/290-setting-up-grabcad-analyze-installation`;

    const overviewCard = (
        <SdkStigCard
            id={1}
            description={Parser(t("configure_analyze.grabcad_analyze_agent_description"))}
            url={url}
            version={version}
            lastModified={lastModified}
            imageSrc={AnalyzeIcon}
            releaseNotesLink={``}
            expired={false}
            showBorder={false}
            showBoxShadow={false}
            showVersionAndDetails={false}
        />
    );

    return (
        <div>
            <p style={{ marginTop: "18px" }}>
                {Parser(
                    t("configure_analyze.sub_field_title", {
                        system_requirements: helpSystemRequirementLink,
                        installation_guide: helpInstallationGuideLink,
                    })
                )}
            </p>
            <h3 style={{ paddingTop: "10px" }}>
                {t("configure_analyze.installing_analyze_agent")}
            </h3>
            {overviewCard}
        </div>
    );
};
