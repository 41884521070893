import gql from "graphql-tag";

export const CREATE_PACKAGE = gql`
    mutation createLicensePackage(
        $name: String!
        $description: String!
        $productId: Int!
        $partNumber: String
        $key: String
        $maxUsers: Int
        $unlimitedMaxUsers: Boolean
        $companyWide: Boolean
        $expiringMsgDisplayDaysLeft: Int
        $duration: String
        $attributeIds: [Int]
    ) {
        createLicensePackage(
            name: $name
            description: $description
            productId: $productId
            partNumber: $partNumber
            key: $key
            maxUsers: $maxUsers
            unlimitedMaxUsers: $unlimitedMaxUsers
            companyWide: $companyWide
            expiringMsgDisplayDaysLeft: $expiringMsgDisplayDaysLeft
            duration: $duration
            attributeIds: $attributeIds
        ) {
            id
            name
            description
            key
            partNumber
            maxUsers
            disabled
            product {
                id
            }
            unlimitedMaxUsers
            companyWide
        }
    }
`;

export const UPDATE_PACKAGE = gql`
    mutation updateLicensePackage(
        $id: Int!
        $name: String
        $description: String
        $disabled: Boolean
        $partNumber: String
        $key: String
        $maxUsers: Int
        $unlimitedMaxUsers: Boolean
        $companyWide: Boolean
        $expiringMsgDisplayDaysLeft: Int
        $duration: String
        $attributeIds: [Int]
    ) {
        updateLicensePackage(
            id: $id
            data: {
                name: $name
                description: $description
                disabled: $disabled
                partNumber: $partNumber
                key: $key
                maxUsers: $maxUsers
                unlimitedMaxUsers: $unlimitedMaxUsers
                companyWide: $companyWide
                expiringMsgDisplayDaysLeft: $expiringMsgDisplayDaysLeft
                duration: $duration
                attributeIds: $attributeIds
            }
        ) {
            id
            name
            description
            key
            partNumber
            maxUsers
            disabled
            product {
                id
            }
            unlimitedMaxUsers
            companyWide
        }
    }
`;
