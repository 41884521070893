import gql from "graphql-tag";

export const FeatureFragments = {
    featureFields: gql`
        fragment featureFields on Feature {
            id
            key
            displayName
            disabled
            applicationType {
                id
                description
            }
            isPrinterType
            isMaterialType
            isAssignedViaRoles
        }
    `,
};
