import { TimeSelect } from "./OperatingHoursEditor.styled";

export interface TimePickerProps {
    onChange: (selectedTime: string) => void;
    value: string;
    disabled: boolean;
    minTime?: string;
    includeTomorrow?: boolean;
    testid: string;
}

const STEP_IN_MINUTES = 30;

const BEFORE_MIDNIGHT = "23:30";
export const MIDNIGHT = "0:00";
export const END_OF_DAY = [MIDNIGHT, BEFORE_MIDNIGHT];

export const TimeDropdown: React.FunctionComponent<TimePickerProps> = ({
    onChange,
    value,
    disabled,
    minTime,
    includeTomorrow,
    testid,
}) => {
    const timeRanges = getTimePeriods(minTime, includeTomorrow);

    const handleTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedTime = event.target.value;
        onChange(selectedTime);
    };

    return (
        <TimeSelect
            value={value}
            disabled={disabled}
            onChange={handleTimeChange}
            className={"ui dropdown"}
            data-testid={testid}
        >
            {timeRanges.map((item, index) => (
                <option key={index} value={formatTime(item)}>
                    {item.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })}
                </option>
            ))}
        </TimeSelect>
    );
};

// returns time in H:MM format, to leading 0
function formatTime(date: Date): string {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedHours = hours.toString();
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
}

export const parseTimeString = (time: string, includeTommorow?: boolean): Date => {
    if (includeTommorow) {
        return parseTimeStringIncludeTommorow(time);
    }

    const [hours, minutes] = time.split(":");
    const date = new Date(getTodayStart());
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    return date;
};

export const parseTimeStringIncludeTommorow = (time: string): Date => {
    const [hours, minutes] = time.split(":");
    const date = new Date(getTomorrowStart());
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    return date;
};

export function incrementTime(time: string, numberOfTimes?: number): string {
    const date = parseTimeString(time);
    date.setMinutes(date.getMinutes() + (numberOfTimes ?? 1) * STEP_IN_MINUTES);
    return formatTime(date);
}

const getTodayStart = (): Date => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
};

const getTomorrowStart = (): Date => {
    const currentDate = getTodayStart();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
};

const getTimePeriods = (minValue?: string, includeTomorrow?: boolean): Date[] => {
    const tomorrow = getTomorrowStart();

    const startTime = minValue
        ? new Date(parseTimeString(minValue).getTime() + STEP_IN_MINUTES * 60000)
        : getTodayStart();
    let endTime =
        minValue && includeTomorrow
            ? tomorrow
            : new Date(tomorrow.getTime() - STEP_IN_MINUTES * 60000);

    const timeRanges: Date[] = [];

    while (startTime <= endTime) {
        timeRanges.push(new Date(startTime));
        startTime.setMinutes(startTime.getMinutes() + STEP_IN_MINUTES);
    }
    return timeRanges;
};
