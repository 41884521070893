import { FunctionComponent, useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { find } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import { IScreenProps } from "../../screens/Screen";
import styled, { Button, Form, FormField, Input, Checkbox, Loader } from "grabcad-ui-elements";
import {
    User,
    UPDATE_USER_GROUP,
    SEND_NOTIFICATIONS,
    CREATE_USER_GROUP,
    GroupRole,
    UserGroup,
    UserGroupRoleInput,
    useUsersQuery,
    UserGroupPrinterGroupAccessOutput,
    DELETE_USER_GROUP,
} from "../../graphql";
import type {
    DetailedLicenseData,
    Product,
    UserGroupPrinterGroupAccessOutput as UserGroupPrinterGroupAccess,
} from "../../../../shared/src/types";
import { UserContext } from "../../components/User/UserProvider";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { PATHS } from "../../Routes";
import { alphabetizeByProp } from "../../utils/sort";
import {
    LIST_USER_GROUPS,
    useUserGroupQuery,
    useUserGroupsQuery,
} from "../../graphql/Queries/UserGroups";
import { Notifier } from "../../view/Notifier";
import { RoleSelector } from "../../components/roles/RoleSelector";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { PrinterGroupMultiSelect } from "../../view/PrinterGroupMultiSelect";
import { UPDATE_USER_GROUP_PRINTER_GROUP_ACCESSES } from "../../../../shared/src/graphql/Mutations/UserGroupPrinterGroupAccess";
import { USER_GROUP_PRINTER_GROUP_ACCESSES } from "../../../../shared/src/graphql/Queries/UserGroupPrinterGroupAccesses";
import { StyledDeleteButton } from "../../components/Styles";
import { DeleteEntityConfirmation } from "../../view/Modals/DeleteEntityConfirmation";
import {
    GET_COMPANY_LICENSES,
    useCompanyLicensesQuery,
} from "../../graphql/Queries/Licenses/GetCompanyLicenses";

const GROUP_NAME_MAX_LENGTH = 100;

const ShuttleCol = styled.div`
    flex-grow: 1;
    flex-basis: 50%;
`;
const ShuttleList = styled.div`
    border-radius: 2px;
    border: 1px solid #ccc;
    max-height: 300px;
    overflow: auto;
`;
const ShuttleItem = styled.div`
    padding: 6px 10px;
    border-bottom: 1px solid #dfdfdf;
    cursor: pointer;
    &:hover {
        background-color: #deeeff;
    }
`;

export const CreateUserGroup: FunctionComponent<IScreenProps> = (props) => {
    const { t } = useContext(ApplicationContext);

    return (
        <>
            <Breadcrumbs
                sections={[
                    {
                        label: t("user_group.breadcrumb"),
                        to: PATHS.userGroups,
                    },
                    { label: t("user_group.new") },
                ]}
            />

            <UserGroupForm {...props} />
        </>
    );
};

export const EditUserGroup: FunctionComponent<IScreenProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    const { userGroup } = useUserGroupQuery({ id: +(props.match.params as any).id });
    if (!userGroup) {
        return <Loader active />;
    }

    return (
        <>
            <Breadcrumbs
                sections={[
                    {
                        label: t("user_group.breadcrumb"),
                        to: PATHS.userGroups,
                    },
                    { label: userGroup.name },
                ]}
            />
            <UserGroupForm userGroup={userGroup} {...props} />
        </>
    );
};

interface UserGroupState {
    id?: number;
    name: string;
    userIds: number[];
    roles: UserGroupRoleInput[];
    licenseIds: number[];
}

interface IUserGroupFormProps extends IScreenProps {
    userGroup?: UserGroup;
}

function extractStateFromUserGroup(userGroup?: UserGroup): UserGroupState {
    const userIds = (userGroup?.users || []).map((user) => user.id);
    const roles: UserGroupRoleInput[] = (userGroup?.roles || []).map((gr: GroupRole) => {
        return {
            applicationId: gr.application.id,
            role: gr.applicationRole.name,
        };
    });
    return {
        userIds,
        roles,
        id: userGroup ? userGroup.id : undefined,
        name: userGroup ? userGroup.name : "",
        licenseIds: userGroup?.licenses ? userGroup.licenses.map((license) => license.id) : [],
    };
}

function roleDifference(
    left: UserGroupRoleInput[],
    right: UserGroupRoleInput[]
): UserGroupRoleInput[] {
    return (left || []).filter(
        (roleInput) =>
            !find(
                right || [],
                (input) =>
                    input.applicationId === roleInput.applicationId && input.role === roleInput.role
            )
    );
}

function findProductsWithMissingLicenses(
    licenseData: DetailedLicenseData[],
    state: UserGroupState
): Product[] {
    type ExpirableProduct = Product & { expired: boolean };
    let products: Set<ExpirableProduct> = new Set();
    let productUserIdsMap: Map<number, Set<number>> = new Map();
    for (let license of licenseData) {
        const product = license.package.product;
        if (product && state.licenseIds.find((id) => id === license.id)) {
            products.add({ ...product, expired: license.state === "expired" });
            let productUserIds = productUserIdsMap.get(product.id);
            if (!productUserIds) {
                productUserIds = new Set();
                productUserIdsMap.set(product.id, productUserIds);
            }

            for (let assignment of license.licenseAssignments) {
                if (!assignment.dateRevoked) {
                    productUserIds.add(assignment.user.id);
                }
            }
        }
    }

    let badProducts: Product[] = [];

    products.forEach((product) => {
        if (product.expired) {
            badProducts.push(product);
            return;
        }

        let licensedUserIds = productUserIdsMap.get(product.id);
        for (let userId of state.userIds) {
            if (!licensedUserIds?.has(userId)) {
                badProducts.push(product);
                return;
            }
        }
    });

    return badProducts;
}

export const UserGroupForm: FunctionComponent<IUserGroupFormProps> = (props) => {
    const userContext = useContext(UserContext);
    const { t } = useContext(ApplicationContext);
    const { users, loadingUsers } = useUsersQuery();
    const { userGroups, loadingUserGroups } = useUserGroupsQuery();
    const { companyLicenses, enabledFeatures } = useCompanyLicensesQuery();

    const [createUserGroup] = useMutation(CREATE_USER_GROUP);
    const [updateUserGroup] = useMutation(UPDATE_USER_GROUP);
    const [sendNotifications] = useMutation(SEND_NOTIFICATIONS);

    const [newUserGroupState, setNewUserGroupState] = useState(
        extractStateFromUserGroup(props.userGroup)
    );
    const [oldUserGroupState, setOldUserGroupState] = useState(
        extractStateFromUserGroup(props.userGroup)
    );
    const [sendEmails, setSendEmails] = useState(false);
    const [deleteClicked, setDeleteClicked] = useState(false);

    const [newPrinterGroupIds, setNewPrinterGroupIds] = useState<number[]>([]);
    const [oldPrinterGroupIds, setOldPrinterGroupIds] = useState<number[]>([]);

    const [nameInvalidReason, setNameInvalidReason] = useState<string | undefined>();
    const getNameInvalidReason = (newName: string): string | undefined => {
        if (!newName.length) {
            return t("user_group.name_required");
        }
        if (newName.length > GROUP_NAME_MAX_LENGTH) {
            return t("user_group.name_too_long", { max_length: GROUP_NAME_MAX_LENGTH });
        }
        if (newName === oldUserGroupState.name) {
            return undefined; // Name hasn't changed, return undefined to indicate this is peachy
        }
        if (userGroups.find((group) => group.name === newName)) {
            return t("user_group.name_taken");
        }
    };

    const exhaustedProducts = findProductsWithMissingLicenses(
        companyLicenses?.licenses || [],
        oldUserGroupState
    );

    useEffect(() => {
        const rolesToAdd = roleDifference(newUserGroupState.roles, oldUserGroupState.roles);
        const userIdsToAdd = newUserGroupState.userIds.filter(
            (id) => !oldUserGroupState.userIds.includes(id)
        );
        const licenseIdsToAdd = newUserGroupState.licenseIds.filter(
            (license) => !oldUserGroupState.licenseIds.includes(license)
        );
        setSendEmails(
            rolesToAdd.length > 0 || userIdsToAdd.length > 0 || licenseIdsToAdd.length > 0
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newUserGroupState]);

    const isUserGroupDirty =
        JSON.stringify(newUserGroupState) !== JSON.stringify(oldUserGroupState);
    const arePrinterGroupsDirty =
        JSON.stringify(oldPrinterGroupIds) !== JSON.stringify(newPrinterGroupIds);

    const existingUsers = users
        .filter((user) => newUserGroupState.userIds.includes(user.id))
        .sort(alphabetizeByProp("email"));
    const availableUsers = users
        .filter((user) => !newUserGroupState.userIds.includes(user.id))
        .sort(alphabetizeByProp("email"));
    const getShuttleRow = (user: User, onClick: (user: User) => void) => (
        <ShuttleItem
            id={`qa-userGroup-user_${user.id}`}
            className="qa-userGroup-user"
            key={user.id}
            onClick={() => onClick(user)}
        >
            {`${user.email}`}
        </ShuttleItem>
    );

    const { loading: accessesLoading, data: accessesData } = useQuery<{
        userGroupPrinterGroupAccesses: UserGroupPrinterGroupAccessOutput[];
    }>(USER_GROUP_PRINTER_GROUP_ACCESSES, {
        onError: (error) => Notifier.error(error),
    });

    const [updateUserGroupPrinterGroupAccesses] = useMutation(
        UPDATE_USER_GROUP_PRINTER_GROUP_ACCESSES
    );

    useEffect(() => {
        if (accessesData?.userGroupPrinterGroupAccesses) {
            const initialPrinterGroupIds = accessesData?.userGroupPrinterGroupAccesses
                .filter((access) => access.userGroup.id === props.userGroup?.id)
                .map((access) => access.printerGroup.id);
            setOldPrinterGroupIds(initialPrinterGroupIds);
            setNewPrinterGroupIds(initialPrinterGroupIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessesData?.userGroupPrinterGroupAccesses]);

    /**
     * Update user group IDs for user-printer group mappings.
     *
     * @param userGroupId needs providing for the case of creating a new user
     * group, where props.userGroup will be undefined
     */
    const updatePrinterGroupIds = (userGroupId: number) => {
        return new Promise<void>((resolve) => {
            const printerGroupIdsToAdd = newPrinterGroupIds.filter(
                (localId) => !oldPrinterGroupIds.includes(localId)
            );
            const printerGroupIdsToRemove = oldPrinterGroupIds.filter(
                (remoteId) => !newPrinterGroupIds.includes(remoteId)
            );

            if (printerGroupIdsToAdd.length > 0 || printerGroupIdsToRemove.length > 0) {
                void updateUserGroupPrinterGroupAccesses({
                    variables: {
                        toCreate: printerGroupIdsToAdd.map((id) => {
                            return { printerGroupId: id, userGroupId: userGroupId };
                        }),
                        toDelete: accessesData?.userGroupPrinterGroupAccesses
                            .filter(
                                (ugpga) =>
                                    ugpga.userGroup.id === userGroupId &&
                                    printerGroupIdsToRemove.includes(ugpga.printerGroup.id)
                            )
                            .map((ugpga) => ugpga.id),
                    },
                    update: (cache, { data }) => {
                        // extract current access mappings from Apollo cache
                        const { userGroupPrinterGroupAccesses } =
                            cache.readQuery<{
                                userGroupPrinterGroupAccesses: UserGroupPrinterGroupAccess[];
                            }>({ query: USER_GROUP_PRINTER_GROUP_ACCESSES }) || {};

                        // if cache hit, update access mapping
                        if (userGroupPrinterGroupAccesses) {
                            cache.writeQuery({
                                query: USER_GROUP_PRINTER_GROUP_ACCESSES,
                                data: {
                                    userGroupPrinterGroupAccesses: [
                                        ...userGroupPrinterGroupAccesses,
                                        // add new ones
                                        ...data.updateUserGroupPrinterGroupAccesses,
                                    ].filter(
                                        // filter out removed ones
                                        (access) =>
                                            !(
                                                access.userGroup.id === userGroupId &&
                                                printerGroupIdsToRemove.includes(
                                                    access.printerGroup.id
                                                )
                                            )
                                    ),
                                },
                            });
                        }
                    },
                    onError: (error) => Notifier.error(error),
                    onCompleted: () => {
                        setOldPrinterGroupIds([...newPrinterGroupIds]);
                        resolve();
                    },
                });
            }
        });
    };

    const updateOrCreateUserGroup = () => {
        return new Promise<number>((resolve) => {
            const refetchQueries = [{ query: LIST_USER_GROUPS }, { query: GET_COMPANY_LICENSES }];
            if (!props.userGroup) {
                void createUserGroup({
                    variables: {
                        ...newUserGroupState,
                        sendEmails,
                        adminName: userContext.name,
                    },
                    onCompleted: (result) => {
                        setOldUserGroupState(newUserGroupState);
                        resolve(result.createUserGroup?.id);
                    },
                    refetchQueries,
                    awaitRefetchQueries: true,
                });
            } else {
                const userGroupId = props.userGroup.id;
                const userIdsToAdd = newUserGroupState.userIds.filter(
                    (id) => !oldUserGroupState.userIds.includes(id)
                );
                const userIdsToRemove = oldUserGroupState.userIds.filter(
                    (id) => !newUserGroupState.userIds.includes(id)
                );
                const rolesToAdd = roleDifference(newUserGroupState.roles, oldUserGroupState.roles);
                const rolesToRemove = roleDifference(
                    oldUserGroupState.roles,
                    newUserGroupState.roles
                );
                const licenseIdsToAdd = newUserGroupState.licenseIds.filter(
                    (license) => !oldUserGroupState.licenseIds.includes(license)
                );
                const licenseIdsToRemove = oldUserGroupState.licenseIds.filter(
                    (license) => !newUserGroupState.licenseIds.includes(license)
                );
                void updateUserGroup({
                    variables: {
                        userIdsToAdd,
                        userIdsToRemove,
                        rolesToAdd,
                        rolesToRemove,
                        licenseIdsToAdd,
                        licenseIdsToRemove,
                        id: props.userGroup.id,
                        name: newUserGroupState.name,
                        adminName: userContext.name,
                        sendEmails,
                    },
                    refetchQueries,
                    awaitRefetchQueries: true,
                    onCompleted: (userGroup) => {
                        if (sendEmails) {
                            void sendNotifications({
                                variables: {
                                    id: userGroup.updateUserGroup.id,
                                    name: newUserGroupState.name,
                                    userIdsToAdd,
                                    rolesToAdd,
                                    licenseIdsToAdd,
                                    adminName: userContext.name,
                                },
                            });
                        }
                        setOldUserGroupState(newUserGroupState);
                        resolve(userGroupId);
                    },
                    onError: (error) => Notifier.error(error),
                });
            }
        });
    };

    if (loadingUsers || loadingUserGroups || accessesLoading || !companyLicenses) {
        return <Loader active />;
    }

    let exhaustedCount = exhaustedProducts.length;
    const getExhaustedLicensesWarning = () => (
        <p className="ui yellow message qa-licenseWarning">
            {t("license.exhausted_warn_1")}
            {isUserGroupDirty ? (
                `    ${exhaustedProducts.map((product) => ` ${product.name}`).toString()}.`
            ) : (
                <ul>
                    {exhaustedProducts.map((product) => (
                        <li key={`product-${product.id}`}>
                            {product.name}
                            {companyLicenses?.licenses.some(
                                (l) =>
                                    l.state === "expired" && l.package?.product?.id === product.id
                            )
                                ? ` (${t("license.exhausted_warn_2")})`
                                : null}
                        </li>
                    ))}
                </ul>
            )}
        </p>
    );

    const delta = newUserGroupState.userIds.length - oldUserGroupState.userIds.length;
    const txtDelta = delta > 0 ? ` (+${delta})` : delta === 0 ? "" : ` (${delta})`;
    const txtSelected = `${txtDelta}, ${newUserGroupState.userIds.length} ${t("user_group.total")}`;
    const txtAvailable = `${t("user_group.available_users")}, ${availableUsers.length} ${t(
        "user_group.total"
    )}`;

    // Compute whether or not to show the warning about users needing to register.
    // This only needs showing if any of the printers we are associating to has no existing registered users
    // i.e. if there is a printer group which is not associated to any user group that has registered users
    const userGroupIdsWithRegisteredUsers = userGroups.map((userGroup) =>
        userGroup.users.some((user) => user.hasRegistered) ? userGroup.id : null
    );
    const anAssociatedPrinterGroupHasNoRegisteredUsers = newPrinterGroupIds.some(
        (printerGroupId) =>
            !accessesData?.userGroupPrinterGroupAccesses.some(
                (ugpga) =>
                    ugpga.printerGroup.id === printerGroupId &&
                    userGroupIdsWithRegisteredUsers.includes(ugpga.userGroup.id)
            )
    );
    // anAssociatedPrinterGroupHasNoRegisteredUsers above corresponds to the state in the backend
    // someUserOfThisGroupHasRegistered refers to the state on the client that the admin is thinking of committing
    const someUserOfThisGroupHasRegistered = users.some(
        (user) => user.hasRegistered && newUserGroupState.userIds.includes(user.id)
    );
    const showNoneRegisteredWarning =
        newPrinterGroupIds.length > 0 &&
        newUserGroupState.userIds.length > 0 &&
        anAssociatedPrinterGroupHasNoRegisteredUsers &&
        !someUserOfThisGroupHasRegistered;

    return (
        <>
            <Form>
                <div className="ui one column grid">
                    <div className="eight wide column">
                        <FormField disabled={false}>
                            <label>
                                {t("user_group.user_group_name")}
                                <span style={{ color: "red" }}>&nbsp;*</span>
                            </label>
                            <Input
                                className={classnames("qa-userGroup-name-input", {
                                    error: !!nameInvalidReason,
                                })}
                                value={newUserGroupState.name}
                                onChange={(event) =>
                                    setNewUserGroupState({
                                        ...newUserGroupState,
                                        name: event.currentTarget.value,
                                    })
                                }
                            />
                            {nameInvalidReason && (
                                <p className="ui negative message qa-nameInvalidReason">
                                    {nameInvalidReason}
                                </p>
                            )}
                        </FormField>
                    </div>
                    <div className="wide column" style={{ marginTop: -10 }}>
                        <FormField key="roles" disabled={false}>
                            <label>{t("user_group.assign_shops_roles")}</label>
                            <RoleSelector
                                id="qa-userGroup-roles"
                                roles={newUserGroupState.roles}
                                licenseIds={newUserGroupState.licenseIds}
                                groupLicenses={userGroups
                                    .find((group) => group.id === props.userGroup?.id)
                                    ?.licenses.map((e) => e.id)}
                                onChange={(roles, licenseIds) => {
                                    setNewUserGroupState({
                                        ...newUserGroupState,
                                        roles,
                                        licenseIds,
                                    });
                                }}
                            />
                            {exhaustedCount > 0 && getExhaustedLicensesWarning()}
                        </FormField>
                    </div>
                    {enabledFeatures.accessControl && (
                        <div className="wide column" style={{ marginTop: -10 }}>
                            <FormField key="printerGroups" disabled={false}>
                                <PrinterGroupMultiSelect
                                    ids={newPrinterGroupIds}
                                    onChange={(ids: number[]) => {
                                        setNewPrinterGroupIds(ids);
                                    }}
                                    labelFontSize={18}
                                    warning={
                                        showNoneRegisteredWarning
                                            ? t("user_group.none_registered")
                                            : undefined
                                    }
                                />
                            </FormField>
                        </div>
                    )}
                </div>
                <FormField disabled={false} style={{ marginTop: 20 }}>
                    <div style={{ display: "flex", alignItems: "stretch" }}>
                        <ShuttleCol className="field">
                            <label>{`${txtAvailable}`}</label>
                            <div
                                style={{
                                    height: "10px",
                                    margin: "14px",
                                }}
                            />
                            <ShuttleList className="qa-availableUsers">
                                {availableUsers.map((user) =>
                                    getShuttleRow(user, () => {
                                        let group = { ...newUserGroupState };
                                        group.userIds = group.userIds.concat(user.id);
                                        setNewUserGroupState(group);
                                    })
                                )}
                            </ShuttleList>
                        </ShuttleCol>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <i className="ui icon large angle right" />
                        </div>
                        <ShuttleCol className="field">
                            <label>{`${t("user_group.selected_users")}${txtSelected}`}</label>
                            <FormField>
                                <Checkbox
                                    className="qa-userGroup-notifyUsers"
                                    onClick={() => setSendEmails(!sendEmails)}
                                    // TODO: Display userIdsToAdd.length?
                                    label={t("user_group.notify_users")}
                                    checked={sendEmails}
                                />
                            </FormField>
                            <ShuttleList className="qa-existingUsers">
                                {existingUsers.map((user) =>
                                    getShuttleRow(user, () => {
                                        let group = { ...newUserGroupState };
                                        group.userIds = group.userIds.filter(
                                            (userId) => userId !== user.id
                                        );
                                        setNewUserGroupState(group);
                                    })
                                )}
                            </ShuttleList>
                        </ShuttleCol>
                    </div>
                </FormField>
                <div
                    style={{
                        display: "flex",
                    }}
                >
                    <div style={{ flexGrow: 1 }}></div>
                    {!!props.userGroup && (
                        <StyledDeleteButton
                            className="qa-userGroups-delete ui button"
                            style={{ height: "100%" }}
                            negative
                            type="button"
                            onClick={() => {
                                setDeleteClicked(true);
                            }}
                        >
                            {t("user_groups.delete_user_group")}
                        </StyledDeleteButton>
                    )}

                    <FormField key="submit" style={{ display: "flex" }}>
                        <Button
                            type="submit"
                            className="primary qa-userGroup-submit"
                            disabled={!isUserGroupDirty && !arePrinterGroupsDirty}
                            onClick={async () => {
                                setNameInvalidReason(getNameInvalidReason(newUserGroupState.name));
                                if (!getNameInvalidReason(newUserGroupState.name)) {
                                    let userGroupId = props.userGroup?.id;
                                    if (isUserGroupDirty) {
                                        userGroupId = await updateOrCreateUserGroup();
                                    }
                                    if (arePrinterGroupsDirty && userGroupId) {
                                        await updatePrinterGroupIds(userGroupId);
                                    }
                                    if (!props.userGroup && userGroupId) {
                                        Notifier.success(t("user_group.successfully_created"));
                                        // Redirect to newly created group
                                        props.history.push(`${PATHS.userGroup}/${userGroupId}`);
                                    } else {
                                        Notifier.success(t("user_group.successfully_updated"));
                                    }
                                }
                            }}
                        >
                            {t("forms.submit")}
                        </Button>
                    </FormField>
                </div>
            </Form>
            {deleteClicked && (
                <DeleteEntityConfirmation
                    open
                    entity={{ id: props.userGroup?.id, name: props.userGroup?.name }}
                    type={t("user_group.type")}
                    mutation={DELETE_USER_GROUP}
                    refetchQueries={[
                        { query: LIST_USER_GROUPS },
                        { query: GET_COMPANY_LICENSES },
                        { query: USER_GROUP_PRINTER_GROUP_ACCESSES },
                    ]}
                    onClose={() => setDeleteClicked(false)}
                    update={() => {
                        Notifier.success(t("user_groups.successfully_deleted"));
                        props.history.push(`${PATHS.userGroups}`);
                    }}
                />
            )}
        </>
    );
};
