// This regions data provided by business team
// TODO: make this a subset
export const gatewayRegions = [
    { text: "AMER", value: "AMER" },
    { text: "EMEA", value: "EMEA" },
    { text: "INTERNAL", value: "INTERNAL" },
    { text: "NORTH ASIA", value: "NORTH ASIA" },
    { text: "SOUTH ASIA", value: "SOUTH ASIA" },
    { text: "OTHER", value: "OTHER" },
];
