import { FormField, Input } from "grabcad-ui-elements";
import { ReactNode, useEffect, useState } from "react";

/** Metadata */
export interface FormFieldMeta<DT> {
    name: Exclude<keyof DT, symbol>;
    placeholder: string;
    validator?: Validator<any>;
    /** Indicates whether a red star should appear on required fields, not used for validation */
    isRequired?: boolean;
}

export type Validator<T> = (val: T, isDuplicate?: boolean) => boolean;

/** Returns true if a value is not undefined, empty, or whitespace */
export const notEmptyValidator: Validator<any> = (val) => !(val === undefined || isWhitespace(val));

export function isWhitespace(str: any): boolean {
    return !str || (typeof str === "string" && str.trim() === "");
}

/** Detect user attempting to manually type wildcards by mistake */
export function isWildcard(str: any): boolean {
    return typeof str === "string" && str.trim() === "*";
}

/** either <th> or <th> with asterisk */
export const DecoratedHeader: React.FunctionComponent<{
    children: ReactNode;
    meta: FormFieldMeta<any>;
}> = ({ children, meta }) =>
    meta.isRequired ? <RequiredHeader>{children}</RequiredHeader> : <th>{children}</th>;

/** <th> with asterisk to indicate it's for a required item */
export const RequiredHeader: React.FunctionComponent = ({ children }) => (
    <th>
        {children}
        <span style={{ color: "red" }}>&nbsp;*</span>
    </th>
);

/**
 * Event handler to handle changes to underlying data model.
 *
 * index = is which row changed (0-based)
 * DT[fieldName] = is the value that should be changed
 *
 * @param DT is the data type whose field will be changed . e.g., DT = { foo: 10, bar: "x" } can be changed by onChange(0, "foo", ...)
 */
export type OnFormEditableFieldChange<DT> = (
    index: number,
    fieldName: keyof DT,
    e: React.ChangeEvent<HTMLInputElement>
) => void;

export interface FormEditableFieldProps<DT> {
    index: number;
    value?: string | null;
    meta: FormFieldMeta<DT>;
    onChange?: OnFormEditableFieldChange<DT>;
    idPrefix?: "qa-machine-input-" | "qa-material-input-";

    /** Bump this up to reset validation state (useful for when row is deleted but we re-use the DOM/React component) */
    resetStateCounter?: number;
    isDuplicate?: boolean;
}

/**
 * Cell (<td><input/></td>) inside a <form>.
 *
 * NB: A "form" looks like multiple rows of
 *
 * row: index=0: <tr><FormEditableField/> <FormEditableField/> ... <FormEditableField/></tr>
 * row: index=1: <tr><FormEditableField/> <FormEditableField/> ... <FormEditableField/></tr>
 * ...
 *
 * @param DT see EditableFieldChangeHandler
 */
export function FormEditableField<DT>({
    index,
    value = "",
    meta,
    onChange,
    idPrefix,
    resetStateCounter,
    isDuplicate,
}: FormEditableFieldProps<DT>): React.ReactElement {
    const [isValid, setValid] = useState(true);

    // Reset valid state (e.g., after last row is deleted but we still want to render an empty row)
    useEffect(() => {
        setValid(meta.validator ? meta.validator(value, isDuplicate) : true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetStateCounter, meta.validator, isDuplicate]);

    return (
        <td>
            <FormField key={`${meta.name}-${index}`}>
                <Input
                    id={`${idPrefix}${meta.name}-${index}`}
                    style={{ width: "100%", outline: isValid ? null : "4px solid red" }}
                    placeholder={meta.placeholder}
                    value={value || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = e.target?.value;
                        const isStillValid = meta.validator
                            ? meta.validator(val, isDuplicate)
                            : true;
                        setValid(isStillValid);

                        if (onChange) {
                            onChange(index, meta.name, e);
                        }
                    }}
                />
            </FormField>
        </td>
    );
}

interface FieldPlaceholderValueProps {
    value: string;
}

export const FieldPlaceholderValue: React.FunctionComponent<FieldPlaceholderValueProps> = ({
    value,
}: FieldPlaceholderValueProps) => <i style={{ color: "gray" }}>{value}</i>;
