import { useContext, FunctionComponent } from "react";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { useCompanyLicensesQuery } from "../../graphql/Queries/Licenses/GetCompanyLicenses";
import { Placeholder, PlaceholderWrapper } from "../../components/placeholder";
import styled, { Image, Label } from "grabcad-ui-elements";
import PlaceholderSvg from "../../images/placeholder_licenses.svg";
import { IScreenProps } from "../Screen";
import { ProductData, DetailedLicenseData, SdkIcon } from "../../graphql";
import { InfoBox } from "../../components/Styles";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { ICON_MAP } from "../SDK/SdksOverview";

import ShopLogoSvg from "../../images/Shop_logo.svg";
import PrintLogoSvg from "../../images/print_logo.svg";
import SdkLogoSvg from "../../images/printer_connectivity_sdk.svg";
import MachineApiSvg from "../../images/machine_api_runtime.svg";
import ProtectAMLogoSvg from "../../images/protect_am_icon.svg";
import OpenAMLogoSvg from "../../images/OpenAM_logo.svg";
import defaultLicenseSvg from "../../images/thumb_licenses.svg";
import StreamlineProSvg from "../../images/streamline_pro_logomark.svg";
import AnalyzeSvg from "../../images/analyze_logomark.svg";

// TODO: Centralize these strings
const SHOP_APPLICATION_TYPE = "shop";
const SDK_APPLICATION_TYPE = "sdk";
const API_APPLICATION_TYPE = "api";
const PRINT_APPLICATION_TYPE = "print";
const PRINT_MANAGER_APPLICATION_TYPE = "print_manager";
const OPEN_MATERIALS_APPLICATION_TYPE = "open_materials";
const PROTECT_AM = "project_am";
const STREAMLINE_PRO = "streamline";
const ANALYZE = "analyze";
const GENERIC_LICENSE_ICON = { path: defaultLicenseSvg, height: 80 };

/**
 * @todo Allow image upload on the product creation screen and use those images instead of making this based on application type
 */
const icons: { [key: string]: { path: string; height: number } } = {
    [SHOP_APPLICATION_TYPE]: { path: ShopLogoSvg, height: 128 }, // This SVG has built in padding on top and bottom
    [SDK_APPLICATION_TYPE]: { path: SdkLogoSvg, height: 80 },
    [API_APPLICATION_TYPE]: { path: MachineApiSvg, height: 80 },
    [PRINT_APPLICATION_TYPE]: { path: PrintLogoSvg, height: 80 },
    [PRINT_MANAGER_APPLICATION_TYPE]: GENERIC_LICENSE_ICON, // May be replaced by Control logo soon?
    [OPEN_MATERIALS_APPLICATION_TYPE]: { path: OpenAMLogoSvg, height: 80 },
    [PROTECT_AM]: { path: ProtectAMLogoSvg, height: 80 },
    [STREAMLINE_PRO]: { path: StreamlineProSvg, height: 63 }, // This height makes the rounded rect match Shop et al.'s logomark dimensions
    [ANALYZE]: { path: AnalyzeSvg, height: 63 }, // This height makes the rounded rect match Shop et al.'s logomark dimensions
};

type SdkIcons = {
    [icon in SdkIcon]: {
        path: string;
        height: number;
    };
};

const sdkIcons = (Object.keys(ICON_MAP) as SdkIcon[]).reduce((res: SdkIcons, icon: SdkIcon) => {
    res[icon] = { path: ICON_MAP[icon], height: 80 };
    return res;
}, {} as SdkIcons);

const ProductLicensesOverview = styled.div<{ disabled: boolean }>`
    background: #fdfdfd;
    border: 0.5px solid #c4c4c4;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    margin: 10px 0;
    cursor: pointer;
    position: relative;
    &:hover {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    }
    h2 {
        margin-top: -6px;
    }
    .imgHolder {
        background: #fdfdfd;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        width: 130px;
        min-width: 130px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-right: 20px;
    }
    dl {
        display: grid;
        grid-template-columns: max-content auto;
        margin: 0 0 -2px 0;
    }
    dt {
        grid-column-start: 1;
        text-align: right;
        &:after {
            content: ":";
        }
    }
    dd {
        grid-column-start: 2;
        margin-left: 10px;
        font-weight: bold;
        // Prevent text from stretching container
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .ribbon.label {
        position: absolute;
        left: calc(100% + 1.2em);
    }
`;

export const LicensesOverview: FunctionComponent<IScreenProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    const { companyLicenses, loadingLicenses } = useCompanyLicensesQuery();

    const getProductIcon = (product: ProductData) => {
        if (product.sdk) {
            return sdkIcons[product.sdk.icon];
        } else if (product.applicationType) {
            if (product.name.toLowerCase().includes("protectam")) {
                // "ProtectAM Downloads" in prod
                // ProtectAM has generic 'print_manager' app type, but we have a special logo for it.
                // Unfortunately with current business logic we're forced to use its display name
                // as a magic string.
                return icons[PROTECT_AM];
            } else if (product.name.toLowerCase().includes("streamline")) {
                // "GrabCAD Streamline Pro" in prod
                // Similar to above, Streamline Pro has an 'any' applicationType.id.
                return icons[STREAMLINE_PRO];
            }
            return icons[product.applicationType.id] || GENERIC_LICENSE_ICON;
        } else {
            return GENERIC_LICENSE_ICON;
        }
    };

    const renderProductLogo = (product: ProductData) => {
        const icon = getProductIcon(product);
        return (
            icon && (
                <div className="imgHolder">
                    <Image src={icon.path} height={icon.height} />
                </div>
            )
        );
    };

    const renderActiveProduct = (product: ProductData, licenses: DetailedLicenseData[]) => {
        let totalSeats = 0;
        let totalSeatsUsed = 0;
        let earliestEndDate = licenses[0].endDate;
        const groupIds = new Set<number>();
        const serials: string[] = [];
        licenses.forEach((license) => {
            if (license.endDate && !earliestEndDate) {
                earliestEndDate = license.endDate;
            } else if (earliestEndDate && license.endDate && earliestEndDate > license.endDate) {
                earliestEndDate = new Date(license.endDate);
            }
            totalSeats += license.maxUsers;
            totalSeatsUsed += license.seatsUsed;
            license.groups.forEach((group) => {
                groupIds.add(group.id);
            });
            license.machineEntitlements?.forEach((scope) => {
                if (scope.serial) {
                    serials.push(scope.serial);
                }
            });
        });
        let anyUnlimitedLicense = licenses.some((license) => !!license.package.unlimitedMaxUsers);
        let hasCompanyWideLicense = licenses.some((license) => !!license.package.companyWide);
        let availableSeats: string = anyUnlimitedLicense
            ? t("license.unlimited")
            : (totalSeats - totalSeatsUsed).toString();
        if (hasCompanyWideLicense) {
            availableSeats += `, ${t("license.company_wide")}`;
        }

        return (
            <ProductLicensesOverview
                id="qa-licenseOverview-viewProductLicenses"
                onClick={() => props.history?.push(`/product/${product.id}`)}
                key={product.id}
                disabled={licenses.length === 0}
            >
                {renderProductLogo(product)}
                <div>
                    <h2>{product.name}</h2>
                    <dl>
                        {!anyUnlimitedLicense && (
                            <>
                                <dt>{t("licenses_overview.total_seats")}</dt>
                                <dd>{totalSeats}</dd>
                            </>
                        )}
                        {product.applicationType &&
                            product.applicationType.id !== API_APPLICATION_TYPE && (
                                <>
                                    <dt>{t("licenses_overview.available_seats")}</dt>
                                    <dd>{availableSeats}</dd>
                                    <dt>{t("licenses_overview.used_seats")}</dt>
                                    <dd>{totalSeatsUsed}</dd>
                                    <dt>{t("licenses_overview.num_user_groups")}</dt>
                                    <dd>{groupIds.size}</dd>
                                    <dt>{t("licenses_overview.serial_numbers")}</dt>
                                    <dd>{serials.join(", ")}</dd>
                                </>
                            )}
                        <dt>{t("licenses_overview.exp_date")}</dt>
                        <dd>
                            {earliestEndDate
                                ? new Date(earliestEndDate).toLocaleDateString("en-US")
                                : "-"}
                        </dd>
                    </dl>
                </div>
            </ProductLicensesOverview>
        );
    };

    const renderExpiredProduct = (product: ProductData, licenses: DetailedLicenseData[]) => {
        let earliestEndDate = licenses[0].endDate;
        licenses.forEach((license) => {
            if (license.endDate && !earliestEndDate) {
                earliestEndDate = license.endDate;
            } else if (earliestEndDate && license.endDate && earliestEndDate > license.endDate) {
                earliestEndDate = new Date(license.endDate);
            }
        });

        return (
            <ProductLicensesOverview
                id="qa-licenseOverview-viewProductLicenses"
                key={product.id}
                disabled={true}
                style={{ cursor: "not-allowed" }}
            >
                <Label ribbon="right" color="red">
                    {t("license.states.expired")}
                </Label>
                {renderProductLogo(product)}
                <div>
                    <h2>{product.name}</h2>
                    <dl>
                        <dt>{t("licenses_overview.expired_date")}</dt>
                        <dd style={{ color: "red" }}>
                            {earliestEndDate
                                ? new Date(earliestEndDate).toLocaleDateString("en-US")
                                : "-"}
                        </dd>
                    </dl>
                    {/* This will need to be dynamic once multiple products are supported */}
                    <p style={{ marginTop: 15 }}>{t("licenses_overview.license_expired_msg")}</p>
                </div>
            </ProductLicensesOverview>
        );
    };

    const renderNotStartedProduct = (product: ProductData, licenses: DetailedLicenseData[]) => {
        let earliestStartDate = licenses[0].startDate;
        licenses.forEach((license) => {
            if (earliestStartDate > license.startDate) {
                earliestStartDate = new Date(license.startDate);
            }
        });

        return (
            <ProductLicensesOverview
                id="qa-licenseOverview-viewProductLicenses"
                key={product.id}
                disabled={true}
                style={{ cursor: "not-allowed" }}
            >
                {renderProductLogo(product)}
                <div>
                    <h2>{product.name}</h2>
                    <dl>
                        <dt>{t("licenses_overview.earliest_start_date")}</dt>
                        <dd>{new Date(earliestStartDate).toLocaleDateString("en-US")}</dd>
                    </dl>
                    {/* This will need to be dynamic once multiple products are supported */}
                    <p style={{ marginTop: 15 }}>
                        {t("licenses_overview.license_not_started_msg")}
                    </p>
                </div>
            </ProductLicensesOverview>
        );
    };

    const title = <h2 className="page-header">{t("licenses_overview.header")}</h2>;

    let placeholder: null | JSX.Element = null;
    if (companyLicenses?.licenses.length === 0) {
        placeholder = (
            <PlaceholderWrapper>
                {title}
                <Placeholder id="qa-companyLicenses-placeholder">
                    <Image src={PlaceholderSvg} />
                    <h2>{t("licenses_overview.no_licenses")}</h2>
                </Placeholder>
            </PlaceholderWrapper>
        );
    }

    // organize our licenses into groups based on product
    const productLicenses: { [key: string]: DetailedLicenseData[] } = {};
    companyLicenses?.licenses.forEach((licenseData) => {
        if (!licenseData.package?.product) {
            return;
        }
        const productId = licenseData.package.product.id;
        productLicenses[productId] = productLicenses[productId] || [];
        productLicenses[productId].push(licenseData);
    });
    // in case we have two products with the same name, use the id
    const overviewCards = Object.keys(productLicenses).map((productId) => {
        const productLicensesData = productLicenses[productId];
        if (!productLicensesData?.length) {
            return null;
        }
        const product = productLicensesData[0].package.product as ProductData;
        const activeLicenses = productLicensesData.filter(
            (license) => license.state === "active" || license.state === "expiring"
        );
        const expiredLicenses = productLicensesData.filter(
            (license) => license.state === "expired"
        );
        const notStartedLicenses = productLicensesData.filter(
            (license) => license.state === "not_started"
        );
        if (activeLicenses.length > 0) {
            return renderActiveProduct(product, activeLicenses);
        }
        if (notStartedLicenses.length > 0) {
            return renderNotStartedProduct(product, notStartedLicenses);
        }
        return renderExpiredProduct(product, expiredLicenses);
    });

    return (
        <>
            <Breadcrumbs sections={[{ label: t("licenses_overview.breadcrumb") }]} />
            {loadingLicenses && <div className="ui loader active" />}
            {placeholder || (
                <div style={{ paddingBottom: 25 }}>
                    {title}
                    <InfoBox>{t("licenses_overview.info")}</InfoBox>
                    {overviewCards}
                </div>
            )}
        </>
    );
};
