import gql from "graphql-tag";

export const CREATE_PRINTER_GROUP = gql`
    mutation createPrinterGroup($name: String, $printerIds: [String!]!) {
        createPrinterGroup(name: $name, printerIds: $printerIds) {
            id
            name
            printerIds
        }
    }
`;

export const UPDATE_PRINTER_GROUP = gql`
    mutation updatePrinterGroup(
        $id: Int!
        $name: String
        $printerIdsToAdd: [String!]
        $printerIdsToRemove: [String!]
    ) {
        updatePrinterGroup(
            id: $id
            name: $name
            printerIdsToAdd: $printerIdsToAdd
            printerIdsToRemove: $printerIdsToRemove
        ) {
            id
            name
            printerIds
        }
    }
`;

export const DELETE_PRINTER_GROUP = gql`
    mutation deletePrinterGroup($id: Int!) {
        deletePrinterGroup(id: $id)
    }
`;
