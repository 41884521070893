import { MachineEntitlementData, MaterialEntitlementCreateData } from "../graphql";
import { FIELD_VALUE_ANY } from "@grabcad/user-license";

const MACHINE_ENTITLEMENT_ALL_FIELDS: (keyof MachineEntitlementData)[] = [
    "serial",
    "technology",
    "model",
];

export function machineEntitlementHasAnyField(printer: MachineEntitlementData): boolean {
    return MACHINE_ENTITLEMENT_ALL_FIELDS.some((fieldName) => printer[fieldName] === null);
}

/** Returns true if a machineEntitlement is underdefined, i.e. is all "ANY" (represented by null) */
export function machineEntitlementMatchesAny(printer: MachineEntitlementData): boolean {
    return MACHINE_ENTITLEMENT_ALL_FIELDS.every((fieldName) => printer[fieldName] === null);
}

const MATERIAL_REQUIRED_FIELDNAMES: (keyof MaterialEntitlementCreateData)[] = [
    "name",
    "technology",
];
const MATERIAL_ALL_FIELDNAMES: (keyof MaterialEntitlementCreateData)[] =
    MATERIAL_REQUIRED_FIELDNAMES;

/** At least one field is "*" */
export function materialHasAnyField(material: Partial<MaterialEntitlementCreateData>): boolean {
    return MATERIAL_ALL_FIELDNAMES.some((fieldName) => material[fieldName] === FIELD_VALUE_ANY);
}

/** All mandatory fields are "*" and optional fields are omitted or empty */
export function materialMatchesAny(material: Partial<MaterialEntitlementCreateData>): boolean {
    return MATERIAL_REQUIRED_FIELDNAMES.every(
        (fieldName) => material[fieldName] === FIELD_VALUE_ANY
    );
}
