import React from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryOptions, QueryResult, ApolloError } from "@apollo/client";
import { LicenseData } from "../../types";
import { FeatureFragments } from "../../Fragments/Feature";
import { LicenseFragments } from "../../Fragments/License";

export const GET_LICENSE = gql`
    query license($id: Int!) {
        license(id: $id) {
            ...licenseFields
            package {
                id
                name
                maxUsers
                unlimitedMaxUsers
                companyWide
                duration
                product {
                    features {
                        ...featureFields
                    }
                }
            }
        }
    }
    ${LicenseFragments.licenseFields}
    ${FeatureFragments.featureFields}
`;

export interface GetLicenseQueryVariables {
    id: number;
}

export interface GetLicenseQueryResult {
    license: LicenseData;
}

export interface GetLicenseQueryProps
    extends Omit<QueryOptions<GetLicenseQueryVariables>, "query"> {
    children: (result: QueryResult<GetLicenseQueryResult, GetLicenseQueryVariables>) => JSX.Element;
    displayName?: string;
    skip?: boolean;
    onCompleted?: (data: GetLicenseQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class GetLicenseQuery extends React.Component<GetLicenseQueryProps> {
    render() {
        const { children, variables, ...props } = this.props;

        return (
            <Query<GetLicenseQueryResult, GetLicenseQueryVariables>
                query={GET_LICENSE}
                fetchPolicy="no-cache"
                variables={variables}
                {...props}
            >
                {children}
            </Query>
        );
    }
}
