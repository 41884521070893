import gql from "graphql-tag";
import { UserGroup } from "..";
import { useQueryWithCatch } from "../../utils/hooks/useQueryWithCatch";
import { alphabetizeByProp } from "../../utils/sort";

export const LIST_USER_GROUPS = gql`
    query company {
        company {
            userGroups {
                id
                name
                users {
                    id
                    hasRegistered
                }
                roles {
                    application {
                        id
                        visible
                        dateDeleted
                    }
                    applicationRole {
                        id
                        name
                    }
                }
                licenses {
                    id
                    state
                }
            }
        }
    }
`;

interface UserGroupsQueryResult {
    company: {
        userGroups: Pick<UserGroup, "id" | "name" | "users" | "roles" | "licenses">[];
    };
}

export function useUserGroupsQuery() {
    const { data, loading } = useQueryWithCatch<UserGroupsQueryResult>(LIST_USER_GROUPS);

    return {
        loadingUserGroups: loading,
        userGroups: data?.company
            ? [...data.company.userGroups].sort(alphabetizeByProp("name"))
            : [],
    };
}

// GC-93984: This gets less info than the list query above. Could be deleted and the hook could remain unchanged.
export const GET_USER_GROUP = gql`
    query userGroup($id: Int!) {
        userGroup(id: $id) {
            id
            name
            users {
                id
                hasRegistered
            }
            roles {
                application {
                    id
                }
                applicationRole {
                    id
                    name
                }
            }
            licenses {
                id
            }
        }
    }
`;

interface UserGroupQueryVariables {
    id: number;
}

interface UserGroupQueryResult {
    userGroup: UserGroup;
}

export function useUserGroupQuery(variables: UserGroupQueryVariables) {
    const { data, loading } = useQueryWithCatch<UserGroupQueryResult, UserGroupQueryVariables>(
        GET_USER_GROUP,
        {
            variables,
        }
    );

    return {
        loadingUserGroup: loading,
        userGroup: data?.userGroup,
    };
}
