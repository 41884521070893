import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import type {
    CompanySettings,
    CompanyTOSState,
    CustomerInfo,
    OperatingSchedule,
    ResellerInfo,
} from "../types";
import { CompanyFragments } from "../Fragments/Company";

export const CREATE_COMPANY = gql`
    mutation createCompany(
        $name: String!
        $urlPrefix: String
        $admins: [String!]!
        $internal: Boolean
        $customerInfo: CustomerInput
        $resellerInfo: ResellerInput
        $settings: CompanySettingsInput
        $notifyAdmins: Boolean
    ) {
        createCompany(
            name: $name
            urlPrefix: $urlPrefix
            admins: $admins
            internal: $internal
            customerInfo: $customerInfo
            resellerInfo: $resellerInfo
            settings: $settings
            notifyAdmins: $notifyAdmins
        ) {
            id
        }
    }
`;

export const UPDATE_COMPANY_GLOBAL_ADMIN = gql`
    mutation updateCompany(
        $id: Int!
        $name: String!
        $urlPrefix: String
        $admins: [String!]!
        $internal: Boolean
        $customerInfo: CustomerInput
        $resellerInfo: ResellerInput
        $settings: CompanySettingsInput
    ) {
        updateCompany(
            id: $id
            data: {
                name: $name
                urlPrefix: $urlPrefix
                admins: $admins
                internal: $internal
                customerInfo: $customerInfo
                resellerInfo: $resellerInfo
                settings: $settings
            }
        ) {
            id
            ...adminEditableFields
            customerInfo {
                accountNumber
                name
                phoneNumber
                email
            }
            resellerInfo {
                name
                phoneNumber
                email
                contactPerson
                accountNumber
            }
        }
    }
    ${CompanyFragments.adminEditableFields}
`;

export const UPDATE_COMPANY = gql`
    mutation updateCompany(
        $id: Int!
        $name: String!
        $admins: [String!]!
        $settings: CompanySettingsInput
        $operatingSchedule: OperatingScheduleInput
        $analyzeServerAddress: String
    ) {
        updateCompany(
            id: $id
            data: {
                name: $name
                admins: $admins
                settings: $settings
                operatingSchedule: $operatingSchedule
                analyzeServerAddress: $analyzeServerAddress
            }
        ) {
            id
            ...adminEditableFields
            ...operatingSchedule
        }
    }
    ${CompanyFragments.adminEditableFields}
    ${CompanyFragments.operatingSchedule}
`;

export const DELETE_COMPANY = gql`
    mutation deleteCompany($id: Int!) {
        deleteCompany(id: $id)
    }
`;

export const DELETE_COMPANIES = gql`
    mutation deleteCompanies($ids: [Int!]!, $hard: Boolean) {
        deleteCompanies(ids: $ids, hard: $hard)
    }
`;

export const RESYNC_COMPANY_ACLS = gql`
    mutation resyncCompanyACLs($companyId: Int!) {
        resyncCompanyACLs(companyId: $companyId) {
            nTotal
            nMissing
            nExtra
        }
    }
`;

export const ACCEPT_TOS = gql`
    mutation acceptTOS {
        acceptTOS {
            id
            tosState
        }
    }
`;

export const UPDATE_COMPANY_ANALYZE_ADDRESS = gql`
    mutation updateCompanyAnalyzeAddress($analyzeAddressUrl: String) {
        updateCompanyAnalyzeAddress(data: { analyzeAddressUrl: $analyzeAddressUrl }) {
            id
            analyzeServerAddress
        }
    }
`;

export interface CreateCompanyAsGlobalAdminVariables {
    urlPrefix?: String;
    admins?: string[];
    internal?: boolean;
    customerInfo?: CustomerInfo;
    resellerInfo?: ResellerInfo;
    notifyAdmins?: boolean;
}
export interface UpdateCompanyAsGlobalAdminVariables
    extends Omit<CreateCompanyAsGlobalAdminVariables, "notifyAdmins"> {
    id: number;
}

export interface UpdateCompanyVariables {
    id: number;
    name: string;
    analyzeServerAddress?: string;
    admins?: string[];
    settings?: CompanySettings;
    operatingSchedule?: OperatingSchedule;
}

export type CompanyFormInput = UpdateCompanyAsGlobalAdminVariables & UpdateCompanyVariables;

export interface DeleteCompaniesVariables {
    ids: number[];
    hard: boolean;
}

export interface DeleteCompaniesResult {
    deleteCompanies: boolean;
}

export const useDeleteCompanies = (
    options: MutationHookOptions<DeleteCompaniesResult, DeleteCompaniesVariables>
) => useMutation<DeleteCompaniesResult, DeleteCompaniesVariables>(DELETE_COMPANIES, options);

interface AcceptTOSResult {
    acceptTOS: {
        id: number;
        tosState: CompanyTOSState;
    };
}

export const useAcceptTOS = (options?: MutationHookOptions<{}, AcceptTOSResult>) =>
    useMutation<{}, AcceptTOSResult>(ACCEPT_TOS, options);
