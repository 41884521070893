import styled, { Button, FormField, Icon, StyledModal as Modal } from "grabcad-ui-elements";

// This because the style-component types don't seem to like what is going on below
const FormFieldNoType = FormField as any;

export const StyledModal = styled(Modal)`
    width: 100% !important;
    max-width: 1185px;
    padding: 2rem;
    min-height: 666px;
    max-height: ${(props) => props?.maxheight ?? "calc(100% - 250px)"};
    overflow: hidden;
    &.visible.transition {
        display: flex !important;
        flex-direction: column;
    }
    ${FormFieldNoType} {
        max-width: 368px;
    }
    form {
        flex: 1 1 auto;
        min-height: 100px;
        display: flex;
        flex-direction: column;
    }
    .printer-cards {
        margin: -15px -20px 0 -20px;
        padding: 20px;
    }
    .printers-placeholder {
        width: 200px;
    }
`;
export const StyledMiniModal = styled(Modal)`
    width: 45% !important;
    max-width: 550px;
    padding: 2rem;
    min-height: 450px;
    max-height: ${(props) => props?.maxheight ?? "calc(100% - 250px)"};
    overflow: hidden;
    &.visible.transition {
        display: flex !important;
        flex-direction: column;
    }
    ${FormFieldNoType} {
        max-width: 300px;
    }
    form {
        flex: 1 1 auto;
        min-height: 100px;
        display: flex;
        flex-direction: column;
    }
`;
export const StyledModalHeader = styled(Modal.Header)`
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px 10px 0 0 !important;

    * {
        margin: 0;
    }
`;
export const StyledCloseButton = styled(Icon)`
    margin: 0 !important;
    height: 100% !important;
    cursor: pointer;
    opacity: 0.5 !important;

    &:hover {
        opacity: 1 !important;
    }
`;
export const StyledModalContent = styled(Modal.Content)`
    color: black;
    background-color: white !important;
    padding: 20px;
    flex: 1 1 auto;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    .ui.message {
        flex: 0 0 auto;
    }
    .printer-list {
        // Negative margin + padding to prevent cards' drop-shadow :hover style from being cropped,
        // and to push scrollbar to right edge of screen.
        margin: -15px -20px 0 -20px;
        padding: 20px 20px;
    }
`;
export const StyledModalActions = styled(Modal.Actions)`
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: ${(props) => props?.justifycontent ?? "space-between !important"};
    width: 100%;
    height: 100%;
    border-radius: 0 0 10px 10px;
`;
export const StyledActions = styled.div`
    display: flex;
`;
export const StyledDeleteButton = styled(Button)`
    &.ui.button.negative {
        border-bottom: 1px solid red;
        font-weight: 500;
    }
`;
export const StyledNumberSelected = styled.p<{ noneSelected: boolean }>`
    margin: 0;
    color: ${({ noneSelected }) => (noneSelected ? "#999" : "#003393")};
`;
