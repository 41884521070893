import gql from "graphql-tag";
import { ProductData } from "../../types";
import { ProductFragments } from "../../Fragments/Product";

export const LIST_PRODUCTS = gql`
    query listProducts {
        listProducts {
            products {
                ...productFields
            }
        }
    }
    ${ProductFragments.productFields}
`;

export const LIST_PRODUCTS_WITH_COMPANIES_AND_PACKAGES = gql`
    query listProducts {
        listProducts {
            products {
                ...productFields
                companies {
                    id
                }
                packages {
                    id
                    name
                }
            }
        }
    }
    ${ProductFragments.productFields}
`;

export interface ListProductsQueryResult {
    listProducts: {
        products: ProductData[];
    };
}
