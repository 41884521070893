import { Notifier } from "grabcad-ui-elements";
import { Navigator } from "./ExtendedNavigator";
import type { TranslationFunction } from "../../components/ApplicationProvider";

const OPEN_GCP_LINK = "gcprint://open/?skipDownload=true";
const GCP_REDIRECT_TO_LINK = "https://www.grabcad.com/print";

const ON_SUCCESS_KEY = "gcp.supported_os";
const WINDOWS = "Windows";
const SHOW_MESSAGE_TIME = 10000;
const MESSAGE_OPTIONS = { autoClose: SHOW_MESSAGE_TIME, closeOnClick: false };

// this is ok in this case since we want to render html from a known source
const renderMessage = (t: TranslationFunction) => {
    const text = t(ON_SUCCESS_KEY).replace(
        "{auto_GC_print}",
        `
        <a href="${GCP_REDIRECT_TO_LINK}" target="_blank">
                GrabCAD Print
        </a>
    `
    );
    return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
};

const getOpenActionAsync = async (translate: TranslationFunction) => {
    const uAgent = await Navigator.userAgentData?.getHighEntropyValues(["platformVersion"]);

    const isSupported = !!(
        Navigator.userAgentData?.platform === WINDOWS &&
        uAgent?.platformVersion &&
        parseInt(uAgent.platformVersion.split(".")[0], 10) > 0
    );

    if (isSupported || window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1) {
        Notifier.warn(renderMessage(translate), MESSAGE_OPTIONS);
        window.open(OPEN_GCP_LINK, "_self");
        return;
    }

    window.open(GCP_REDIRECT_TO_LINK, "_blank");
};

export default getOpenActionAsync;
