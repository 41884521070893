import React, { FunctionComponent } from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { ApolloError, QueryOptions, QueryResult } from "@apollo/client";
import { Dropdown } from "grabcad-ui-elements";
import { Redirect } from "react-router-dom";
import { Notifier } from "../../view/Notifier";

export const APPLICATION_TYPES = gql`
    query applicationTypes {
        applicationTypes {
            id
            description
        }
    }
`;

interface ApplicationTypesQueryResult {
    applicationTypes: { id: string; description: string }[];
}

export interface ApplicationTypesQueryProps extends Omit<QueryOptions, "query"> {
    children: (result: QueryResult<ApplicationTypesQueryResult>) => JSX.Element;
    displayName?: string;
    skip?: boolean;
    onCompleted?: (data: ApplicationTypesQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class ApplicationTypesQuery extends React.Component<ApplicationTypesQueryProps> {
    render() {
        const { children, ...props } = this.props;

        return (
            <Query<ApplicationTypesQueryResult, Record<string, unknown>>
                query={APPLICATION_TYPES}
                {...props}
            >
                {children}
            </Query>
        );
    }
}

interface IApplicationTypeDropdown {
    applicationTypeId: string | undefined;
    onChange: (id: string) => void;
    disabled: boolean;
}

export const ApplicationTypeDropdown: FunctionComponent<IApplicationTypeDropdown> = (props) => {
    return (
        <ApplicationTypesQuery>
            {({ loading, error, data }) => {
                if (loading) {
                    return <div className="ui loader active" />;
                }
                if (error) {
                    Notifier.error(error);
                    return <Redirect to="/package" />;
                }

                let applicationTypes = data?.applicationTypes
                    ? data.applicationTypes.map((applicationType) => ({
                          key: applicationType.id,
                          text: applicationType.description,
                          value: applicationType.id,
                      }))
                    : [];
                let dropDownProps = {
                    id: "applicationTypeIds",
                    disabled: !applicationTypes.length || props.disabled,
                    placeholder: !applicationTypes.length
                        ? "No application types defined"
                        : "Select application type",
                    defaultValue: props.applicationTypeId,
                    options: applicationTypes,
                    fluid: true,
                    selection: true,
                };

                return (
                    <>
                        <label>
                            Application Type<span style={{ color: "red" }}>&nbsp;*</span>
                        </label>
                        <Dropdown
                            {...dropDownProps}
                            onChange={(e, newData) => {
                                props.onChange(newData.value as string);
                            }}
                        />
                    </>
                );
            }}
        </ApplicationTypesQuery>
    );
};
