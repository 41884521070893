import { cloneDeep, forIn, isObject } from "lodash";

// Omit nested `__typename`s to get around this issue - https://github.com/apollographql/apollo-client/issues/1913
export const omitDeepTypenames = (obj: any) => {
    const _obj = cloneDeep(obj);
    recursivelyMutate(_obj);
    return _obj;
};

// Inspired by https://stackoverflow.com/a/39087474
const recursivelyMutate = (obj: any) => {
    forIn(obj, function (value: any, key: string) {
        if (isObject(value)) {
            recursivelyMutate(value);
        } else if (key === "__typename") {
            delete obj[key];
        }
    });
};
