import { IPrinter } from "grabcad-printers-api";
import { useContext } from "react";
import styled, { ColorLabel, IBackgroundColor } from "grabcad-ui-elements";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { MappedPrinterStatus } from "../../utils/printer_utils";

const StyledStatus = styled(ColorLabel)<IBackgroundColor>`
    display: flex;
    font-size: 14px;
`;

const StatusCircle = styled.span<{ color: string }>`
    background-color: ${({ color }) => color || "white"};
    height: 12px;
    width: 12px;
    margin-left: 10px;
    border-radius: 50%;
    display: inline-block;
`;

export enum PrinterStatusLabelStyle {
    Label = "Label",
    Circle = "Circle",
}

interface PrinterStatusLabelProps {
    printer: IPrinter;
    style?: PrinterStatusLabelStyle;
}

export const PrinterStatusLabel = ({
    printer,
    style = PrinterStatusLabelStyle.Label,
}: PrinterStatusLabelProps) => {
    const { t } = useContext(ApplicationContext);

    const mappedStatus = new MappedPrinterStatus(printer);

    if (style === PrinterStatusLabelStyle.Circle) {
        return <StatusCircle color={mappedStatus.statusColor} />;
    }

    const translatedPrinterStatus = t(`printer_display_status.${mappedStatus.statusText}`);

    return <StyledStatus color={mappedStatus.statusColor}>{translatedPrinterStatus}</StyledStatus>;
};
