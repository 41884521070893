/*
 * This code was copied from Package: https://github.com/lukewarlow/user-agent-data-types since pulling in a package for
 * for 1 file and overriding the tscompile settings seemed over the top in this case and caused too many problems.
 * Reason:
 * this code is needed is that the native ts types don't support userAgentData for the global.navigator object while
 * browsers have implemented this some time ago. Maybe some update for ts will get this fixed.
 */

interface ExtendedNavigator {
    readonly userAgentData?: NavigatorUAData;
}

interface NavigatorUABrandVersion {
    readonly brand: string;
    readonly version: string;
}

interface UADataValues {
    readonly brands?: NavigatorUABrandVersion[];
    readonly mobile?: boolean;
    readonly platform?: string;
    readonly architecture?: string;
    readonly bitness?: string;
    readonly model?: string;
    readonly platformVersion?: string;
    readonly uaFullVersion?: string;
    readonly fullVersionList?: NavigatorUABrandVersion[];
    readonly wow64?: boolean;
}

interface UALowEntropyJSON {
    readonly brands: NavigatorUABrandVersion[];
    readonly mobile: boolean;
    readonly platform: string;
}

interface NavigatorUAData extends UALowEntropyJSON {
    getHighEntropyValues(hints: string[]): Promise<UADataValues>;
    toJSON(): UALowEntropyJSON;
}

export const Navigator = navigator as ExtendedNavigator;
