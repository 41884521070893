import { useState } from "react";
import { Link, match, Redirect } from "react-router-dom";
import { IScreenProps } from "../../screens/Screen";
import styled, { Button } from "grabcad-ui-elements";
import { PaginationLinks } from "../../view/Navigation/PaginationLinks";
import { ProductData } from "../../graphql";
import { alphabetizeByProp } from "../../utils/sort";
import { PATHS } from "../../Routes";
import {
    LIST_PRODUCTS_WITH_COMPANIES_AND_PACKAGES,
    ListProductsQueryResult,
} from "../../graphql/Queries/Products/ListProducts";
import { useQueryWithCatch } from "../../utils/hooks/useQueryWithCatch";

const PRODUCTS_PER_PAGE = 20;

interface IProductListProps extends IScreenProps {
    match: match<{ id?: string }>;
}

const HighlightRow = styled.tr`
    cursor: pointer;
    &:hover {
        background-color: #deeeff;
    }
`;

const LicensePackageList = styled.span`
    span:not(:last-child)::after {
        content: ", ";
    }
`;

export const ProductList: React.FC<IProductListProps> = (props) => {
    const [searchString, setSearchString] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const { loading, error, data } = useQueryWithCatch<ListProductsQueryResult>(
        LIST_PRODUCTS_WITH_COMPANIES_AND_PACKAGES
    );

    if (error) {
        return <Redirect to="/" />;
    }
    if (loading || !data) {
        return <div className="ui loader active" />;
    }
    let products = [...data.listProducts.products];
    let productCount = products.length;
    let filteredProductCount = products.length;
    if (searchString) {
        products = products.filter((productData: ProductData) => {
            let search = (searchString as string).toLowerCase();
            return productData.name.toLowerCase().includes(search);
        });
        filteredProductCount = products.length;
    }

    products.sort(alphabetizeByProp("key"));
    products = products.slice((page - 1) * PRODUCTS_PER_PAGE, page * PRODUCTS_PER_PAGE);

    return (
        <div>
            <Button
                id="qa-productList-newProduct"
                className="ui button primary right floated"
                type="submit"
                onClick={() => props.history?.push("product/new")}
            >
                New Product
            </Button>
            <div className="ui left icon input">
                <i className="search icon" />
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchString}
                    onChange={(event) => {
                        setSearchString(event.currentTarget.value);
                        setPage(1);
                    }}
                />
            </div>
            <h2 className="page-header">
                Products (
                {filteredProductCount !== productCount
                    ? `${filteredProductCount} of ${productCount}`
                    : productCount}
                )
            </h2>

            <table className="ui table">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Company Total</th>
                        <th>License Package Ids</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product: ProductData) => (
                        <HighlightRow
                            data-testid="product-item"
                            key={product.id}
                            onClick={() => props.history?.push(`${PATHS.product}/${product.id}`)}
                        >
                            <td data-label="Name">
                                {product.name}
                                {product.disabled ? <b> (disabled)</b> : ""}
                            </td>
                            <td data-testid="company-total">{product.companies?.length}</td>
                            <td data-testid="license-packages">
                                <LicensePackageList>
                                    {product.packages?.map((p, index) => (
                                        <span key={index} className="product">
                                            <Link
                                                to={`${PATHS.licensePackage}/${p.id}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            >
                                                {p.id}
                                            </Link>
                                        </span>
                                    ))}
                                </LicensePackageList>
                            </td>
                        </HighlightRow>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={3}>
                            <PaginationLinks
                                total={filteredProductCount}
                                perPage={PRODUCTS_PER_PAGE}
                                currentPage={page}
                                goToPage={(p) => setPage(p)}
                            />
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};
