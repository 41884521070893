import { Breadcrumbs } from "frontend/src/view/Navigation/BreadCrumbs";
import { Redirect } from "react-router-dom";
import { useContext } from "react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { Placeholder, PlaceholderWrapper } from "../../components/placeholder";
import { InfoBox } from "../../components/Styles";
import { GetCompanyStigsQuery } from "../../graphql/Queries/Stig";
import { Notifier } from "../../view/Notifier";
import { Image } from "grabcad-ui-elements";
import PlaceholderSvg from "../../images/placeholder_licenses.svg";
import ProtectAMIcon from "../../images/protect_am_icon.svg";
import { SdkStigCard } from "../SDK/SdkStigCard";

export function ProtectAMUpdates() {
    const { t } = useContext(ApplicationContext);

    const breadcrumb = t("protect_am_updates.breadcrumb");
    const info = t("protect_am_updates.info");
    return (
        <>
            <Breadcrumbs sections={[{ label: breadcrumb }]} />
            <GetCompanyStigsQuery>
                {({ loading, error, data, refetch }) => {
                    if (error) {
                        Notifier.error(error);
                        return <Redirect to="/" />;
                    }
                    if (loading || !data) {
                        return <div className="ui loader active" />;
                    }

                    const title = <h2 className="page-header">{t("protect_am_updates.header")}</h2>;
                    const stigs = data.companyStigs.stigs.sort((a, b) => {
                        if (!a.version || !b.version) {
                            return 0;
                        }

                        if (a.version < b.version) {
                            return 1;
                        }
                        if (a.version > b.version) {
                            return -1;
                        }

                        return 0;
                    });
                    if (stigs.length === 0) {
                        return (
                            <PlaceholderWrapper>
                                {title}
                                <Placeholder id="qa-stig-placeholder">
                                    <Image src={PlaceholderSvg} />
                                    <h2>{t("protect_am_updates.no_protect_am")}</h2>
                                </Placeholder>
                            </PlaceholderWrapper>
                        );
                    }
                    const overviewCards = stigs.map((stig, index) => {
                        const expired: boolean = index !== 0;
                        let description = `${t("protect_am_updates.sha56")}: ${
                            stig.sha256 ?? t("protect_am_updates.no_hash")
                        }`;
                        if (!expired) {
                            description += `\n${t("protect_am_updates.description")}`;
                        }

                        return (
                            <SdkStigCard
                                key={`stig-${stig.id}`}
                                id={stig.id}
                                name={stig.name}
                                description={description}
                                url={stig.url}
                                version={stig.version}
                                lastModified={stig.lastModified}
                                imageSrc={ProtectAMIcon}
                                releaseNotesLink={stig.releaseNotesLink}
                                expired={index !== 0}
                                showVersionAndDetails
                            />
                        );
                    });

                    return (
                        <>
                            <h1>{breadcrumb}</h1>
                            <InfoBox>{info}</InfoBox>
                            {overviewCards}
                        </>
                    );
                }}
            </GetCompanyStigsQuery>
        </>
    );
}
