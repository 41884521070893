import styled, { Button, Image, Beacon } from "grabcad-ui-elements";
import { useContext } from "react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import PlaceholderSvg from "../../images/placeholder_printers.svg";
import { useMutation } from "@apollo/client";
import { gql } from "graphql-tag";
import { UserContext } from "../../components/User/UserProvider";
import { useCompanyAsAdminQuery } from "../../graphql";

const Container = styled.div`
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1 1 auto;
`;

const TextContainer = styled.div`
    margin: 32px 0;
    text-align: center;
    p {
        margin-bottom: 0;
    }
    h1 {
        margin-bottom: 32;
    }
`;

export const TRACK_PRINT_SERVER_DOWNLOADS = gql`
    mutation trackPrintServerDownload {
        trackPrintServerDownload
    }
`;

const downloadUrl =
    "https://ssys-eagle-cloud-agent.s3.amazonaws.com/installer/release/GrabCADPrintServerInstallerPerMachine.exe";

export const NO_AGENTS_MESSAGE = "No agents are connected to selected account.";
export const CLOUD_UNAVAILABLE_MESSAGE = "Unable to connect to your printers.";

export const NoPrinter = ({
    errorOccurred,
    errorMessage,
    isPopUp,
}: {
    errorOccurred?: boolean;
    errorMessage?: string;
    isPopUp?: boolean;
}) => {
    const { t } = useContext(ApplicationContext);
    const { role, name, email } = useContext(UserContext);
    const { company } = useCompanyAsAdminQuery();

    const [trackPrintServerDownloads] = useMutation(TRACK_PRINT_SERVER_DOWNLOADS);

    let copy: JSX.Element | null = null;

    let errorMsgLocal = errorMessage;
    if (errorMessage === NO_AGENTS_MESSAGE) {
        errorMsgLocal = t("printer_groups.no_printer.no_agents_message");
    } else if (errorMessage === CLOUD_UNAVAILABLE_MESSAGE) {
        errorMsgLocal = t("printer_groups.no_printer.cloud_unavailable_message");
    }

    if (errorMessage === NO_AGENTS_MESSAGE) {
        copy = (
            <>
                <div className="ui orange message">
                    {t("printer_groups.no_printer.print_server_required")}
                    <br />
                    <a
                        href={downloadUrl}
                        onClick={() => {
                            void trackPrintServerDownloads();
                        }}
                    >
                        {`${t("printer_groups.no_printer.download_link")} `}
                    </a>
                    {t("printer_groups.no_printer.download_latest")} <b>{company?.name}</b>
                    {"."}
                </div>
                <p>{t("printer_groups.no_printer.visit_help_page")}</p>
            </>
        );
    }

    if (!errorOccurred) {
        copy = (
            <>
                <div className="ui orange message" style={{ textAlign: "center" }}>
                    {t("printers.empty_list.p1")}
                    <br />
                    {t("printers.empty_list.p2")}
                </div>
                <p>{t("printer_groups.no_printer.visit_help_page")}</p>
            </>
        );
    }

    return (
        <Container>
            <Image src={PlaceholderSvg} className="printers-placeholder" />
            <TextContainer>
                <h1>
                    {errorOccurred && errorMessage !== NO_AGENTS_MESSAGE
                        ? t("printers.api_error_title")
                        : t("printer_groups.no_printer.title")}
                </h1>
                {errorMessage && errorMessage !== NO_AGENTS_MESSAGE && (
                    <div className="ui red message">
                        {errorMsgLocal}
                        {errorMessage === CLOUD_UNAVAILABLE_MESSAGE && (
                            <>
                                {` ${t("printer_groups.no_printer.contact_verb")} `}
                                <Beacon
                                    userName={name}
                                    userEmail={email}
                                    companyName={company?.name}
                                    shopName={""}
                                    currentPage={"Printers"}
                                    userRole={role}
                                    buttonName={"GrabCAD Support"}
                                />
                            </>
                        )}
                    </div>
                )}
                {!isPopUp && copy}
            </TextContainer>
            {!isPopUp && (
                <Button
                    type="button"
                    onClick={() => {
                        window.open("https://help.grabcad.com/", "_blank");
                    }}
                    className="ui button primary"
                >
                    {t("printer_groups.no_printer.help_button")}
                </Button>
            )}
        </Container>
    );
};
