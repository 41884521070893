import styled, { Beacon, Navbar } from "grabcad-ui-elements";
import { useContext } from "react";
import { GCApplication } from "grabcad-ui-elements/dist/ui/General/ApplicationLogo";
import { ApplicationContext } from "../components/ApplicationProvider";
import openGcpAsync from "../view/Navigation/openGcpAsync";
import { UserContext } from "../components/User/UserProvider";
import { PlatformConstants } from "grabcad-ui-elements/dist/Theme";
import { useHistory } from "react-router-dom";
import isSdkPage from "../utils/isSdkPage";
import { config } from "../config";
import { fullAnalyzeUrl } from "../Routes";
import { Notifier } from "../view/Notifier";
import { useCompanyAsAdminQuery } from "../graphql";
import { useCompanyLicensesQuery } from "../graphql/Queries/Licenses/GetCompanyLicenses";

export const NAVBAR_HEIGHT_PX = `${PlatformConstants.NAVBAR_HEIGHT_PX}px`;

export const TopNavbarWrapper = ({
    logout,
    updatePassword,
    openShop,
}: ITopNavbarWrapperProps): JSX.Element => {
    const { t } = useContext(ApplicationContext);
    const { name, email, role } = useContext(UserContext);
    const history = useHistory();
    const { company } = useCompanyAsAdminQuery();
    const {
        enabledFeatures: { sdkAccess, analyzeAccess },
        allEnabledFeatureKeys,
    } = useCompanyLicensesQuery();

    const beaconProps = {
        userName: name,
        userEmail: email,
        companyName: company?.name,
        shopName: "",
        userRole: role,
        text: {
            contactSupport: t("feedback.contact_support"),
        },
    };

    const isSdk = isSdkPage();

    let data = {
        userDropdown: {
            pointing: true,
            fullName: name,
            options: [
                {
                    key: 1,
                    text: t("top_navbar_update_password"),
                    value: 1,
                    active: false,
                    selected: false,
                    onClick: updatePassword,
                    icon: "lock",
                },
                {
                    key: 2,
                    text: t("top_navbar_user_settings"),
                    value: 2,
                    active: false,
                    selected: false,
                    onClick: () => (window.location.href = config.REACT_APP_USER_SETTINGS_URL!),
                    icon: "user circle outline",
                },
                {
                    key: 3,
                    text: t("top_navbar_logout"),
                    value: 3,
                    active: false,
                    selected: false,
                    onClick: logout,
                    icon: "sign out alternate",
                },
            ],
        },
        mainDropdown: {
            pointing: true,
            text: t("top_navbar_contact_us"),
            options: [
                {
                    key: 1,
                    value: 1,
                    selected: false,
                    active: false,
                    children: <Beacon {...beaconProps} />,
                },
                {
                    key: 2,
                    text: t("top_navbar_request_demo"),
                    value: 2,
                    selected: false,
                    active: false,
                    onClick: () =>
                        window.location.assign(
                            "https://calendly.com/d/yk5-vqs-grj/grabcad-control-shop-demo"
                        ),
                    icon: "video",
                },
            ],
        },
        helpDropdown: {
            pointing: true,
            options: [
                {
                    key: 1,
                    value: 1,
                    text: t("feedback.help_link"),
                    selected: false,
                    active: false,
                    icon: "question",
                    onClick: () =>
                        window.location.assign(
                            "https://help.grabcad.com/category/261-about-grabcad-shop"
                        ),
                },
                {
                    key: 2,
                    value: 2,
                    selected: false,
                    active: false,
                    children: (
                        <>
                            <i aria-hidden="true" className="list icon"></i>
                            <a
                                href={`${process.env.PUBLIC_URL}/acknowledgements.txt`}
                                download={"acknowledgements"}
                            >
                                {t("feedback.acknowledgements")}
                            </a>
                        </>
                    ),
                },
            ],
        },
        applications: [
            {
                application: "GC_CONTROL" as GCApplication,
                active: !isSdk,
                name: "Control",
                onClick: () => {
                    if (isSdk) {
                        history.push("/");
                    }
                },
            },
            {
                application: "GC_SHOP" as GCApplication,
                name: "Shop",
                onClick: openShop,
            },
            {
                application: "GC_PRINT" as GCApplication,
                name: "Print",
                onClick: async () => openGcpAsync(t),
            },
            {
                application: "GC_DEVELOP" as GCApplication,
                name: "Develop",
                active: isSdk,
                onClick: () => {
                    if (!isSdk) {
                        if (sdkAccess) {
                            history.push("/sdk");
                            return;
                        }
                        window.open("https://grabcad.com/software-development-kit", "_blank");
                    }
                },
            },
        ],
        featureKeys: allEnabledFeatureKeys,
    };

    if (analyzeAccess) {
        data.applications.push({
            application: "GC_ANALYZE" as GCApplication,
            name: "Analyze",
            onClick: company?.analyzeServerAddress
                ? () => (window.location.href = fullAnalyzeUrl(company.analyzeServerAddress!))
                : () => Notifier.warn(t("launch_analyze.warn_no_url")),
        });
    }

    return (
        <StyledTopNavbarWrapper>
            <Navbar {...data} />
        </StyledTopNavbarWrapper>
    );
};

interface ITopNavbarWrapperProps {
    logout: () => void;
    updatePassword: () => void;
    openShop: () => void;
}

const StyledTopNavbarWrapper = styled.div`
    flex: 0 0 auto;
`;
