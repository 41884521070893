import { useMutation } from "@apollo/client";
import { UPDATE_PRINTER_GROUP } from "../../../../../shared/src/graphql/Mutations/PrinterGroups";
import { PRINTER_GROUPS } from "../../../../../shared/src/graphql/Queries/PrinterGroups";
import { USER_GROUP_PRINTER_GROUP_ACCESSES } from "../../../../../shared/src/graphql/Queries/UserGroupPrinterGroupAccesses";
import {
    PrinterGroup,
    UserGroup,
    UserGroupPrinterGroupAccessOutput,
} from "../../../../../shared/src/types";
import { useQueryWithCatch } from "../../../utils/hooks/useQueryWithCatch";
import { useUserGroupsQuery } from "../UserGroups";
import { ApolloError } from "@apollo/client";

/**
 * Hook to use with printer access on printer modal
 *
 * @param {string} id Printer ID
 */
export const usePrinterAccess = (id: string): UsePrinterAccessReturn => {
    const { data: pgData, loading } = useQueryWithCatch<{ printerGroups: PrinterGroup[] }>(
        PRINTER_GROUPS
    );
    const printerGroups = pgData?.printerGroups.filter((g) => g.printerIds.indexOf(id) >= 0) ?? [];
    const printerGroupIds = new Set(printerGroups?.map((g) => g.id));

    const { data: accessesData } = useQueryWithCatch<{
        userGroupPrinterGroupAccesses: UserGroupPrinterGroupAccessOutput[];
    }>(USER_GROUP_PRINTER_GROUP_ACCESSES);

    const userGroupIdsWithPrinterGroupAccess = new Set(
        accessesData?.userGroupPrinterGroupAccesses.map((ugpga) => {
            if (printerGroupIds.has(ugpga.printerGroup.id)) {
                return ugpga.userGroup.id;
            }
        })
    );

    const { userGroups } = useUserGroupsQuery();
    const filteredUserGroups = userGroups.filter((ug) =>
        userGroupIdsWithPrinterGroupAccess.has(ug.id)
    );

    const [updatePrinterGroup] = useMutation(UPDATE_PRINTER_GROUP);

    const updatePrinterGroupAccess = async ({
        addToGroups,
        removeFromGroups,
        printerId,
        onError,
        onUpdate,
    }: PrinterGroupModificationInput): Promise<void> => {
        // This is orphaning promises! - perhaps this is intentional?
        // an await Promise.all with map instead of forEach would prevent this
        // eslint-disable-next-line @grabcad-golden-tools/golden-rules/no-async-for-each-callback
        addToGroups.forEach(async (groupId) => {
            await updatePrinterGroup({
                variables: {
                    id: groupId,
                    printerIdsToAdd: printerId,
                },
                onError,
                update: onUpdate,
            });
        });
        // This is orphaning promises! - perhaps this is intentional?
        // an await Promise.all with map instead of forEach would prevent this
        // eslint-disable-next-line @grabcad-golden-tools/golden-rules/no-async-for-each-callback
        removeFromGroups.forEach(async (groupId) => {
            await updatePrinterGroup({
                variables: {
                    id: groupId,
                    printerIdsToRemove: printerId,
                },
                onError,
                update: onUpdate,
            });
        });
    };

    return [
        {
            PrinterGroups: printerGroups,
            UserGroups: filteredUserGroups,
        },
        updatePrinterGroupAccess,
        loading,
    ];
};

type UserGroupForPrinter = Pick<UserGroup, "id" | "name" | "users" | "roles">[];

export interface PrinterAccess {
    PrinterGroups: PrinterGroup[];
    UserGroups: UserGroupForPrinter;
}

type UsePrinterAccessReturn = [
    printerAccess: PrinterAccess,
    updatePrinterGroupAccess: (data: PrinterGroupModificationInput) => Promise<void>,
    loading: boolean
];

interface PrinterGroupModificationInput {
    addToGroups: number[];
    removeFromGroups: number[];
    printerId: string;
    onError: (error: ApolloError) => void;
    onUpdate: () => void;
}
