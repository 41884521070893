import { useContext, FunctionComponent } from "react";
import styled, { Image, Button, Label } from "grabcad-ui-elements";
import { ApplicationContext, TranslationFunction } from "../../components/ApplicationProvider";
import Parser from "html-react-parser";

const ProductSdksOverview = styled.div<{
    disabled: boolean;
    showBorder: boolean;
    showBoxShadow: boolean;
}>`
    background: #fdfdfd;
    border: ${(props) => (props.showBorder ? "0.5px solid #c4c4c4" : "none")};
    box-shadow: ${(props) => (props.showBoxShadow ? "0px 0px 5px rgba(0, 0, 0, 0.1)" : "none")};
    border-radius: 4px;
    padding: 20px;
    display: flex;
    margin: 10px 0;
    height: 172px;
    position: relative;

    h2 {
        margin-top: -4px;
    }

    .imgHolder {
        background: #fdfdfd;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        width: 130px;
        height: 130px;
        min-width: 130px;
        min-height: 130px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-right: 20px;
    }

    .cardDetails {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 130px;
    }

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-line;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .cardDownloadContainer,
    a {
        display: flex;
        align-items: center;
        align-self: flex-begin;
    }

    .ribbon.label {
        position: absolute;
        left: calc(100% + 1.2em);
    }
`;

interface IDownloadCard {
    id: number;
    name?: string;
    description: string | JSX.Element | JSX.Element[];
    url: string;
    version?: string;
    lastModified?: string;
    imageSrc: string;
    expired?: boolean;
    hash?: string;
    releaseNotesLink?: string;
    showBorder?: boolean;
    showBoxShadow?: boolean;
    //Temporarily added to hide version and other details as analyze installer details can't be fetched from S3 for now.
    showVersionAndDetails?: boolean;
}

const versionElement = (version: string, t: TranslationFunction) => {
    const versionkey = "sdks_overview.version";
    return <>{t(versionkey, { version })} - </>;
};

export const SdkStigCard: FunctionComponent<IDownloadCard> = (props) => {
    const { t } = useContext(ApplicationContext);

    const renderDownloadContainer = (
        url: string,
        inputVersion: string | undefined,
        inputLastModified: string | undefined,
        releaseNotesLink?: string
    ) => {
        const version = inputVersion || "[unknown]";
        const lastModified = inputLastModified || "[unknown]";
        if (url) {
            return (
                <div className="cardDownloadContainer">
                    {/* The URL will eventually be generated at download time */}
                    <a href={url}>
                        <Button
                            id="qa-download"
                            type="submit"
                            className="primary"
                            style={{ marginRight: "10px" }}
                        >
                            {t("sdks_overview.download")}
                        </Button>
                    </a>
                    <p>
                        {props.showVersionAndDetails && versionElement(version, t)}
                        {props.showVersionAndDetails && (
                            <i>
                                {t("sdks_overview.last_updated", {
                                    lastModified,
                                })}
                            </i>
                        )}
                        {releaseNotesLink && (
                            <a style={{ display: "inline" }} href={releaseNotesLink}>
                                {" "}
                                - {t("protect_am_updates.release_notes")}
                            </a>
                        )}
                    </p>
                </div>
            );
        }
        return (
            <p style={{ display: "flex" }}>
                <b>{t("sdks_overview.download_unavailable")}</b>&nbsp;-{" "}
                {Parser(t("error.get_help", { email: "developers@grabcad.com" }))}
            </p>
        );
    };

    return (
        <ProductSdksOverview
            id="qa-downloadCard"
            key={`${props.name}-${props.id}`}
            disabled={props.expired ? true : false}
            style={{ cursor: props.expired ? "not-allowed" : "auto" }}
            showBorder={!!props.showBorder}
            showBoxShadow={!!props.showBoxShadow}
        >
            {props.expired && (
                <Label ribbon="right" color="red">
                    {t("license.states.expired")}
                </Label>
            )}
            <div className="imgHolder">
                <Image src={props.imageSrc} height="80" />
            </div>
            <div className="cardDetails">
                {props.name && <h2>{props.name}</h2>}
                <p>{props.description}</p>
                {renderDownloadContainer(
                    props.url,
                    props.version,
                    props.lastModified,
                    props.releaseNotesLink
                )}
            </div>
        </ProductSdksOverview>
    );
};
