import { FunctionComponent, useState, useContext } from "react";
import { match, Link, Redirect } from "react-router-dom";
import _ from "lodash";
import moment, { now } from "moment-timezone";
import { Mutation } from "@apollo/client/react/components";
import { IScreenProps } from "../../screens/Screen";
import {
    Button,
    Form,
    FormField,
    Label,
    Icon,
    Checkbox,
    StyledJoyride,
    Tab,
    Loader,
} from "grabcad-ui-elements";
import { PaginationLinks } from "../../view/Navigation/PaginationLinks";
import { parseEmails } from "../../utils/email";
import { UserContext } from "../../components/User/UserProvider";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { PATHS } from "../../Routes";
import { UserGroupMultiSelect } from "../../view/UserGroupMultiSelect";
import {
    User,
    Role,
    ADD_USERS,
    LIST_USERS,
    LIST_USER_GROUPS,
    useUsersQuery,
    useUserGroupsQuery,
    useCompanyAsAdminQuery,
} from "../../graphql";
import { Notifier } from "../../view/Notifier";
import { RemoveUserFromCompanyModal, UpdateAdminForCompanyModal } from "../User";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { validate } from "isemail";
import { InfoBox } from "../../components/Styles";
import { CompanyAccessOptionsQuery } from "../../graphql/Queries/CompanyAccessOptions";
import { CreateAccessOptionMutation } from "../../graphql/Mutations/CompanyAccessOptions";
import { CompanyAccessOptionsTable } from "../../components/CompanyAccessOptionsTable";
import { useOnboarding } from "../../utils/hooks/onboarding";
import { useCompanyLicensesQuery } from "../../graphql/Queries/Licenses/GetCompanyLicenses";

const USERS_PER_PAGE = 20;

interface ICompanyUsersProps extends IScreenProps {
    match: match<{ id?: string }>;
}

export const ASSIGN_USER_GROUPS = "assign-user-groups";

export const CompanyUsers: FunctionComponent<ICompanyUsersProps> = (props) => {
    const userContext = useContext(UserContext);
    const { t } = useContext(ApplicationContext);
    const [page, setPage] = useState(1);
    const [userToRemove, setUserToRemove] = useState<User>();
    const [searchString, setSearchString] = useState("");
    const [emailsString, setEmailsString] = useState("");
    const [emailsInputString, setEmailsInputString] = useState("");
    const [userGroupIds, setUserGroupIds] = useState<number[]>([]);
    const [dupeEmails, setDupeEmails] = useState<string[]>([]);
    const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
    const [sendEmails, setSendEmails] = useState<boolean>(true);
    const [domainsInputString, setDomainsInputString] = useState("");
    const onboarding = useOnboarding();
    const { company } = useCompanyAsAdminQuery();
    const { loadingUsers, users } = useUsersQuery();
    const { userGroups } = useUserGroupsQuery();
    const { companyLicenses, loadingLicenses } = useCompanyLicensesQuery();
    const hasCompanyWideLicenses =
        companyLicenses?.licenses.some((l) => l.package.companyWide) || false;

    const getUserGroupsForUser = (user: User) =>
        userGroups.filter((group) => group.users.find((u) => u.id === user.id));

    const breadCrumbs = <Breadcrumbs sections={[{ label: t("users.breadcrumb") }]} />;
    if (!company || loadingUsers || loadingLicenses) {
        return (
            <>
                {breadCrumbs}
                <Loader active />
            </>
        );
    }

    let usersDisplay = [...users];
    if (searchString) {
        let search = searchString.toLowerCase();
        usersDisplay = usersDisplay.filter(
            (user) =>
                user.email.toLowerCase().includes(search) ||
                user.name.toLowerCase().includes(search) ||
                getUserGroupsForUser(user).some((group) =>
                    group.name.toLowerCase().includes(search)
                )
        );
    }
    const paginatedUsersDisplay = usersDisplay.slice(
        (page - 1) * USERS_PER_PAGE,
        page * USERS_PER_PAGE
    );

    const timezone = company.settings?.timezone;
    const timezoneAbbrev = timezone && moment.tz(now(), timezone).zoneAbbr();
    const outerContainerStyle = {
        background: "white",
        border: "1px solid #d6d6d6",
        borderRadius: 2,
        display: "flex",
        flexWrap: "wrap" as const,
        padding: "0",
    };
    const innerContainerStyle = {
        display: "inline-flex",
        flexWrap: "wrap" as const,
        margin: 0,
        padding: 0,
        width: "100%",
        overflowY: "scroll" as const,
        height: "70px",
    };
    const labelStyle = {
        height: "25px",
        display: "inline-flex",
        flexWrap: "wrap" as const,
        margin: "5px 5px",
        backgroundColor: "hsl(198,71.4%,53.3%)",
        color: "white",
        padding: "7px 15px",
    };
    const negativeLabelStyle = {
        height: "25px",
        display: "inline-flex",
        flexWrap: "wrap" as const,
        margin: "5px 5px",
        backgroundColor: "#f43",
        color: "white",
        padding: "7px 15px",
    };
    const inputstyle = {
        display: "inline-flex",
        outline: "none",
        border: "none",
        width: "100%",
        borderRadius: "3px",
    };

    const inputKeyDown = (event: any): any => {
        const val = event.target.value;
        if (
            (event.key === " " || event.key === "Spacebar" || event.type === "blur") &&
            val.length > 1
        ) {
            setEmailsString(`${emailsString} ${emailsInputString}`);
            setEmailsInputString("");
        } else if (event.key === "Backspace" && !val) {
            removeEmailTag(emailsInputString.length - 1);
        } else if (
            event.key === "Enter" &&
            parseEmails(emailsString).filter((email) => validate(email))
        ) {
            event.preventDefault();
            setEmailsString(`${emailsString} ${emailsInputString}`);
            setEmailsInputString("");
        }
    };

    const removeEmailTag = (i: any) => {
        const newTags = parseEmails(emailsString);
        newTags.splice(i, 1);
        setEmailsString(newTags.join(","));
    };

    const shouldEnableSubmit = () => {
        // if the user has input a valid email address, we should
        // not require them to press space before proceeding. All pressing
        // space does is make a tag in the UI for the email address.
        const hasAddedValidEmails =
            parseEmails(emailsString).filter((email) => validate(email)).length > 0;
        const isCurrentInputSingleEmail = validate(emailsInputString);
        const isCurrentInputMultipleEmails =
            parseEmails(emailsInputString).filter((email) => validate(email)).length > 0;
        return hasAddedValidEmails || isCurrentInputSingleEmail || isCurrentInputMultipleEmails;
    };

    const shouldEnableRequestApproval = () => {
        return domainsInputString && domainsInputString.length > 0;
    };

    const shouldEnableSendEmailCheckbox = () => {
        return (
            (shouldEnableSubmit() && userGroupIds.length > 0) ||
            (shouldEnableSubmit() && hasCompanyWideLicenses)
        );
    };

    const getInputUserEmails = () => {
        if (!emailsString) {
            return parseEmails(emailsInputString);
        }
        // add last "untag-ified" email to input email if valid
        let lastDanglingValidInput = validate(emailsInputString) ? emailsInputString : "";
        let addedEmails = parseEmails(emailsString);
        if (lastDanglingValidInput) {
            addedEmails.push(lastDanglingValidInput);
        }
        return addedEmails;
    };

    const updateEmailInput = (input: string) => {
        if (input.length - emailsInputString.length > 1) {
            // Assume the user pasted, check for emails after sanitizing input
            // If any exist, only retain valid emails
            // If none exist, fallback to default behavior
            const sanitizedInput = input.replace(/[<>;,]/g, "");
            const emails = parseEmails(sanitizedInput).filter((email) => validate(email));
            if (emails.length > 0) {
                setEmailsString(`${emailsString} ${emails}`);
                setEmailsInputString("");
                return;
            }
        }
        setEmailsInputString(input);
    };

    const userGroupsOnboarding = !onboarding.isCompleted(ASSIGN_USER_GROUPS) && (
        <StyledJoyride
            steps={[
                {
                    target: ".user-groups-multiselect",
                    title: t("onboarding.assign_users.title"),
                    content: t("onboarding.assign_users.content"),
                    placement: "bottom",
                },
            ]}
            dismissCopy={t("onboarding.dismiss")}
            callback={({ lifecycle }) => {
                if (lifecycle === "complete") {
                    void onboarding.setCompleted(ASSIGN_USER_GROUPS);
                }
            }}
        />
    );

    const getAddUsersTabs = () => (
        <>
            <InfoBox className="qa-inviteuserslabel">{t("users.info")}</InfoBox>
            <Tab
                menu={{ secondary: true, pointing: true }}
                panes={[
                    {
                        menuItem: t("users.users_header.add_by_email"),
                        render: () => <>{getAddUsersForm()}</>,
                    },
                    {
                        menuItem: t("users.users_header.add_by_domain"),
                        render: () => <>{getAddByDomainForm()}</>,
                    },
                ]}
            />
        </>
    );

    const getAddUsersForm = () => (
        <Mutation<{ createUsers: { users: User[]; existingUsers: User[] } }>
            mutation={ADD_USERS}
            refetchQueries={[{ query: LIST_USERS }, { query: LIST_USER_GROUPS }]}
            onCompleted={({ createUsers }) => {
                if (createUsers.users.length) {
                    Notifier.success(t("users.successfully_added"));
                } else if (createUsers.existingUsers.length) {
                    Notifier.warn(t("users.no_new_added"));
                }
                if (createUsers.existingUsers.length) {
                    setDupeEmails(createUsers.existingUsers.map((user) => user.email));
                } else {
                    setDupeEmails([]);
                }
            }}
            onError={(error) => Notifier.error(error)}
        >
            {(update) => (
                <Form
                    onSubmit={() => {
                        if (!emailsString && !emailsInputString) {
                            return;
                        }
                        const parsedEmails: string[] = getInputUserEmails();
                        let emails: string[] = parsedEmails.filter((email) => validate(email));
                        let invalidEmailsFromString: string[] = parsedEmails.filter(
                            (email) => !emails.includes(email)
                        );
                        if (invalidEmailsFromString.length > 0) {
                            setEmailsString(invalidEmailsFromString.join(", "));
                            setInvalidEmails(invalidEmailsFromString);
                        } else {
                            setInvalidEmails([]);
                            setEmailsString("");
                            setEmailsInputString("");
                        }

                        // TODO validate emails & get user names & member_ids from grabcad.com
                        let newUsers = emails.map((email) => ({ email }));
                        void update({
                            variables: {
                                users: newUsers,
                                company_id: company?.id,
                                group_ids: userGroupIds,
                                sendEmails: sendEmails,
                                adminName: userContext.name,
                            },
                        });
                    }}
                >
                    <FormField>
                        <div style={outerContainerStyle}>
                            <div style={innerContainerStyle}>
                                {parseEmails(emailsString).map((tag, i) =>
                                    tag && validate(tag) ? (
                                        <Label key={tag} style={labelStyle}>
                                            {tag}
                                            <Icon
                                                name="delete"
                                                onClick={() => {
                                                    removeEmailTag(i);
                                                }}
                                            />
                                        </Label>
                                    ) : (
                                        <Label key={tag} style={negativeLabelStyle}>
                                            {tag}
                                            <Icon
                                                name="delete"
                                                onClick={() => {
                                                    removeEmailTag(i);
                                                }}
                                            />
                                        </Label>
                                    )
                                )}
                                <div style={{ background: "none", flexGrow: 1, padding: 0 }}>
                                    <input
                                        className="qa-companyUsers-addUsersField"
                                        placeholder={
                                            emailsString.length > 1
                                                ? ""
                                                : t("forms.emails_placeholder")
                                        }
                                        onKeyDown={inputKeyDown}
                                        onBlur={inputKeyDown}
                                        style={inputstyle}
                                        value={emailsInputString}
                                        onChange={(event) =>
                                            updateEmailInput(event.currentTarget.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </FormField>
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <FormField style={{ flexGrow: 1 }} className="user-groups-multiselect">
                            <UserGroupMultiSelect ids={userGroupIds} onChange={setUserGroupIds} />
                        </FormField>
                        <FormField key="submit" style={{ margin: "0 0 1em 1em" }}>
                            <Button
                                type="submit"
                                className="primary qa-companyUsers-addUsersSubmit"
                                disabled={!shouldEnableSubmit()}
                            >
                                {t("users.add_users")}
                            </Button>
                        </FormField>
                        {userGroupsOnboarding}
                    </div>
                    <FormField>
                        <Checkbox
                            id="qa-companyUsers-sendEmailCheckbox"
                            onChange={() => {
                                setSendEmails(!sendEmails);
                            }}
                            disabled={!shouldEnableSendEmailCheckbox()}
                            label={t("users.notify_users")}
                            checked={sendEmails && shouldEnableSendEmailCheckbox()}
                        />
                    </FormField>
                    {dupeEmails.length > 0 && (
                        <p className="ui negative message qa-duplicateEmailsWarning">
                            {`${
                                dupeEmails.length === 1
                                    ? t("users.duplicate_email.single")
                                    : t("users.duplicate_email.multiple")
                            }${dupeEmails.join(", ")}`}
                        </p>
                    )}
                    {invalidEmails.length > 0 && (
                        <p className="ui negative message">
                            {`${
                                invalidEmails.length > 1
                                    ? t("users.invalid_email.single")
                                    : t("users.invalid_email.multiple")
                            } ${invalidEmails.join(", ")}`}
                        </p>
                    )}
                </Form>
            )}
        </Mutation>
    );

    const getAddByDomainForm = () => (
        <CreateAccessOptionMutation
            update={(cache, { data }) => {
                if (data) {
                    Notifier.success(t("users.successfully_added_domain"));
                }
            }}
            refetchQueries={[{ query: LIST_USERS }]}
            onError={(error) => Notifier.error(error)}
        >
            {(update, { loading, data, error }) => (
                <Form
                    onSubmit={() => {
                        if (!domainsInputString || loading) {
                            return;
                        }
                        const domains = _.uniq(
                            `${domainsInputString.toLowerCase()}`.split(/[,\s]+/)
                        ).filter((s) => !!s);
                        setDomainsInputString("");
                        const urlData = domains.map((domain) => {
                            return { url: domain, isUrlGenerated: false };
                        });
                        update({
                            variables: {
                                urls: urlData,
                                companyId: company.id,
                                groupIdsToAdd: userGroupIds,
                            },
                        }).catch(Notifier.error);
                    }}
                >
                    <FormField>
                        <div style={outerContainerStyle}>
                            <div style={innerContainerStyle}>
                                <div style={{ background: "none", flexGrow: 1, padding: 0 }}>
                                    <input
                                        id="qa-companyUsers-addDomainsField"
                                        placeholder={
                                            domainsInputString.length > 1
                                                ? ""
                                                : t("forms.domains_placeholder")
                                        }
                                        onKeyDown={inputKeyDown}
                                        onBlur={inputKeyDown}
                                        style={inputstyle}
                                        value={domainsInputString}
                                        onChange={(event) =>
                                            setDomainsInputString(event.currentTarget.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </FormField>
                    <FormField style={{ flexGrow: 1 }} className="user-groups-multiselect">
                        <UserGroupMultiSelect ids={userGroupIds} onChange={setUserGroupIds} />
                    </FormField>
                    <InfoBox>{t("users.domain_security")}</InfoBox>
                    <FormField key="submit" style={{ margin: "0 0 1em 1em" }}>
                        <Button
                            id="qa-companyUsers-requestDomainApprovalSubmit"
                            type="submit"
                            className="primary"
                            disabled={!shouldEnableRequestApproval()}
                        >
                            {t("users.invitation_settings.request_domain_approval")}
                        </Button>
                    </FormField>
                    {userGroupsOnboarding}
                </Form>
            )}
        </CreateAccessOptionMutation>
    );

    const getJoinDate = (user: User) => {
        if (!timezone) {
            return null;
        }

        const localTime = user.joinDate;
        let formattedDate = "";

        if (localTime) {
            formattedDate = moment(localTime).tz(timezone).format("YYYY-MM-DD hh:mm A");
        }

        return <td data-label="Join Date">{formattedDate}</td>;
    };

    const joinDateHeader = () => {
        if (timezone) {
            return <th>{`${t("users.join_date")} (${timezoneAbbrev})`}</th>;
        }
    };

    const getUsersTable = () => (
        <>
            <div className="ui left icon input">
                <i className="search icon" />
                <input
                    type="text"
                    placeholder={t("users.search")}
                    value={searchString}
                    className="qa-users-search"
                    onChange={(event) => {
                        setSearchString(event.currentTarget.value);
                        setPage(1);
                    }}
                />
            </div>
            <table className="ui table qa-usersTable">
                <thead>
                    <tr>
                        <th>{t("users.email")}</th>
                        <th>{t("users.groups")}</th>
                        {joinDateHeader()}
                        <th>{t("users.actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedUsersDisplay.map((user: User) => (
                        <tr key={user.id}>
                            <td data-label="Email">
                                <Link
                                    id={`qa-companyUsers-userTable-email_${user.id}`}
                                    className="qa-companyUsers-userTable-email"
                                    to={`${PATHS.user}/${user.email}`}
                                >
                                    {user.email}
                                </Link>
                            </td>
                            <td data-label="Groups">
                                {getUserGroupsForUser(user).map((group, index) => (
                                    <span key={index}>
                                        {!!index && ", "}
                                        <Link
                                            id={`qa-companyUsers-userTable-group_${group.id}`}
                                            className="qa-companyUsers-userTable-group"
                                            to={`${PATHS.userGroup}/${group.id}`}
                                        >
                                            {group.name}
                                        </Link>
                                    </span>
                                ))}
                            </td>
                            {getJoinDate(user)}
                            <td data-label="Action">
                                <a
                                    id={`qa-companyUsers-userTable-removeEnabled_${user.id}`}
                                    className="qa-companyUsers-userTable-removeEnabled"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setUserToRemove(user)}
                                >
                                    {t("users.remove")}
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={4}>
                            <PaginationLinks
                                total={usersDisplay.length}
                                perPage={USERS_PER_PAGE}
                                currentPage={page}
                                goToPage={(p) => setPage(p)}
                            />
                        </th>
                    </tr>
                </tfoot>
            </table>
        </>
    );
    const getUsersHeader = () => {
        if (usersDisplay.length !== users.length) {
            return t("users.users_header.filtered", {
                filteredUserCount: usersDisplay.length,
                userCount: users.length,
            });
        }

        return t("users.users_header.unfiltered", {
            userCount: users.length,
        });
    };

    const getInvitationSettingsTable = () => (
        <CompanyAccessOptionsQuery>
            {({ loading, error, data, refetch }) => {
                if (error) {
                    Notifier.error(error);
                    return <Redirect to="/" />;
                }
                if (loading || !data) {
                    return <div className="ui loader active" />;
                }
                const companyAccessOptions = data.companyAccessOptions.accessOptions || [];
                return (
                    <>
                        <InfoBox className="qa-invitationSettingsLabel">
                            {t("users.invitation_settings.infobox_content")}
                        </InfoBox>
                        <CompanyAccessOptionsTable
                            accessOptions={companyAccessOptions}
                            role={Role.CompanyAdmin}
                            refetch={refetch}
                            companyId={company.id}
                            {...{
                                history: props.history,
                                location: props.location,
                                match: props.match,
                            }}
                        />
                    </>
                );
            }}
        </CompanyAccessOptionsQuery>
    );

    return (
        <div style={{ paddingBottom: 25 }}>
            {breadCrumbs}
            <h2 className="page-header">{t("users.breadcrumb")}</h2>

            <Tab
                menu={{ secondary: true, pointing: true, className: "qa-usersTabs" }}
                panes={[
                    {
                        menuItem: getUsersHeader(),
                        render: () => getUsersTable(),
                    },
                    {
                        menuItem: t("users.users_header.invite"),
                        render: () => getAddUsersTabs(),
                    },
                    {
                        menuItem: t("users.users_header.invitation_settings"),
                        render: () => getInvitationSettingsTable(),
                    },
                ]}
            />

            {userToRemove &&
                (userContext.email === userToRemove.email ? (
                    <UpdateAdminForCompanyModal
                        companyId={company.id}
                        user={userToRemove}
                        onClose={() => setUserToRemove(undefined)}
                    />
                ) : (
                    <RemoveUserFromCompanyModal
                        user={userToRemove}
                        onClose={() => setUserToRemove(undefined)}
                    />
                ))}
        </div>
    );
};
