import { ApolloError, ServerError } from "@apollo/client";
import { ToastOptions } from "react-toastify";
import { Notifier as StyledNotifier } from "grabcad-ui-elements";
import { formattedMessageElement } from "../i18n";

export interface IErrorObject extends ApolloError {
    errorKey: string;
}

export class Notifier {
    public static notify = (message: string | JSX.Element, options: ToastOptions = {}) =>
        StyledNotifier.notify(message, options);

    public static success = (message: string | JSX.Element, options: ToastOptions = {}) =>
        StyledNotifier.success(message, options);

    public static fail = (message: string | JSX.Element, options: ToastOptions = {}) =>
        StyledNotifier.error(message, options);

    public static error = (
        { errorKey, networkError, graphQLErrors, message }: Partial<IErrorObject>,
        options: ToastOptions = {}
    ) => {
        if (networkError) {
            // `as ServerError` needed because networkError is non-discriminated union type
            const result = (networkError as ServerError).result;
            const errors = result?.errors || [];
            if (errors.length) {
                errors.forEach(
                    (error: Error) => error.message && StyledNotifier.error(error.message)
                );
            } else {
                StyledNotifier.error(
                    formattedMessageElement(
                        "server-errors.networkError",
                        "Network error: failed to reach the server."
                    )
                );
            }
            return null;
        }
        if (graphQLErrors) {
            graphQLErrors.forEach((err) => {
                if (err.extensions) {
                    StyledNotifier.error(
                        formattedMessageElement(`server-errors.${err.extensions.code}`, err.message)
                    );
                }
            });
            return null;
        }
        if (errorKey) {
            StyledNotifier.error(errorKey, options);
            return null;
        }
        if (message) {
            StyledNotifier.error(message, options);
            return null;
        }
    };

    public static warn = (message: string | JSX.Element, options: ToastOptions = {}) =>
        StyledNotifier.warn(message, options);

    public static info = (message: string | JSX.Element, options: ToastOptions = {}) =>
        StyledNotifier.info(message, options);
}
