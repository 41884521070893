import { useContext, useState } from "react";
import { Link, match as routerMatch, Redirect } from "react-router-dom";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { IScreenProps } from "../Screen";
import { PATHS } from "../../Routes";
import { Notifier } from "../../view/Notifier";
import {
    GetProductLicensesQueryResult,
    GetProductLicensesQueryVariables,
    GET_PRODUCT_WITH_LICENSES,
} from "../../graphql/Queries/Licenses/GetProductLicensesForCompany";
import {
    LicenseAssignment,
    GroupData,
    User,
    LicenseData,
    MachineEntitlementData,
    MaterialEntitlementData,
    UPDATE_USER_GROUP,
} from "../../graphql";
import { HighlightRow, InfoBox } from "../../components/Styles";
import { RemoveUserFromGroupsConfirmationModal } from "../../view/Modals/RemoveUserFromGroupsConfirmation";
import { CompanyLicenseTable } from "../../components/license/LicenseTable";
import styled, { Button } from "grabcad-ui-elements";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { MaterialEntitlementList } from "../Material/MaterialEntitlementList";
import { UserGroupMultiSelect } from "../../view/UserGroupMultiSelect";
import { alphabetizeByProp, removeDuplicatesFromSortedArray } from "../../utils/sort";
import { EditMachineEntitlementList } from "../License/MachineEntitlementList";
import { useMutation } from "@apollo/client";
import { useQueryWithCatch } from "../../utils/hooks/useQueryWithCatch";
import { GET_COMPANY_LICENSES } from "../../graphql/Queries/Licenses/GetCompanyLicenses";
import { UserContext } from "../../components/User/UserProvider";

const paragraphStyle = { marginBottom: 20, fontSize: "1em", maxWidth: 500 };
const API_APPLICATION_TYPE = "api";

export interface ProductLicenseDetailsProps extends IScreenProps {
    match: routerMatch<{ id?: string }>;
}

const UserRow = styled(HighlightRow)`
    a {
        opacity: 0;
    }
    &:hover {
        cursor: default;
        a {
            opacity: 1;
        }
    }
`;

const InfoBoxWrapper = styled(InfoBox)`
    margin: 0px !important;
`;

export const ProductLicenseDetails = ({ history, location, match }: ProductLicenseDetailsProps) => {
    const { t } = useContext(ApplicationContext);
    const { companyId } = useContext(UserContext);
    const { data, loading, error, refetch } = useQueryWithCatch<
        GetProductLicensesQueryResult,
        GetProductLicensesQueryVariables
    >(GET_PRODUCT_WITH_LICENSES, {
        variables: { id: Number(match.params.id) },
        // caching is too complicated because the result of the query depends on user groups and all sorts of things
        // however "no-cache" prevents the user group multi select updating when used, hence "network-only"
        fetchPolicy: "network-only",
    });
    const [userToRemoveFromGroups, setUserToRemoveFromGroups] = useState<string | null>(null);
    const [updateUserGroup] = useMutation(UPDATE_USER_GROUP, {
        refetchQueries: [
            { query: GET_PRODUCT_WITH_LICENSES, variables: { id: Number(match.params.id) } },
        ],
    });
    if (!companyId) {
        return null;
    }
    if (error) {
        Notifier.error(error);
        return <Redirect to="/" />;
    }
    if (loading || !data) {
        return <div className="ui loader active" />;
    }

    const renderUserGroupRoles = () => {
        if (!groups?.length) {
            return (
                <>
                    <p style={paragraphStyle}>{t("license_details.no_roles")}</p>
                    <p>
                        <Link
                            id={`qa-license-noGroups`}
                            className="qa-license-noGroups"
                            to={PATHS.userGroups}
                        >
                            {t("license_details.manage_user_groups")}
                        </Link>
                    </p>
                </>
            );
        }
        const filteredGroups = groups.filter(
            (group) =>
                group.roles.length > 0 &&
                group.roles.some((role) => role.application.dateDeleted === null)
        );
        return (
            <table className="ui table" id="userGroupRoles">
                <thead>
                    <tr>
                        <th>{t("license_details.group_name")}</th>
                        <th>{t("license_details.group_roles")}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredGroups.map((group) => {
                        return (
                            group.roles.length > 0 && (
                                <HighlightRow
                                    key={group.id}
                                    onClick={() => history?.push(`${PATHS.userGroup}/${group.id}`)}
                                >
                                    <td data-label="Name">{group.name}</td>
                                    <td data-label="Roles">
                                        {group.roles.map(
                                            (role) =>
                                                role.application.dateDeleted === null && (
                                                    <span
                                                        className="ui label"
                                                        key={`${role.application.name}:${role.applicationRole.name}`}
                                                    >{`${role.application.name}: ${role.applicationRole.name}`}</span>
                                                )
                                        )}
                                    </td>
                                </HighlightRow>
                            )
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const renderGrantedUserDetails = () => {
        return (
            <table className="ui table" id="qa-grantedUserDetails">
                <thead>
                    <tr>
                        <th>{t("users.email")}</th>
                        <th>{t("users.name")}</th>
                        <th>{t("license.order_id")}</th>
                        <th>{t("users.actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {licenseAssignments.map((licenseAssignment: LicenseAssignment) => {
                        const user = licenseAssignment.user;
                        return (
                            <UserRow key={user.email}>
                                <td data-label="Email">{user.email}</td>
                                <td data-label="Name">{user.name}</td>
                                <td data-label="OrderId">{licenseAssignment.orderId}</td>
                                <td data-label="Actions">
                                    <a onClick={() => setUserToRemoveFromGroups(user.email)}>
                                        {t("license_details.remove_license")}
                                    </a>
                                </td>
                            </UserRow>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const renderRestrictedUserDetails = (users: User[]) => {
        return (
            <table className="ui table" id="restrictedUserDetails">
                <thead>
                    <tr>
                        <th>{t("users.email")}</th>
                        <th colSpan={3}>{t("users.name")}</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user: User) => {
                        return (
                            <tr key={user.email}>
                                <td data-label="Email">{user.email}</td>
                                <td data-label="Name">{user.name}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const renderButtons = () => {
        const negativeButton = (
            <div style={{ marginTop: "15px" }}>
                <Button
                    id="qa-productLicenses-back"
                    type="button"
                    secondary
                    onClick={() => history.push("/licenses")}
                >
                    {t("navigation.back")}
                </Button>
            </div>
        );
        return negativeButton;
    };

    const product = data.product;
    const licenses = product.licenses;
    const totalSeatsUsed = licenses
        .map((aLicense) => aLicense.seatsUsed)
        .reduce((prev, next) => prev + next);
    const groups: GroupData[] = product.groups;
    const licenseAssignments: LicenseAssignment[] = product.licenseAssignments;
    const restrictedUsers: User[] = product.restrictedUsers;
    const productName = product ? `${product.name}` : "";
    const isLicenseAssignedViaRoles = product.features.some(
        (feature) => feature.isAssignedViaRoles
    );

    const hasCompanyWideLicense = licenses.some((license) => license.package.companyWide);
    const hasUnlimitedMaxUsersLicense = licenses.some(
        (license) => license.package.unlimitedMaxUsers
    );

    const renderUserDetails = () => {
        if (!product.applicationType || product.applicationType?.id !== API_APPLICATION_TYPE) {
            return (
                <div>
                    {hasCompanyWideLicense ? null : <h3>{t("license_details.user_groups")}</h3>}

                    {!isLicenseAssignedViaRoles && !hasCompanyWideLicense && (
                        <UserGroupMultiSelect
                            ids={groups.map((group) => group.id)}
                            onChange={async (ids) => {
                                const existingGroupIds = groups.map((group) => group.id);
                                const groupIdsToAdd = ids.filter(
                                    (id) => !existingGroupIds.includes(id)
                                );
                                const groupIdToAdd = !!groupIdsToAdd.length && groupIdsToAdd[0];
                                const groupIdsToRemove = existingGroupIds.filter(
                                    (id) => !ids.includes(id)
                                );
                                const groupIdToRemove =
                                    !!groupIdsToRemove.length && groupIdsToRemove[0];

                                let licenseIds = licenses.map((license) => license.id);
                                await updateUserGroup({
                                    variables: {
                                        id: groupIdToAdd || groupIdToRemove,
                                        licenseIdsToAdd: groupIdToAdd ? licenseIds : undefined,
                                        licenseIdsToRemove: groupIdToRemove
                                            ? licenseIds
                                            : undefined,
                                    },
                                    refetchQueries: [{ query: GET_COMPANY_LICENSES }],
                                    onCompleted: () => {
                                        Notifier.success(
                                            groupIdToAdd
                                                ? t("license_details.added_group")
                                                : t("license_details.removed_group")
                                        );
                                    },
                                });
                            }}
                        />
                    )}
                    {isLicenseAssignedViaRoles && renderUserGroupRoles()}

                    {!hasCompanyWideLicense && (
                        <>
                            <h3>
                                {t("license_details.who_can_use_product", {
                                    totalSeatsUsed: totalSeatsUsed || 0,
                                })}
                            </h3>
                            {renderGrantedUserDetails()}
                            {!hasUnlimitedMaxUsersLicense && (
                                <>
                                    <h3>
                                        {t("license_details.who_cannot_use_product", {
                                            restrictedUsersCount: restrictedUsers.length || 0,
                                        })}
                                    </h3>
                                    {restrictedUsers.length > 0 && (
                                        <p className="ui negative message qa-licenseError">
                                            {t("license_details.warn_cannot_use")}
                                        </p>
                                    )}
                                    {renderRestrictedUserDetails(restrictedUsers)}
                                </>
                            )}
                        </>
                    )}
                </div>
            );
        }
    };

    const renderMachineDetails = () => {
        // FIXME GC-70984 - refactor to functional component
        if (!!product.features.find((f) => f?.isPrinterType)) {
            const originalMachines: MachineEntitlementData[] = [];
            licenses.forEach((license) => {
                if (license.machineEntitlements) {
                    originalMachines.push(...license.machineEntitlements);
                }
            });
            // FIXME GC-70984 Dedupe like we do in server generateLicenseFile()
            originalMachines.sort(alphabetizeByProp("serial"));
            removeDuplicatesFromSortedArray("serial", originalMachines);
            // Sort and remove duplicates by model name
            originalMachines.sort(alphabetizeByProp("model"));
            removeDuplicatesFromSortedArray("model", originalMachines);
            // Copy machines without reference for later dirty comparison
            const machines = originalMachines.map((machine) => ({
                ...machine,
            }));

            // We need to get the number of licensed printers to display. We display "unlimited" if
            // machines contains a machine where the model, serial, and technology are null
            const printerCount = machines.some(
                (machine) => !machine.model && !machine.serial && !machine.technology
            )
                ? t("machines.unlimited")
                : machines.length;

            return (
                <EditMachineEntitlementList
                    title={t("machines.machines_using", { printerCount })}
                    machines={machines}
                    // @ts-ignore  TODO: FIX THIS! Types are different for onChange here!
                    onChange={null}
                />
            );
        }
    };

    return (
        <>
            {userToRemoveFromGroups && (
                <RemoveUserFromGroupsConfirmationModal
                    open={true}
                    email={userToRemoveFromGroups}
                    groupIdsToRemove={groups
                        .filter((x) =>
                            x.users.some((user) => user.email === userToRemoveFromGroups)
                        )
                        .map((group) => group.id)}
                    groupNamesToRemove={groups
                        .filter((x) =>
                            x.users.some((user) => user.email === userToRemoveFromGroups)
                        )
                        .map((group) => group.name)}
                    onUpdate={() => {
                        Notifier.success(t("license_details.removed_license"));
                        refetch().catch(Notifier.error);
                    }}
                    onClose={() => setUserToRemoveFromGroups(null)}
                    {...{ history, location, match }}
                />
            )}
            <Breadcrumbs
                sections={[
                    {
                        label: t("license_details.breadcrumb"),
                        to: PATHS.licenses,
                    },
                    { label: `${productName}` },
                ]}
            />
            <h2 className="page-header">{productName}</h2>
            {!product.applicationType ||
                (product.applicationType.id !== API_APPLICATION_TYPE && !hasCompanyWideLicense && (
                    <InfoBox>
                        {isLicenseAssignedViaRoles
                            ? t("license_details.roles_info")
                            : t("license_details.info")}
                    </InfoBox>
                ))}
            <h3>{t("license_details.details")}</h3>
            {hasCompanyWideLicense && (
                <InfoBoxWrapper>
                    {t("license_details.company_wide").replace("{product_name}", product.name)}
                </InfoBoxWrapper>
            )}
            <CompanyLicenseTable
                key={`table${productName}`}
                licenses={licenses}
                companyId={companyId}
                {...{ history, location, match }}
            />
            {renderUserDetails()}
            {renderMachineDetails()}
            {!!product.features.find((f) => f?.isMaterialType) && (
                <MaterialEntitlementList
                    readOnly={true}
                    materialEntitlements={getDedupeMaterials(licenses)}
                    title={t("materials.entitlement")}
                />
            )}
            {renderButtons()}
        </>
    );
};

function getDedupeMaterials(licenses: LicenseData[]): MaterialEntitlementData[] {
    let materialEntitlements: MaterialEntitlementData[] = licenses
        // LicenseData[] -> (MaterialEntitlement | undefined)[]
        .flatMap((l) => l.materialEntitlements)
        // (MaterialEntitlement | undefined)[] -> MaterialEntitlement[]
        // Drop undefined
        .filter((m) => !!m) as MaterialEntitlementData[];
    // Dedupe by material (vendor, name) tuple.
    // Same product may be reached via different licenses.
    // Each license can contribute some material, but there can be dupes between them.
    // NB: Must match in server generateLicenseFile()
    materialEntitlements = Array.from(
        new Map(
            materialEntitlements.map((material) => [
                `${material.name}_${material.technology}`,
                material,
            ])
        ).values()
    );
    return materialEntitlements;
}
