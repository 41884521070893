import styled, { Button, Form } from "grabcad-ui-elements";
import { useCallback, useContext, useEffect, useState, ChangeEvent, FormEvent } from "react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { Notifier } from "../../view/Notifier";
import GatewayModal from "./GatewayModal";
import GatewaysList from "./GatewaysList";
import { Container } from "semantic-ui-react";
import { IGateway, PrinterDetails } from "../../../../shared/src/types";
import { getGatewayList, getPrinterList } from "../../zeus-client/gatewaysApis";
import { useCompanyAsAdminQuery } from "../../graphql";

const Card = styled.div`
    width: 20%;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 10px;
    border: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    h4 {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .labelContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.4rem 0.3rem;
    }
`;

const StyledGatewaysListUI = styled.div`
    padding: 0px;
    & > div {
        &.centered {
            text-align: center;
        }

        &.flex {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 10px;

            label {
                h3 {
                    margin-top: 5px;
                }
            }
        }
    }
`;

const spinnerUI = <div className="ui active centered inline loader" />;

const StyledForm = styled(Form)`
    max-width: 550px;
    padding-bottom: 25px;
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
    gap: 12px;

    label {
        color: #4183c4;
    }
`;

const StyledButton = styled(Button)`
    background-color: #700ac1 !important;
    border-color: #700ac1 !important;
    color: white !important;
    &:hover {
        background-color: #700ac1 !important;
    }
    &:focus {
        background-color: #700ac1 !important;
    }
    .MuiCircularProgress-root {
        color: #700ac1 !important;
    }
    margin-left: 10px;
`;

const FormContainer = styled.div`
    margin: 0 0 1em 0;
`;

export const PrinterSerial = () => {
    const { t } = useContext(ApplicationContext);
    const { company } = useCompanyAsAdminQuery();

    const [printerSerial, setPrinterSerial] = useState<string>("");
    const [findPrinter, setfindPrinter] = useState<string>("");
    const [newThingName, setcoreDeviceThingName] = useState<string>("");

    const [isShowSpinner, setSpinnerState] = useState<boolean>(false); // Loading gateway list by button
    const [isShowLoader, setLoaderState] = useState<boolean>(false); // Loading gateway list
    const [openwith, setOpenWith] = useState<boolean>(false); // Check if opened with outer modal inner modal list
    const [createGatewayModalOpen, setCreateGatewayModalOpen] = useState<boolean>(false); // Check if the create gateway modal is open
    const [checkIfPollingTrue, setCheckIfPollingTrue] = useState<boolean>(false); // Close modal and recall API gateway status
    const [displayAddGatewayButton, setDisplayAddGatewayButton] = useState<boolean>(false); // Show add button or not

    const [displayPrinterSerial, setDisplayPrinterSerial] = useState<any>(); // Display printer serial

    const [gatewaysList, setGatewaysList] = useState<IGateway[]>(); // Gateways list
    const [gatewaysDefaultList, setDefaultGatewaysList] = useState<IGateway[]>(); // Default gateways list
    const [printerList, setPrinterList] = useState<PrinterDetails[]>([]); // Printer list
    const [printerGateway, setPrinterGateway] = useState<String>("none"); // Printer gateway

    const fetchGatewaysList = useCallback(
        (gatewaysData: IGateway[]) => {
            setGatewaysList(gatewaysData);
            setDisplayAddGatewayButton(false);
            setDisplayPrinterSerial("");

            if (!gatewaysData.length) {
                Notifier.success(t("gateways.no_gateways_available_text"));
                return setDisplayAddGatewayButton(true);
            }

            if (gatewaysData) {
                setDisplayPrinterSerial(printerSerial);
                setGatewaysList(gatewaysData);
                Notifier.success(t("gateways.gateways_success"));
                setPrinterGateway(gatewaysData[0].coreDeviceThingName);
                const indexData = gatewaysData?.find((item) =>
                    item.associatedClientDevices.some((e) => e.thingName === printerSerial)
                );
                if (indexData) {
                    setPrinterGateway(indexData?.site);
                }
            }
        },
        [printerSerial, t]
    );

    const onPrinteridchange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setPrinterSerial(event.currentTarget.value ? event.currentTarget.value.trim() : "");
    };

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const findSerial = printerList?.find((e) => e?.thingName === printerSerial);
        if (!!findSerial) {
            setfindPrinter(findSerial.thingName);
        } else {
            setfindPrinter("");
        }
        setSpinnerState(true);
        getGatewayList(printerSerial)
            .then((gatewayListData: any) => {
                setSpinnerState(false);
                fetchGatewaysList(gatewayListData);
            })
            .catch(() => {
                setSpinnerState(false);
                setGatewaysList([]);
                setDisplayAddGatewayButton(false);
                setDisplayPrinterSerial("");
                Notifier.fail(t("gateways.printer_serial_not_found"));
            });
    };

    const fetchGatewayList = useCallback(() => {
        setLoaderState(true);
        getGatewayList()
            .then((gatewayListData) => {
                setLoaderState(false);
                if (!!gatewayListData) {
                    setDefaultGatewaysList(gatewayListData);
                    if (openwith) {
                        fetchGatewaysList(gatewayListData);
                    }
                }
            })
            .catch(() => {
                setLoaderState(false);
                setDisplayAddGatewayButton(false);
                setDisplayPrinterSerial("");
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openwith]);

    const fetchPrinterList = useCallback(() => {
        setLoaderState(true);
        getPrinterList()
            .then((printerListData) => {
                setLoaderState(false);
                if (!!printerListData) {
                    const filterPrinterList = printerListData.filter(
                        (x: PrinterDetails) => !x.hasOwnProperty("coreDeviceThingName")
                    );
                    setPrinterList(filterPrinterList);
                }
            })
            .catch(() => {
                setLoaderState(false);
            });
    }, []);

    useEffect(() => {
        fetchGatewayList();
        fetchPrinterList();
        if (checkIfPollingTrue) {
            fetchGatewayList();
            fetchPrinterList();
        }
    }, [checkIfPollingTrue, fetchGatewayList, fetchPrinterList]);

    function openGatewayModal(isSearch: boolean) {
        setOpenWith(isSearch);
        setCreateGatewayModalOpen(!createGatewayModalOpen);
        setCheckIfPollingTrue(false);
    }

    const shouldEnableConnectPrinter = () => {
        return printerSerial ? (printerSerial.length ? true : false) : false;
    };

    const filterAndSetList = (
        list: IGateway[] | undefined,
        filterFunction: (e: IGateway) => boolean,
        setFunction: (list: IGateway[]) => void
    ) => {
        if (!list || list.length === 0) {
            return;
        }

        const filteredList = list.filter(filterFunction);
        setFunction(filteredList);
    };

    const filterGatewaysPrinterList = (
        thingName: string,
        printerType: string | undefined,
        isPrinter: boolean
    ) => {
        if (!thingName) {
            return;
        }

        if (isPrinter) {
            [gatewaysDefaultList, gatewaysList].forEach((list) => {
                if (Array.isArray(list)) {
                    list.forEach((e) => {
                        e.associatedClientDevices = e.associatedClientDevices.filter(
                            (x: { thingName: string }) => x.thingName !== thingName
                        );
                    });
                }
            });
            if (gatewaysDefaultList) {
                setDefaultGatewaysList([...gatewaysDefaultList]);
            }
            if (gatewaysList) {
                setGatewaysList([...gatewaysList]);
            }

            const foundPrinter = printerList.find((e) => e?.thingName === thingName);
            if (!foundPrinter) {
                const newPrinterList = [...printerList, { thingName, printerType }];
                setPrinterList(newPrinterList);
            }
        } else {
            [gatewaysDefaultList, gatewaysList].forEach((list, index) => {
                filterAndSetList(
                    list,
                    (e) => e?.coreDeviceThingName !== thingName,
                    [setDefaultGatewaysList, setGatewaysList][index]
                );
            });
        }
    };

    const gatewayModal = (
        <>
            <GatewayModal
                open={createGatewayModalOpen}
                onClose={() => {
                    setCreateGatewayModalOpen(false);
                }}
                onSuccessfullRequest={() => {
                    setCreateGatewayModalOpen(false);
                    Notifier.success(t("gateways.job_status_fetching_message"));
                }}
                setCheckIfPollingTrue={(property: boolean) => setCheckIfPollingTrue(property)}
                setNewThingName={(thingName: string) => setcoreDeviceThingName(thingName)}
            ></GatewayModal>
        </>
    );
    const displayAddGatewaybtn = displayAddGatewayButton ? (
        <>
            <Container style={{ padding: "10px" }} display="!shouldEnableConnectPrinter()">
                <label style={{ color: "#4183c4", fontWeight: 600 }}>
                    <h3>
                        {t("gateways.printer_label")} {displayPrinterSerial}
                    </h3>
                </label>
                <Card>
                    <h4>{displayPrinterSerial}</h4>
                    <div className="labelContainer">
                        <label>
                            {t("gateways.owned_by")}: {company?.name}
                        </label>
                        <label>
                            {t("gateways.gatway_label")}: {printerGateway}
                        </label>
                    </div>
                </Card>
                <Button
                    type="button"
                    onClick={() => openGatewayModal(true)}
                    className="right floated"
                    style={{ backgroundColor: "#700ac1", marginTop: "10px" }}
                >
                    {t("gateway_modal.add_gateway")}
                </Button>
            </Container>
            {gatewayModal}
        </>
    ) : (
        <></>
    );
    const getwaysListUI = !isShowLoader ? (
        <StyledGatewaysListUI>
            {(gatewaysDefaultList === undefined || gatewaysDefaultList.length < 1) &&
            printerList?.length < 1 ? (
                <div className="centered">
                    <span>{t("gateways.no_gateways_available_text")}</span>
                </div>
            ) : (
                <div className="flex">
                    <label>
                        <h3>
                            {company?.name} {t("gateways.gateways_title")}
                        </h3>
                    </label>
                    <GatewaysList
                        list={gatewaysDefaultList}
                        newThingName={newThingName}
                        printerList={printerList}
                        removeThingByName={(
                            thingName: string,
                            printerType: string | undefined,
                            isPrinter: boolean
                        ) => {
                            filterGatewaysPrinterList(thingName, printerType, isPrinter);
                        }}
                    ></GatewaysList>
                    <span>{t("gateways.add_more_gateways_text")}</span>
                    <span>{t("gateways.gateways_printer_compatibility_text")}</span>
                    <div>
                        <Button
                            type="button"
                            onClick={() => openGatewayModal(false)}
                            className="right floated"
                            style={{ backgroundColor: "#700ac1", marginTop: "10px" }}
                        >
                            {t("gateway_modal.add_gateway")}
                        </Button>
                    </div>
                </div>
            )}
            {gatewayModal}
        </StyledGatewaysListUI>
    ) : (
        spinnerUI
    );

    const content =
        !gatewaysList || gatewaysList.length === 0 ? (
            <>{displayAddGatewaybtn}</>
        ) : (
            <>
                <StyledGatewaysListUI>
                    <label style={{ color: "#4183c4", fontWeight: 600 }}>
                        <h3 data-testid="printer_serial_label">Printer {displayPrinterSerial}</h3>
                    </label>
                    <Card>
                        <h4 data-testid="printer_serial_number_label">{displayPrinterSerial}</h4>
                        <div className="labelContainer">
                            <label data-testid="printer_owned_by_label">
                                {t("gateways.owned_by")}:&nbsp;{company?.name}
                            </label>
                            <label data-testid="printer_associated_gatway_label">
                                {t("gateways.gateways_site_header")}: &nbsp;
                                {findPrinter ? t("gateways.gateways_no_site") : printerGateway}
                            </label>
                        </div>
                    </Card>
                    {gatewaysList === undefined ? (
                        <div style={{ textAlign: "center" }}>
                            <span>{t("gateways.no_gateways_available_text")}</span>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                gap: 10,
                            }}
                        >
                            <label>
                                <h3>
                                    {company?.name} {t("gateways.gateways_title")}
                                </h3>
                            </label>
                            <GatewaysList
                                list={gatewaysList}
                                newThingName={newThingName}
                                printerList={printerList}
                                removeThingByName={(
                                    thingName: string,
                                    printerType: string | undefined,
                                    isPrinter: boolean
                                ) => {
                                    filterGatewaysPrinterList(thingName, printerType, isPrinter);
                                }}
                            ></GatewaysList>
                            <span>{t("gateways.add_more_gateways_text")}</span>
                            <span>{t("gateways.gateways_printer_compatibility_text")}</span>
                        </div>
                    )}
                    <Button
                        type="button"
                        onClick={() => openGatewayModal(true)}
                        className="right floated"
                        style={{ backgroundColor: "#700ac1", marginTop: "10px" }}
                    >
                        {t("gateway_modal.add_gateway")}
                    </Button>
                </StyledGatewaysListUI>
                {gatewayModal}
            </>
        );

    return (
        <FormContainer>
            {!!gatewaysList && gatewaysList?.length > 0 ? null : getwaysListUI}
            <StyledForm onSubmit={onSubmit}>
                <FormContainer>
                    <label htmlFor="input-printerId">{t("gateways.printer_serial_number")}</label>
                    <input
                        data-testid="find-gateways-searchbox"
                        id="input-printerId"
                        value={printerSerial}
                        onChange={onPrinteridchange}
                        className="qa-company-input"
                    />
                </FormContainer>
                <FormContainer>
                    <StyledButton
                        data-testid="find-gateways-button"
                        id="connect-printer-button"
                        className="right floated buttonBgColor"
                        type="submit"
                        loading={isShowSpinner}
                        disabled={!shouldEnableConnectPrinter()}
                    >
                        {t("gateways.find_gateways")}
                    </StyledButton>
                </FormContainer>
            </StyledForm>
            {isShowSpinner && !openwith ? null : content}
        </FormContainer>
    );
};
