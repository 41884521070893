import { FunctionComponent, useContext } from "react";
import styled, { Dropdown } from "grabcad-ui-elements";
import { ApplicationContext } from "../components/ApplicationProvider";
import { useUserGroupsQuery } from "../graphql";

export interface IUserGroupMultiSelectProps {
    companyId?: number;
    ids: number[];
    onChange: (ids: number[]) => void;
    labelFontSize?: number;
}

export const UserGroupMultiSelect: FunctionComponent<IUserGroupMultiSelectProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    const { loadingUserGroups, userGroups } = useUserGroupsQuery();

    if (loadingUserGroups) {
        return <div className="ui loader active" />;
    }

    return (
        <>
            <StyledLabel labelFontSize={props.labelFontSize}>
                {t("user_group_multiselect.assign")}
            </StyledLabel>
            <Dropdown
                id="qa-userGroupMultiSelect"
                className="qa-userGroupMultiSelect"
                options={userGroups.map((group) => ({
                    key: group.id,
                    text: group.name,
                    value: group.id,
                }))}
                value={props.ids}
                disabled={!userGroups.length}
                placeholder={
                    !userGroups.length
                        ? t("user_group_multiselect.no_groups")
                        : t("user_group_multiselect.multiple_selection")
                }
                onChange={(e, newData) => {
                    props.onChange(newData.value as number[]);
                }}
                fluid
                multiple
                selection
            />
        </>
    );
};

const StyledLabel = styled.label<{ labelFontSize?: number }>`
    font-size: ${({ labelFontSize }) => labelFontSize}px;
    font-weight: 700;
    margin-bottom: 15px;
`;
