// eslint-disable-next-line max-classes-per-file
import gql from "graphql-tag";
import React from "react";
import { Mutation } from "@apollo/client/react/components";
import { ApolloError, MutationFunction, MutationOptions, MutationResult } from "@apollo/client";
import { LIST_CLIENTS } from "../Queries/Clients";

export const CREATE_CLIENT = gql`
    mutation createClient($name: String!, $description: String!) {
        createClient(name: $name, description: $description) {
            id
        }
    }
`;
export const UPDATE_CLIENT = gql`
    mutation updateClient($id: Int!, $name: String, $description: String) {
        updateClient(id: $id, data: { name: $name, description: $description }) {
            id
            name
            description
        }
    }
`;
export const DELETE_CLIENT = gql`
    mutation deleteClient($id: Int!) {
        deleteClient(id: $id)
    }
`;

export interface CreateClientVariables {
    name?: string;
    description?: string;
}

export interface CreateClientResult {
    createClient: {
        id: number;
    };
}

export interface CreateClientProps
    extends Omit<MutationOptions<CreateClientResult, CreateClientVariables>, "mutation"> {
    children: (
        mutateFn: MutationFunction<CreateClientResult, CreateClientVariables>,
        result: MutationResult<CreateClientResult>
    ) => JSX.Element;
    onError?: (error: ApolloError) => void;
}

export class CreateClientMutation extends React.Component<CreateClientProps> {
    render() {
        const { children, ...props } = this.props;

        return (
            <Mutation<CreateClientResult, CreateClientVariables>
                mutation={CREATE_CLIENT}
                refetchQueries={[{ query: LIST_CLIENTS }]}
                {...props}
            >
                {children}
            </Mutation>
        );
    }
}

export interface UpdateClientVariables {
    id: number;
    name?: string;
    description?: string;
}

export interface UpdateClientResult {
    updateClient: {
        id: number;
        name?: string;
        description?: string;
    };
}

export interface UpdateClientProps
    extends Omit<MutationOptions<UpdateClientResult, UpdateClientVariables>, "mutation"> {
    children: (
        mutateFn: MutationFunction<UpdateClientResult, UpdateClientVariables>,
        result: MutationResult<UpdateClientResult>
    ) => JSX.Element;
    onError?: (error: ApolloError) => void;
}

export class UpdateClientMutation extends React.Component<UpdateClientProps> {
    render() {
        const { children, variables, ...props } = this.props;
        return (
            <Mutation<UpdateClientResult, UpdateClientVariables>
                mutation={UPDATE_CLIENT}
                refetchQueries={[{ query: LIST_CLIENTS }]}
                variables={variables}
                {...props}
            >
                {children}
            </Mutation>
        );
    }
}

export interface DeleteClientResult {
    deleteClient: boolean;
}

export interface DeleteClientVariables {
    id: number;
}

export interface DeleteClientProps
    extends Omit<MutationOptions<DeleteClientResult, DeleteClientVariables>, "mutation"> {
    children: (
        mutateFn: MutationFunction<DeleteClientResult, DeleteClientVariables>,
        result: MutationResult<DeleteClientResult>
    ) => JSX.Element;
    type: string;
    onError?: (error: ApolloError) => void;
}

export class DeleteClientMutation extends React.Component<DeleteClientProps> {
    render() {
        const { children, variables, ...props } = this.props;

        return (
            <Mutation<DeleteClientResult, DeleteClientVariables>
                mutation={DELETE_CLIENT}
                variables={variables}
                {...props}
            >
                {children}
            </Mutation>
        );
    }
}
