import { getPrinterByName } from "@grabcad/printer-information";
import { IPrinter } from "grabcad-printers-api/node";
import styled, { Button, Grid, GridColumn, GridRow, Input } from "grabcad-ui-elements";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { usePrinterAccess } from "../../graphql/Queries/Printers/UsePrinterAccess";
import DefaultPrinterImage from "../../images/default.png";
import { PrinterGroupMultiSelect } from "../../view/PrinterGroupMultiSelect";
import { StyledLabel } from "../../view/UtilityComponents";
import {
    StyledCloseButton,
    StyledModal,
    StyledModalContent as ModalContent,
    StyledModalHeader,
} from "./StyledModal";
import { Notifier } from "../../view/Notifier";
import { isEqual } from "lodash";
import { PrinterStatusLabel, PrinterStatusLabelStyle } from "./PrinterStatusLabel";
import { usePrintersApi } from "./UseGetPrintersApi";

const StyledModalContent = styled(ModalContent)`
    p {
        color: #999;
        font-weight: 600;
    }
`;

const StyledGridRow = styled(GridRow)`
    padding: 10px 20px;
`;

const PrinterImage = styled.img`
    width: 100%;
    height: 100%;
    border: 2px solid #999;
    border-radius: 10px;
`;

interface IPrinterDetailsModalProps {
    printer: IPrinter | null;
    isOpen: boolean;
    onClose: () => void;
    history: RouteComponentProps["history"];
    onUpdate: () => void;
}

export const PrinterDetailsModal: React.FC<IPrinterDetailsModalProps> = ({
    printer,
    isOpen,
    onClose,
    history,
    onUpdate,
}) => {
    if (!printer) {
        return <></>;
    }

    /* eslint-disable react-hooks/rules-of-hooks */
    const printerId = printer.getId() ?? "";
    const { t } = useContext(ApplicationContext);
    const [renamePrinter] = usePrintersApi();
    const [accessData, updatePrinterGroupAccess, loading] = usePrinterAccess(printerId);
    const [printerName, setPrinterName] = useState<string>(printer.getName());
    const printerGroupsInitial = accessData?.PrinterGroups.map((g) => g.id) || [];
    const [printerGroupIds, setPrinterGroupIds] = useState<number[]>(printerGroupsInitial);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [initialStateIsSet, setInitialStateIsSet] = useState<boolean>(false);
    const [addPrinterToGroupsIds, setAddPrinterToGroupsIds] = useState<number[]>([]);
    const [removePrinterFromGroupsIds, setRemovePrinterFromGroupsIds] = useState<number[]>([]);
    const [errorOccured, setErrorOccurred] = useState<boolean>(false);
    /* eslint-enable react-hooks/rules-of-hooks */

    const labelSize = 14;
    const labelSizeHeader = 16;

    const setPrinterGroups = async (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            if (isEqual(printerGroupsInitial, printerGroupIds)) {
                resolve();
            }

            await updatePrinterGroupAccess({
                addToGroups: addPrinterToGroupsIds,
                removeFromGroups: removePrinterFromGroupsIds,
                printerId,
                onError: (error) => {
                    setErrorOccurred(true);
                    Notifier.error(error);
                    return reject();
                },
                onUpdate: () => resolve(),
            });
        });
    };

    const updatePrinterGroups = async (ids: number[]) => {
        const oldGroupIdsSet = new Set(printerGroupIds);
        const newGroupIdsSet = new Set(ids);

        const addedId = ids.filter((id) => !oldGroupIdsSet.has(id));
        const removedId = printerGroupIds.filter(
            (id) => id !== undefined && !newGroupIdsSet.has(id)
        );

        if (addedId.length !== 0) {
            setAddPrinterToGroupsIds(() => [...addPrinterToGroupsIds, ...addedId]);

            const i = removePrinterFromGroupsIds.indexOf(addedId[0]);
            if (i !== -1) {
                const arr = [...removePrinterFromGroupsIds];
                arr.splice(i, 1);
                setRemovePrinterFromGroupsIds(arr);
            }
        }

        if (removedId.length !== 0) {
            setRemovePrinterFromGroupsIds(() => [...removePrinterFromGroupsIds, ...removedId]);

            const i = addPrinterToGroupsIds.indexOf(removedId[0]);
            if (i !== -1) {
                const arr = [...addPrinterToGroupsIds];
                arr.splice(i, 1);
                setAddPrinterToGroupsIds(arr);
            }
        }

        setPrinterGroupIds(ids);
    };

    const updatePrinterName = async (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            const currentName = printer.getName();
            const newName = printerName;

            if (isEqual(currentName, newName)) {
                return resolve();
            }

            await renamePrinter(printerId, newName)
                .then(() => resolve())
                .catch((error) => {
                    setErrorOccurred(true);
                    Notifier.error(error);
                    return reject();
                });
        });
    };

    const onSaveClick = async () => {
        await updatePrinterName();
        await setPrinterGroups();

        if (!errorOccured) {
            Notifier.success("Changes successfully saved");

            onClose();
            onUpdate();
        }
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (isOpen) {
            setErrorOccurred(false);

            if (!initialStateIsSet) {
                setSubmitDisabled(true);
                setPrinterName(printer.getName());

                if (!isEqual(printerGroupsInitial, printerGroupIds)) {
                    setPrinterGroupIds(printerGroupsInitial);
                }

                if (!loading) {
                    setInitialStateIsSet(true);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printer, isOpen, printerGroupsInitial]);

    return (
        <StyledModal
            basic
            open={isOpen}
            onClose={() => {
                onClose();
                setInitialStateIsSet(false);
            }}
            maxheight={"calc(100% - 50px)"}
        >
            <StyledModalHeader>
                <h3>{t("printer_modal.header")}</h3>
                <StyledCloseButton
                    className={`qa-printerGroups-PrinterModal-cancel`}
                    name="remove"
                    onClick={() => {
                        onClose();
                        setInitialStateIsSet(false);
                    }}
                />
            </StyledModalHeader>
            <StyledModalContent>
                <Grid>
                    <StyledGridRow>
                        <GridColumn width={3}>
                            <PrinterImage
                                src={
                                    getPrinterByName(printer.getModelName())?.imageUri ||
                                    DefaultPrinterImage
                                }
                            />
                        </GridColumn>
                        <GridColumn width={13}>
                            <StyledGridRow>
                                <StyledLabel labelFontSize={labelSizeHeader}>
                                    {printer.getName()}
                                    <PrinterStatusLabel
                                        printer={printer}
                                        style={PrinterStatusLabelStyle.Circle}
                                    />
                                </StyledLabel>
                                <p>{printer.getModelName()}</p>
                            </StyledGridRow>
                            <StyledGridRow>
                                <StyledLabel labelFontSize={labelSize}>
                                    {t("printer_modal.serial")}
                                </StyledLabel>
                                <p>{printer.getSerial()}</p>
                            </StyledGridRow>
                            <StyledGridRow>
                                <StyledLabel labelFontSize={labelSize}>
                                    {t("printer_modal.printer_name")}
                                </StyledLabel>
                                <Input
                                    className={`qa-printerDetails-PrinterGroupModal-name-input`}
                                    value={printerName}
                                    onChange={(event) => {
                                        let inputValue = event.currentTarget.value;
                                        const limit = 30;
                                        inputValue = inputValue.slice(0, limit);
                                        setPrinterName(inputValue);
                                        setSubmitDisabled(false);
                                    }}
                                />
                            </StyledGridRow>
                        </GridColumn>
                    </StyledGridRow>
                    <StyledGridRow>
                        <GridColumn>
                            <PrinterGroupMultiSelect
                                ids={printerGroupIds}
                                onChange={(ids) => {
                                    if (printerName !== "") {
                                        setSubmitDisabled(false);
                                    }
                                    void updatePrinterGroups(ids);
                                }}
                                labelFontSize={labelSize}
                            />
                        </GridColumn>
                    </StyledGridRow>
                    <StyledGridRow>
                        <GridColumn>
                            <StyledLabel labelFontSize={labelSize}>
                                {t("printer_modal.user_groups")}
                            </StyledLabel>
                            {renderTextBubble(
                                accessData?.UserGroups,
                                (id: number) => history.push(`/user_group/${id}`),
                                "external square alternate"
                            )}
                        </GridColumn>
                    </StyledGridRow>
                </Grid>
            </StyledModalContent>
            <StyledModalActions>
                <Button
                    className="ui button primary qa-printerGroups-PrinterModal-save"
                    disabled={submitDisabled}
                    onClick={async () => {
                        await onSaveClick();
                    }}
                >
                    {t("forms.save")}
                </Button>
            </StyledModalActions>
        </StyledModal>
    );
};

const StyledBubbleContainer = styled.div`
    border-radius: 4px;
    padding: 0 2.1em 0 7px;
    max-height: 150px;
    overflow-y: scroll;
    margin-bottom: 10px;
    border: 1px solid rgba(34, 36, 38, 0.15);
`;

const StyledBubble = styled.a`
    user-select: none;
    vertical-align: top;
    white-space: normal;
    font-size: 1em;
    padding: 0.35714286em 0.78571429em;
    margin: 0.14285714rem 0.28571429rem 0.14285714rem 0;
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    background-color: #e8e8e8;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    font-weight: 700;
    border: 0 solid transparent;
    border-radius: 0.28571429rem;
    transition: background 0.1s ease;
    cursor: default;
    i {
        margin-left: 5px;
        cursor: pointer;
        opacity: 0.5;
        :hover {
            opacity: 1;
        }
    }
    :hover {
        background-color: #e0e0e0;
        border-color: #e0e0e0;
        background-image: none;
        color: rgba(0, 0, 0, 0.8);
    }
    .active {
        background-color: #c8c8c8;
        border-color: #c8c8c8;
        background-image: none;
        color: rgba(0, 0, 0, 0.95);
    }
`;

export const StyledModalActions = styled(StyledModal.Actions)`
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: ${(props) => props?.justifycontent ?? "space-between !important"};
    width: 100%;
    height: 100%;
    border-radius: 0 0 10px 10px;
    flex-direction: row-reverse;
`;

const renderTextBubble = (
    group: { id: number; name: string }[] | undefined,
    onClick: (id: number) => void,
    icon?: string
) => {
    return !!group && group.length > 0 ? (
        <StyledBubbleContainer>
            {group.map((e, i) => (
                <StyledBubble key={i}>
                    {e.name}
                    {icon && <i className={`${icon} icon`} onClick={() => onClick(e.id)} />}
                </StyledBubble>
            ))}
        </StyledBubbleContainer>
    ) : (
        <div>-</div>
    );
};
