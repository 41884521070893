import { FunctionComponent, useContext } from "react";
import { Dropdown } from "grabcad-ui-elements";
import { DropdownProps, DropdownItemProps } from "semantic-ui-react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { gatewayRegions } from "../../utils/gatewayRegions";

export interface GatewayRegionSelectorProps {
    id: string;
    disabled: boolean;
    selectedRegion: string | undefined;
    onChange(event: React.SyntheticEvent<HTMLElement>, data: string): void;
}

export const GatewayRegionSelector: FunctionComponent<GatewayRegionSelectorProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    const dropdownProps = {
        id: props.id,
        role: "region-Selector",
        className: "qa-role-selector",
        disabled: props.disabled,
        placeholder: t("gateway_modal.placeholder"),
        options: gatewayRegions,
        fluid: true,
        multiple: false,
        selection: true,
        onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
            props.onChange(event, data.value as string);
        },
        renderLabel: (item: DropdownItemProps) => item.label,
        value: props.selectedRegion,
    };
    return (
        <>
            <label>
                {t("gateway_modal.Region")}
                <span style={{ color: "red" }}>&nbsp;*</span>
            </label>
            <Dropdown {...dropdownProps} />
        </>
    );
};
