import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { LicenseData } from "../..";

type ShopLicensesResult = {
    getActiveShopCompanyLicenses: {
        totalCount: number;
        licenses: Pick<LicenseData, "id" | "partNumber">[];
    };
};

const useActiveShopCompanyLicenses = () => {
    // GC-94066: This should be replaced with useCompanyLicensesQuery & hasEnabledApplication.
    // Having a single-product license query is odd. It would also be a better UX to ignore the
    // 'active' filter for this query so that companies with expired licenses can still link
    // to Shop and see renew-license-CTA.
    const GET_ACTIVE_SHOP_COMPANY_LICENSE = gql`
        query getActiveShopCompanyLicenses($companyId: Int) {
            getActiveShopCompanyLicenses(companyId: $companyId) {
                licenses {
                    id
                    partNumber
                }
                totalCount
            }
        }
    `;

    return useQuery<ShopLicensesResult>(GET_ACTIVE_SHOP_COMPANY_LICENSE);
};

export default useActiveShopCompanyLicenses;
