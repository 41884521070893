import { FunctionComponent, useContext } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbSection, BreadcrumbDivider } from "grabcad-ui-elements";
import { ApplicationContext } from "../../components/ApplicationProvider";

interface IBreadcrumbsProps {
    sections?: { label: string; to?: string }[];
}

export const Breadcrumbs: FunctionComponent<IBreadcrumbsProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    return (
        <Breadcrumb id="qa-breadcrumb" style={{ marginBottom: 10 }}>
            <BreadcrumbSection
                id="qa-breadcrumb-section_home"
                className="qa-breadcrumb-section"
                as={Link}
                to={"/"}
                key={"home"}
            >
                {t("breadcrumbs.admin_home")}
            </BreadcrumbSection>

            {props.sections?.map((section, index) => (
                <span key={index}>
                    <BreadcrumbDivider />
                    <BreadcrumbSection
                        id={`qa-breadcrumb-section_${section.label}`}
                        className="qa-breadcrumb-section"
                        as={section.to && Link}
                        to={section.to}
                    >
                        {section.label}
                    </BreadcrumbSection>
                </span>
            ))}
        </Breadcrumb>
    );
};
