import React, { FunctionComponent } from "react";
import { Query } from "@apollo/client/react/components";
import { QueryOptions, QueryResult, ApolloError } from "@apollo/client";
import { Dropdown } from "grabcad-ui-elements";
import { Redirect } from "react-router-dom";
import { Notifier } from "../../view/Notifier";
import { alphabetizeByProp } from "../../utils/sort";
import classnames from "classnames";
import { Properties } from "csstype";
import {
    ListProductsQueryResult,
    LIST_PRODUCTS,
} from "../../graphql/Queries/Products/ListProducts";

export interface ProductsQueryProps extends Omit<QueryOptions, "query"> {
    children: (result: QueryResult<ListProductsQueryResult>) => JSX.Element;
    displayName?: string;
    skip?: boolean;
    onCompleted?: (data: ListProductsQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class ProductsQuery extends React.Component<ProductsQueryProps> {
    render() {
        const { children, ...props } = this.props;

        return (
            <Query<ListProductsQueryResult> query={LIST_PRODUCTS} fetchPolicy="no-cache" {...props}>
                {children}
            </Query>
        );
    }
}

interface IProductDropdown {
    productId: number | undefined;
    onChange: (id: number) => void;
    disabled?: boolean;
    style?: Properties<string | number>;
    invalid: boolean;
}

export const ProductDropdown: FunctionComponent<IProductDropdown> = (props) => {
    return (
        <ProductsQuery>
            {({ loading, error, data }) => {
                if (loading) {
                    return <div className="ui loader active" />;
                }
                if (error) {
                    Notifier.error(error);
                    return <Redirect to="/license_package" />;
                }
                const products = data?.listProducts?.products
                    ? data.listProducts.products
                          .slice()
                          .sort(alphabetizeByProp("name"))
                          .map((product) => ({
                              key: product.id,
                              text: product.name,
                              value: product.id,
                              disabled: product.disabled,
                          }))
                    : [];
                const dropDownProps = {
                    id: "qa-products",
                    disabled: !products.length || props.disabled,
                    placeholder: !products.length ? "No products defined" : "Select product",
                    defaultValue: props.productId,
                    options: products,
                    style: props.style,
                    fluid: true,
                    selection: true,
                };

                return (
                    <>
                        <label>
                            Product Name<span style={{ color: "red" }}>&nbsp;*</span>
                        </label>
                        <Dropdown
                            {...dropDownProps}
                            className={classnames({ error: props.invalid })}
                            onChange={(e, newData) => {
                                props.onChange(newData.value as number);
                            }}
                        />
                        <p style={{ fontStyle: "italic", fontSize: 12, marginTop: 2 }}>
                            Note: Multiple License Packages can share the same Product Name
                        </p>
                        {props.invalid && (
                            <p style={{ marginRight: 30 }} className="ui negative message">
                                {"This field is required."}
                            </p>
                        )}
                    </>
                );
            }}
        </ProductsQuery>
    );
};
