import gql from "graphql-tag";

export const ProductFragments = {
    productFields: gql`
        fragment productFields on Product {
            id
            name
            disabled
        }
    `,
};
