import gql from "graphql-tag";

export const CompanyFragments = {
    adminEditableFields: gql`
        fragment adminEditableFields on Company {
            name
            analyzeServerAddress
            admins {
                id
                email
            }
            settings {
                timezone
                locale
            }
        }
    `,
    operatingSchedule: gql`
        fragment operatingSchedule on Company {
            operatingSchedule {
                enabled
                days {
                    monday {
                        enabled
                        periods {
                            start
                            end
                        }
                    }
                    tuesday {
                        enabled
                        periods {
                            start
                            end
                        }
                    }
                    wednesday {
                        enabled
                        periods {
                            start
                            end
                        }
                    }
                    thursday {
                        enabled
                        periods {
                            start
                            end
                        }
                    }
                    friday {
                        enabled
                        periods {
                            start
                            end
                        }
                    }
                    saturday {
                        enabled
                        periods {
                            start
                            end
                        }
                    }
                    sunday {
                        enabled
                        periods {
                            start
                            end
                        }
                    }
                }
            }
        }
    `,
};
