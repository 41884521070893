import styled from "grabcad-ui-elements";

export const Placeholder = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    img,
    p {
        margin: 0 auto;
    }
    img {
        height: 202px;
    }
    p {
        max-width: 300px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 18px;
    }
    p,
    h2 {
        color: #666666;
    }
`;

export const PlaceholderWrapper = styled.div`
    position: relative;
    // Todo: Refactor parent in Screen.tsx to avoid hard-coded pixel height
    height: calc(100vh - 100px);
`;
