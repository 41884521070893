import { addLocaleData, IntlProvider } from "react-intl";
import locale_de from "react-intl/locale-data/de";
import locale_en from "react-intl/locale-data/en";
import locale_es from "react-intl/locale-data/es";
import locale_fr from "react-intl/locale-data/fr";
import locale_it from "react-intl/locale-data/it";
import locale_ja from "react-intl/locale-data/ja";
import locale_ko from "react-intl/locale-data/ko";
import locale_ru from "react-intl/locale-data/ru";
import locale_zh from "react-intl/locale-data/zh";
import { isLanguageSupported, translations } from "./Translations";
import { flatten } from "flat";
import { assign } from "lodash";
import moment from "moment";

// https://create-react-app.dev/docs/troubleshooting/#momentjs-locales-are-missing
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/ja";
import "moment/locale/ko";
import "moment/locale/ru";
import "moment/locale/zh-cn";
import "moment/locale/zh-tw";

// When adding a new language in data.json you need also to:
// 1) import it in this file: e.g. "import locale_it from 'react-intl/locale-data/it';"
// 2) add to ReactIntl locales array: e.g. addLocaleData([...locale_en, ...locale_it]);
// 3) import the locale for moment

addLocaleData([
    ...locale_de,
    ...locale_en,
    ...locale_es,
    ...locale_en,
    ...locale_fr,
    ...locale_it,
    ...locale_ja,
    ...locale_ko,
    ...locale_ru,
    ...locale_zh,
]);

const extractIso6391LanguageCode = (locale: string) => {
    return locale.toLowerCase().split(/[_-]+/)[0];
};

// A locale, aka IETF language tag, is a combination of language and region: e.g. en-US
let appLocale: string | undefined;

export const browser = {
    // For automated tests only
    userPreferredLocales: () => {
        // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/languages
        return navigator.languages;
    },
};

export const clearApplicationLocale = (): void => {
    // For automated tests only
    appLocale = undefined;
};

export const applicationLocale = (): string => {
    if (!appLocale) {
        // Only USA and some other small islands use the month before day format:
        // https://en.wikipedia.org/wiki/Date_format_by_country
        // So for all other countries we default to English (United Kingdom)
        // to have the dates formatted properly to dd/mm/yyyy.
        appLocale = "en-gb";
        const locales = browser.userPreferredLocales();
        for (const navLocale of locales) {
            const languageCode = extractIso6391LanguageCode(navLocale);
            if (isLanguageSupported(languageCode)) {
                appLocale = navLocale;
                break;
            }
        }
    }
    return appLocale;
};

export const i18n = (node: JSX.Element | null) => {
    const locale = applicationLocale();
    let languageCode = extractIso6391LanguageCode(locale);
    const traditionalChineseTags = ["zh-TW", "zh-Hant-HK", "zh-Hant-MO", "zh-Hant-TW", "zh-Hant"];
    if (traditionalChineseTags.includes(locale)) {
        languageCode = "zh-TW"; // This is traditional Chinese.
        // All other Chinese lang codes can remain 'zh', which maps to simplified: 'zh-CH'
    }
    const english = flatten(translations.en);
    const localized = flatten(translations[languageCode]);
    const messages: { [key: string]: string } = assign({}, english, localized);
    // Replace template variables with product names
    // GC-67673: Reuse code from Print when replacing the DNT list
    Object.entries(messages).forEach((value: string[]) => {
        value[1] = value[1]
            .replace(/{auto_grabcad}/g, "GrabCAD")
            .replace(/{auto_shop}/g, "Shop")
            .replace(/{auto_shops}/g, "Shops")
            .replace(/{auto_GC_control}/g, "GrabCAD Control")
            .replace(/{auto_GC_shop}/g, "GrabCAD Shop")
            .replace(/{auto_GC_analyze}/g, "GrabCAD Analyze")
            .replace(/{auto_stratasys}/g, "Stratasys")
            .replace(/{auto_GC_hyperlink_color}/g, "#003393")
            .replace(/{auto_GC_analyze_server}/g, "Analyze Server")
            .replace(/{auto_GC_analyze_agent}/g, "Analyze Agent")
            .replace(/{auto_GC_analyze_short}/g, "Analyze")
            .replace(/{auto_system_windows}/g, "Windows");
        messages[value[0]] = value[1];
    });

    moment.locale(applicationLocale());

    return (
        <IntlProvider key={locale} locale={locale} messages={messages}>
            {node}
        </IntlProvider>
    );
};
