import gql from "graphql-tag";
import { FeatureData } from "../../types";
import { FeatureFragments } from "../../Fragments/Feature";

export const LIST_FEATURES = gql`
    query listFeatures {
        listFeatures {
            features {
                ...featureFields
            }
        }
    }
    ${FeatureFragments.featureFields}
`;

export const LIST_FEATURES_WITH_PRODUCTS = gql`
    query listFeatures {
        listFeatures {
            features {
                ...featureFields
                products {
                    id
                }
            }
        }
    }
    ${FeatureFragments.featureFields}
`;

export interface ListFeaturesQueryResult {
    listFeatures: {
        features: FeatureData[];
    };
}
