import gql from "graphql-tag";

export const PRINTER_GROUPS = gql`
    query printerGroups {
        printerGroups {
            id
            name
            printerIds
        }
    }
`;
