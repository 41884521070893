import { ReactNode, FunctionComponent, createContext } from "react";
import { RoleInfo } from "../../graphql";
import { Route } from "react-router-dom";
import { PageNotFound } from "../../screens/PageNotFound";
import { UserRolesQuery, UserRolesQueryResult } from "../../graphql/Queries/UserRoles";
import { Role } from "../../graphql/types";

const defaultRoleInfo: RoleInfo = {
    email: "",
    name: "",
    role: Role.User,
    companyId: 0,
    urlPrefix: "",
};

export const UserContext = createContext<RoleInfo>(defaultRoleInfo);

function getRole(userRoles: UserRolesQueryResult["userRoles"]) {
    if (userRoles.isCompanyAdmin) {
        return Role.CompanyAdmin;
    }
    if (userRoles.isGlobalAdmin) {
        return Role.GlobalAdmin;
    }
    return Role.User; // This isn't really a role... more of an error state
}

// TODO: we are transforming the user roles data into RoleInfo, at some point just remove RoleInfo from app altogether
export function transformRoleData(data: UserRolesQueryResult): RoleInfo {
    const userRoles = data.userRoles ?? {};

    return {
        email: userRoles.user?.email,
        name: userRoles.user?.name,
        role: getRole(userRoles),
        companyId: userRoles.company?.id,
        urlPrefix: userRoles.company?.urlPrefix, // KILLME GC-93997: This is pure tech-debt
    };
}

export const UserProvider: FunctionComponent<{ children: ReactNode }> = (props) => {
    return (
        <UserRolesQuery>
            {({ loading, error, data }) => {
                if (loading) {
                    return null;
                }
                if (error || !data) {
                    return <Route component={PageNotFound} />;
                }
                return (
                    <UserContext.Provider value={{ ...transformRoleData(data) }}>
                        {props.children}
                    </UserContext.Provider>
                );
            }}
        </UserRolesQuery>
    );
};
