import { Component, FunctionComponent } from "react";
import { Link, match, Redirect } from "react-router-dom";
import { PureQueryOptions } from "@apollo/client";
import { IScreenProps } from "../Screen";
import { PaginationLinks } from "../../view/Navigation/PaginationLinks";
import { Button } from "grabcad-ui-elements";
import { alphabetizeByProp } from "../../utils/sort";
import { Notifier } from "../../view/Notifier";
import { ListClientsQuery, ClientData } from "../../graphql";
import { DeleteClientMutation } from "../../graphql/Mutations/Clients";
import { Modal, Header } from "semantic-ui-react";

const CLIENTS_PER_PAGE = 20;

interface IClientListProps extends IScreenProps {
    match: match<{ id?: string }>;
}
interface IClientListState {
    clientToDelete?: ClientData;
    page: number;
}

interface IPaginationLinksProps {
    entity: { id: number; name: string };
    open: boolean;
    id?: number;
    onClose: () => void;
    type: string;
    update: () => void;
    refetchQueries?: (string | PureQueryOptions)[];
}

export const DeleteClientModal: FunctionComponent<IPaginationLinksProps> = (props) => (
    <DeleteClientMutation
        update={props.update}
        refetchQueries={props.refetchQueries}
        type={props.type}
        onError={(error) => Notifier.error(error)}
    >
        {(mutateFn) => (
            <Modal basic size="small" open={props.open} onClose={props.onClose}>
                <Header icon="trash" content={`Delete the following ${props.type}?`} />
                <Modal.Content>
                    <h3>{props.entity.name}</h3>
                    <p>{"This cannot be easily undone!"}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color="red" inverted onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        color="green"
                        inverted
                        onClick={() => {
                            mutateFn({
                                variables: {
                                    id: props.entity.id,
                                },
                            }).catch(Notifier.error);
                            props.onClose();
                        }}
                    >
                        Delete
                    </Button>
                </Modal.Actions>
            </Modal>
        )}
    </DeleteClientMutation>
);
export class ClientList extends Component<IClientListProps, IClientListState> {
    constructor(props: IClientListProps, context: []) {
        super(props, context);
        this.state = {
            page: 1,
        };
    }

    render() {
        return (
            <ListClientsQuery>
                {({ loading, error, data, refetch }) => {
                    if (error) {
                        Notifier.error(error);
                        return <Redirect to="/" />;
                    }
                    if (loading || !data) {
                        return <div className="ui loader active" />;
                    }

                    let clients = [...data.listClients.clients];
                    let clientCount = clients.length;
                    let filteredClientCount = clients.length;

                    clients.sort(alphabetizeByProp("name"));
                    clients = clients.slice(
                        (this.state.page - 1) * CLIENTS_PER_PAGE,
                        this.state.page * CLIENTS_PER_PAGE
                    );

                    return (
                        <div>
                            <Button
                                id="qa-clientList-newClient"
                                className="ui button primary right floated"
                                type="submit"
                                onClick={() => this.props.history?.push("client/new")}
                            >
                                New Client
                            </Button>
                            <h2 className="page-header">
                                Clients (
                                {filteredClientCount !== clientCount
                                    ? `${filteredClientCount} of ${clientCount}`
                                    : clientCount}
                                )
                            </h2>

                            <table className="ui table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Client Id</th>
                                        <th>Client Secret</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clients.map((client: ClientData) => (
                                        <tr key={client.id}>
                                            <td data-label="Name">{client.name}</td>
                                            <td data-label="Description">{client.description}</td>
                                            <td
                                                data-label="clientId"
                                                style={{ wordBreak: "break-word" }}
                                            >
                                                {client.clientId}
                                            </td>
                                            <td
                                                data-label="clientSecret"
                                                style={{ wordBreak: "break-word" }}
                                            >
                                                {client.clientSecret}
                                            </td>
                                            <td data-label="Actions">
                                                <Link
                                                    id={`qa-clientList-edit_${client.id}`}
                                                    className="qa-clientList-edit"
                                                    to={`/client/${client.id}`}
                                                >
                                                    edit
                                                </Link>
                                                &nbsp;|&nbsp;
                                                <a
                                                    id={`qa-clientList-delete_${client.id}`}
                                                    className="qa-clientList-delete"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() =>
                                                        this.setState({
                                                            clientToDelete: client,
                                                        })
                                                    }
                                                >
                                                    delete
                                                </a>
                                                <DeleteClientModal
                                                    entity={client}
                                                    update={refetch}
                                                    type="Client"
                                                    open={
                                                        !!this.state.clientToDelete &&
                                                        this.state.clientToDelete.id === client.id
                                                    }
                                                    id={this.state.clientToDelete?.id}
                                                    onClose={() =>
                                                        this.setState({
                                                            clientToDelete: undefined,
                                                        })
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={5}>
                                            <PaginationLinks
                                                total={filteredClientCount}
                                                perPage={CLIENTS_PER_PAGE}
                                                currentPage={this.state.page}
                                                goToPage={(page) => this.setState({ page })}
                                            />
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    );
                }}
            </ListClientsQuery>
        );
    }
}
